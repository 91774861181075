import React from 'react';

import styled from '@emotion/styled';

import { Colors } from '@/shared/styles';

export const UserInformationContainer = styled.div<React.CSSProperties>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;

  .contour-line {
    margin-top: 10px;
    width: 100%;
    height: 2px;
    flex-shrink: 0;
    opacity: 0.1;
    background: #fff;
  }

  .information-title {
    margin-bottom: 40px;
    font-weight: 600;
    font-size: 36px;
    line-height: 46px;
    color: #272a2d;
  }

  .info-titl {
    margin-bottom: 34px;
    color: #fff;
    font-feature-settings: 'liga' off, 'clig' off;

    /* Label 3 - Medium, 12, 18 */
    font-family: pretendard, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
    opacity: 0.8;
  }

  .info-contents {
    margin-bottom: 50px;
    color: #fff;
    font-feature-settings: 'liga' off, 'clig' off;

    /* Body 2 - Regular, 14, 20 */
    font-family: pretendard, sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
  .information {
    display: flex;
    margin-bottom: 120px;
    > div {
      div:first-of-type {
        margin-bottom: 12px;
      }
    }
    > :first-of-type {
      margin-right: 40px;
      > div {
        color: ${Colors.gray6};
        font-weight: 400;
        font-size: 28px;
        line-height: 36px;
      }
    }
    > :not(:first-of-type) {
      > :first-of-type {
        font-weight: 600;
      }
      > div {
        font-weight: 400;
        font-size: 28px;
        line-height: 36px;
        color: ${Colors.gray8};
      }
    }
  }
`;
