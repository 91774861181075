import React from 'react';

import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

function Index() {
  return <div></div>;
}

const Layout = styled.div`
  padding: 80px 32px 32px 0;
`;

const Header = styled.header`
  ${Typography.title_44}
  color: ${Colors.gray9}
`;

export default Index;
