import React from 'react';

import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

interface ISave extends React.CSSProperties {
  isSave?: boolean;
}

export const ChooseSubscriptionContainer = styled.div<React.CSSProperties>`
  padding-inline: 32px;
  padding-top: 80px;

  .radio-group {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 64px;
  }

  .radio-custom {
    border-radius: 3px;
    /*border: 1px solid var(--green-300, #E8FF68);*/
    background: rgba(255, 255, 255, 0.1);
    /*
    background: ${Colors.white};
    border: 2px solid ${Colors.gray3};
    border-radius: 4px;
    */

    label {
      align-items: flex-start;
      gap: 20px;
      padding: 24px 32px 25px;
    }
  }
`;

export const Title = styled.h1`
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 56px;
`;

export const SubscriptionContent = styled.section`
  flex: 1;

  h5 {
    font-feature-settings: 'liga' off, 'clig' off;

    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 44px; /* 137.5% */
    padding-bottom: 8px;
  }
  div {
    ${Typography.small_1}
    opacity: 0.8;
    line-height: 36px;
  }
`;
export const Saving = styled.section<ISave>`
  display: flex;
  padding: 12px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  background: var(--secondary-500, #ddc5a4);
  color: var(--gray-900, #14120e);
  text-align: center;

  /* Body 3 - Regular, 12, 18 */
  font-family: 'Pretendard Variable';
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */

  //  ${Typography.small_b}
  //  color: ${(props) => (props.isSave ? Colors.red2 : Colors.gray6)};
  //  align-self: flex-start;
`;
