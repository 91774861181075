import React, { useId, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Close } from '@/assets/img/icon/Close.svg';
import { ReactComponent as InputClear } from '@/assets/img/icon/input_clear.svg';
import Banner from '@/assets/img/membership/express.png';
import Button from '@/components/Button/Button';
import CheckBox from '@/components/CheckBox/CheckBox';
import Input from '@/components/Input/Input';
import Modal from '@/components/Modal/Modal';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import RadioGroup from '@/components/RadioGroup/RadioGroup';
import ToastPortal from '@/components/ToastPortal/ToastPortal';
import { HomeContainer } from '@/shared/styles/pages';
import { useModalStore } from '@/stores/common/useModalStore';

const RADIO_CONTENTS = [
  {
    id: 1,
    value: '1',
    render: () => <div>라디오버튼입니다1</div>,
  },
  {
    id: 2,
    value: '2',
    render: () => <div>라디오버튼입니다2</div>,
  },
  {
    id: 3,
    value: '3',
    render: () => <div>라디오버튼입니다3</div>,
    disabled: true,
  },
];

const Home = (): React.ReactElement => {
  const { setMenuModal, setToastModal, setAlertModal } = useModalStore();
  const alertModalKey = useId();
  const menuModalKey = useId();
  const toastModalKey = useId();
  const naivgate = useNavigate();
  const [selected, setSelected] = useState('1');

  return (
    <>
      <HomeContainer>
        <button onClick={() => setAlertModal({ visible: true, key: alertModalKey })}>
          얼럿모달 오픈
          <br />
          (normal 버전은 버튼을 하나만 넣으시면 자동으로 normal 버전으로 정렬이 됩니다.)
        </button>
        <button onClick={() => setMenuModal({ visible: true, key: menuModalKey })}>메뉴모달 오픈 ("익스프레스 예약 진입" 메뉴모달 마크업과 동일하므로 사용하세요)</button>
        <button onClick={() => setToastModal({ visible: true, key: toastModalKey })}>토스트모달 오픈</button>
        <div onClick={() => naivgate('/membership/entry', { state: { prevPath: location.pathname } })}>멤버십 가입 후 진입페이지로 이동하는지 테스트하는 버튼</div>
      </HomeContainer>
      <ModalPortal>
        <Modal.Alert key={alertModalKey} modal="alert" isDim={true} isAnimation={true}>
          <section className="content-wrapper">
            <h2 className="title">공유하기</h2>
            <p className="desc">예약 내역을 다른 사람에게 공유하시겠습니까?</p>
          </section>
          <section className="button-wrapper">
            <button onClick={() => setAlertModal({ visible: false, key: alertModalKey })}>아니요</button>
            <button
              onClick={() => {
                setAlertModal({ visible: false, key: alertModalKey });
              }}
            >
              텍스트 복사
            </button>
          </section>
        </Modal.Alert>
        <Modal.Menu key={menuModalKey} modal="menu" isDim={true} isAnimation={true}>
          <section className="header-wrapper">
            <h2 className="title">
              에그회원은 <b>7일</b> 후에 <br />
              예약할 수 있는 날짜예요.
            </h2>
            <Close onClick={() => setMenuModal({ visible: false, key: menuModalKey })} />
          </section>
          <section className="content-wrapper">
            지금 예약하고 싶으신 분~?
            <br />
            욜크 멤버십 둘러보고 가세요!<img src={Banner} alt="익스프레스 이미지"></img>
          </section>
          <section className="button-wrapper">
            <Button colorLevel="secondary" type="sticky">
              다른 날짜 선택
            </Button>
            <Button colorLevel="primary" type="sticky">
              멤버십 가입
            </Button>
          </section>
        </Modal.Menu>
      </ModalPortal>
      <ToastPortal>
        <Modal.Toast key={toastModalKey} toastKey={toastModalKey} modal="toast" autoHideDuration={20000} isAnimation={true} position="bottom">
          <div>dsdsdsds</div>
        </Modal.Toast>
      </ToastPortal>
      <CheckBox>
        <div>큰 네모네모 체크박스 defalut (checkType 설정 안해도 됩니다)</div>
      </CheckBox>
      <CheckBox checkType="small">
        <div>스몰 네모네모 체크박스</div>
      </CheckBox>
      <CheckBox checkType="empty">
        <div>안 네모네모 비어있는 체크박스</div>
      </CheckBox>
      <RadioGroup selected={selected} name="components" className="radio-group" items={RADIO_CONTENTS} onChange={(e) => setSelected(e.target.value)} />
      <Input type="text" label="레이블" placeholder="내용입력" clearicon={<InputClear />} />
      <Input type="text" label="레이블" placeholder="내용입력" clearicon={<InputClear />} required />
      <Input type="text" label="레이블" placeholder="내용입력" error="error!" clearicon={<InputClear />} required />
      <br />
      <br />
      <Button colorLevel="primary" type="sticky">
        1234
      </Button>
      <Button colorLevel="primary" type="extra-small">
        1234
      </Button>
      <Button colorLevel="primary" type="large">
        1234
      </Button>
      <Button colorLevel="primary" type="extra-large" disabled={true}>
        1234
      </Button>
      <Button colorLevel="primary" type="medium">
        1234
      </Button>
      <Button colorLevel="primary" type="small">
        1234
      </Button>
      <Button colorLevel="secondary" type="sticky">
        1234
      </Button>
      <Button colorLevel="secondary" type="extra-small" isActive={true}>
        1234
      </Button>
      <Button colorLevel="secondary" type="large" disabled={true}>
        1234
      </Button>
      <Button colorLevel="secondary" type="medium">
        1234
      </Button>
    </>
  );
};

export default Home;
