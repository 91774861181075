import React from 'react';

import styled from '@emotion/styled';

import Arrow from '@/assets/img/icon/chevron_right.svg';
import { Colors, Typography } from '@/shared/styles';

export const MenuListContainer = styled.section<React.CSSProperties>`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-left: 0px !important;
  padding-right: 0px !important;
`;

export const MenuListTitle = styled.div<React.CSSProperties>`
  display: flex;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
  ${Typography.title_36_sb};
  color: ${Colors.white};
  padding: 0 32px;
  & > label {
    ${Typography.body_28};
    color: ${Colors.gray8};
    background: ${Colors.gray2};
    border-radius: 23px;
    margin-right: auto;
    padding: 2px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 46px;
    min-height: 40px;
  }
  & > span {
    ${Typography.small_1};
    color: ${Colors.white};
    opacity: 70%;
    cursor: pointer;
    &::after {
      content: '';
      background: url(${Arrow}) no-repeat center 9px;
      display: inline-block;
      width: 24px;
      height: 29px;
      margin-left: 10px;
    }
  }
`;
