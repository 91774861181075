import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { IGetRaffleData } from '../Raffle.types';
import { IRaffleUserCheck } from './Infomation.types';

import { useUserMembership } from '@/components/ManageMembership/ManageMembership.hooks';
import { eggdiningApi } from '@/shared/apis/eggdiningApi';
import RAFFLE_QUERY_KEYS from '@/shared/apis/queryKeys/raffle';
import { useFooterStore } from '@/stores/common/useFooterStore';
import { useHeaderStore } from '@/stores/common/useHeaderStore';
import { useNavigationStore } from '@/stores/common/useNavigationStore';

const useGetRaffleDataQuery = (eventType) => {
  return useQuery(useGetRaffleDataQuery.getKeys(), () => useGetRaffleDataQuery.getRaffleData(eventType), {});
};

useGetRaffleDataQuery.getKeys = () => RAFFLE_QUERY_KEYS.GET_RAFFLE_DATA;
useGetRaffleDataQuery.getRaffleData = (eventType) => {
  return eggdiningApi.get<IGetRaffleData>(`/eggdining/raffles/${eventType}`);
};

const useRaffleInfoInitHooks = (eventType) => {
  const navigate = useNavigate();
  const setIsNavigationVisible = useNavigationStore((store) => store.setIsNavigationVisible);
  const setIsFooterVisible = useFooterStore((store) => store.setIsFooterVisible);
  const setHeaderState = useHeaderStore((store) => store.setHeaderState);

  const { data, isLoading } = useGetRaffleDataQuery(eventType);

  useEffect(() => {
    setIsNavigationVisible(false);
    setIsFooterVisible(false);
    setHeaderState({
      title: data?.raffle?.title,
      handleGoToBack: null,
      handleGoToHome: null,
      handleOnAlarm: null,
      handleOnClose: () => navigate(-1),
      handleOnShare: null,
    });
  }, [isLoading]);
};

const useRafflesEntryCheckQuery = (eventType) => {
  const navigate = useNavigate();
  return useQuery(useRafflesEntryCheckQuery.getKeys(), () => useRafflesEntryCheckQuery.getRaffleCheck(eventType), {
    onError(err) {
      alert('잘못된 요청 입니다.');
      navigate('/main');
    },
  });
};

useRafflesEntryCheckQuery.getKeys = () => RAFFLE_QUERY_KEYS.GET_RAFFLE_ENTRY_CHECK;
useRafflesEntryCheckQuery.getRaffleCheck = (eventType) => {
  return eggdiningApi.get<IRaffleUserCheck>(`/eggdining/raffles/${eventType}/entry/check`);
};

const useOnClickCehckvisitEvent = ({ setAlertModal, failUserMembershipCheck, eventType, optionsParams }) => {
  const { data: userMembership, isLoading: userMembershipLoading } = useUserMembership();
  const { data: entryCheck, isLoading: checkLoading } = useRafflesEntryCheckQuery(eventType);
  const navigate = useNavigate();

  const checkedMembership = () => {
    if (userMembership?.membership?.membershipGrade !== '02') {
      setAlertModal({ visible: true, key: failUserMembershipCheck });
      return;
    }
    return entryCheck.isEntry
      ? navigate(`/raffle-reservation/complete?eventType=${eventType}&options=${optionsParams}`)
      : navigate(`/raffle-reservation/apply?eventType=${eventType}&options=${optionsParams}`);
  };
  const checkEventChageClickEvent = (state) => {
    switch (state) {
      case '00':
        return navigate(-1);
      case '01':
        return checkedMembership();
      case '02':
        return navigate(-1);
      case '03':
        return navigate(-1);
      case '04':
        return navigate(-1);
      case '05':
        return navigate(-1);
    }
    return navigate(-1);
  };
  return { checkEventChageClickEvent };
};

export { useRaffleInfoInitHooks, useGetRaffleDataQuery, useRafflesEntryCheckQuery, useOnClickCehckvisitEvent };
