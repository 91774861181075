import React from 'react';

import styled from '@emotion/styled';

export const MembershipcancelSuccessContainer = styled.div<React.CSSProperties>`
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 80px 32px 0;

  .cancel-success-container {
    width: 100%;
    height: 65vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    > h4 {
      color: #fff;
      margin-top: 12px;
      color: #fff;
      text-align: center;

      /* Headline 2 - SemiBold, 24, 30 */
      font-family: pretendard, sans-serif;
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: 60px; /* 125% */
    }
    .success-desc {
      margin-top: 24px;
      color: #fff;
      text-align: center;
      font-feature-settings: 'liga' off, 'clig' off;

      /* Body 2 - Regular, 14, 20 */
      font-family: pretendard, sans-serif;
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: 40px; /* 142.857% */
    }
    .use-able-period {
      margin-top: 20px;
      color: #fff;
      text-align: center;

      /* Body 3 - Regular, 12, 18 */
      font-family: pretendard, sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
      opacity: 0.8;
    }
  }

  .cancel-success-img {
    margin-bottom: 40px;
  }
  .cancel-success-descrip {
    .comment-name {
      margin-bottom: 20px;
      font-weight: 700;
      font-size: 44px;
      line-height: 50px;
      letter-spacing: -0.02em;
      & > span {
        color: #2e81f8;
      }
    }
    .comment-date {
      margin-bottom: 40px;
      font-weight: 500;
      font-size: 30px;
      line-height: 40px;
      color: #393d42;
    }
    .duration {
      font-weight: 400;
      font-size: 28px;
      line-height: 36px;
      color: #878d94;
    }
  }
  .bottom-rayout {
    width: 100%;
    bottom: 10px;
    position: fixed;
  }
`;

export const CancelImageWrapper = styled.div<React.CSSProperties>`
  margin-bottom: 40px;
`;
