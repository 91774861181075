import React from 'react';

import { Subtitle as SubTitleEmotion } from './Subtitle.constants';
import { ISubtitle } from './Subtitle.type';

const Subtitle = (props: ISubtitle): React.ReactElement => {
  return (
    <SubTitleEmotion>
      {props.subtitle} {props.firebrick ? <span className="firebrick">*</span> : null}
    </SubTitleEmotion>
  );
};

export default Subtitle;
