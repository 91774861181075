import React from 'react';

import styled from '@emotion/styled';

import { Typography } from '@/shared/styles/typography';

export const MenuSelectorContainer = styled.div<React.CSSProperties>`
  padding: 0 32px 64px;

  .menu-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
    border: 1px solid #dee1e5;
    border-radius: 4px;
    padding: 24px 32px;
    &.error {
      border: 1px solid #ff471e;
    }
    .label-container {
      .name {
        margin-bottom: 12px;
        ${Typography.body_28}
      }
      .description {
        ${Typography.small_2}
      }
      .price {
        margin-top: 4px;
        font-weight: 600;
        color: #272a2d;
        ${Typography.small_2}
      }
    }
    .select-container {
      ${Typography.body_28}
      .select-menu-box {
        color: #3b3b3d;
        height: 80px;
        border: none;
        border-radius: 4px;
        font-weight: 500;
        -webkit-appearance: none;
        outline: 0;
        width: 140px;
        padding-left: 24px;
        background: url(/images/chevronDown.svg) 90% 50% no-repeat;
        background-size: 40px 40px;
      }
    }
  }
`;
