import React, { useRef } from 'react';
import { useQuery } from 'react-query';

import classNames from 'classnames';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import { TimeSelectorContainer, getInfoState, setInfoState } from './TimeSelector.constants';
import { IReservationTimeResult } from './TimeSelector.type';

import Seperator from '@/components/Seperator/Seperator';
import { eggdiningApi } from '@/shared/apis/eggdiningApi';
import QUERY_KEYS from '@/shared/apis/queryKeys/booking';
import { useBookingInfoStore } from '@/stores/useBookingInfoStore';
import { useBookingStore } from '@/stores/useBookingStore';
import { useInfoStore } from '@/stores/useInfoStore';

dayjs.extend(localizedFormat);

const TimeBox = React.memo(({ time, viewDate, setViewDate, setTimeDirty, timeDirty }: any) => {
  const dispatchBookingInfo = useBookingInfoStore((store) => store.dispatchBookingInfo);

  const selectTime = (e) => {
    const cloneDate = viewDate.startOf('date').clone().set('minute', time);
    setTimeDirty(true);
    setViewDate(cloneDate);
    dispatchBookingInfo({
      type: 'CHANGE_DATE_TIME',
      value: cloneDate.clone(),
    });
  };
  return (
    <div className={classNames('time-box', { selected: viewDate.hour() * 60 + viewDate.minute() === time && timeDirty })} onClick={selectTime}>
      {dayjs(viewDate).startOf('date').set('minute', time).format('LT')}
    </div>
  );
});

const TimeSelector = (): React.ReactElement => {
  const { viewDate, times, timeDirty, calendarDirty } = useInfoStore(getInfoState);
  const { setViewDate, setTimes, setTimeDirty } = useInfoStore(setInfoState);
  const partySize = useBookingInfoStore((store) => store.partySize);
  const storeUri = useBookingStore((store) => store.storeUri);

  const timeSliderContainer = useRef(null);

  const { isFetching } = useQuery(
    [QUERY_KEYS.MALLDETAIL_GETTIMES, dayjs(viewDate).format('YYYY-MM-DD'), partySize],
    () =>
      eggdiningApi.get<IReservationTimeResult>(`eggdining/stores/${storeUri}/reservation/time`, {
        params: {
          partySize,
          date: viewDate.format('YYYY-MM-DD'),
        },
      }),
    {
      enabled: Boolean(storeUri && viewDate && partySize),
      onSuccess: (res) => {
        setTimes(res.schedules.filter((item) => item.state !== '00'));
        if (viewDate.hour() === 0 && viewDate.minute() === 0) {
          const cloneDate = viewDate.clone().set('minute', res.schedules.filter((item) => item.state !== '00')[0].time);
          setViewDate(cloneDate);
        }
      },
    }
  );

  let pos: { left?: number; top?: number; x?: number; y?: number } = { left: 0, x: 0 };

  const handleMouseMove = (e) => {
    const dx = e.clientX - pos.x;
    const dy = e.clientY - pos.y;
    const ele = document.getElementById('time-slider');
    ele.scrollTop = pos.top - dy;
    ele.scrollLeft = pos.left - dx;
  };

  const handleMouseUp = () => {
    const ele = document.getElementById('time-slider');
    ele.style.cursor = 'default';
    ele.style.removeProperty('user-select');
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  const handleMouseDown = (e) => {
    const ele = document.getElementById('time-slider');
    pos = {
      left: ele.scrollLeft,
      x: e.clientX,
    };
    ele.style.cursor = 'grabbing';
    ele.style.userSelect = 'none';
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  if (!times || !calendarDirty || isFetching) {
    return <></>;
  }
  return (
    <>
      {/* <Seperator type="line" inline={true} /> */}
      <TimeSelectorContainer>
        <h4>예약시간</h4>
        <div className="time-slider-container" id="time-slider" onMouseDown={handleMouseDown} ref={timeSliderContainer}>
          {times.map((data, idx) => (
            <TimeBox time={data.time} viewDate={viewDate} setViewDate={setViewDate} timeDirty={timeDirty} setTimeDirty={setTimeDirty} key={idx} />
          ))}
        </div>
      </TimeSelectorContainer>
    </>
  );
};

export default TimeSelector;
