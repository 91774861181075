import { useMutation } from 'react-query';

import { useAuthPhoneTokenCheckVerifyStore, useAuthPhoneTokenStore } from './ColreIdentity.sotre';
import { IAuthCheckOtpRes, IAuthOtpPostResponse, IPhoneOtpCheckReq } from './CoreIdentity.types';

import { eggdiningApi } from '@/shared/apis/eggdiningApi';
import OAUTH_USER_QUERY_KEYS from '@/shared/apis/queryKeys/auth';
import { useModalStore } from '@/stores/common/useModalStore';

const usePhoneOtpQuery = () => {
  const { setAuthPhoneToken } = useAuthPhoneTokenStore();

  return useMutation(usePhoneOtpQuery.getKeys(), (phoneNumber: string) => usePhoneOtpQuery.userPhonePost(phoneNumber), {
    onSuccess: ({ phoneToken }) => {
      setAuthPhoneToken(phoneToken);
    },
  });
};

usePhoneOtpQuery.getKeys = () => [OAUTH_USER_QUERY_KEYS.USER_PHONE_OTP];
usePhoneOtpQuery.userPhonePost = (phoneNumber: string) => {
  return eggdiningApi.post<IAuthOtpPostResponse>('/auth/otp', {
    phone: phoneNumber.split('-').join(''),
  });
};

const usePhoneOtpCheck = (alertModalKey) => {
  const { setVerifyCode } = useAuthPhoneTokenCheckVerifyStore();
  const { setAlertModal } = useModalStore();

  return useMutation(usePhoneOtpQuery.getKeys(), ({ authPhoneToken, phoneAuthCode }: IPhoneOtpCheckReq) => usePhoneOtpCheck.userAuthPhoneCheck({ authPhoneToken, phoneAuthCode }), {
    onSuccess({ verifyCode }) {
      setVerifyCode(verifyCode);
    },
    onError() {
      setAlertModal({ visible: true, key: alertModalKey });
    },
  });
};

usePhoneOtpCheck.getKeys = () => [OAUTH_USER_QUERY_KEYS.USER_PHONE_OTP_CHECK];
usePhoneOtpCheck.userAuthPhoneCheck = ({ authPhoneToken, phoneAuthCode }: IPhoneOtpCheckReq) => {
  return eggdiningApi.post<IAuthCheckOtpRes>('/auth/check-otp', {
    phoneToken: authPhoneToken,
    code: phoneAuthCode,
  });
};

export { usePhoneOtpQuery, usePhoneOtpCheck };
