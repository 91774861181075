import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import CardPaymentInfo from '@/components/CardPaymentInfo/CardPaymentInfo';
import { useFooterStore } from '@/stores/common/useFooterStore';
import { useHeaderStore } from '@/stores/common/useHeaderStore';

const Add = (): React.ReactElement => {
  const setIsFooterVisible = useFooterStore((store) => store.setIsFooterVisible);
  const setHeaderState = useHeaderStore((store) => store.setHeaderState);
  const navigate = useNavigate();

  useEffect(() => {
    setIsFooterVisible(false);
    setHeaderState({
      title: '새 결제수단 추가',
      handleGoToBack: null,
      handleGoToHome: null,
      handleOnAlarm: null,
      handleOnClose: () => navigate(-1),
      handleOnShare: null,
    });
  }, []);

  return <CardPaymentInfo type="add" title="카드 정보" buttonName="추가" />;
};

export default Add;
