import dayjs, { Dayjs } from 'dayjs';
import create from 'zustand';

import { IInfoAdditionalValue, Payment, User } from './useBookingStore';

import { Nullable } from '@/shared/types/common.types';
import { SessionStorage } from '@/shared/utils/storage';

export interface IBookingInfo {
  auth: Nullable<boolean>;
  partySize: Nullable<number>;
  storeName: string;
  detailPartySize: number[];
  dateTime: Nullable<Dayjs>;
  categoryId: Nullable<number>;
  provisionalToken: Nullable<string>;
  expiryTime: Nullable<Dayjs>;
  deposit: number;
  bookingMenus: any[];
  menuPrice: number;
  user: User;
  phoneAuthCode: Nullable<string>;
  phoneAuthToken: Nullable<string>;
  phoneAuth: Nullable<boolean>;
  additionalInfos: Nullable<IInfoAdditionalValue[]>;
  request: string;
  policyList: any[];
  agreement: any;
  payment: Payment;
}

export interface IBookingInfoStore extends IBookingInfo {
  dispatchBookingInfo: (bookingInfo: any) => void;
  clear: () => void;
}
const initialBookingInfo: IBookingInfo = {
  auth: null,
  partySize: 0,
  storeName: '',
  detailPartySize: [0, 0, 0],
  dateTime: null,
  categoryId: null,
  provisionalToken: null,
  expiryTime: null,
  deposit: null,
  bookingMenus: [],
  menuPrice: 0,
  user: {
    name: '',
    email: '',
    phoneNumber: '',
  },
  phoneAuthCode: null,
  phoneAuthToken: null,
  phoneAuth: true,
  additionalInfos: null,
  request: '',
  policyList: [],
  agreement: {},
  payment: {
    type: 'BILLING_KEY',
  },
};

const bookingInfoReducer = (state, action) => {
  const { value, index } = action;
  let changedState;
  switch (action.type) {
    case 'SET_STATE': {
      if (value.dateTime) {
        value.dateTime = dayjs(value.dateTime);
      }
      if (value.expiryTime) {
        value.expiryTime = dayjs(value.expiryTime);
      }
      changedState = { ...value, user: { ...value.user } };
      break;
    }
    case 'CHANGE_PARTY_SIZE':
      changedState = { partySize: value };
      break;
    case 'CHANGE_DATE_TIME':
      changedState = { dateTime: value };
      break;
    case 'SELECT_CATEGORY':
      changedState = { categoryId: value, dateTime: state.dateTime, additionalInfos: state.additionalInfos, partySize: state.partySize };
      break;
    case 'RAFFLE_PRE_RESERVATION':
      changedState = { ...value, dateTime: dayjs(value.dataTime), additionalInfos: state.additionalInfos };
      break;
    case 'SET_PROVISIONAL_TOKEN':
      changedState = { provisionalToken: value };
      break;
    case 'SET_EXPIRY_TIME':
      changedState = { expiryTime: dayjs(value) };
      break;
    case 'SET_DEPOSIT':
      changedState = { deposit: value };
      break;
    case 'CHANGE_PHONE_NUMBER':
      changedState = { user: { ...state.user, phoneNumber: value } };
      break;
    case 'CLEAR_AUTH':
      changedState = { phoneAuthToken: null, phoneAuthCode: null, phoneAuth: null, user: { ...state.user, phoneNumber: '' } };
      break;
    case 'SET_PHONE_AUTH_TOKEN':
      changedState = { phoneAuthToken: value };
      break;
    case 'SET_PHONE_AUTH_CODE':
      changedState = { phoneAuthCode: value };
      break;
    case 'AUTH':
      changedState = { phoneAuth: true };
      break;
    case 'SET_USER_NAME':
      changedState = { user: { ...state.user, name: value } };
      break;
    case 'SET_USER_EMAIL':
      changedState = { user: { ...state.user, email: value } };
      break;
    case 'SET_DETAIL_PARTY_SIZE':
      changedState = { detailPartySize: value };
      break;
    case 'SET_DETAIL_PARTY_SIZE_INDEX':
      state.detailPartySize[index] = Number(value);
      changedState = { detailPartySize: state.detailPartySize };
      break;
    case 'SET_BOOKING_MENU':
      changedState = { bookingMenus: value };
      break;
    case 'SET_ADDITIONAL_INFO':
      changedState = { additionalInfos: value };
      break;
    case 'SET_REQUEST':
      changedState = { request: value };
      break;
    case 'TOGGLE_AGREEMENT':
      changedState = { agreement: Object.assign({}, state.agreement, { [value]: !state.agreement[value] }) };
      break;
    case 'TOGGLE_AGREEMENT_ALL':
      changedState = {
        ...state,
        agreement: value.reduce((acc, cur) => {
          acc[cur] = !state.agreement[cur];
          return acc;
        }, Object.assign({}, state.agreement)),
      };
      break;
    case 'CHANGE_AGREEMENT_LIST':
      changedState = { agreement: Object.fromEntries(value.map((key) => [key, false])) };
      break;
    case 'SET_MENU_PRICE':
      changedState = { menuPrice: value };
      break;
    case 'CLEAR':
      changedState = initialBookingInfo;
      SessionStorage.setItem('bookingInfo', JSON.stringify(changedState));
      return changedState;
    default:
      changedState = state;
      break;
  }
  if (changedState) {
    const stored = JSON.parse(sessionStorage.getItem('bookingInfo') || '{}');
    sessionStorage.setItem('bookingInfo', JSON.stringify(Object.assign(stored, changedState)));
  }
  return changedState;
};
export const useBookingInfoStore = create<IBookingInfoStore>((set) => ({
  ...initialBookingInfo,
  dispatchBookingInfo: (action) => set((state) => bookingInfoReducer(state, action)),
  clear: () =>
    set((state) => {
      return { ...initialBookingInfo, partySize: state.partySize };
    }),
}));
