import React from 'react';

import { IInputProps } from './Input.types';

import { ReactComponent as ExclamationCircle } from '@/assets/img/icon/exclamation_circle.svg';
import { InputContainer } from '@/components/Input/Input.styles';

const Input = ({ children, ...props }: IInputProps): React.ReactElement => {
  return (
    <InputContainer className={props.className} error={props.error}>
      {props.label && (
        <label>
          {props.label}
          {props.required ? <span>*</span> : null}
        </label>
      )}
      <div className="input-wrapper">
        <div>
          <input {...props} />
          {props.clearicon ? props.clearicon : null}
        </div>
        {children}
      </div>
      {props.error || props.timer ? (
        <div className="error-wrapper">
          <span>
            {props.error ? (
              <>
                <ExclamationCircle />
                {props.error}
              </>
            ) : (
              ''
            )}
          </span>
          <span>{props.timer}</span>
        </div>
      ) : null}
    </InputContainer>
  );
};

export default Input;
