import React from 'react';

import styled from '@emotion/styled';

import { Colors } from '@/shared/styles/colors';
import { Typography } from '@/shared/styles/typography';

export const Payment = styled.div<React.CSSProperties>``;

export const PaymentLoaderContainer = styled.div<React.CSSProperties>`
  display: flex;
  min-width: 720px;
  height: 52px;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  z-index: 10;
`;
export const PaymentAgreementContainer = styled.div<React.CSSProperties>`
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin: 0px 32px 40px;
  .check-icon {
    width: 44px;
    height: 44px;
  }
  .seperator-line {
    background-color: ${Colors.white_opacity10};
    width: 100%;
    /* margin: 40px 0; */
    height: 2px;
    box-sizing: border-box;
  }
  .checkbox-all {
    margin-left: 16px;
    ${Typography.body_26_s}
  }
  .booking-agree-checkbox-container {
    /* margin-bottom: 32px; */
  }
`;

export const getServicePolicies = (partnerName) => {
  let policyList = [];
  switch (partnerName) {
    case 'skt':
      policyList = ['SERVICE', 'PRIVACY_FOR_SKT', 'PRIVACY_FOR_SKT_THIRD_PARTY', 'VOUCHER_CANCEL_PENALTY', 'MARKETING', 'LATE_NIGHT_MARKETING', 'KEEP_ACTIVE_EVEN_DORMANT'];
      break;
    case 'tablemanager':
      policyList = ['SERVICE', 'PRIVACY_FOR_PAYMENT', 'PRIVACY_FOR_PAYMENT_THIRD_PARTY', 'VOUCHER_CANCEL_PENALTY', 'MARKETING', 'LATE_NIGHT_MARKETING', 'KEEP_ACTIVE_EVEN_DORMANT'];
      break;

    case 'kb':
      policyList = ['SERVICE', 'PRIVACY_FOR_PAYMENT', 'PRIVACY_FOR_KB_PAY', 'VOUCHER_CANCEL_PENALTY', 'MARKETING', 'LATE_NIGHT_MARKETING', 'KEEP_ACTIVE_EVEN_DORMANT'];
      break;
    case 'mfg':
      policyList = ['SERVICE', 'PRIVACY_FOR_PAYMENT', 'PRIVACY_FOR_PAYMENT_THIRD_PARTY', 'VOUCHER_CANCEL_PENALTY', 'MARKETING', 'LATE_NIGHT_MARKETING', 'KEEP_ACTIVE_EVEN_DORMANT'];
      break;
    case 'samsung':
      policyList = [
        'SERVICE',
        'PRIVACY_FOR_SAMSUNG_CARD',
        'PRIVACY_FOR_PAYMENT_THIRD_PARTY',
        'VOUCHER_CANCEL_PENALTY',
        'MARKETING',
        'LATE_NIGHT_MARKETING',
        'KEEP_ACTIVE_EVEN_DORMANT',
      ];
      break;
  }
  return policyList;
};

export const INITIAL_VALIDATION_STATE = {
  user: {},
  agreements: [],
};

export const INITIAL_SAVE_CARD_STATE = {
  id: null,
  createdAt: null,
  content: {
    KO: { name: '카드정보를 저장하여 다음 결제 시 저장된 카드 정보를 사용하겠습니다.', contentUrl: null },
    EN: { name: null, contentUrl: null },
  },
  required: false,
  type: 'saveCard',
  value: false,
};
