import create from 'zustand';

import { Nullable } from '@/shared/types/common.types';

interface IUseScrollStore {
  lastScrollY: Nullable<number>;
  setLastScrollY: (scrollY: Nullable<number>) => void;
}

export const useScrollStore = create<IUseScrollStore>((set) => ({
  lastScrollY: null,
  setLastScrollY: (scrollY: number) => set(() => ({ lastScrollY: scrollY })),
}));
