import React from 'react';

import { datadogRum } from '@datadog/browser-rum';
import { GoogleOAuthProvider } from '@react-oauth/google';
import ReactDOM from 'react-dom/client';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import App from '@/App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const googleClientId = process.env.REACT_APP_GOOGLE_APP_KEY;

try {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_KEY,
    clientToken: process.env.REACT_APP_DATADOG_TOKEN,
    site: 'datadoghq.com',
    service: process.env.REACT_APP_DATADOG_SERVICE,

    // Specify a version number to identify the deployed version of your application in Datadog
    version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [process.env.REACT_APP_API_HOST],
  });
  datadogRum.startSessionReplayRecording();
} catch (e) {
  console.log(e);
}

root.render(
  <GoogleOAuthProvider clientId={googleClientId}>
    <App />
  </GoogleOAuthProvider>
);

serviceWorkerRegistration.register();
