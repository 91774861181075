import React from 'react';

import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

export const PaymentContainer = styled.div<React.CSSProperties>`
  padding: 0px 32px 96px;
  display: flex;
  flex-direction: column;
  gap: 40px;

  .radio-group {
    .radio-custom {
      > label {
        align-items: center;

        .radio-text {
          margin-left: 16px;
          ${Typography.body_28_s};
          /* color: ${Colors.gray8}; */
        }
      }
      &:not(&:first-of-type) {
        padding-top: 40px;
      }
    }
  }
`;
