import create from 'zustand';

import { Nullable } from '@/shared/types/common.types';

interface ICategory {
  name: string;
  reservable: boolean;
  category_id: number;
  category_image: string;
}

export interface ICategoryResult {
  result: boolean;
  category_type: string;
  category_image: string;
  list: ICategory[];
}
interface ICategoryStore {
  categories: Nullable<ICategory[]>;
  setCategories: (categories: ICategory[]) => void;
}

export const useCategoryStore = create<ICategoryStore>((set) => ({
  categories: null,
  setCategories: (categories) => set(() => ({ categories })),
}));
