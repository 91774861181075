import React from 'react';

import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

export const AnnouncementContainer = styled.div<React.CSSProperties>`
  .letter-box {
    display: inline-block;
    padding: 14px 20px;
    background-color: ${Colors.gray1};
    border-radius: 4px;
    &.time {
      width: 70px;
      height: 78px;
      ${Typography.title_44}
    }
    &.count {
      margin: 0 12px 0 20px;
      padding: 8px 20px;
      ${Typography.title_32_b}
    }
  }
  .an-header {
    .banner-image {
      /**
      이상한 빈공간이 생겨 일단 임시 처리
      */
      margin-bottom: -3px;
    }
    .description {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .title {
        font-style: normal;
        font-weight: 700;
        font-size: 56px;
        line-height: 67px;
        color: ${Colors.yellow1};
        &.wait {
          margin-bottom: 40px;
          ${Typography.title_36_b}
        }
        &.raffling {
          margin-bottom: 20px;
        }
        &.complete {
          margin-bottom: 20px;
          font-weight: 700;
          font-size: 56px;
          line-height: 67px;
          color: ${Colors.yellow1};
        }
      }
      .desc {
        display: flex;
        color: ${Colors.gray2};
        ${Typography.title_32_sb}
        text-align: center;
        span:nth-of-type(2n-1) {
          margin-right: 8px;
        }
        > div {
          color: ${Colors.white};
          /* ${Typography.title_40} */
          &.colon {
            margin: 0 24px;
          }
          div:last-of-type {
            text-align: center;
            color: ${Colors.gray5};
            ${Typography.body_28_m}
          }
        }
        &.wait {
          > div div:first-of-type {
            margin-bottom: 12px;
          }
        }

        &.raffling {
          display: flex;
          align-items: center;
        }
        &.complete {
          flex-direction: column;
          ${Typography.title_32_sb}
        }
        .raffle-done {
          margin-top: 28px;
          ${Typography.small_m}
        }
      }
      min-height: 340px;
      background: #222222;
    }
  }
  .con {
    padding: 64px 32px;
    background-color: ${Colors.gray1};
    .title-icon {
      text-align: center;
      margin-bottom: 8px;
    }
    .header .title {
      margin-bottom: 40px;
      text-align: center;
      font-style: normal;
      font-weight: 700;
      font-size: 56px;
      line-height: 67px;
    }
    .emergency {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 124px;
      background-color: #fff1f2;
      .info-icon {
        margin-right: 24px;
        > svg {
          width: 40px;
          height: 40px;
          fill: ${Colors.red1};
        }
      }
      .description {
        color: ${Colors.red1};
        ${Typography.body_26_m}
      }
    }
    .seperator-custom {
      margin: 64px 0;
      > div {
        background-color: ${Colors.gray3};
      }
    }
  }
  .auth-button {
    background: ${Colors.yellow_gradation};
  }
`;

export const WinningModalContent = styled.div<React.CSSProperties>`
  text-align: center;
  padding: 50px 40px;
  .winning-title {
    margin-bottom: 30px;
    color: ${Colors.gray9};
    letter-spacing: -0.01em;
    ${Typography.title_32_sb}
  }
  .visit-date {
    margin-bottom: 30px;
    letter-spacing: -0.01em;
    color: ${Colors.gray6};
    ${Typography.small_1}
  }
  .description {
    text-align: center;
    letter-spacing: -0.01em;
    color: ${Colors.gray8};
    ${Typography.body_26}
  }
`;
