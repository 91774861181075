import React, { useMemo } from 'react';

import { CategorySelectorContainer } from './CategorySelector.constants';
import { CategoryItem } from './CategorySelector.type';

import MainSubtitle from '@/components/MainSubtitle/MainSubtitle';
import RadioGroup from '@/components/RadioGroup/RadioGroup';
import { useBookingInfoStore } from '@/stores/useBookingInfoStore';
import { useCategoryStore } from '@/stores/useCategoryStore';

const CategorySelector = (): React.ReactElement => {
  const { dispatchBookingInfo, categoryId } = useBookingInfoStore();
  const { categories } = useCategoryStore();
  const radioItems: CategoryItem[] = useMemo(() => {
    return categories?.map((item) => {
      return { id: item.category_id, value: item.category_id, render: () => <div>{item.name}</div> };
    });
  }, [categories]);
  if (!categories) {
    return null;
  }

  function selectCategory(value) {
    dispatchBookingInfo({ type: 'SELECT_CATEGORY', value });
  }

  return (
    <CategorySelectorContainer>
      <MainSubtitle subtitle="예약 좌석 테이블" />
      <RadioGroup selected={categoryId} name="components" className="radio-group" items={radioItems} onChange={(e) => selectCategory(Number(e.target.value))} />
    </CategorySelectorContainer>
  );
};

export default CategorySelector;
