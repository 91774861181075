import React from 'react';

import styled from '@emotion/styled';

import { Typography, Colors } from '@/shared/styles';

export const CompleteHeaderContainer = styled.div<React.CSSProperties>`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 0;
  padding-bottom: 80px;

  & > svg {
    width: 64px;
    height: 64px;
  }

  .status-info-text {
    ${Typography.title_32_ss}/* color: ${Colors.green1}; */
  }
`;

// export const CompleteHeaderContainer = styled.div<React.CSSProperties>`
// margin: 80px 32px 100px;
// .status-info-text {
// ${Typography.title_32_sb}
// color: ${Colors.blue};
// margin-bottom: 8px;
// }
// .mall-name {
// ${Typography.title_44}
// color: ${Colors.black}
// }
// `;
