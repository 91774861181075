import React, { useEffect, useId, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import dayjs from 'dayjs';
import throttle from 'lodash/throttle';

import Modal from '../Modal/Modal';
import ModalPortal from '../ModalPortal/ModalPortal';
import { TimerContainer } from './Timer.constants';

import { useModalStore } from '@/stores/common/useModalStore';
import { useBookingInfoStore } from '@/stores/useBookingInfoStore';

const Timer = (): React.ReactElement => {
  const { setAlertModal } = useModalStore();
  const reservationTimeOut = useId();
  const { expiryTime, dispatchBookingInfo } = useBookingInfoStore();
  const query = useParams();
  const navigate = useNavigate();
  const [now, setNow] = useState(dayjs());
  const [scrollHeight, setScrollHeight] = useState(0);

  const handleScroll = throttle(() => {
    const position = window.pageYOffset;
    setScrollHeight(position);
  }, 300);

  const getDiffTimeFormat = (() => {
    if (!expiryTime) {
      return '-';
    }
    const diffSecond = expiryTime.diff(now, 'second');
    if (diffSecond <= 0) {
      return '00:00';
    }
    return `${Math.floor(diffSecond / 60)
      .toString()
      .padStart(2, '0')}:${(diffSecond % 60).toString().padStart(2, '0')}`;
  })();

  const timeOutReservation = () => {
    setAlertModal({ visible: true, key: reservationTimeOut });
  };

  const onCloseReservationTimeout = () => {
    navigate(`/detail/${query.storeUri}`);
    dispatchBookingInfo({ type: 'CLEAR' });
  };

  useEffect(() => {
    const interval = setInterval(() => setNow(dayjs()), 1000);
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      clearInterval(interval);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (now.isAfter(expiryTime)) {
      timeOutReservation();
    }
  }, [now]);

  //   <BigSizeBookingInfoTimer>
  //   {diffTimeFormat ? <div className="time">{diffTimeFormat}</div> : null}
  //   <div className="text">원활한 예약을 위해 좌석을 홀딩합니다. 해당 시간 내에 예약을 완료해 주세요.</div>
  // </BigSizeBookingInfoTimer>
  return (
    <>
      <TimerContainer>
        {getDiffTimeFormat ? <div className="time">{getDiffTimeFormat}</div> : null}
        <div className="text">이내에 예약을 완료해 주세요.</div>
        {/* {getDiffTimeFormat ? (
          <div>
            <b>{getDiffTimeFormat}</b>이내에 예약을 완료해주세요.
          </div>
        ) : (
          <div>시간이 만료되었습니다.</div>
        )} */}
      </TimerContainer>
      {/* <TimerPlaceholder>&nbsp;</TimerPlaceholder> */}
      <ModalPortal>
        <Modal.Alert key={reservationTimeOut} modal="alert" isDim={true} isAnimation={true} onClose={onCloseReservationTimeout}>
          <section className="content-wrapper">
            <h2 className="title">알림</h2>
            <section className="desc">
              <div>예약유효시간이 만료되었습니다.</div>
            </section>
          </section>
          <section className="button-wrapper">
            <button onClick={onCloseReservationTimeout}>닫기</button>
          </section>
        </Modal.Alert>
      </ModalPortal>
    </>
  );
};

export default Timer;
