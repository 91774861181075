import React from 'react';

import { HowParticipateContainer } from '@/components/Raffle/Complete/HowParticipate/HowParticipate.styles';

const mocking = {
  title: '재추첨 진행',
  desc: '당첨 후 15분 안에 예약하지 않을 경우, 해당 좌석에 대해 재추첨을 진행합니다.',
};

const guidelinesPrecautionList = [
  '당첨 시 발표 후 15분 이내에 예약하지 않으면 예약 기회가 사라지고, 다음 사람에게 넘어갑니다.',
  '당첨되지 않아도 당첨 발표에 참여해주세요. 예약하지 않은 당첨자가 있을 경우, 슬롯만큼 재추첨을 진행합니다.',
  '당첨된 예약의 양도 및 판매는 엄격하게 금지되어 있으며, 당첨자 본인만 방문 가능합니다. 양도 및 판매 적발 시 남은 멤버십 기간에 상관 없이 에그다이닝 서비스에서 탈퇴되며 재가입은 불가합니다.',
];

const HowParticipate = (): React.ReactElement => {
  return (
    <HowParticipateContainer>
      <div className="how-participate-title">참여 방법</div>
      <div className="how-participate-wrapper">
        <div>
          <b>1</b>
          <strong>8월 7일 월요일 오후 3시,</strong> 에그 앱에 접속.
        </div>
        <div>
          <b>2</b>
          <strong>추첨당첨자 결과 발표</strong>, 배너를 클릭.
        </div>
        <div>
          <b>3</b>
          <strong>당첨되면, 빠르게 15분 안에 예약을 마친다.</strong>
        </div>
        <div>
          <b>4</b>
          당첨되지 않았으면<strong>, 재추첨을 기다린다.</strong>
        </div>
      </div>
      <div className="guide">
        <div className="title">{mocking.title}</div>
        <div className="desc">{mocking.desc}</div>
      </div>
      <div className="guide-line-precaution-wrapper">
        <div className="title">유의사항 안내</div>
        <div className="desc">
          {guidelinesPrecautionList.map((guidelinesPrecautions: string, index: number) => {
            return (
              <div key={index}>
                <p>{index + 1}.</p>
                <div>{guidelinesPrecautions}</div>
              </div>
            );
          })}
        </div>
      </div>
    </HowParticipateContainer>
  );
};

export default HowParticipate;
