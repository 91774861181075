import React, { useId } from 'react';

import { ICheckBoxProps } from './CheckBox.types';

import { CheckBoxContainer } from '@/components/CheckBox/CheckBox.styles';

const CheckBox = (props: ICheckBoxProps): React.ReactElement => {
  const { checked, children, checkType, className, alignType, ...rest } = props;
  const checkboxId = useId();
  return (
    <CheckBoxContainer checkType={checkType} className={className} alignType={alignType}>
      <input {...rest} id={checkboxId} checked={checked} type="checkbox" />
      <label htmlFor={checkboxId}>
        <div className="check-img-wrapper">
          <i className="check-img"></i>
        </div>
        {children}
      </label>
    </CheckBoxContainer>
  );
};

export default CheckBox;
