import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { IKakaoRegister } from './LoginCallback.types';

import { eggdiningApi } from '@/shared/apis/eggdiningApi';
import OAUTH_USER_QUERY_KEYS from '@/shared/apis/queryKeys/auth';
import { LocalStorage } from '@/shared/configs/storage';
import { useLilloSignUp } from '@/shared/utils/useLilloSdk';
import { useUserProfileStore } from '@/stores/useUserProfle';

const useKakaoSignInQuery = () => {
  const navigate = useNavigate();
  return useMutation(useKakaoSignInQuery.getKeys(), () => useKakaoSignInQuery.oauthSocialRegister(), {
    onSuccess: (data) => {
      LocalStorage.setItem('jwtToken', data);
      LocalStorage.removeItem('accessToken');
      navigate('/main');
    },
  });
};

useKakaoSignInQuery.getKeys = () => [OAUTH_USER_QUERY_KEYS.USER_SIGN_UP];
useKakaoSignInQuery.oauthSocialRegister = () => {
  const social = LocalStorage.getItem('social');
  return eggdiningApi.post<IKakaoRegister>(`/eggdining/oauth/${social}/register`, {
    accessToken: LocalStorage.getItem('accessToken'),
  });
};

interface IOuthRegisterRes {
  code: string;
  token: string;
  name: string;
  phone: string;
  email: string;
  memberId: string;
  provider: string;
}

const useKakaoSignupMutation = () => {
  const { setMemberId } = useUserProfileStore();
  return useMutation(useKakaoSignupMutation.getKeys(), () => useKakaoSignupMutation.sendUserPhoneSignUp(), {
    onSuccess: (data) => {
      LocalStorage.setItem('memberId', data.memberId);
      LocalStorage.setItem('jwtToken', data.token);
      LocalStorage.setItem('provider', data.provider);
      setMemberId(data.memberId);
      useLilloSignUp(data);
      LocalStorage.removeItem('accessToken');
    },
  });
};

useKakaoSignupMutation.getKeys = () => [OAUTH_USER_QUERY_KEYS.USER_SIGN_UP];
useKakaoSignupMutation.sendUserPhoneSignUp = () => {
  const social = LocalStorage.getItem('social');
  return eggdiningApi.post<IOuthRegisterRes>(`/eggdining/oauth/${social}/register`, {
    accessToken: LocalStorage.getItem('accessToken'),
  });
};

export { useKakaoSignInQuery, useKakaoSignupMutation };
