import React from 'react';
import { useLocation } from 'react-router-dom';

import { HeaderVisibleExceptions } from './Header.constants';
import { HeaderContainer, LeftSection, RightSection } from './Header.styles';
import { IHeader } from './Header.types';

import { ReactComponent as Alarm } from '@/assets/img/icon/alarm.svg';
import { ReactComponent as AlarmOn } from '@/assets/img/icon/alarm_on.svg';
import { ReactComponent as Back } from '@/assets/img/icon/Back.svg';
// import { ReactComponent as Close } from '@/assets/img/icon/Close.svg';
// import { ReactComponent as Home } from '@/assets/img/icon/Home.svg';
import { ReactComponent as Left } from '@/assets/img/icon/chevron_left.svg';
import { ReactComponent as Share } from '@/assets/img/icon/Share.svg';
import MainHeader from '@/components/MainHeader/MainHeader';
import { useHeaderStore } from '@/stores/common/useHeaderStore';

const Header = ({ containerRef }: IHeader): React.ReactElement => {
  const { headerState } = useHeaderStore();
  const { title, handleGoToBack, handleGoToHome, handleOnAlarm: handleOnAlarm, handleOnAlarmOff, handleOnClose, handleOnShare, alarmOn } = headerState;

  const { pathname } = useLocation();

  if (HeaderVisibleExceptions.includes(pathname)) {
    return <></>;
  }
  if (pathname === '/main') {
    return <MainHeader containerRef={containerRef} />;
  }

  return (
    <HeaderContainer>
      {/* <LeftSection>{handleGoToBack ? <Back onClick={handleGoToBack} /> : null}</LeftSection> */}
      <LeftSection>{handleGoToBack ? <Left onClick={handleGoToBack} /> : null}</LeftSection>
      <h1>{title}</h1>
      {
        <RightSection>
          {/* {handleOnAlarm ? <>{alarmOn ? <AlarmOn onClick={handleOnAlarmOff} /> : <Alarm onClick={handleOnAlarm} />}</> : null} */}
          {/* {handleGoToHome ? <Home onClick={handleGoToHome} /> : null} */}
          {/* {handleOnClose ? <Close onClick={handleOnClose} className="close-btn" /> : null} */}
          {handleOnClose ? (
            <svg onClick={handleOnClose} xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 20 20" fill="none">
              <path
                d="M3.66549 2.19238L2.19235 3.66552L8.52686 10L2.19237 16.3345L3.66551 17.8077L10 11.4732L16.3345 17.8077L17.8076 16.3345L11.4731 10L17.8076 3.66553L16.3345 2.19239L10 8.52689L3.66549 2.19238Z"
                fill="white"
              />
            </svg>
          ) : null}
          {/*handleOnShare ? <Share onClick={handleOnShare} /> : null*/}
          {handleOnShare ? (
            <svg onClick={handleOnShare} xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 20 20" fill="none">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.4106 7.60078L15.5892 6.42226L9.9999 0.833008L4.41064 6.42226L5.58916 7.60078L9.16658 4.02335L9.16658 13.6782H10.8332L10.8332 4.02338L14.4106 7.60078Z"
                fill="white"
              />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0.833252 9.16699H2.49992V16.667H17.4999V9.16699H19.1666V18.3337H0.833252V9.16699Z" fill="white" />
            </svg>
          ) : null}
        </RightSection>
      }
    </HeaderContainer>
  );
};

export default Header;
