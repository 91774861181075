import {} from './RequestForm.constants';
import {} from './RequestForm.type';

import React from 'react';

import { RequestFormContainer } from './RequestForm.styles';

import { ReactComponent as InfoIcon } from '@/assets/img/icon/bullet_wrapper.svg';
// import { ReactComponent as Info } from '@/assets/img/icon/info.svg';
import { ReactComponent as TextareaSideIcon } from '@/assets/img/icon/textareaSideIcon.svg';
import MainSubtitle from '@/components/MainSubtitle/MainSubtitle';
import { useBookingInfoStore } from '@/stores/useBookingInfoStore';

const RequestForm = (): React.ReactElement => {
  const { request, dispatchBookingInfo } = useBookingInfoStore();

  function sendParentEvent(e) {
    window.parent.postMessage({ func: 'hideKeyPad' }, '*');
  }
  return (
    <RequestFormContainer>
      <div>
        <MainSubtitle subtitle="요청사항" />
        <div className="request-form-box">
          <section className="textarea-wrapper">
            <textarea
              className="request-textarea"
              placeholder="(선택) 요청사항을 입력해 주세요."
              value={request}
              maxLength={100}
              rows={4}
              onChange={(event) => dispatchBookingInfo({ type: 'SET_REQUEST', value: event.target.value })}
              onBlur={sendParentEvent}
            />
            {/* <TextareaSideIcon className="textarea-resize-icon" /> */}
          </section>
          {/* <div className="clear-button"><Close className="icon" /></div> */}
          <div className="description">
            <InfoIcon className="icon" />
            <span className="notice">매장 상황에 따라 요청사항이 반영되지 않을 수 있습니다. 요청사항에 대한 확인은 매장에 직접 문의하시기 바랍니다.</span>
          </div>
        </div>
      </div>
    </RequestFormContainer>
  );
};

export default RequestForm;
