import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import styled from '@emotion/styled';

import NotFoundImg from '@/assets/img/icon/notfound.png';
import { useFooterStore } from '@/stores/common/useFooterStore';
import { useHeaderStore } from '@/stores/common/useHeaderStore';
import { useNavigationStore } from '@/stores/common/useNavigationStore';

const NotFoundContainer = styled.div`
  img {
    width: 100%;
  }
`;

function NotFound() {
  const navigate = useNavigate();

  const setIsFooterVisible = useFooterStore((store) => store.setIsFooterVisible);
  const setHeaderState = useHeaderStore((store) => store.setHeaderState);
  const setIsNavigationVisible = useNavigationStore((store) => store.setIsNavigationVisible);

  useEffect(() => {
    setIsFooterVisible(false);
    setHeaderState({
      title: '에그가 이상해요',
      handleGoToBack: null,
      handleGoToHome: null,
      handleOnAlarm: null,
      handleOnClose: () => navigate('/main'),
      handleOnShare: null,
    });
    setIsNavigationVisible(false);
  }, []);

  return (
    <NotFoundContainer>
      <img src={NotFoundImg} alt="notFound" />
    </NotFoundContainer>
  );
}

export default NotFound;
