import React, { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { useUserProfileStore } from '@/stores/useUserProfle';

// 1. 로그인을 했는지 안했는지 확인
// 2. 로그인 했으면 -> 리다이렉트 투가 있으면 /membership/invitation/1234
// 3. 로그인 했는데 리다이렉트투가 없으면 /main
// 4. 로그인이 안되면 로그인 페이지로 이동

const Callback = (): React.ReactElement => {
  const { memberId } = useUserProfileStore();
  const navigate = useNavigate();
  const [serchParams, setSearchParams] = useSearchParams();

  const goThroughMain = () => {
    navigate('/main');
    navigate(serchParams.get('redirectTo'));
  };

  useEffect(() => {
    if (memberId) {
      return serchParams.get('redirectTo') ? goThroughMain() : navigate('/main');
    }
    navigate('/auth/signin');
  }, []);
  return <></>;
};

export default Callback;
