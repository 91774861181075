import React from 'react';

import SlotTime from '../SlotTime/SlotTime';

import { SlotsContainer } from '@/components/Raffle/Announcement/Slots/Slots.styles';

const Slots = ({ title, leftSubTitle, rightSubTitle, children, slotItems, slotComment, ...props }): React.ReactElement => {
  const { className } = props;

  return (
    <SlotsContainer className={className}>
      <div className="title">{title}</div>
      {children}
      <div className="sub">
        <div>{leftSubTitle}</div>
        <div>{rightSubTitle}</div>
      </div>
      {slotItems.length ? (
        slotItems.map((item, index) => {
          return <SlotTime key={index} item={item} />;
        })
      ) : (
        <div className="data-none">
          <div>{slotComment}</div>
        </div>
      )}
    </SlotsContainer>
  );
};

export default Slots;
