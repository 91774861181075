import {} from './AdditionalInfoForm.constants';
import {} from './AdditionalInfoForm.type';
import React, { useEffect } from 'react';

import { Checkbox, createTheme, FormControlLabel, ListItemText, MenuItem, Radio, RadioGroup, Select, ThemeProvider } from '@mui/material';

import Input from '../Input/Input';
import { AdditionalInfoFormContainer } from './AdditionalInfoForm.styles';

import { ReactComponent as ActiveRadio } from '@/assets/img/icon/activeRadio.svg';
import { ReactComponent as DeactiveRadio } from '@/assets/img/icon/deactiveRadio.svg';
import MainSubtitle from '@/components/MainSubtitle/MainSubtitle';
import { useBookingInfoStore } from '@/stores/useBookingInfoStore';
import { IInfoAdditionalValue, useBookingStore } from '@/stores/useBookingStore';
import { useBookingValidationStore } from '@/stores/useBookingValidationStore';

const theme = createTheme({
  components: {
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontFamily: 'pretendard, sans-serif',
          fontStyle: 'normal',
          fontSize: '28px',
          fontWeight: '400',
          lineHeight: '36px',
        },
      },
    },
  },
});

const AdditionalInfoForm = (): React.ReactElement => {
  const { additionalInfos, dispatchBookingInfo } = useBookingInfoStore();
  const { additionalInfo } = useBookingStore().reservationInfo;
  const { validations, dispatchValidations } = useBookingValidationStore();

  function setAdditionalInfo(value, name) {
    const tmpAdditionalInfos = [...additionalInfos];
    const targetAdditional = tmpAdditionalInfos.find((additional) => additional.name === name);
    targetAdditional.value = value;
    dispatchBookingInfo({ type: 'SET_ADDITIONAL_INFO', value: tmpAdditionalInfos });
  }

  function sendParentEvent() {
    window.parent.postMessage({ func: 'hideKeyPad' }, '*');
  }
  useEffect(() => {
    if (!additionalInfos || additionalInfos.length !== additionalInfo.length) {
      const tmpAdditionalInfos: IInfoAdditionalValue[] = additionalInfo.map((item) => {
        if (!item.isEnabled) {
          return {
            ...item,
            value: '',
          };
        }
        let value;
        if (item.kind === 'select' && !item.isMulitiple) {
          value = item.choices[0];
        }
        if (item.kind === 'select' && item.isMulitiple) {
          value = [];
        }
        if (item.kind === 'count') {
          value = 0;
        }
        if (item.kind === 'text') {
          value = '';
        }
        if (item.kind === 'toggle') {
          value = true;
        }
        return {
          ...item,
          value,
        };
      });
      dispatchBookingInfo({ type: 'SET_ADDITIONAL_INFO', value: tmpAdditionalInfos });
    }
  }, [additionalInfos]);

  // 개선 사항
  useEffect(() => {
    if (additionalInfos) {
      additionalInfos.forEach((item) => {
        if (item.isRequired && item.isEnabled && !item.value.length) {
          if (item.isMulitiple && item.value.length === 0) {
            dispatchValidations({ type: 'ADDITIONAL_INFO_REQUIRED', value: item.name });
          }
        } else {
          dispatchValidations({ type: 'ADDITIONAL_INFO_REQUIRED', value: item.name });
        }
      });

      const everyAdditionalInfoClear = additionalInfos.every((item) => {
        if (!item.isRequired || !item.isEnabled) {
          return true;
        }
        if (item.isRequired && item.value) {
          if (item.isMulitiple) {
            if (item.value.length > 0) {
              return true;
            }
            return false;
          }
          return true;
        }
        return false;
      });
      if (everyAdditionalInfoClear) {
        dispatchValidations({ type: 'ADDITIONAL_INFO_COMPLETED' });
      }
    }
  }, [JSON.stringify(additionalInfos)]);

  if (!additionalInfo || !additionalInfos) {
    return null;
  }

  return (
    <AdditionalInfoFormContainer>
      <div>
        {additionalInfos
          .filter((additional) => additional.isEnabled)
          .map((additional, idx) => (
            <div className="additional-item" key={idx}>
              <MainSubtitle subtitle={additional.name} firebrick={additional.isRequired} />
              {additional.kind === 'select' && !additional.isMulitiple ? (
                <div>
                  <select
                    value={additional.value}
                    className="additional-select"
                    onChange={(event) => setAdditionalInfo(event.target.value, additional.name)}
                    onBlur={sendParentEvent}
                  >
                    {additional.validations.map((validation, index) => (
                      <option value={validation.name} key={index}>
                        {validation.name}
                      </option>
                    ))}
                  </select>
                </div>
              ) : null}
              {additional.kind === 'select' && additional.isMulitiple ? (
                <Select
                  value={additional.value}
                  renderValue={(selected) => selected.join(', ')}
                  className="additional-select"
                  multiple
                  onChange={(event) => setAdditionalInfo(event.target.value, additional.name)}
                  IconComponent={null}
                >
                  {additional.validations.map((validation, index) => {
                    return (
                      <MenuItem value={validation.name} key={index}>
                        <ThemeProvider theme={theme}>
                          <Checkbox checked={additional.value.includes(validation.name)} sx={{ '& .MuiSvgIcon-root': { width: 36, height: 36 } }} />
                          <ListItemText primary={validation.name} />
                        </ThemeProvider>
                      </MenuItem>
                    );
                  })}
                </Select>
              ) : null}
              {additional.kind === 'text' ? (
                <Input
                  placeholder={additional.description ? additional.description : null}
                  value={additional.value}
                  className="input"
                  onChange={(event) => setAdditionalInfo(event.target.value, additional.name)}
                  onBlur={sendParentEvent}
                />
              ) : null}
              {additional.kind === 'toggle' ? (
                <div>
                  <div>
                    <RadioGroup value={additional.value} row>
                      <div className="radio-box">
                        <FormControlLabel
                          name={additional.name}
                          value={false}
                          control={
                            <Radio
                              checkedIcon={<ActiveRadio className="icon active" />}
                              icon={<DeactiveRadio className="icon" />}
                              onChange={(event) => setAdditionalInfo(false, additional.name)}
                            />
                          }
                          label="없음"
                        />
                      </div>
                      <div className="radio-box">
                        <FormControlLabel
                          name={additional.name}
                          value={true}
                          control={
                            <Radio
                              checkedIcon={<ActiveRadio className="icon active" />}
                              icon={<DeactiveRadio className="icon" />}
                              onChange={(event) => setAdditionalInfo(true, additional.name)}
                            />
                          }
                          label="있음"
                        />
                      </div>
                    </RadioGroup>
                  </div>
                  {additional.description ? <div className="additional-notice">{additional.description}</div> : null}
                </div>
              ) : null}
            </div>
          ))}
      </div>
    </AdditionalInfoFormContainer>
  );
};

export default AdditionalInfoForm;
