import React from 'react';

import dayjs from 'dayjs';

import { useUserMembership } from '../ManageMembership/ManageMembership.hooks';

import { ReactComponent as CancelSuccessImg } from '@/assets/img/membership/img_membership_cancel-success.svg';
import { CancelImageWrapper, MembershipcancelSuccessContainer } from '@/components/MembershipcancelSuccess/MembershipcancelSuccess.styles';
import { useUserProfileStore } from '@/stores/useUserProfle';

const MembershipcancelSuccess = (): React.ReactElement => {
  const { data: userMembership, isLoading } = useUserMembership();
  const { name } = useUserProfileStore();

  if (isLoading) {
    return (
      <div>
        <div>loading</div>
      </div>
    );
  }

  return (
    <MembershipcancelSuccessContainer>
      <div className="cancel-success-container">
        <h4>
          {name}님<br />꼭 다시 만나요!
        </h4>
        <div className="success-desc">
          지불하신 날짜까지는
          <br /> 멤버십 혜택이 적용됩니다.
        </div>
        <div className="use-able-period">기한 {dayjs(userMembership.membership.applyEndDate).format('YYYY년 MM월 DD일')}</div>
      </div>
      {/*
      <CancelImageWrapper>
        <CancelSuccessImg />
      </CancelImageWrapper>
      <div className="cancel-success-descrip">
        <div className="comment-name">
          <span>{name}</span>님<br />꼭 다시 만나요!
        </div>
        <div className="comment-date">
          지불하신 날짜까지는
          <br /> 서비스를 이용할 수 있습니다.
        </div>
        <div className="duration">기한 {dayjs(userMembership.membership.applyEndDate).format('YYYY. MM. DD')}</div>
      </div>
      */}
    </MembershipcancelSuccessContainer>
  );
};

export default MembershipcancelSuccess;
