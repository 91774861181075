import { useMutation, useQuery } from 'react-query';

import { eggdiningApi } from '@/shared/apis/eggdiningApi';
import RESERVATION_QUERY_KEYS from '@/shared/apis/queryKeys/reservation';
import { IReservationHistoryDetail } from '@/shared/types/ReservationHistory.types';

// 예약 내역
const useReservationHistoryData = () => {
  return useQuery(useReservationHistoryData.getKeys(), () => {
    return eggdiningApi.get<any>('/eggdining/my/reservation');
  });
};
useReservationHistoryData.getKeys = () => [RESERVATION_QUERY_KEYS.GET_RESERVATION_HISTORY];

const goToDetailPage = (navigate, path: number): void => {
  navigate(`/reservation/history/${path}`, {
    replace: true,
  });
};

// 예약 내역 상세
const useReservationHistoryDetailData = (reservationId) => {
  return useQuery(useReservationHistoryDetailData.getKeys(), () => {
    return eggdiningApi.get<IReservationHistoryDetail>(`/eggdining/reservations/${reservationId}`);
  });
};
useReservationHistoryDetailData.getKeys = () => [RESERVATION_QUERY_KEYS.GET_HISTORY_DETAIL];

const postReservationCancel = (reservationId, setData) => {
  return useMutation(() => eggdiningApi.post(`eggdining/reservations/${reservationId}/cancel`), {
    onSuccess: (data) => {
      setData(data);
    },
  });
};

const deleteReservationData = (reservationId, setIsSuccess) => {
  return useMutation(
    (token: string) =>
      eggdiningApi.deleteApi(`eggdining/reservations/${reservationId}`, {
        data: { token: token },
      }),
    {
      onSuccess: () => {
        setIsSuccess(true);
      },
      onError: () => {
        alert('예약이 올바르지 않습니다.');
        setIsSuccess(false);
      },
    }
  );
};

const filterShowPartyDetailPeople = (partyDetail) => {
  let customPartyDetail = '';
  if (partyDetail.length > 0) {
    customPartyDetail = partyDetail
      .filter((item) => item.size > 0)
      .map((item) => `${item.name_display || item.name} ${item.size}명`)
      .join(',');
  }
  return customPartyDetail;
};

export { useReservationHistoryData, goToDetailPage, useReservationHistoryDetailData, postReservationCancel, filterShowPartyDetailPeople, deleteReservationData };
