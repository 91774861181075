import create from 'zustand';

interface IMembershipInfo {
  membershipGrade: string;
  applyStartDate: string;
  applyEndDate: string;
  cancelDate: string;
}

export interface IMembershipInfoStore {
  membershipGrade: string;
  applyStartDate: string;
  applyEndDate: string;
  cancelDate?: string;
  setMemsershipInfo: (membershipInfo: IMembershipInfo) => void;
}

export const useMembershipInfoStore = create<IMembershipInfoStore>((set) => ({
  membershipGrade: '',
  applyStartDate: '',
  applyEndDate: '',
  cancelDate: '',
  setMemsershipInfo: (membershipInfo) => set(() => ({ ...membershipInfo })),
}));
