import { useId, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { IUserTermsAgreement } from './AgreeTerms.types';

import { eggdiningApi } from '@/shared/apis/eggdiningApi';
import USER_QUERY_KEYS from '@/shared/apis/queryKeys/user';
import { useModalStore } from '@/stores/common/useModalStore';
import { useUserProfileStore } from '@/stores/useUserProfle';

const useAgreeTermsReactHookForm = () => {
  const validationSchema = useMemo(() => {
    return yup.object().shape({
      agreements: yup.array(
        yup.object({
          agreement: yup.boolean(),
          title: yup.string(),
        })
      ),
    });
  }, []);

  const method = useForm({
    defaultValues: { agreements: [] },
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const { control, handleSubmit, reset, setValue, getValues, watch } = method;

  return {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    reset,
  };
};

const useAgreeTermsToast = () => {
  const { setToastModal } = useModalStore();
  const toastModalKey = useId();
  return { setToastModal, toastModalKey };
};

const useQueryUserTerms = (requiredPolicies) => {
  const { phone } = useUserProfileStore();
  return useQuery(useQueryUserTerms.getKeys(), () => useQueryUserTerms.fetcher(requiredPolicies, phone), { enabled: Boolean(phone) });
};

useQueryUserTerms.getKeys = () => [USER_QUERY_KEYS.GET_USER_TERMS];
useQueryUserTerms.fetcher = (requiredPolicies, phone) => eggdiningApi.get<IUserTermsAgreement>(`user/termsAgreements?phone=${phone}&type=${requiredPolicies}`);

export interface ITermsAgreementItems {
  servicePolicyType: string;
  agreement: boolean;
}

const useQueryChangeUserTerms = () => {
  const { phone } = useUserProfileStore();
  const queryClient = useQueryClient();

  return useMutation((resultData: ITermsAgreementItems) => useQueryChangeUserTerms.fetcher(resultData, phone), {
    onSuccess: () => {
      queryClient.invalidateQueries('PUT_USER_TREMS');
    },
  });
};
useQueryUserTerms.getKeys = () => [USER_QUERY_KEYS.PUT_USER_TREMS];
useQueryChangeUserTerms.fetcher = (resultData, phone) =>
  eggdiningApi.put('/user/termsAgreements', {
    user: {
      phone: phone,
    },
    termsAgreements: [resultData],
  });

const deleteUserPayment = () => {
  const { memberId } = useUserProfileStore();

  return useMutation(() => eggdiningApi.deleteApi(`/eggdining/users/${memberId}/payment-method`), {
    onError: (error) => {
      console.log(error);
    },
  });
};

export { useAgreeTermsReactHookForm, useAgreeTermsToast, useQueryUserTerms, useQueryChangeUserTerms, deleteUserPayment };
