import React from 'react';

import Button from '../Button/Button';
import { getFacilityIcon } from './FacilityViewer.constants';

import { FacilityIconItem, FacilityViewerContainer } from '@/components/FacilityViewer/FacilityViewer.styles';

const FacilityViewer = ({ icons, setViewAll = () => {} }): React.ReactElement => {
  return (
    <FacilityViewerContainer onClick={setViewAll} iconLength={icons.length}>
      {icons.map((icon) => (
        <FacilityIconItem key={icon}>
          {/* {getFacilityIcon(icon)} */}
          <Button colorLevel="gray" type="radius-small">
            <span>{icon}</span>
          </Button>
        </FacilityIconItem>
      ))}
    </FacilityViewerContainer>
  );
};

export default FacilityViewer;
