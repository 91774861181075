import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import styled from '@emotion/styled';

import EventShunjiRendingPage from '@/assets/img/event/sungi/event_rending_page.png';
import Button from '@/components/Button/Button';
import { useFooterStore } from '@/stores/common/useFooterStore';
import { useHeaderStore } from '@/stores/common/useHeaderStore';
import { useNavigationStore } from '@/stores/common/useNavigationStore';

const EventShunjiContainer = styled.div<React.CSSProperties>`
  img {
    width: 100%;
  }
`;

const EventShunji = () => {
  const navigate = useNavigate();

  const setIsNavigationVisible = useNavigationStore((store) => store.setIsNavigationVisible);
  const setIsFooterVisible = useFooterStore((store) => store.setIsFooterVisible);
  const setHeaderState = useHeaderStore((store) => store.setHeaderState);

  useEffect(() => {
    setIsNavigationVisible(false);
    setIsFooterVisible(false);
    setHeaderState({
      title: '스시 사이토 팝업 다이닝',
      handleGoToBack: null,
      handleGoToHome: null,
      handleOnAlarm: null,
      handleOnClose: () => navigate(-1),
      handleOnShare: null,
    });
  }, []);

  return (
    <>
      <EventShunjiContainer>
        <img src={EventShunjiRendingPage} alt="shunji evnet image" />
      </EventShunjiContainer>
      <Button disabled={false} colorLevel="primary" type="sticky" onClick={() => navigate(-1)}>
        닫기
      </Button>
    </>
  );
};

export default EventShunji;
