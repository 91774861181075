import React from 'react';

import { InputHintContainer } from './InputHint.styles';
import { IInputHint } from './InputHint.type';
import {} from './InputHint.constants';

const InputHint = ({ text }: IInputHint): React.ReactElement => {
  return (
    <InputHintContainer>
      <span className={'hint-text'}>{text}</span>
    </InputHintContainer>
  );
};

export default InputHint;
