import React from 'react';

import styled from '@emotion/styled';

export const MemberShipContainer = styled.div<React.CSSProperties>`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const MaintainMembershipContainer = styled.div<React.CSSProperties>`
  display: flex;
  justify-content: center;
  i {
    margin-right: 12px;
  }
`;
