declare const global;
if (!global.document) {
  global.document = { cookie: '' };
}
import Cookies from 'js-cookie';

export class CookieStorage {
  constructor() {}

  static setItem(key: string, item: any) {
    if (!global.document) {
      global.document = { cookie: '' };
    }
    if (item === null || item === undefined || item === '') {
      Cookies.remove(key);
      return null;
    }

    const value = typeof item === 'string' ? item : JSON.stringify(item);

    Cookies.set(key, value);
    return item;
  }

  static setPersistItem(key: string, item: any) {
    if (!global.document) {
      global.document = { cookie: '' };
    }
    // 2years
    const value = typeof item === 'string' ? item : JSON.stringify(item);
    Cookies.set(key, value, { expires: 365 * 2 });
    return item;
  }

  static getItem(key: string) {
    if (!global.document) {
      global.document = { cookie: '' };
    }
    return Cookies.get(key);
  }

  static removeItem(key: string) {
    if (!global.document) {
      global.document = { cookie: '' };
    }
    Cookies.remove(key);
  }
}

export class LocalStorage {
  constructor() {}

  static setItem(key: string, item: any) {
    if (item === null || item === undefined || item === '') {
      localStorage.removeItem(key);
      return;
    }
    const value = typeof item === 'string' ? item : JSON.stringify(item);

    localStorage.setItem(key, value);

    return item;
  }

  static getItem(key: string) {
    if (typeof window !== 'undefined') {
      return localStorage.getItem(key);
    }

    return null;
  }

  static removeItem(key: string) {
    localStorage.removeItem(key);
  }
}
export class SessionStorage {
  constructor() {}

  static setItem(key: string, item: string) {
    if (typeof window !== 'undefined') {
      sessionStorage.setItem(key, item);
    }
  }

  static getItem(key: string) {
    if (typeof window !== 'undefined') {
      return sessionStorage.getItem(key);
    }

    return null;
  }

  static removeItem(key: string) {
    if (typeof window !== 'undefined') {
      sessionStorage.removeItem(key);
    }
  }
}
