import { useQuery } from 'react-query';

import { eggdiningApi } from '@/shared/apis/eggdiningApi';
import USER_QUERY_KEYS from '@/shared/apis/queryKeys/user';
import { LocalStorage } from '@/shared/configs/storage';
import { IMembershipInquiry } from '@/shared/types/PaymentMethod.type';
import { useUserProfileStore } from '@/stores/useUserProfle';

// 해지후 재등록: 해지 후 이용 기한이 지나지 않았는데 재등록 하는 경우 이용 기한을 확인하기위한 get
const useMembershipSubscriptionInfo = () => {
  const memberId = useUserProfileStore((store) => store.memberId) || LocalStorage.getItem('memberId');
  return useQuery(useMembershipSubscriptionInfo.getKeys(), () => useMembershipSubscriptionInfo.userAuthPhoneCheck(memberId), {});
};
useMembershipSubscriptionInfo.getKeys = () => [USER_QUERY_KEYS.GET_USER_SUBSCRIPTION_INFO];
useMembershipSubscriptionInfo.userAuthPhoneCheck = (memberId) => {
  return eggdiningApi.get<IMembershipInquiry>(`/eggdining/users/${memberId}/membership`).then((response) => response.membership);
};

const putApplyJoinMembershipData = () => {
  const { memberId } = useUserProfileStore();

  return useQuery(putApplyJoinMembershipData.getKeys(), () => putApplyJoinMembershipData.userMemberIdGrade(memberId), {
    onError: () => {
      // alert('잘못된 접근입니다.');
    },
    enabled: false,
  });
};
putApplyJoinMembershipData.getKeys = () => [USER_QUERY_KEYS.PUT_USER_APPLY_MEMBERSHIP];
putApplyJoinMembershipData.userMemberIdGrade = (memberId) => {
  const YOLK_MEMBERSHIP_GRADE = '02';
  return eggdiningApi.put<any>(`/eggdining/users/${memberId}/membership/join-request`, {
    membershipGrade: YOLK_MEMBERSHIP_GRADE,
  });
};

export { useMembershipSubscriptionInfo, putApplyJoinMembershipData };
