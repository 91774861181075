import create from 'zustand';

import { Nullable } from '@/shared/types/common.types';

export interface IHeaderState {
  title?: Nullable<string>;
  handleGoToBack?: (props?) => Nullable<void>;
  handleGoToHome?: (props?) => Nullable<void>;
  handleOnAlarm?: (props?) => Nullable<void>;
  handleOnAlarmOff?: (props?) => Nullable<void>;
  handleOnClose?: (props?) => Nullable<void>;
  handleOnShare?: (props?) => Nullable<void>;
  alarmOn?: (props?) => Nullable<boolean>;
}
export interface IUseHeaderStore {
  headerState: IHeaderState;
  setHeaderState: (state) => any;
}

export const useHeaderStore = create<IUseHeaderStore>((set) => ({
  headerState: {
    title: null,
    handleGoToBack: null,
    handleGoToHome: null,
    handleOnAlarm: null,
    handleOnAlarmOff: null,
    handleOnClose: null,
    handleOnShare: null,
    alarmOn: null,
  },
  setHeaderState: (state: IHeaderState) =>
    set(() => {
      return {
        headerState: {
          title: state.title,
          handleGoToBack: state.handleGoToBack,
          handleGoToHome: state.handleGoToHome,
          handleOnAlarm: state.handleOnAlarm,
          handleOnAlarmOff: state.handleOnAlarmOff,
          handleOnClose: state.handleOnClose,
          handleOnShare: state.handleOnShare,
          alarmOn: state.alarmOn,
        },
      };
    }),
}));
