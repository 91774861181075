import styled from '@emotion/styled';

import { Colors } from '../../colors';
import { Typography } from '../../typography';

export const InvitationContainer = styled.main`
  height: 100%;

  .green-logo-container {
    margin-top: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .green-emblem {
    display: flex;
    width: 128px;
    height: 128px;
    padding: 3px 12px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 99px;
    margin-right: 8px;
    background: var(--green-300, #e8ff68);
  }

  .complete-desc {
    margin: 24px 0 60px 0;
    padding: 0 33px;

    h4 {
      color: #fff;
      text-align: center;

      /* Headline 2 - SemiBold, 24, 30 */
      font-family: pretendard, sans-serif;
      font-size: 44px;
      font-style: normal;
      font-weight: 500;
      line-height: 55px; /* 125% */
    }

    p {
      margin-top: 24px;
      color: #fff;
      text-align: center;
      font-feature-settings: 'liga' off, 'clig' off;

      /* Body 2 - Regular, 14, 20 */
      font-family: pretendard, sans-serif;
      font-size: 28px;
      font-style: normal;
      font-weight: 300;
      line-height: 40px; /* 142.857% */
    }
  }

  .g-line {
    opacity: 0.1;
    background: #fff;
    width: 100%;
    margin: 0;
    padding: 0;
    height: 12px;

    svg {
      fill: #fff;
      opacity: 0.1;
    }
  }
`;

export const SuccessCompleteContainer = styled.article`
  padding: 80px 32px 100px;
`;

export const TitleSuper = styled.sup`
  ${Typography.title_32_sb};
  color: ${Colors.blue};
  padding-bottom: 8px;
`;

export const Title = styled.h1`
  ${Typography.title_44};
  color: ${Colors.gray9};
`;

export const LimitedImg = styled.img`
  display: block;
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
  padding-block: 100px;
`;

export const CompleteDesc = styled.section`
  text-align: center;
  ${Typography.body_28};
  color: ${Colors.gray8};

  b {
    display: block;
    padding-top: 20px;
    color: ${Colors.gray9};
  }
`;

export const InvitationCodeContainer = styled.article`
  padding-block: 100px;
  padding-inline: 32px;
  /*background: ${Colors.yellow0};*/

  h3 {
    color: #fff;
    /* Title 2 - SemiBold, 18, 24 */
    font-family: pretendard, sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
  }
`;

export const InvitationCode = styled.form`
  display: flex;
  gap: 24px;
  padding-top: 40px;

  .code-input {
    gap: 0px;
    border-color: ${Colors.gray4};
    &:focus {
      border-color: ${Colors.gray9};
    }
  }

  .large.code-submit-btn {
    border-radius: 4px;
    width: 243px;
    padding: 0;
  }
`;
