// 에약 내역
type TReservationHistoryStatus = {
  [key: number]: string;
};

export const RESERVATION_HISTORY_STATUS: TReservationHistoryStatus = {
  0: '방문 예정',
  1: '방문 완료',
  2: '방문 취소',
  4: 'No Show',
};

export const INITIAL_TABS_ITEMS = [{ title: '전체' }, { title: '방문 예정' }, { title: '방문 완료' }, { title: '취소/노쇼' }];

// 예약 상세
export const PARTYDETAIL_PEOPLE = {
  ADULT: '성인',
  TEENAGER: '청소년',
  CHILD: '유아',
};
