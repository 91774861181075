import React from 'react';

import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

export const AddressContainer = styled.section<React.CSSProperties>`
  display: flex;
  flex-direction: column;
  gap: 32px;
  & > h1 {
    ${Typography.title_36_sb};
    color: ${Colors.white};
    /* padding-bottom: 12px; */
  }
  .phone-box {
    display: flex;
    align-items: center;
    margin-top: 16px;
    .phone-number {
      ${Typography.body_28}
      color: ${Colors.blue}
    }
  }
`;

export const AddressMapInfoContainer = styled.div<React.CSSProperties>`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  gap: 24px;
  /* opacity: 0.8; */

  .info-item {
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    justify-content: flex-start;
    ${Typography.body_28_ss}

    & > span {
      &:first-of-type {
        width: 136px;
        ${Typography.body_28_s}
      }
    }
  }

  /* & > span {
    ${Typography.body_28_ss}
    color: ${Colors.white}
  }

  & > p {
    ${Typography.body_28};
    color: #fff;
  }

  span:nth-child(2n-1) {
    width: 120px;
  }

  span:nth-child(2n) {
    flex: 1;
    margin-right: auto;
    min-width: 400px;
  } */
`;
