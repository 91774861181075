import React from 'react';

import { IRadio } from './Radio.types';

import { RadioContainer } from '@/components/Radio/Radio.styles';

const Radio = (props: IRadio): React.ReactElement => {
  const { children, name, value, selected, className, disabled, ...rest } = props;

  return (
    <RadioContainer className={className} disabled={disabled} checked={value === selected}>
      <label>
        <input {...rest} type="radio" name={name} value={value} checked={value === selected} disabled={disabled} />
        <div className="radio-img-wrapper">
          <i className="radio-img"></i>
        </div>
        {children}
      </label>
    </RadioContainer>
  );
};

export default Radio;
