import * as React from 'react';

import styled from '@emotion/styled';

import ActiveMyPage from '@/assets/img/icon/activeMyPage.svg';
import HomeOff from '@/assets/img/icon/home_off.svg';
import HomeOn from '@/assets/img/icon/home_on.svg';
import MypageOff from '@/assets/img/icon/person_off.svg';
import MypageOn from '@/assets/img/icon/person_on.svg';
import { Typography, Variables } from '@/shared/styles';
import { Colors } from '@/shared/styles/colors';

export const NavWrapper = styled.div`
  width: 100%;
`;

export const Nav = styled.nav`
  position: fixed;
  left: 0;
  right: 0;
  bottom: -1px;
  z-index: 10;

  ${Variables.maxWidth};
  /* margin: 0 auto; */
  background: ${Colors.gray12};
  border-top: 1px solid rgba(255, 255, 255, 0.1);

  ul {
    display: flex;
    align-items: center;
  }
`;

interface INavButton extends React.CSSProperties {
  active?: boolean;
}

export const NavButton = styled.li<INavButton>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-block: 16px;
  text-align: center;
  cursor: pointer;
  ${Typography.ss_small};
  line-height: 40px;
  /* color: ${(props) => (props.active ? Colors.white : Colors.gray5)}; */

  opacity: ${(props) => (props.active ? '100%' : '40%')};

  &::before {
    content: '';
    width: 48px;
    height: 48px;
    display: block;
  }

  &.reserv-btn::before {
    background: ${(props) => (props.active ? `url(${HomeOn})` : `url(${HomeOff})`)} no-repeat center center;
    /* opacity: ${(props) => (props.active ? '100%' : '40%')}; */
  }

  &.mypage-btn::before {
    background: ${(props) => (props.active ? `url(${MypageOn})` : `url(${MypageOff})`)} no-repeat center center;
    /* opacity: ${(props) => (props.active ? '100%' : '40%')}; */
  }
`;

export const NavPadding = styled.div`
  height: 104px;
`;
