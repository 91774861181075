import React from 'react';

import { TabsContainer, TabsItemContainer } from './Tabs.styles';
import { ITabs, ITabsItem } from './Tabs.type';

const Tabs = ({ children, onClick, ...props }: ITabs): React.ReactElement => {
  const handleTabsClick = (e) => {
    const value = e.target.dataset.value;
    if (value) {
      onClick(e);
    }
  };
  return <TabsContainer onClick={handleTabsClick}>{children}</TabsContainer>;
};

const Item = ({ value, active, children }: ITabsItem): React.ReactElement => {
  return (
    <TabsItemContainer active={active} data-value={value}>
      {children}
    </TabsItemContainer>
  );
};

Tabs.Item = Item;

export default Tabs;
