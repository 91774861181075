import React from 'react';

import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

export const MallTitleContainer = styled.div<React.CSSProperties>`
  display: flex;
  flex-direction: column;
  padding: 48px 32px;
  gap: 16px;
  & > div {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    & > h1 {
      ${Typography.title_44_m};
      color: ${Colors.white};

      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    button > svg {
      cursor: pointer;
      margin-right: 10px;
    }
  }

  & > span {
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    ${Typography.small_1};
    color: ${Colors.white};
    opacity: 60%;
  }
  & > h1 {
    ${Typography.title_44_m};
    color: ${Colors.white};

    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  & > h2 {
    ${Typography.body_28};
    color: ${Colors.white};
    margin-top: 28px;

    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;
