/* eslint-disable no-unused-expressions */
import React, { Fragment, useEffect, useId, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import ModalPortal from '../ModalPortal/ModalPortal';
import { ButtonWrapper, CardPaymentInfoContainer, PaymentTitle } from './CardPaymentInfo.styles';
import { ICardPaymentInfo } from './CardPaymentInfo.types';

//import { ReactComponent as InputClear } from '@/assets/img/icon/input_clear.svg';
import { ReactComponent as InputClear } from '@/assets/img/icon/x-circle-filled.svg';
import { eggdiningApi } from '@/shared/apis/eggdiningApi';
import { ControlledInput } from '@/shared/hook-form/ControlledInput';
import { autoHypenCardNum } from '@/shared/libs/function/auto-hypen';
import { autoSlashExpiry } from '@/shared/libs/function/auto-slash';
import { useModalStore } from '@/stores/common/useModalStore';
import { useNavigationStore } from '@/stores/common/useNavigationStore';
import { useCardPaymentStore } from '@/stores/useMembershipJoinStore';
import { useUserProfileStore } from '@/stores/useUserProfle';

const CardPaymentInfo = ({ type, title, buttonName }: ICardPaymentInfo): React.ReactElement => {
  const navigate = useNavigate();
  const setIsNavigationVisible = useNavigationStore((store) => store.setIsNavigationVisible);
  const { setIsChangePayment } = useCardPaymentStore();
  const { setAlertModal } = useModalStore();
  const invalidCardErrorKey = useId();
  const invalidPaymentErrorKey = useId();
  const { memberId } = useUserProfileStore();
  const [searchParams] = useSearchParams();
  const validationSchema = useMemo(() => {
    return yup.object().shape({
      cardNum: yup.string().min(17, '카드번호를 올바르게 입력해주세요.').required('카드번호를 올바르게 입력해주세요.'),
      expiration: yup.string().min(5, '유효기간을 올바르게 입력해주세요.').required('유효기간을 올바르게 입력해주세요.'),
      birth: yup.string().min(6, '생년월일을 올바르게 입력해주세요.').required('생년월일을 올바르게 입력해주세요.'),
      password: yup.string().min(2, '비밀번호를 올바르게 입력해주세요.').required('비밀번호를 올바르게 입력해주세요.'),
    });
  }, []);

  useEffect(() => {
    setIsNavigationVisible(false);
  }, []);

  const method = useForm({
    defaultValues: { cardNum: '', expiration: '', birth: '', password: '' },
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const {
    control,
    formState: { isValid },
    setValue,
    handleSubmit,
    resetField,
  } = method;

  // submit 클릭시 카드 등록 post
  const { mutate: termsAgreementMutate, isLoading: isMutateLoading } = useMutation(
    (resultData: any) => {
      return eggdiningApi.post<any>(`/eggdining/users/${memberId}/payment-method?overwrite=true`, {
        card: {
          cardNumber: resultData.cardNum.replace(/-/g, ''),
          expiry: resultData.expiration,
          identifyNumber: resultData.birth,
          password: resultData.password,
        },
      });
    },
    {
      onSuccess: () => {
        setIsChangePayment(true);
        const from = searchParams.get('from');
        if (from) {
          navigate(from, { replace: true });
        } else {
          navigate('/membership/join', { replace: true });
        }
      },
      onError: () => {
        setAlertModal({ visible: true, key: invalidCardErrorKey });
      },
    }
  );

  const submitHandler = (data) => {
    if (data) {
      termsAgreementMutate(data);
    }
  };

  const acceptsOnlyNum = (e) => {
    if (/[^0-9]/g.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <Fragment>
      <CardPaymentInfoContainer>
        <PaymentTitle>{title}</PaymentTitle>
        <ControlledInput
          required
          label="카드번호"
          placeholder="0000-0000-0000-0000"
          type="text"
          name="cardNum"
          inputMode="numeric"
          maxLength="19"
          control={control}
          clearicon={<InputClear onClick={() => resetField('cardNum')} />}
          onChange={(e) => setValue('cardNum', autoHypenCardNum(e.target.value))}
          onKeyPress={acceptsOnlyNum}
        />
        <ControlledInput
          required
          label="유효기간(월/년)"
          placeholder="MM/YY"
          type="text"
          name="expiration"
          inputMode="numeric"
          maxLength="5"
          control={control}
          onChange={(e) => setValue('expiration', autoSlashExpiry(e.target.value))}
          clearicon={<InputClear onClick={() => resetField('expiration')} />}
          onKeyPress={acceptsOnlyNum}
        />
        <ControlledInput
          required
          label="생년월일"
          placeholder="YYMMDD"
          type="text"
          name="birth"
          inputMode="numeric"
          maxLength="6"
          autoComplete="off"
          control={control}
          clearicon={<InputClear onClick={() => resetField('birth')} />}
          onKeyPress={acceptsOnlyNum}
        />
        <ControlledInput
          required
          label="비밀번호 앞 2자리"
          placeholder="00"
          type="password"
          name="password"
          inputMode="numeric"
          maxLength="2"
          control={control}
          clearicon={<InputClear onClick={() => resetField('password')} />}
          onKeyPress={acceptsOnlyNum}
        />
      </CardPaymentInfoContainer>
      <ButtonWrapper
        onClick={handleSubmit(
          (data, error) => {
            submitHandler(data);
          },
          (err) => {
            console.log(err, 'err');
          }
        )}
      >
        <Button disabled={!isValid} colorLevel="primary" type="sticky">
          {buttonName}
        </Button>
      </ButtonWrapper>
      <ModalPortal>
        <Modal.Alert key={invalidCardErrorKey} modal="alert" isDim={true} isAnimation={true}>
          <section className="content-wrapper">
            <h2 className="title">알림</h2>
            <p className="desc">유효하지 않은 카드입니다. 카드 정보를 다시 확인해주세요.</p>
          </section>
          <section className="button-wrapper">
            <button onClick={() => setAlertModal({ visible: false, key: invalidCardErrorKey })}>확인</button>
          </section>
        </Modal.Alert>
        <Modal.Alert key={invalidPaymentErrorKey} modal="alert" isDim={true} isAnimation={true}>
          <section className="content-wrapper">
            <h2 className="title">알림</h2>
            <p className="desc">결제에 실패했습니다. 사용할 수 있는 카드인지 다시 확인해주세요.</p>
          </section>
          <section className="button-wrapper">
            <button onClick={() => setAlertModal({ visible: false, key: invalidPaymentErrorKey })}>확인</button>
          </section>
        </Modal.Alert>
      </ModalPortal>
    </Fragment>
  );
};

export default CardPaymentInfo;
