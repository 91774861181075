import React from 'react';

import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

export const VisitScheduledContainer = styled.div<React.CSSProperties>`
  display: flex;
  padding: 32px 32px;
  flex-direction: column;
  gap: 32px;
`;

export const VisitScheduledItemContainer = styled.div<React.CSSProperties>`
  display: flex;
  gap: 32px;
  align-items: center;
  /* align-self: stretch; */
  .img-wrapper {
    width: 192px;
    height: 192px;
    position: relative;
    border-radius: 4px;
    border: 2px solid rgba(255, 255, 255, 0.1);
    overflow: hidden;
    img {
      max-width: 100%;
      min-height: 100%;
    }
    span {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 42px;
      left: 0;
      bottom: 0;
      ${Typography.small_1};
      color: ${Colors.white};
      background: ${Colors.yellow1};
    }
  }
  .text-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    h3 {
      overflow: hidden;
      text-overflow: ellipsis;

      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 48px;
    }
    span {
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px;
      opacity: 0.8;
    }

    .reserve-diff {
      align-self: flex-start;
      opacity: 1;
      border-radius: 6px;
      background: var(--secondary-50010, rgba(221, 197, 164, 0.1));
      padding: 2px 12px;
      /*max-width : 60px;*/
      justify-content: center;
      gap: 8px;
      color: var(--secondary-500, #ddc5a4);
      text-align: center;

      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px; /* 150% */
    }
  }
`;
