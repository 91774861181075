import React from 'react';

import styled from '@emotion/styled';

import Arrow from '@/assets/img/icon/arrowRightGray.svg';
import { Colors, Typography } from '@/shared/styles';

export const HistoryContainer = styled.div<React.CSSProperties>`
  display: flex;
  flex-direction: column;
`;
export const HistoryTitle = styled.div<React.CSSProperties>`
  display: flex;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
  /*
  ${Typography.title_36_sb};
  color: ${Colors.gray9};
  */

  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px; /* 133.333% */
  padding: 80px 32px 0 32px;

  & > span {
    display: flex;
    align-items: center;
    ${Typography.small_1};
    font-weight: 500;
    line-height: 36px;
    /*color: ${Colors.gray6};*/
    color: #fff;
    opacity: 0.8;
    cursor: pointer;

    /*
    &::after {
      content: '';
      background: url(${Arrow}) no-repeat center center;
      display: inline-block;
      width: 24px;
      height: 24px;
      color:#fff;
      opacity : 0.8;
    }*/
  }
  &:first-of-type {
    padding: 68px 32px 0 32px;
  }
`;

export const HistoryAllContainer = styled.div<React.CSSProperties>`
  display: flex;
  flex-direction: column;

  .d-line-container {
    width: 100%;
    padding: 0 32px;
  }

  .d-line {
    height: 1px;
    align-self: stretch;
    width: 100%;
    background: #fff;
    opacity: 0.1;
  }
`;
