import React from 'react';

import styled from '@emotion/styled';

import { Colors } from '@/shared/styles';

export const IdentityContainer = styled.div<React.CSSProperties>`
  padding: 80px 32px;

  .identify-description {
    margin-bottom: 48px;
    color: #fff;
    /* Title 1 - SemiBold, 20, 28 */
    font-family: pretendard, sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px; /* 140% */
    /*
    > :first-of-type {
      margin-bottom: 28px;
      font-weight: 700;
      font-size: 44px;
      line-height: 50px;
      color: ${Colors.gray9};
    }
    > :last-child {
      font-weight: 500;
      font-size: 30px;
      line-height: 40px;
      color: ${Colors.gray8};
    }
    */
  }
  .core-information {
    margin-bottom: 100px;
    > label {
      color: #fff !important;
      font-feature-settings: 'liga' off, 'clig' off;
      /* Label 3 - Medium, 12, 18 */
      font-family: pretendard, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px; /* 150% */
    }
    > :first-of-type {
      margin-bottom: 40px;
    }
  }
`;
export const ButtonWrapper = styled.div<React.CSSProperties>`
  position: sticky;
  bottom: 0;
`;
