import React from 'react';

import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

export const CoreIdentityContainer = styled.div<React.CSSProperties>`
  margin-top: 40px;
  .information-phone-input {
    .certified {
      .input-wrapper {
        display: flex;
        .phone-auth-button {
          width: 243px;
          padding: 0;
          border-radius: 6px;
        }
        > div:first-of-type {
          flex: auto;
          margin-right: 24px;
        }
      }
    }
    .phone-auth {
      margin-bottom: 20px;
    }
    .phone-auth-input {
      border-radius: 6px;
      /*background: rgba(255, 255, 255, 0.1);*/
      color: ${Colors.white};
    }
  }

  .input-box.phone {
    /* &.phone {

    } */
  }

  .input-box {
    position: relative;
    &:not(&:first-of-type) {
      margin-top: 40px;
    }

    &.phone-auth-success input {
      border-radius: 6px;
      background: rgba(255, 255, 255, 0.1);
      color: #ffffff;
    }

    &.email {
      margin-bottom: 20px;
    }
    .input {
      box-sizing: border-box;
      width: 100%;
      border-radius: 4px;
      outline: none;
      ${Typography.body_28}
      &.error {
        border: 2px solid ${Colors.red2};
      }
      &:focus {
        & + .clear-button {
          opacity: 1;
        }
      }
    }
    .phone-change-button {
      position: absolute;
      bottom: 14px;
      right: 28px;
      padding: 12px 28px;
      border-radius: 3px;
      background: var(--gray-90040, rgba(20, 18, 14, 0.4));
      color: #fff;
      font-size: 24px !important;
      ${Typography.body_28}
    }
  }
  &.phone-auth-success input {
    border-radius: 3px;
    background: rgba(255, 255, 255, 0.1);
    color: #ffffff;
  }
`;
