import {} from './NavigationModal.constants';

import React, { useEffect, useState } from 'react';

import { createBrowserHistory } from '@remix-run/router';

import { NavigationModalContainer } from './NavigationModal.styles';
import { INavigationModal } from './NavigationModal.type';

const NavigationModal = (props: INavigationModal): React.ReactElement => {
  const { isBlocked, okayText, cancelText, header } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const history = createBrowserHistory();

  // const location = useLocation();
  const [lastLocation, setLastLocation] = useState<string>(location?.pathname || null);
  const [shouldUnload, setShouldUnload] = useState(false);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
    setShouldUnload(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const showModal = (nextLocation) => {
    openModal();
    setLastLocation(nextLocation);
  };

  const handleBlockedRoute = (nextLocation) => {
    if (!confirmedNavigation && isBlocked) {
      showModal(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    closeModal();
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      setShouldUnload(true);
      history.go(-1);
    }
  }, [confirmedNavigation, lastLocation, history]);

  useEffect(() => {
    const unload = (event) => {
      if (!shouldUnload) {
        event.returnValue = props.children;
      }
      if (shouldUnload) {
        event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', unload);
    return () => window.removeEventListener('beforeunload', unload);
  }, [shouldUnload]);

  history.listen(({ location, action }) => {
    if (action === 'POP') {
      return handleBlockedRoute(location);
    }
  });

  return (
    <NavigationModalContainer>
      {isModalOpen ? (
        <div className={'modal openModal'}>
          <div className="modal-container">
            <div className="modal-header">{header}</div>
            <div className="modal-body">{props.children}</div>
            <div className={'modal-footer'}>
              <button onClick={closeModal} className="cancel-button">
                {cancelText}
              </button>
              <button onClick={handleConfirmNavigationClick} className="confirm-button">
                {okayText}
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </NavigationModalContainer>
  );
};

export default NavigationModal;
