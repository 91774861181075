import create from 'zustand';

import { LocalStorage } from '@/shared/configs/storage';

export interface IUserInfo {
  memberId: string | null;
  idP: number;
  memberUseServiceId: number;
  name: string;
  phone: string;
}

export interface IUserProfile extends IUserInfo {
  setUser: (userInfo: IUserInfo) => void;
  setUserPhone: (phone) => void;
  setMemberId: (memberId: string) => void;
}

export const useUserProfileStore = create<IUserProfile>((set) => ({
  memberId: LocalStorage.getItem('memberId') || null,
  idP: null,
  memberUseServiceId: null,
  name: '',
  phone: '',
  setUser: (userInfo) => set(() => ({ ...userInfo })),
  setUserPhone: (phone) => set(() => ({ phone })),
  setMemberId: (memberId) => set(() => ({ memberId })),
}));
