import dayjs from 'dayjs';

import { filterShowPartyDetailPeople } from '@/hooks/reservationHistory.hook';
import { SessionStorage } from '@/shared/configs/storage';

// 텍스트를 만들어 주는 로직
const makeShareText = ({ store, reservation, visitor }) => {
  const people = reservation.partyDetail.reduce((a, b) => a + b.size, 0);
  const customPartyDetail = filterShowPartyDetailPeople(reservation.partyDetail);
  const date = dayjs(reservation.visitAt).format('YYYY년 MM월 DD일 HH:mm');
  const mallPhone = store.phone;
  const mallAddress = store.address;
  const storeName = store.name;
  const userName = visitor.name;
  const desc = `[에그다이닝/예약완료]
      
    ▶매장명 : ${storeName}
    ▶이용예정일 : ${date}
    ▶매장연락처 : ${mallPhone}
    ▶주소 : ${mallAddress}
    ▶예약자 : ${userName}님
    ▶예약인원 : ${people}명 (${customPartyDetail})`;

  SessionStorage.setItem('shareText', desc);
};

// 클립보드 복사 로직
function copyMessage() {
  const selBox = document.createElement('textarea');
  const value = SessionStorage.getItem('shareText');
  selBox.style.position = 'fixed';
  selBox.style.left = '0';
  selBox.style.top = '0';
  selBox.style.opacity = '0';
  selBox.value = value;
  document.body.appendChild(selBox);
  selBox.focus();

  if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
    selBox.select();
    selBox.setSelectionRange(0, 999999);
  } else {
    selBox.select();
  }

  document.execCommand('copy');
  document.body.removeChild(selBox);
}

export { makeShareText, copyMessage };
