import React from 'react';
import { useNavigate } from 'react-router-dom';

import classNames from 'classnames';

import { MembershipContainer } from './Membership.styles';

import { ReactComponent as EggBadge } from '@/assets/img/icon/egg_badge.svg';
import { ReactComponent as MembershipBadge } from '@/assets/img/icon/membership-badge.svg';
import Button from '@/components/Button/Button';
import { useUserMembership } from '@/components/ManageMembership/ManageMembership.hooks';
import MembershipEmblem from '@/components/MembershipEmblem/MembershipElblem';

function MemberShip(): React.ReactElement {
  const navigate = useNavigate();
  const { data: userMembership, isLoading } = useUserMembership();

  const handleGoToMembershipManagement = () => {
    if (userMembership.membership.membershipGrade === '01') {
      navigate('/membership/entry', { state: { prevPath: location.pathname } });
    } else {
      navigate('membership');
    }
  };

  if (isLoading) {
    return (
      <div>
        <div>loading</div>
      </div>
    );
  }

  if (!userMembership) {
    return null;
  }

  return (
    <MembershipContainer onClick={handleGoToMembershipManagement}>
      {userMembership.membership.membershipGrade === '01' ? (
        <>
          <div className="membership-info">
            <h4>지금바로 Green Club 으로 업그레이드 해보세요!</h4>
            <br />
          </div>
          <div className="black-benefit">
            <div className="black-benefit-contents">
              <svg xmlns="http://www.w3.org/2000/svg" width="6" height="36" viewBox="0 0 3 18" fill="none">
                <circle cx="1.5" cy="8.5" r="1.5" fill="white" />
              </svg>
              <span className="desc">
                우선 예약을 할 수 있는 권리, 익스프레스 예약을 제공합니다.
                {/* 남들보다 먼저 예약할 권리,&nbsp;<span>익스프레스 예약</span>&nbsp;제공 */}
              </span>
            </div>
            <div className="black-benefit-contents">
              <svg xmlns="http://www.w3.org/2000/svg" width="6" height="36" viewBox="0 0 3 18" fill="none">
                <circle cx="1.5" cy="8.5" r="1.5" fill="white" />
              </svg>
              <span className="desc">
                다양한 미식 이벤트 및 콜라보 다이닝 예약을 제공합니다.
                {/* 그린클럽에게만 오픈되는&nbsp;<span>익스클루시브 다이닝</span>&nbsp;예약 가능 */}
              </span>
            </div>
          </div>
          <Button
            colorLevel="primary"
            type="small100v2"
            classKey="auth-button"
            onClick={() => {
              handleGoToMembershipManagement();
            }}
          >
            Green Club 으로 업그레이드 하기
          </Button>

          {/*
            <div className="right-item">
              <div className="btn-upgrade-plan">업그레이드</div>
            </div>
            */}
        </>
      ) : (
        <>
          <div className="membership-info">
            <h4>그린 클럽</h4>
            {['01', '03'].includes(userMembership.membership.subscriptionType) ? (
              <>
                <span className="year-payment">연간 구독</span>
              </>
            ) : null}
            <div className="right-item">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 12 12" fill="none">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.36038 0.5H4.63962L4.09115 2.14541L3.46163 2.46017L1.74672 1.88853L0.372018 4.63793L1.75 5.74031V6.4669L0.345718 7.30947L1.74672 10.1115L3.40958 9.55719L4.08783 10.0659L4.66148 11.5H7.33852L7.91217 10.0659L8.59042 9.55719L10.2533 10.1115L11.628 7.36207L10.25 6.25969V5.74031L11.628 4.63793L10.2533 1.88853L8.53836 2.46017L7.90885 2.14541L7.36038 0.5ZM4.90885 2.85459L5.36038 1.5H6.63962L7.09115 2.85459L8.46163 3.53983L9.74672 3.11147L10.372 4.36207L9.25 5.25969V6.74031L10.372 7.63793L9.74672 8.88853L8.40958 8.44281L7.08783 9.43412L6.66148 10.5H5.33852L4.91217 9.43412L3.59042 8.44281L2.25328 8.88853L1.65428 7.69053L2.75 7.0331V5.25969L1.62798 4.36207L2.25328 3.11147L3.53836 3.53983L4.90885 2.85459ZM5 6C5 5.44772 5.44772 5 6 5C6.55228 5 7 5.44772 7 6C7 6.55228 6.55228 7 6 7C5.44772 7 5 6.55228 5 6ZM6 4C4.89543 4 4 4.89543 4 6C4 7.10457 4.89543 8 6 8C7.10457 8 8 7.10457 8 6C8 4.89543 7.10457 4 6 4Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div>&nbsp;멤버십 관리</div>
            </div>
          </div>
          <div className="black-benefit">
            <div className="black-benefit-contents">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 12 12" fill="none">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM3.44199 5.49998L2.55811 6.38386L5.00005 8.82581L9.44199 4.38386L8.55811 3.49998L5.00005 7.05804L3.44199 5.49998Z"
                  fill="#DDC5A4"
                />
              </svg>
              가장 빠른 익스프레스 예약 가능
            </div>
            <div className="black-benefit-contents">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 12 12" fill="none">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM3.44199 5.49998L2.55811 6.38386L5.00005 8.82581L9.44199 4.38386L8.55811 3.49998L5.00005 7.05804L3.44199 5.49998Z"
                  fill="#DDC5A4"
                />
              </svg>
              다양한 미식 이벤트 및 콜라보 다이닝 예약 가능
            </div>
          </div>
        </>
      )}
      {/*
      <div className="content">
        <div className="user-name">
          {userMembership.membership.membershipGrade === '01' ? (
            <>
              <EggBadge />
              <span>EGG 회원</span>
            </>
          ) : (
            <>
              <MembershipBadge width="48" height="48" />
              <span>YOLK 회원</span>
            </>
          )}
        </div>
        <div className="membership-status">{userMembership.membership.membershipGrade === '01' ? '업그레이드' : '멤버십 관리'}</div>
      </div>
      */}
    </MembershipContainer>
  );
}

export default MemberShip;
