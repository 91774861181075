import React from 'react';

import { ModalContainer } from '../modal.styles';

import { IModalProps } from '@/components/Modal/Modal.type';
import { useModalStore } from '@/stores/common/useModalStore';

const Menu = ({ isDim, isClose = true, isAnimation, children, onClose }: IModalProps) => {
  const { menuModal, setMenuModal } = useModalStore();
  if (!menuModal.visible) {
    return <></>;
  }

  return (
    <ModalContainer className="modal-wrapper menu">
      {isDim ? <div className="modal-dim" onClick={() => (onClose ? onClose() : setMenuModal(false))} /> : null}
      <div className={`modal-content ${isAnimation ? 'animation' : ''}`}>
        {/* <div className="close">{isClose ? <Close_BoottoMenu onClick={() => onClose ? onClose() : setMenuModal(false)} /> : null}</div> */}
        {children}
      </div>
    </ModalContainer>
  );
};

export default Menu;
