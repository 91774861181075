import { useEffect } from 'react';

declare global {
  interface Window {
    Kakao: any;
  }
}

function useKakaoSignin() {
  useEffect(() => {
    if (!window.Kakao.isInitialized()) {
      window.Kakao.init(process.env.REACT_APP_KAKAO_APP_KEY);
    }
  }, []);

  function handleKakaoLogin(versionValidation) {
    window.Kakao.Auth.authorize({
      redirectUri: versionValidation ? process.env.REACT_APP_KAKAO_REDIRECT_URL : process.env.REACT_APP_KAKAO_WEB_REDIRECT_URL,
      scope: 'account_ci,profile,account_email,gender,age_range,birthday,birthyear,phone_number,name',
    });
  }
  return { handleKakaoLogin };
}

export { useKakaoSignin };
