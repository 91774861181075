import React, { useEffect, useId } from 'react';
import { useNavigate } from 'react-router-dom';

import styled from '@emotion/styled';

import RenewalRendingPage from '@/assets/img/event_renewal_rending_page.jpg';
import { ReactComponent as BulletWrapper } from '@/assets/img/icon/bullet_wrapper.svg';
import { ReactComponent as CheckCircleFilled } from '@/assets/img/icon/check_circle_filled.svg';
import { ReactComponent as MiddleDot } from '@/assets/img/icon/middleDot.svg';
import { ReactComponent as XCircleFilled } from '@/assets/img/icon/x-circle-filled-red.svg';
import Button from '@/components/Button/Button';
import Modal from '@/components/Modal/Modal';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import { BannerMembershipContainer, TitleContainer, ContentsContainer } from '@/shared/styles/pages/banner/memberShip';
import { useFooterStore } from '@/stores/common/useFooterStore';
import { useHeaderStore } from '@/stores/common/useHeaderStore';
import { useModalStore } from '@/stores/common/useModalStore';
import { useNavigationStore } from '@/stores/common/useNavigationStore';

const BannerMemberShip = () => {
  const navigate = useNavigate();
  const yolkInvitationCodeAlert = useId();

  const setIsNavigationVisible = useNavigationStore((store) => store.setIsNavigationVisible);
  const setIsFooterVisible = useFooterStore((store) => store.setIsFooterVisible);
  const setHeaderState = useHeaderStore((store) => store.setHeaderState);
  const { setAlertModal } = useModalStore();

  const applyJoinMembership = () => {
    navigate('/membership/invitation');
  };

  useEffect(() => {
    setIsNavigationVisible(false);
    setIsFooterVisible(false);
    setHeaderState({
      title: 'Dining Club 멤버십',
      handleGoToBack: null,
      handleGoToHome: null,
      handleOnAlarm: null,
      handleOnClose: () => navigate(-1),
      handleOnShare: null,
    });
  }, []);

  return (
    <>
      <BannerMembershipContainer>
        <TitleContainer>
          <div className="title">
            Green Club 멤버가 되어
            <br />
            익스프레스 예약을 경험해 보세요.
          </div>
          <div className="desc">‘익스프레스 예약’은 Green Club 멤버에게만 먼저 예약기간을 열어주는 우선예약 혜택입니다.</div>
        </TitleContainer>
        <ContentsContainer>
          <div className="membership-container green">
            <div className="contents">
              <div className="grade green">
                {/* <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18.5 9C18.5 13.9706 14.4706 18 9.5 18C4.52944 18 0.5 13.9706 0.5 9C0.5 4.02944 4.52944 0 9.5 0C14.4706 0 18.5 4.02944 18.5 9ZM12.3042 4.3058L12.3022 4.48854L10.2264 8.38212H10.0225L11.8751 4.3058H12.3037H12.3042ZM6.91993 13.7321L6.91791 13.9148H7.34649L9.19964 9.83848H8.9957L6.91993 13.7321ZM14.7106 12.3827C14.4895 13.2626 13.8418 13.9148 12.6667 13.9148C11.131 13.9148 10.546 12.7936 10.546 11.3534C10.546 9.96873 11.1361 8.74962 12.6975 8.74962C13.9241 8.74962 14.5668 9.46645 14.7116 10.317H14.0155C13.8479 9.79356 13.4506 9.32409 12.6692 9.32409C11.5899 9.32409 11.2335 10.3196 11.2335 11.3292C11.2335 12.3388 11.5626 13.3398 12.6985 13.3398C13.4688 13.3398 13.8388 12.8396 14.012 12.3827H14.7106ZM6.34192 9.04846C8.09815 9.04846 8.88565 8.09034 8.88565 6.55572V6.55522C8.88565 5.0206 8.09815 4.06298 6.34192 4.06298H4.30048V4.24572C4.7851 4.28813 4.93957 4.4219 4.93957 4.76668V8.34425C4.93957 8.68904 4.7851 8.82281 4.30048 8.86522V9.04846H6.34192ZM5.99416 8.80868H6.39089C7.71147 8.80868 8.32481 7.93587 8.32481 6.55572C8.32481 5.17558 7.6837 4.30276 6.34192 4.30276H5.45094V8.35839C5.45094 8.65764 5.59869 8.79926 5.99416 8.80868Z"
                    fill="#14120E"
                  />
                </svg> */}
                Green&nbsp;<span className="bold">Club</span>
              </div>
              <div className="subscribe">
                <div className="item">
                  <div className="title">월간 구독</div>
                  <div className="desc">
                    <h3>33,000원</h3>
                    <h4>/ 월</h4>
                  </div>
                </div>
                <div className="item">
                  <div className="title">
                    연간 구독 시 30% 할인
                    {/* <div className="tag">66,000원 절약</div> */}
                  </div>
                  <div className="desc">
                    {/* <h4 className="strike-through">396,000원</h4> */}
                    <h3>23,000원</h3>
                    <h4>/ 월</h4>
                  </div>
                  <div className="tag">276,000원 / 연</div>
                </div>
              </div>
            </div>
            <div className="checklist">
              <div className="listitem">
                <CheckCircleFilled />
                가장 빠른 익스프레스 예약 가능
              </div>
              <div className="listitem">
                <CheckCircleFilled />
                다양한 미식 이벤트 및 콜라보 다이닝 예약 가능
              </div>
            </div>
            <div className="divider" />
            <div className="invite-code">
              <h5>Exclusive</h5>
              <h3>
                Green Club 가입 신청을 하시면
                <br />
                초대코드를 보내드립니다.
              </h3>
              <h4>’초대코드 발급 신청’을 해주시면, 한정된 인원으로 운영되기 때문에 멤버 선정 과정을 거쳐 코드가 발급됩니다.</h4>
            </div>
            <div className="invite-desc">
              <div className="listItem">
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                    <g clip-path="url(#clip0_1544_3193)">
                      <path
                        d="M6 0C2.68661 0 0 2.68661 0 6C0 9.31339 2.68661 12 6 12C9.31339 12 12 9.31339 12 6C12 2.68661 9.31339 0 6 0ZM6 10.9821C3.24911 10.9821 1.01786 8.75089 1.01786 6C1.01786 3.24911 3.24911 1.01786 6 1.01786C8.75089 1.01786 10.9821 3.24911 10.9821 6C10.9821 8.75089 8.75089 10.9821 6 10.9821Z"
                        fill="white"
                      />
                      <path
                        d="M5.35693 8.35714C5.35693 8.52764 5.42466 8.69115 5.54522 8.81171C5.66578 8.93227 5.82929 9 5.99979 9C6.17029 9 6.3338 8.93227 6.45436 8.81171C6.57492 8.69115 6.64265 8.52764 6.64265 8.35714C6.64265 8.18665 6.57492 8.02313 6.45436 7.90257C6.3338 7.78202 6.17029 7.71429 5.99979 7.71429C5.82929 7.71429 5.66578 7.78202 5.54522 7.90257C5.42466 8.02313 5.35693 8.18665 5.35693 8.35714ZM5.67836 6.85714H6.32122C6.38015 6.85714 6.42836 6.80893 6.42836 6.75V3.10714C6.42836 3.04821 6.38015 3 6.32122 3H5.67836C5.61943 3 5.57122 3.04821 5.57122 3.10714V6.75C5.57122 6.80893 5.61943 6.85714 5.67836 6.85714Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1544_3193">
                        <rect width="12" height="12" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <span>과거에 노쇼나 취소 내역이 많으면 초대코드 신청이 거절될 수 있습니다.</span>
              </div>
              <div className="listItem">
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                    <g clip-path="url(#clip0_1544_3193)">
                      <path
                        d="M6 0C2.68661 0 0 2.68661 0 6C0 9.31339 2.68661 12 6 12C9.31339 12 12 9.31339 12 6C12 2.68661 9.31339 0 6 0ZM6 10.9821C3.24911 10.9821 1.01786 8.75089 1.01786 6C1.01786 3.24911 3.24911 1.01786 6 1.01786C8.75089 1.01786 10.9821 3.24911 10.9821 6C10.9821 8.75089 8.75089 10.9821 6 10.9821Z"
                        fill="white"
                      />
                      <path
                        d="M5.35693 8.35714C5.35693 8.52764 5.42466 8.69115 5.54522 8.81171C5.66578 8.93227 5.82929 9 5.99979 9C6.17029 9 6.3338 8.93227 6.45436 8.81171C6.57492 8.69115 6.64265 8.52764 6.64265 8.35714C6.64265 8.18665 6.57492 8.02313 6.45436 7.90257C6.3338 7.78202 6.17029 7.71429 5.99979 7.71429C5.82929 7.71429 5.66578 7.78202 5.54522 7.90257C5.42466 8.02313 5.35693 8.18665 5.35693 8.35714ZM5.67836 6.85714H6.32122C6.38015 6.85714 6.42836 6.80893 6.42836 6.75V3.10714C6.42836 3.04821 6.38015 3 6.32122 3H5.67836C5.61943 3 5.57122 3.04821 5.57122 3.10714V6.75C5.57122 6.80893 5.61943 6.85714 5.67836 6.85714Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1544_3193">
                        <rect width="12" height="12" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <span>멤버십은 한정 인원으로 관리되기 때문에 빨리 신청할 수록 선정 확률이 높아집니다.</span>
              </div>
            </div>
            {/* <Button colorLevel="primary" type="large" onClick={() => setAlertModal({ visible: true, key: yolkInvitationCodeAlert })}>
              그린클럽 가입 신청
            </Button> */}
          </div>
          <div className="membership-container">
            <div className="contents">
              <div className="grade white">
                {/* <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM11.8042 4.3058L11.8022 4.48854L9.72642 8.38212H9.52247L11.3751 4.3058H11.8037H11.8042ZM6.41993 13.7321L6.41791 13.9148H6.84649L8.69964 9.83848H8.4957L6.41993 13.7321ZM14.2106 12.3827C13.9895 13.2626 13.3418 13.9148 12.1667 13.9148C10.631 13.9148 10.046 12.7936 10.046 11.3534C10.046 9.96873 10.6361 8.74962 12.1975 8.74962C13.4241 8.74962 14.0668 9.46645 14.2116 10.317H13.5155C13.3479 9.79356 12.9506 9.32409 12.1692 9.32409C11.0899 9.32409 10.7335 10.3196 10.7335 11.3292C10.7335 12.3388 11.0626 13.3398 12.1985 13.3398C12.9688 13.3398 13.3388 12.8396 13.512 12.3827H14.2106ZM5.84192 9.04846C7.59815 9.04846 8.38565 8.09034 8.38565 6.55572V6.55522C8.38565 5.0206 7.59815 4.06298 5.84192 4.06298H3.80048V4.24572C4.2851 4.28813 4.43957 4.4219 4.43957 4.76668V8.34425C4.43957 8.68904 4.2851 8.82281 3.80048 8.86522V9.04846H5.84192ZM5.49416 8.80868H5.89089C7.21147 8.80868 7.82481 7.93587 7.82481 6.55572C7.82481 5.17558 7.1837 4.30276 5.84192 4.30276H4.95094V8.35839C4.95094 8.65764 5.09869 8.79926 5.49416 8.80868Z"
                    fill="#14120E"
                  />
                </svg> */}
                White&nbsp;<span className="bold">Club</span>
              </div>
              <div className="price">
                <span className="title">무료</span>
              </div>
            </div>
            <div className="checklist">
              <div className="listitem">
                <XCircleFilled />
                익스프레스 예약 불가
              </div>
              <div className="listitem">
                <XCircleFilled />
                다양한 미식 이벤트 및 콜라보 다이닝 예약 불가
              </div>
            </div>
          </div>
          <div className="membership-container">
            <div className="contents">
              <div className="grade black">
                {/* <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18.5 9C18.5 13.9706 14.4706 18 9.5 18C4.52944 18 0.5 13.9706 0.5 9C0.5 4.02944 4.52944 0 9.5 0C14.4706 0 18.5 4.02944 18.5 9ZM12.3042 4.3058L12.3022 4.48854L10.2264 8.38212H10.0225L11.8751 4.3058H12.3037H12.3042ZM6.91993 13.7321L6.91791 13.9148H7.34649L9.19964 9.83848H8.9957L6.91993 13.7321ZM14.7106 12.3827C14.4895 13.2626 13.8418 13.9148 12.6667 13.9148C11.131 13.9148 10.546 12.7936 10.546 11.3534C10.546 9.96873 11.1361 8.74962 12.6975 8.74962C13.9241 8.74962 14.5668 9.46645 14.7116 10.317H14.0155C13.8479 9.79356 13.4506 9.32409 12.6692 9.32409C11.5899 9.32409 11.2335 10.3196 11.2335 11.3292C11.2335 12.3388 11.5626 13.3398 12.6985 13.3398C13.4688 13.3398 13.8388 12.8396 14.012 12.3827H14.7106ZM6.34192 9.04846C8.09815 9.04846 8.88565 8.09034 8.88565 6.55572V6.55522C8.88565 5.0206 8.09815 4.06298 6.34192 4.06298H4.30048V4.24572C4.7851 4.28813 4.93957 4.4219 4.93957 4.76668V8.34425C4.93957 8.68904 4.7851 8.82281 4.30048 8.86522V9.04846H6.34192ZM5.99416 8.80868H6.39089C7.71147 8.80868 8.32481 7.93587 8.32481 6.55572C8.32481 5.17558 7.6837 4.30276 6.34192 4.30276H5.45094V8.35839C5.45094 8.65764 5.59869 8.79926 5.99416 8.80868Z"
                    fill="white"
                  />
                </svg> */}
                Black&nbsp;<span className="bold">Club</span>
              </div>
              <div className="price">
                <span className="title">
                  최고의 미식 컨시어지 서비스,
                  <br />
                  블랙클럽 멤버십
                </span>
              </div>
            </div>
            <div className="checklist">
              <div className="listitem">전 세계의 예약이 불가한 레스토랑 및 다양한 글로벌 미식행사에 참석할 수 있는 하이엔드 멤버십이 곧 오픈됩니다.</div>
            </div>
            <Button classKey="soon" colorLevel="gray3" type="large" disabled={true} onClick={() => setAlertModal({ visible: true, key: yolkInvitationCodeAlert })}>
              Coming Soon
            </Button>
          </div>
          <div className="divider" />
          <div className="notice-container">
            <div className="title">유의사항 안내</div>
            <div className="listitem">
              <MiddleDot />
              멤버십 금액은 VAT(부가가치세) 포함 가격입니다.
            </div>
            <div className="listitem">
              <MiddleDot />
              익스프레스 예약의 양도 및 매매는 불가합니다. 적발 즉시 멤버십이 정지됩니다. (환불 불가)
            </div>
            <div className="listitem">
              <MiddleDot />
              매장당 한 달에 최대 2번까지 예약할 수 있습니다. 예약을 취소해도 횟수는 복원되지 않습니다.
            </div>
            <div className="listitem">
              <MiddleDot />
              예약의 양도 및 매매를 방지하는 차원에서 같은 날 동시간대 중복 예약은 불가합니다.
            </div>
            <div className="listitem">
              <MiddleDot />
              멤버십 해지 시 결제 금액은 환불되지 않습니다.
            </div>
            <div className="listitem">
              <MiddleDot />
              위약금 적용기간 내 예약 취소 3회 초과 시 멤버십이 자동 해지됩니다.
            </div>
            <div className="listitem">
              <MiddleDot />
              위약금 적용기간 외 예약 취소 10회 초과 시 멤버십이 자동 해지됩니다.
            </div>
            <div className="listitem">
              <MiddleDot />
              노쇼의 경우 조건 없이 멤버십이 해지됩니다.
            </div>
            <div className="listitem">
              <MiddleDot />위 규정 위반 시 재가입은 불가합니다.
            </div>
            <div className="listitem">
              <MiddleDot />
              예약 시간 기준 30분 이상 지각 혹은 위반시에는 향후 예약에 대해 불이익을 받을 수 있습니다.
            </div>
          </div>
        </ContentsContainer>
        <div className="button-wrapper">
          <Button classKey="premium" disabled={false} colorLevel="primary" type="sticky" onClick={() => setAlertModal({ visible: true, key: yolkInvitationCodeAlert })}>
            Green Club 멤버십 신청
          </Button>
        </div>
      </BannerMembershipContainer>
      <ModalPortal>
        <Modal.Alert key={yolkInvitationCodeAlert} modal="alert" isDim={true} isAnimation={true}>
          <section className="content-wrapper">
            <h2 className="title">초대코드 발급을 신청할까요?</h2>
            <p className="desc">그린클럽 멤버십은 초대코드를 발급받고 결제한 후 이용하실 수 있습니다.</p>
          </section>
          <section className="button-wrapper">
            <button className="white" onClick={() => setAlertModal({ visible: false, key: yolkInvitationCodeAlert })}>
              취소
            </button>
            <button onClick={applyJoinMembership}>신청</button>
          </section>
        </Modal.Alert>
      </ModalPortal>
    </>
    // <>
    //   <RenewalContainer>
    //     <img src={RenewalRendingPage} alt="에그 인사말 상세보기" />
    //   </RenewalContainer>
    //   <Button classKey="yellow3" disabled={false} colorLevel="primary" type="sticky" onClick={() => navigate('/membership/entry', { state: 'goToMypage' })}>
    //     욜크멤버십 둘러보기
    //   </Button>
    // </>
  );
};

export default BannerMemberShip;
