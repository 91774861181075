import React from 'react';

import styled from '@emotion/styled';

import { Colors } from '@/shared/styles/colors';
import { Typography } from '@/shared/styles/typography';

export const BookingInfoContainer = styled.section<React.CSSProperties>`
  display: flex;
  height: 110px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  margin: 96px 32px 0px 32px;
  .mall-name {
    /* margin-bottom: 8px; */
    ${Typography.title_48}
    font-weight: 600;
  }
  .info-text {
    display: flex;
    /* width: 343px; */
    align-items: flex-start;
    gap: 24px;
    border-radius: 3px;
    .reservation-info {
      display: flex;
      align-items: center;
      gap: 12px;
      ${Typography.title_32_m}

      & > svg {
        width: 32px;
        height: 32px;
      }

      /* text-align: center;
      display: flex;
      flex-direction: column;
      width: 33.3%; */

      & > div {
        display: inline-block;
      }

      .label {
        /* margin-bottom: 26px; */
        ${Typography.title_32}
        > span {
          /* color: ${Colors.gray6}; */
        }
      }

      .value {
        color: ${Colors.gray8};
        ${Typography.body_28_m}
      }
    }
  }
`;

export const BigSizeBookingInfoTimer = styled.div<React.CSSProperties>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px;
  margin: 0 32px;
  align-items: center;
  gap: 24px;

  border-radius: 6px;
  border: 2px solid var(--secondary-500, #ddc5a4);
  background: var(--secondary-50010, rgba(221, 197, 164, 0.1));
  /* 큰 타이머 */
  /* 
  background-color: ${Colors.red0};
  border-radius: 8px;
  padding: 28px 60px 28px 48px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 16px;
  @media (min-width: 640px) and (max-width: 719px) {
    padding: 28px 32px;
  } */
  .time {
    display: flex;
    padding: 0px 16px;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 180px;

    color: ${Colors.beige};
    font-feature-settings: 'liga' off, 'clig' off;
    ${Typography.title_32_m}
  }

  .text {
    /* width: 490px; */
    color: ${Colors.white};
    ${Typography.small_2}
  }
  .small-size {
    background-color: red;
  }
`;

export const SmallSizeBookingInfoTimer = styled.div<React.CSSProperties>`
  /* small 타이머 */
  position: sticky;
  top: 0px;
  z-index: 10;

  background: ${Colors.beige};
  padding: 16px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  @media (min-width: 640px) and (max-width: 719px) {
    padding: 28px 32px;
  }
  .time {
    color: ${Colors.gray11};
    ${Typography.small_m}
  }

  .text {
    color: ${Colors.gray11};
    ${Typography.small_2}
  }
  .small-size {
    background-color: red;
  }
`;

export const TimerInterserction = styled.div<React.CSSProperties>`
  /* margin-top: -120px; */
  /* margin-bottom: 220px; */
`;
