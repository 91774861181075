import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';

function SlotTime({ item }) {
  const [diffTime, setDiffTime] = useState('0분 0초');
  const [diffTimeSecond, setDiffTimeSecond] = useState(null);

  function secondsToTime(seconds: number) {
    const duration = dayjs.duration(seconds, 'seconds');
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    const remainingSeconds = duration.seconds();
    return `${minutes}분 ${remainingSeconds}초`;
  }

  const raffleSlotTime = (items: any) => {
    const { raffleSlotStatus } = items;
    if (raffleSlotStatus === '00') {
      return <div className="announce-time wait">{diffTimeSecond > 0 ? diffTime + '후 추첨' : '추첨 중..'}</div>;
    }
    //대기
    if (raffleSlotStatus === '01') {
      return <div className="announce-time complete">{diffTimeSecond > 0 ? diffTime + '남음' : '재추첨 중..'}</div>;
    }
    //대기
    if (raffleSlotStatus === '99') {
      return <div className="announce-time reservation">{items.raffleSlotWinner}</div>;
    }
  };

  useEffect(() => {
    if (item.raffleSlotTime) {
      const id = setInterval(() => {
        const nowDate = dayjs(dayjs(), 'YYYY-MM-DD HH:mm:ss');
        const convertTime = dayjs(item.raffleSlotTime, 'YYYY-MM-DD HH:mm:ss');
        const covertTimeDiffNow = convertTime.diff(nowDate, 's');
        setDiffTime(secondsToTime(covertTimeDiffNow));
        setDiffTimeSecond(covertTimeDiffNow);
        if (convertTime.diff(nowDate) <= 0) {
          clearInterval(id);
        }
      }, 1000);
      return () => clearInterval(id);
    }
  }, [String(item.raffleSlotTime)]);

  return (
    <>
      <div className="content">
        <div className="reservation-time">
          <div>
            {/* date/ partySize */}
            {/* {dayjs(item.raffleSlotVisitDateTime).format('M. D. ddd ∙ A h:mm')} / {item.raffleSlotVisitPartySize}명 */}
            {item.raffleItemName}
          </div>
          <div>{item.raffleSlotStatus === '99' ? null : <div className="user">{item.raffleSlotWinner}</div>}</div>
        </div>
        {raffleSlotTime(item)}
      </div>
    </>
  );
}

export default SlotTime;
