import React from 'react';

import { DepositInfoContainer, DepositInfoTextContainer } from './DepositInfo.styles';

const DepositInfo = (): React.ReactElement => {
  return (
    <DepositInfoContainer>
      <h1>예약금</h1>
      <DepositInfoTextContainer>
        <span>No Show</span> (예약을 하고 나타나지 않는 행위) 방지를 위해 예약금 제도를 운영하는 매장입니다. 예약금은 예약 일시 및 인원에 따라 결정되며, 정확한 금액은 예약 단계에서
        확인할 수 있습니다.
      </DepositInfoTextContainer>
    </DepositInfoContainer>
  );
};

export default DepositInfo;
