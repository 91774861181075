import create from 'zustand';

export interface INavigationState {
  isNavigationVisible: boolean;
  setIsNavigationVisible: (props) => void;
}
export const useNavigationStore = create<INavigationState>((set) => ({
  isNavigationVisible: true,
  setIsNavigationVisible: (isNavigationVisible) => set(() => ({ isNavigationVisible })),
}));
