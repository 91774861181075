import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import axios from 'axios';

import { IRaffleResult } from './Announcement.types';

import { eggdiningApi } from '@/shared/apis/eggdiningApi';
import RAFFLE_QUERY_KEYS from '@/shared/apis/queryKeys/raffle';
import { useFooterStore } from '@/stores/common/useFooterStore';
import { useHeaderStore } from '@/stores/common/useHeaderStore';
import { useModalStore } from '@/stores/common/useModalStore';
import { useNavigationStore } from '@/stores/common/useNavigationStore';

const useHeaderSetting = () => {
  const setIsFooterVisible = useFooterStore((store) => store.setIsFooterVisible);
  const setIsNavigationVisible = useNavigationStore((store) => store.setIsNavigationVisible);
  const setHeaderState = useHeaderStore((store) => store.setHeaderState);
  const navigate = useNavigate();

  useEffect(() => {
    setIsFooterVisible(false);
    setIsNavigationVisible(false);
    setHeaderState({
      title: '8월 패키지',
      handleGoToBack: null,
      handleGoToHome: null,
      handleOnAlarm: null,
      handleOnClose: () => navigate(-1),
      handleOnShare: null,
    });
  }, []);
};

const useRafflesEntryResultQuery = ({ reservationComplete, timeOut, notJoinRaffle, failRaffle, winRaffle, setUserPreToken }) => {
  const { setAlertModal } = useModalStore();
  const [searchParams] = useSearchParams();
  const eventType = searchParams.get('eventType');

  return useQuery(useRafflesEntryResultQuery.getKeys(), () => useRafflesEntryResultQuery.getRaffleCheck(eventType), {
    enabled: false,
    onSuccess(data) {
      // 예약완료
      if (data.entry.winItemSlotList.find((item) => item.itemSlotState === '99')) {
        setAlertModal({ visible: true, key: reservationComplete });
        return;
      }
      // 당첨 무효
      if (data.entry.winItemSlotList.find((item) => item.itemSlotState === '22')) {
        setAlertModal({ visible: true, key: timeOut });
        return;
      }
      // 당첨
      if (data.entry.winItemSlotList.find((item) => item.itemSlotState === '20')) {
        setAlertModal({ visible: true, key: winRaffle });
        const filter = data.entry.winItemSlotList.find((item) => item.itemSlotState === '20');
        setUserPreToken(filter.reservationTempToken);
        return;
      }
      setAlertModal({ visible: true, key: failRaffle });
    },
    onError() {
      setAlertModal({ visible: true, key: notJoinRaffle });
    },
  });
};

useRafflesEntryResultQuery.getKeys = () => RAFFLE_QUERY_KEYS.GET_USER_RAFFLE_REUSLT;
useRafflesEntryResultQuery.getRaffleCheck = (eventType: string) => {
  return eggdiningApi.get<{ entry: IRaffleResult }>(`/eggdining/raffles/${eventType}/entry/result`);
};

const useRaffleSlotList = () => {
  const [searchParams] = useSearchParams();
  const eventType = searchParams.get('eventType');
  const navigate = useNavigate();
  return useQuery(useRaffleSlotList.getKeys(), () => useRaffleSlotList.getRaffleResult(eventType).then((res) => res.data.data), {
    onError(err) {
      alert('잘못된 접근 입니다.');
      navigate('/main');
    },
  });
};

useRaffleSlotList.getKeys = () => RAFFLE_QUERY_KEYS.GET_RAFFLE_ENTRY_RESULT;
useRaffleSlotList.getRaffleResult = (eventType) => {
  return axios.get(`${process.env.REACT_APP_API_RAFFLE_URL}/${eventType}_announcement_result.json`);
};

export { useHeaderSetting, useRafflesEntryResultQuery, useRaffleSlotList };
