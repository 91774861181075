import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { MainMallContainer, MainMallFullBadge, MainMallNewBadge, MainMallComingSoonBadge, MainMallLiveBadge } from '@/components/MainMall/MainMall.styles';
import { IMall } from '@/shared/types/MallList.type';
import { useScrollStore } from '@/stores/common/useScrollStore';

const MainMall = ({ mall }): React.ReactElement => {
  const { storeImages, storeFoods, storeName, storeUri, storeState }: IMall = mall;
  const navigate = useNavigate();
  const { setLastScrollY } = useScrollStore();
  const moveDetailMall = () => {
    const layoutRef = document.querySelector('main');
    setLastScrollY(layoutRef.scrollTop);
    navigate(`/detail/${storeUri}`);
  };

  return (
    <MainMallContainer onClick={moveDetailMall} backgroundImage={storeImages[0]}>
      {storeState === '05' ? <MainMallLiveBadge /> : null}
      {storeState === '09' ? <MainMallFullBadge /> : null}
      {storeState === '01' ? <MainMallComingSoonBadge /> : null}
      <div className="text-area">
        <div className="title-area">
          <h2>{storeName}</h2>
          <div className="desc">{storeFoods.join('∙')}</div>
        </div>
      </div>

      {/* {storeState === '09' ? <MainMallFullBadge /> : null} */}
      {/* {storeState === '01' ? <MainMallComingSoonBadge /> : null} */}
      {/* {storeState === '05' ? <MainMallLiveBadge /> : null} */}
      {/* <section className="mall-img-wrapper"> */}
      {/* <img src={storeImages[0]} loading="lazy" /> */}
      {/* <div className="blur-overlay"> */}
      {/* <div className="blur-background"></div> */}
      {/* <div className="overlay-contents"> */}
      {/* <h2>{storeName}</h2> */}
      {/* <p>{storeFoods.join('∙')}</p> */}
      {/* </div> */}
      {/* </div> */}
      {/* </section> */}
      {/* <h2>{storeName}</h2>
      <span>{storeFoods.join('∙')}</span> */}
    </MainMallContainer>
  );
};

export default MainMall;
