import React from 'react';

import styled from '@emotion/styled';

import { ImageCarouselContainer, ImageDetailCarouselItem } from '@/components/ImageCarousel/ImageCarousel.styles';

export const DetailContainer = styled.section<React.CSSProperties>`
  height: calc(100vh - 104px - 96px);
  position: relative;
  overflow: scroll;
  overscroll-behavior: none;

  & > ${ImageCarouselContainer} {
    ${ImageDetailCarouselItem} {
      height: 380px;
      > img {
        height: 100%;
      }
    }
  }
`;

export const DetailAdditionalInformation = styled.div<React.CSSProperties>`
  > section:first-of-type {
    padding: 48px 32px 48px;
  }
  > section:not(:first-of-type) {
    padding: 48px 32px;
  }
  > section:last-of-type {
    padding: 48px 32px 96px;
  }
`;
