import * as React from 'react';
import { Controller } from 'react-hook-form';

import RadioGroup from '@/components/RadioGroup/RadioGroup';
import { HookFormProps } from '@/shared/types/common.types';

export const ControlledRadioGroup: React.FC<HookFormProps> = ({ ...props }: any) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      render={({ field: { onChange, name: fieldName, value: hookFormValue }, fieldState: { error } }) => {
        return (
          <RadioGroup
            {...props}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onChange(e);
              return props.onChange && props.onChange(e);
            }}
            name={fieldName}
            selected={hookFormValue ?? ''}
          />
        );
      }}
    />
  );
};
