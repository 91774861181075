import { useQuery } from 'react-query';

import { eggdiningApi } from '@/shared/apis/eggdiningApi';
import MAIN_PAGE_QUERY_KEYS from '@/shared/apis/queryKeys/mainPage';
import { IBannerResult } from '@/shared/types/Banner.type';
import { IMallListResults } from '@/stores/useBookingStore';

function useQueryBanner() {
  return useQuery(useQueryBanner.getKeys(), () => useQueryBanner.fetcher(), {
    staleTime: Infinity,
    retry: false,
  });
}

useQueryBanner.getKeys = () => [MAIN_PAGE_QUERY_KEYS.MAIN_PAGE_BANNER_LIST];
useQueryBanner.fetcher = () => eggdiningApi.get<IBannerResult>('/partners/banners');

function useQueryMallList() {
  return useQuery(useQueryMallList.getKeys(), () => useQueryMallList.fetcher(), {
    staleTime: Infinity,
    retry: false,
  });
}

useQueryMallList.getKeys = () => [MAIN_PAGE_QUERY_KEYS.MAIN_PAGE_MALL_LIST];
useQueryMallList.fetcher = () => eggdiningApi.get<IMallListResults>('/eggdining/stores');

export { useQueryBanner, useQueryMallList };
