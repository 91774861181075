import { useGoogleLogin } from '@react-oauth/google';

const useGoogleLoginHook = () => {
  const googleClientId = process.env.REACT_APP_GOOGLE_APP_KEY;

  const handleGoogleLogin = useGoogleLogin({
    flow: 'auth-code',
    ux_mode: 'redirect',
    // redirect_uri: 'http://localhost:3000/eggdining/oauth/google/login',
    redirect_uri: process.env.REACT_APP_GOOGLE_REDIRECT_URL,
  });
  return { handleGoogleLogin, googleClientId };
};
export { useGoogleLoginHook };
