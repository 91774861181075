import { useMutation } from 'react-query';

import { eggdiningApi } from '@/shared/apis/eggdiningApi';
import { LocalStorage } from '@/shared/configs/storage';

interface IUserWithdrawal {
  code: string;
}

const useMemberWithdrawalQuery = () => {
  const memberId = LocalStorage.getItem('memberId') || null;

  return useMutation(() => useMemberWithdrawalQuery.withdrawal(memberId), {
    onError(error, variables, context) {
      console.log(error);
    },
  });
};

useMemberWithdrawalQuery.withdrawal = (memberId: string) => {
  return eggdiningApi.post<IUserWithdrawal>(`eggdining/users/${memberId}/deleteMember/02`);
};

export { useMemberWithdrawalQuery };
