import create from 'zustand';

import { Nullable } from '@/shared/types/common.types';

export interface IBookingValidationStore {
  validations: any;
  dispatchValidations: (validations: any) => void;
  clear: () => void;
}

export interface IValidations {
  userError: Nullable<string>;
  emailError: Nullable<string>;
  phoneNumbersError: Nullable<string>;
  phoneAuthError: Nullable<string>;
  detailPartySizeError: Nullable<string>;
  agreementError: Nullable<string>;
  bookingMenusError: Nullable<string>;
  additionalInfoError: Nullable<string>;
}

const initialValidations: IValidations = {
  userError: null,
  emailError: null,
  phoneNumbersError: null,
  phoneAuthError: null,
  detailPartySizeError: null,
  agreementError: '서비스 이용 및 약관 결제 동의를 체크해주세요.',
  bookingMenusError: null,
  additionalInfoError: null,
};

const validationsReducer = (state, action) => {
  const { value } = action;
  switch (action.type) {
    case 'AGREEMENT_INVALID':
      return { ...state, agreementError: '서비스 이용 및 약관 결제 동의를 체크해주세요.' };
    case 'AGREEMENT_COMPLETE':
      return { ...state, agreementError: null };
    case 'ADDITIONAL_INFO_REQUIRED':
      return { ...state, additionalInfoError: `${value}를 입력해주세요.` };
    case 'ADDITIONAL_INFO_COMPLETED':
      return { ...state, additionalInfoError: null };
  }
};

export const useBookingValidationStore = create<IBookingValidationStore>((set) => ({
  validations: initialValidations,
  dispatchValidations: (action) => set(({ validations }) => ({ validations: validationsReducer(validations, action) })),
  clear: () =>
    set(() => {
      return {
        validations: initialValidations,
      };
    }),
}));
