import {} from './MenuSelector.type';

import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import classNames from 'classnames';
import hasIn from 'lodash/hasIn';

import { MenuSelectorContainer } from './MenuSelector.constants';

import InputHint from '@/components/InputHint/InputHint';
import MainSubtitle from '@/components/MainSubtitle/MainSubtitle';
import { useBookingInfoStore } from '@/stores/useBookingInfoStore';
import { useDetailInfoStore } from '@/stores/useDetailInfoStore';

const MenuSelector = (): React.ReactElement => {
  const { bookingMenus, partySize, dispatchBookingInfo } = useBookingInfoStore();
  const { policyMenus } = useDetailInfoStore();
  const [requiredMenuSelect, setRequiredMenuSelect] = useState(false);

  const methods = useFormContext();
  const {
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  }: any = methods;

  const setMenus = (value, menuId) => {
    let count = 0;
    let menuPrice = 0;
    const selectedMenus = [...bookingMenus];
    const targetMenuIndex = selectedMenus.findIndex((bMenu) => bMenu.menu_id === menuId);
    if (targetMenuIndex > -1) {
      selectedMenus[targetMenuIndex].count = Number(value);
    } else {
      selectedMenus.push({ menu_id: menuId, count: Number(value) });
    }
    policyMenus.forEach((pMenu) => {
      selectedMenus.forEach((bMenu) => {
        if (pMenu.menu_id === bMenu.menu_id) {
          menuPrice += bMenu.count * pMenu.deposit_price;
          count += pMenu.population * bMenu.count;
        }
      });
    });
    if (requiredMenuSelect) {
      setValue('menuSelector', Boolean(count === partySize));
      if (count === partySize) {
        clearErrors('menuSelector');
      } else {
        setError('menuSelector', { message: '인원 수 만큼 메뉴를 선택해주세요.', type: 'BOOKING_MENUS_INVALID' });
      }
    }
    dispatchBookingInfo({ type: 'SET_MENU_PRICE', value: menuPrice });
    dispatchBookingInfo({ type: 'SET_BOOKING_MENU', value: selectedMenus });
  };

  const makeMenuOption = (menuId) => {
    const targetMenu = policyMenus.find((pMenu) => pMenu.menu_id === menuId);
    let numbers = [];
    if (targetMenu.population_use) {
      let count = 0;
      policyMenus.forEach((pMenu) => {
        bookingMenus.forEach((bMenu) => {
          if (pMenu.menu_id === bMenu.menu_id && pMenu.menu_id !== menuId) {
            count = pMenu.population * bMenu.count;
          }
        });
      });
      numbers = Array.from(Array(Math.floor((partySize - count) / targetMenu.population) + 1).keys());
    } else {
      numbers = Array.from(Array(partySize + 1).keys());
    }
    return numbers;
  };

  const getMenuCount = (menuId) => {
    const targetMenu = bookingMenus.find((bMenu) => bMenu.menu_id === menuId);
    if (targetMenu) {
      return targetMenu.count;
    }
    return 0;
  };

  const sendParentEvent = () => window.parent.postMessage({ func: 'hideKeyPad' }, '*');

  useEffect(() => {
    if ((bookingMenus.length === 0 && policyMenus.length > 0) || bookingMenus.length !== policyMenus.length) {
      const menus = [];
      policyMenus.forEach((menu) => {
        menus.push({ menu_id: menu.menu_id, count: 0 });
      });
      dispatchBookingInfo({ type: 'SET_BOOKING_MENU', value: menus });
    }
  }, [policyMenus]);

  useEffect(() => {
    if (policyMenus.some((menu) => menu.population_use)) {
      setRequiredMenuSelect(true);
      let count = 0;
      policyMenus.forEach((pMenu) => {
        bookingMenus.forEach((bMenu) => {
          if (pMenu.menu_id === bMenu.menu_id) {
            count = pMenu.population * bMenu.count;
          }
        });
      });
      setValue('menuSelector', Boolean(count === partySize));
    }
  }, [policyMenus]);

  return (
    <MenuSelectorContainer>
      <MainSubtitle subtitle="메뉴상세" firebrick={true} />
      {policyMenus.map((menu, idx) => (
        <div key={idx}>
          {!menu.population_use || (menu.population_use && menu.population <= partySize) ? (
            <div className={classNames('menu-container', { error: hasIn(errors, 'menuSelector') })}>
              <div className="label-container">
                <div className="name">
                  {menu.name} {menu.population_use ? <span>({menu.population} 인분)</span> : null}
                </div>
                <div className="description">{menu.desc}</div>
                <div className="price">{menu.deposit_price} 원</div>
              </div>
              <div className="select-container">
                <FormControl>
                  <select
                    placeholder="0개"
                    onChange={(e) => setMenus(e.target.value, menu.menu_id)}
                    value={getMenuCount(menu.menu_id)}
                    className="select-menu-box"
                    onBlur={sendParentEvent}
                  >
                    {makeMenuOption(menu.menu_id).map((n) => (
                      <option value={n} key={n}>
                        {n}개
                      </option>
                    ))}
                  </select>
                </FormControl>
              </div>
            </div>
          ) : null}
        </div>
      ))}
      {hasIn(errors, 'menuSelector') ? <InputHint text={errors.menuSelector.message} /> : null}
    </MenuSelectorContainer>
  );
};

export default MenuSelector;
