const useAppleLoginHook = () => {
  const config = {
    client_id: process.env.REACT_APP_APPLE_APP_KEY,
    redirect_uri: process.env.REACT_APP_APPLE_REDIRECT_URL,
    response_type: 'code id_token',
    state: 'origin:web',
    scope: 'name email',
    response_mode: 'form_post',
    m: 11,
    v: '1.5.4',
    usePopup: true,
  };
  const queryString = Object.entries(config)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');

  const handleAppleLogin = () => {
    location.href = `https://appleid.apple.com/auth/authorize?${queryString}`;
  };
  return {
    handleAppleLogin,
  };
};

export { useAppleLoginHook };
