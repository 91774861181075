const QUERY_KEYS = {
  BOOKING_GLOBAL_REGIONS: 'BOOKING_GLOBAL_REGIONS',
  BOOKING_GLOBAL_MALLLIST: 'BOOKING_GLOBAL_MALLLIST',

  BOOKING_MAIN_PARTNER_BANNER: 'BOOKING_MAIN_PARTNER_BANNER',
  BOOKING_MAIN_VOUCHER_PRODUCT: 'BOOKING_MAIN_VOUCHER_PRODUCT',

  BOOKING_MALLDETAIL_GETMALL: 'BOOKING_MALLDETAIL_GETMALL',

  MALLDETAIL_GETDATES: 'MALLDETAIL_GETDATES',
  MALLDETAIL_GETTIMES: 'MALLDETAIL_GETTIMES',
  MALL_STEP_CATEGORY: 'MALL_STEP_CATEGORY',

  BOOKING_PROVIDER_REGIONS: 'BOOKING_PROVIDER_REGIONS',
  BOOKING_PROVIDER_MALLS: 'BOOKING_PROVIDER_MALLS',
  BOOKING_RESERVATION_PRE: 'BOOKING_RESERVATION_PRE',
};

export default QUERY_KEYS;
