import React, { useEffect, useRef, useState } from 'react';

import { ModalContainer } from '../modal.styles';

import { IModalProps, IhideDuration } from '@/components/Modal/Modal.type';
import { useModalStore } from '@/stores/common/useModalStore';

const Toast = ({ autoHideDuration, isAnimation, position = 'top', margin, children, toastKey }: IModalProps): React.ReactElement => {
  const { toastList, setToastList } = useModalStore();
  const hideDurations = useRef<IhideDuration>();
  const [open, setOpen] = useState<boolean>(true);
  const [fadeOut, setFadeOut] = useState<boolean>(false);

  useEffect(() => {
    if (!hideDurations.current) {
      hideDurations.current = setTimeout(() => {
        setOpen(false);
      }, autoHideDuration + 500);
    }
  }, []);

  useEffect(() => {
    if (!open) {
      clearTimeout(hideDurations.current);
      const removeToast = toastList.filter((item) => item.key !== toastKey);
      setToastList(removeToast);
    }
  }, [open, toastList]);

  useEffect(() => {
    hideDurations.current = setTimeout(() => {
      setFadeOut(true);
    }, autoHideDuration);
  }, []);

  return (
    <ModalContainer toastModal={true} className={` toast ${isAnimation ? 'animation' : ''} ${fadeOut ? 'animation-fadeout' : ''}`}>
      <div className={`modal-content ${position}`} style={margin ? { transform: `translate(-50%, -50%) translateY(${position === 'bottom' ? '-' : ''}${margin}px)` } : null}>
        {children}
      </div>
    </ModalContainer>
  );
};

export default React.memo(Toast);
