import { useEffect, useState } from 'react';
import type { NavigateFunction } from 'react-router-dom';

import { TUseFacilityViewer } from './FacilityViewer.types';

import { useHeaderStore } from '@/stores/common/useHeaderStore';

export const useStateFacilityViewer = (navigate: NavigateFunction): TUseFacilityViewer => {
  const setHeaderState = useHeaderStore((store) => store.setHeaderState);

  const [isFacilityViewAll, setIsFacilityViewAll] = useState(false);

  useEffect(() => {
    if (isFacilityViewAll) {
      setHeaderState({
        title: '매장 상세보기',
        handleGoToBack: () => setIsFacilityViewAll(false),
        handleGoToHome: () => navigate('/main'),
        handleOnAlarm: null,
        handleOnClose: null,
        handleOnShare: null,
      });
      setIsFacilityViewAll(true);
    }
  }, [isFacilityViewAll]);

  return [isFacilityViewAll, setIsFacilityViewAll];
};
