import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { HeaderPathData } from '../Header/Header.constants';

import { eggdiningApi } from '@/shared/apis/eggdiningApi';
import { LocalStorage } from '@/shared/configs/storage';
import { useHeaderStore } from '@/stores/common/useHeaderStore';
import { useModalStore } from '@/stores/common/useModalStore';
import { useEggLoadingStore } from '@/stores/useEggLoadingStore';

const useAxiosInterceptors = () => {
  const { isOpenyEggLoading, isOpenIconLoading, setOpenEggLoading, setOpenIconLoading } = useEggLoadingStore();
  const defalutSpinnerArr = [
    {
      path: 'user/termsAgreements',
      type: 'none',
    },
    {
      path: 'payment-method',
      type: 'icon',
    },
    {
      path: '/eggdining/users',
      type: 'none',
    },
    {
      path: 'reservation/date?partySize',
      type: 'icon',
    },
    {
      path: 'reservation/category?count_people',
      type: 'icon',
    },
    {
      path: 'reservation/pre',
      type: 'icon',
    },
    {
      path: 'reservations/pre',
      type: 'icon',
    },
    {
      path: 'reservation/time',
      type: 'icon',
    },
    {
      path: 'reservations/confirm',
      type: 'icon',
    },
    {
      path: 'reservation/category',
      type: 'none',
    },
    {
      path: 'service/policies',
      type: 'none',
    },
    {
      path: 'eggdining/reservations',
      type: 'icon',
    },
  ];

  const findUrlInSpinner = (url) => {
    return defalutSpinnerArr.find((item) => url.includes(item.path));
  };

  useEffect(() => {
    eggdiningApi.api.interceptors.request.use((value) => {
      if (isOpenyEggLoading || isOpenIconLoading) {
        return value;
      }
      const findSpinnerObject = findUrlInSpinner(value.url);
      if (findSpinnerObject?.type === 'none') {
        return value;
      }
      if (findSpinnerObject) {
        setOpenIconLoading(true);
        return value;
      }
      setOpenEggLoading(true);
      return value;
    });
    eggdiningApi.api.interceptors.response.use(
      (response) => {
        const findSpinnerObject = findUrlInSpinner(response.config.url);
        if (findSpinnerObject) {
          setOpenIconLoading(false);
          return response;
        }
        setOpenEggLoading(false);
        return response;
      },
      (error) => {
        setOpenIconLoading(false);
        setOpenEggLoading(false);
        return Promise.reject(error);
      }
    );
  }, []);

  return { isOpenyEggLoading, isOpenIconLoading };
};

const closeApp = () => {
  window?.webkit?.messageHandlers?.shutdown?.postMessage('');
  window?.Eggapp?.shutDown();
};

const useCustomHistoryBackHooks = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { headerState, setHeaderState } = useHeaderStore();
  const { setOpenGlobalConfirmModal, alertModal, setAlertModal } = useModalStore();
  const { title, handleGoToBack, handleGoToHome, handleOnAlarm: handleOnAlarm, handleOnAlarmOff, handleOnClose, handleOnShare, alarmOn } = headerState;

  const HeaderData = HeaderPathData(navigate)[pathname];

  useEffect(() => {
    if (HeaderData) {
      setHeaderState({
        title: HeaderData.title,
        handleGoToBack: HeaderData.handleGoToBack,
        handleGoToHome: HeaderData.handleGoToHome,
        handleOnAlarm: HeaderData.handleOnAlarm,
        handleOnAlarmOff: HeaderData.handleOnAlarmOff,
        handleOnClose: HeaderData.handleOnClose,
        handleOnShare: HeaderData.handleOnShare,
        alarmOn: HeaderData.alarmOn,
      });
    }
  }, [pathname]);

  window.LilloBackHook = () => {
    const loginType = LocalStorage.getItem('provider');
    if (pathname === '/main') {
      if (alertModal.key === 'alertEventPopupKey') {
        if (alertModal.visible) {
          setAlertModal({ visible: false, key: 'alertEventPopupKey' });
          return;
        }
      }
      if (loginType) {
        setOpenGlobalConfirmModal({
          visible: true,
          title: '안내',
          message: '다이닝클럽을 종료하시겠습니까?',
          closeText: '취소',
          okayText: '종료',
          callback: () => {
            closeApp();
          },
        });
      } else {
        return navigate('/auth/signin');
      }
    } else if (pathname === '/mypage' || pathname === '/auth/signin' || pathname === '/') {
      setOpenGlobalConfirmModal({
        visible: true,
        title: '안내',
        message: '다이닝클럽을 종료하시겠습니까?',
        closeText: '취소',
        okayText: '종료',
        callback: () => {
          closeApp();
        },
      });
      return;
    }

    if (handleGoToBack) {
      return handleGoToBack();
    } else if (handleOnClose) {
      return handleOnClose();
    }
    return null;
  };
  window.LilloForwardHook = () => {
    return null;
  };
};

export { useAxiosInterceptors, useCustomHistoryBackHooks };
