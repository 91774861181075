import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useAuthPhoneTokenCheckVerifyStore } from '../CoreIdentity/ColreIdentity.sotre';
import { mock } from '../TermsGroup/TermsGroup.constants';

import { eggdiningApi } from '@/shared/apis/eggdiningApi';
import OAUTH_USER_QUERY_KEYS from '@/shared/apis/queryKeys/auth';
import { LocalStorage } from '@/shared/configs/storage';
import { useLilloSignUp } from '@/shared/utils/useLilloSdk';
import { validationName, validationPhoneCertified, validationPhoneNumber } from '@/shared/validation';
import { useFooterStore } from '@/stores/common/useFooterStore';
import { useHeaderStore } from '@/stores/common/useHeaderStore';
import { useNavigationStore } from '@/stores/common/useNavigationStore';
import { useUserProfileStore } from '@/stores/useUserProfle';

interface ITermsList {
  id: number;
  type: string;
  required: boolean;
  value: boolean;
  content: {
    EN: {
      name: string;
      contentUrl: string;
    };
    KO: {
      name: string;
      contentUrl: string;
    };
  };
  createdAt: string;
}

interface ITermsHookForm {
  user: any;
  userName: string;
  phoneNumber: string;
  certifiedNumber: string;
  phoneCertified: boolean;
  agreements: ITermsList[];
}

const useIdentityLoaoutController = (navigate) => {
  const setHeaderState = useHeaderStore((store) => store.setHeaderState);
  const setIsFooterVisible = useFooterStore((store) => store.setIsFooterVisible);
  const setIsNavigationVisible = useNavigationStore((store) => store.setIsNavigationVisible);
  useEffect(() => {
    setHeaderState({
      title: '회원가입',
      handleGoToBack: null,
      handleGoToHome: null,
      handleOnAlarm: null,
      handleOnClose: () => navigate('/auth/signin'),
      handleOnShare: null,
    });
    setIsFooterVisible(false);
    setIsNavigationVisible(false);
  }, []);
  return null;
};

const useIdentityHookFormController = () => {
  const name = LocalStorage.getItem('signInName');
  const validationSchema = useMemo(() => {
    return yup.object().shape({
      user: yup.object({
        userName: validationName,
        phoneNumber: validationPhoneNumber,
      }),
      certifiedNumber: yup.string().min(6, '인증번호를 입력해 주세요.').required('필수'),
      phoneCertified: validationPhoneCertified,
      agreements: yup.array(
        yup.object({
          required: yup.boolean(),
          value: yup.boolean().when('required', {
            is: (required) => required,
            then: yup.boolean().test('AGREEMENT_VALUE', '인증 ', (value) => {
              return value;
            }),
          }),
        })
      ),
    });
  }, []);

  const methods = useForm<ITermsHookForm>({
    defaultValues: {
      user: { userName: name && name !== 'none name' ? name : '', phoneNumber: '' },
      certifiedNumber: '',
      phoneCertified: true,
      agreements: [],
    },
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const { getValues, reset } = methods;

  useEffect(() => {
    const settingAgreement = mock.map((x) => {
      return {
        ...x,
        value: false,
      };
    });
    reset({ ...getValues(), agreements: settingAgreement });
  }, []);
  return methods;
};

interface IOuthRegisterRes {
  code: string;
  token: string;
  name: string;
  phone: string;
  email: string;
  memberId: string;
  provider: string;
}

interface ISignUpMatationParams {
  phone: string;
  name: string;
}

const useSignUpQuery = () => {
  const { verifyCode } = useAuthPhoneTokenCheckVerifyStore();
  const { setMemberId } = useUserProfileStore();

  return useMutation(({ phone, name }: ISignUpMatationParams) => useSignUpQuery.sendUserPhoneSignUp(name, phone, verifyCode), {
    onSuccess: (data) => {
      LocalStorage.setItem('memberId', data.memberId);
      LocalStorage.setItem('jwtToken', data.token);
      LocalStorage.setItem('provider', data.provider);
      useLilloSignUp(data);
      setMemberId(data.memberId);
      LocalStorage.removeItem('accessToken');
    },
  });
};

useSignUpQuery.getKeys = () => [OAUTH_USER_QUERY_KEYS.USER_SIGN_UP];
useSignUpQuery.sendUserPhoneSignUp = (name, phone, verifyCode) => {
  const social = LocalStorage.getItem('social');
  return eggdiningApi.post<IOuthRegisterRes>(`/eggdining/oauth/${social}/register`, {
    name: name,
    accessToken: LocalStorage.getItem('accessToken'),
    phone: phone.split('').join(''),
    verifyCode,
  });
};

export { useIdentityLoaoutController, useIdentityHookFormController, useSignUpQuery };
