import React from 'react';

import Announcement from '@/components/Raffle/Announcement/Announcement';
import { useRafflesEntryResultQuery, useRaffleSlotList } from '@/components/Raffle/Announcement/Announcement.hooks';

function AnnouncementPage() {
  const { isLoading: raffleIsloading } = useRaffleSlotList();

  if (raffleIsloading) {
    return null;
  }
  return <Announcement />;
}

export default AnnouncementPage;
