import React from 'react';

import { ModalContainer } from '../modal.styles';

import { IModalProps } from '@/components/Modal/Modal.type';
import { useModalStore } from '@/stores/common/useModalStore';

const Alert = ({ isDim, classKey = undefined, isAnimation, children, onClose }: IModalProps) => {
  const { alertModal, setAlertModal } = useModalStore();

  if (!alertModal.visible) {
    return <></>;
  }
  return (
    <ModalContainer className={`alert ${isAnimation ? 'animation' : ''} ${classKey}`}>
      {isDim ? <div className="modal-dim" onClick={() => (onClose ? onClose() : setAlertModal(false))} /> : null}
      <div className="modal-content">
        {/* <div className="close"><Close onClick={() => onClose ? onClose() : setAlertModal(false)} /></div> */}
        {children}
      </div>
    </ModalContainer>
  );
};

export default Alert;
