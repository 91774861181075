import React from 'react';

import Button from '../Button/Button';
import { MallTitleContainer } from './MallTitle.constants';

import { ReactComponent as Bell } from '@/assets/img/icon/bell.svg';
import { ReactComponent as BellOff } from '@/assets/img/icon/bell_off.svg';
import { useHeaderStore } from '@/stores/common/useHeaderStore';
import { useBookingStore } from '@/stores/useBookingStore';

const MallTitle = (): React.ReactElement => {
  const { mall } = useBookingStore();
  const { headerState } = useHeaderStore();
  const { title, handleGoToBack, handleGoToHome, handleOnAlarm: handleOnAlarm, handleOnAlarmOff, handleOnClose, handleOnShare, alarmOn } = headerState;

  return (
    <MallTitleContainer>
      <div>
        <h1>{mall.storeName}</h1>
        {handleOnAlarm && (
          <Button colorLevel={alarmOn ? 'gray-white' : 'green'} type="small" onClick={alarmOn ? handleOnAlarmOff : handleOnAlarm}>
            {alarmOn ? <BellOff></BellOff> : <Bell></Bell>}
            <span>{alarmOn ? '알림 해제' : '알림 받기'}</span>
          </Button>
        )}
      </div>
      <span>{mall.storeFoods.join('∙')}</span>
      {mall.storeDescription && <h2>{mall.storeDescription}</h2>}
    </MallTitleContainer>
  );
};

export default MallTitle;
