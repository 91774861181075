import {} from './RefundPolicy.constants';
import React from 'react';

import styled from '@emotion/styled';

import { IRefundPolicy } from './RefundPolicy.type';

import { Colors } from '@/shared/styles';
import { Typography } from '@/shared/styles/typography';

const RefundPolicyEmotion = styled.div<React.CSSProperties>`
  display: flex;
  /* padding: 0px 16px; */
  padding-bottom: 96px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;

  & > h3 {
    ${Typography.body_28};
    word-break: keep-all;
  }
  .cancel-notice {
    /* color: #FFF; */
    font-feature-settings: 'liga' off, 'clig' off;
    ${Typography.body_28_ss};
  }
  .refund-policy-items {
    color: var(--red-400, #ff7c75);
    font-feature-settings: 'liga' off, 'clig' off;

    /* Body 2 - Regular, 14, 20 */
    font-family: pretendard, sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
  & > table {
    width: 100%;
    margin-top: 40px;
    border-collapse: separate;
    border-top: 2px solid ${Colors.gray9};
    border-bottom: 2px solid ${Colors.gray2};

    thead {
      ${Typography.small_1}
      background: ${Colors.gray0};
      th {
        width: 50%;
        height: 64px;
        vertical-align: middle;
        &:first-of-type {
          border-right: 2px solid ${Colors.gray2};
        }
      }
    }
    tbody {
      ${Typography.small_1}
      td {
        width: 50%;
        height: 64px;
        vertical-align: middle;
        text-align: center;
        border-top: 2px solid ${Colors.gray2};
        &:first-of-type {
          border-right: 2px solid ${Colors.gray2};
        }
      }
    }
  }
`;

const RefundPolicy = ({ policies }: IRefundPolicy): React.ReactElement => {
  function replaceRefundPolicy(item, valueType) {
    let dateType = '일';
    let percent = '';
    let value = '';
    switch (item.dateType) {
      case 0:
        dateType = '일';
        break;
      case 1:
        dateType = '월';
        break;
      case 2:
        dateType = '노쇼';
        break;
      default:
        dateType = '일';
        break;
    }
    if (item.refundable) {
      switch (item.percent) {
        case 100:
          percent = '없음';
          break;
        default:
          percent = `결제 금액의 ${100 - item.percent}%`;
          break;
      }
    } else {
      percent = '환불 불가';
    }
    if (dateType === '노쇼') {
      value = '이용일 당일 및 No Show';
    } else if (item.dateValue === 0) {
      value = '당일';
    } else {
      value = item.dateValue + dateType + ' 전';
    }

    if (valueType) {
      return value;
    }
    return percent;
  }
  return (
    <RefundPolicyEmotion>
      <h3 className="cancel-notice">
        예약 후 노쇼로 인한 다른 회원의 이용 제한을 취소화하고 합리적인 예약문화를 정착하고자 취소 수수료 부과 제도를 통한 예약 시스템을 운영하고 있습니다.
      </h3>
      {policies.map((item, idx) => (
        <div className="refund-policy-items">
          <svg xmlns="http://www.w3.org/2000/svg" width="3" height="20" viewBox="0 0 3 20" fill="none">
            <circle cx="1.5" cy="9.5" r="1.5" fill="#FF7C75" />
          </svg>
          &nbsp;
          {replaceRefundPolicy(item, true)} : {replaceRefundPolicy(item, false)}
        </div>
      ))}
      {/*
      <table>
        <thead>
          <tr>
            <th>취소 시점 (이용일 기준)</th>
            <th>취소 수수료</th>
          </tr>
        </thead>
        <tbody>
          {policies.map((item, idx) => (
            <tr key={idx}>
              <td>{replaceRefundPolicy(item, true)}</td>
              <td>{replaceRefundPolicy(item, false)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      */}
    </RefundPolicyEmotion>
  );
};

export default RefundPolicy;
