import React from 'react';

import styled from '@emotion/styled';

import { Typography } from '@/shared/styles';

export const PartySizeSelectorContainer = styled.div<React.CSSProperties>`
  margin-top: 48px;
  padding: 32px;
  display: flex;
  justify-content: space-between;
  background: #14120e66;
  align-self: stretch;
  align-items: center;
  border-radius: 6px;

  .people-text {
    ${Typography.body_28}
    line-height: 72px;
  }

  .icon-box {
    display: flex;
    align-items: center;
    border-radius: 12px;
    background: var(--gray-90040, rgba(20, 18, 14, 0.4));

    .icon {
      display: flex;
      width: 80px;
      height: 80px;
      padding: 24px;
      justify-content: center;
      align-items: center;
      border-radius: 99px;
      &.disabled {
        opacity: 0.2;
      }
    }

    .people-value {
      width: 112px;
      text-align: center;
      font-feature-settings: 'liga' off, 'clig' off;

      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: 40px; /* 142.857% */
    }

    .divider {
      width: 2px;
      align-self: stretch;
      opacity: 0.1;
      background: #d9d9d9;
    }
  }
`;
