import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { eggdiningApi } from '@/shared/apis/eggdiningApi';
import { useFooterStore } from '@/stores/common/useFooterStore';
import { useHeaderStore } from '@/stores/common/useHeaderStore';
import { useModalStore } from '@/stores/common/useModalStore';
import { useNavigationStore } from '@/stores/common/useNavigationStore';

export interface IReservationPrepayment {
  reservationId: number;
  result: boolean;
  status: string;
}

export default function PaymentComplete() {
  const navigate = useNavigate();
  const location = useLocation();
  const { setOpenGlobalErrorModal } = useModalStore();
  const search = location.search;
  const status = new URLSearchParams(search).get('status');
  const merchantUid = new URLSearchParams(search).get('orderId');
  const message = new URLSearchParams(search).get('message');
  const [count, setCount] = useState(0);
  const setIsFooterVisible = useFooterStore((store) => store.setIsFooterVisible);
  const setHeaderState = useHeaderStore((store) => store.setHeaderState);
  const setIsNavigationVisible = useNavigationStore((store) => store.setIsNavigationVisible);
  const { data: validInvitationCodeData, refetch } = useQuery(
    ['INVITATION_CODE'],
    () => {
      const token = localStorage.getItem('jwtToken');
      if (token) {
        eggdiningApi.configure({ headers: { authorization: 'Bearer ' + token } });
      }
      return eggdiningApi.get<IReservationPrepayment>(`/reservations/prepayment/status?impUid=${merchantUid}`);
    },
    {
      onSuccess: (data) => {
        if (data.status === 'FAIL') {
          navigate(-4);
          return;
        }
        if (data.status === 'WAIT') {
          if (count < 10) {
            setCount(count + 1);
            refetch();
          }
        }
        if (data.status === 'SUCCESS') {
          navigate(`/reservation/step/complete-view/${data.reservationId}`);
        }
      },
      onError: (error: any) => {},
      enabled: Boolean(merchantUid) && status === 'SUCCESS',
      retry: 10,
      retryDelay: 100,
    }
  );
  useEffect(() => {
    const storeUri = localStorage.getItem('storeUri');
    if (status === 'FAIL') {
      setOpenGlobalErrorModal({ visible: true, message: message, title: '알림', closeText: '닫기', callback: () => navigate(`/detail/${storeUri}`, { replace: true }) });
    }
  }, [status]);

  useEffect(() => {
    setIsFooterVisible(false);
    setIsNavigationVisible(false);
    setHeaderState({
      title: null,
      handleGoToBack: null,
      handleGoToHome: null,
      handleOnAlarm: null,
      handleOnClose: null,
      handleOnShare: null,
    });
  }, []);
  return null;
}
