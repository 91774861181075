import React, { useRef } from 'react';
import ReactDOM from 'react-dom';

import styled from '@emotion/styled';

import { useEggLoadingStore } from '@/stores/useEggLoadingStore';

interface ISpinnerPortal {
  isLoading: boolean;
}

const PortalWrapper = styled.div<ISpinnerPortal>`
  width: 100vw;
  height: 100vh;
  /* background-color: rgba(39, 42, 45, 0.4); */
  position: ${(props) => (props.isLoading ? 'absolute' : '')};
  top: 0;
  z-index: 99;
`;

const SpinnerPortal = ({ children }): React.ReactElement => {
  const { isOpenyEggLoading, isOpenIconLoading } = useEggLoadingStore();

  const container = useRef(document.body);
  return ReactDOM.createPortal(<PortalWrapper isLoading={isOpenyEggLoading || isOpenIconLoading}>{children}</PortalWrapper>, container.current);
};

export default SpinnerPortal;
