import React from 'react';

import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

export const ScheduleContainer = styled.div<React.CSSProperties>`
  margin-bottom: 80px;
  .schedule-header {
    margin-bottom: 64px;
    ${Typography.title_44}
  }
  .custom-check-style {
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin-bottom: 20px;
    > label {
      height: 126px;
      padding: 24px 32px;
      border: 2px solid ${Colors.gray3};
      border-radius: 4px;
    }

    input:disabled + label div {
      color: #dee1e5;
    }

    .check-img-wrapper {
      border: 2px solid #cfd3d9;
      border-radius: 50px;
    }

    &:active {
      .check-img-wrapper {
        background: rgba(246, 201, 68, 0.2);
      }
    }
    input:checked + label {
      border: 2px solid ${Colors.yellow1};
      .check-img-wrapper {
        background-color: ${Colors.yellow1};
        border: 0;
        .check-img {
          border: 0px;
          border-radius: 4px;
          svg > path {
            stroke: #ffffff;
          }
        }
      }
    }
  }
  .checkbox-wrapper {
    .raffle-title {
      margin-bottom: 6px;
      ${Typography.body_28_sb}
      color: ${Colors.gray8}
    }
    .raffle-description {
      ${Typography.small_1}
      color: ${Colors.gray6}
    }
  }
`;
