/* eslint-disable comma-dangle */
import React, { useEffect, useId, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as InputClear } from '@/assets/img/icon/x-circle-filled.svg';
import Limited from '@/assets/img/membership/img_invitation.png';
import Button from '@/components/Button/Button';
import Input from '@/components/Input/Input';
import { useUserMembership } from '@/components/ManageMembership/ManageMembership.hooks';
import Modal from '@/components/Modal/Modal';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import { putApplyJoinMembershipData } from '@/hooks/join.hook';
import { eggdiningApi } from '@/shared/apis/eggdiningApi';
import {
  CompleteDesc,
  InvitationCode,
  InvitationCodeContainer,
  InvitationContainer,
  LimitedImg,
  SuccessCompleteContainer,
  Title,
  TitleSuper,
} from '@/shared/styles/pages/membership/invitation';
import { IErrorInvitationCode } from '@/shared/types/Invitation.type';
import { useFooterStore } from '@/stores/common/useFooterStore';
import { useHeaderStore } from '@/stores/common/useHeaderStore';
import { useModalStore } from '@/stores/common/useModalStore';
import { useNavigationStore } from '@/stores/common/useNavigationStore';
import { useInvitationCodeStore } from '@/stores/useMembershipJoinStore';
import { useUserProfileStore } from '@/stores/useUserProfle';

const Invitation = (): React.ReactElement => {
  const setIsNavigationVisible = useNavigationStore((store) => store.setIsNavigationVisible);
  const setHeaderState = useHeaderStore((store) => store.setHeaderState);
  const setIsFooterVisible = useFooterStore((store) => store.setIsFooterVisible);
  const navigate = useNavigate();
  const invitationCodeErrorKey = useId();
  const [codeErrorMessage, setCodeErrorMessage] = useState<string>(null);
  const { memberId } = useUserProfileStore();
  const { data: userMembershipState } = useUserMembership();
  const { refetch: checkUserMembershipGrade } = putApplyJoinMembershipData();
  const { setInvitationCode, invitationCode } = useInvitationCodeStore();
  const { setAlertModal } = useModalStore();
  const location = useLocation();

  useEffect(() => {
    setIsNavigationVisible(false);
    setIsFooterVisible(false);
    setHeaderState({
      title: 'Dining Club 멤버십',
      handleGoToBack: null,
      handleGoToHome: null,
      handleOnAlarm: null,
      handleOnClose: () => navigate(-1),
      handleOnShare: null,
    });

    const getCode = location.pathname.split('/');
    if (getCode[getCode.length - 1] !== 'invitation') {
      setInvitationCode(getCode[getCode.length - 1]);
    } else if (!invitationCode.length) {
      setInvitationCode('');
    }
  }, []);

  useEffect(() => {
    if (userMembershipState) {
      checkUserMembershipGrade();
    }
  }, [userMembershipState]);

  const { data: validInvitationCodeData, refetch } = useQuery(
    ['INVITATION_CODE'],
    () => eggdiningApi.get(`/eggdining/users/${memberId}/membership/invite-ticket/validate?code=${invitationCode}`),
    {
      onSuccess: () => {
        navigate('/membership/join');
      },
      onError: (error: IErrorInvitationCode) => {
        console.log(error, 'error');
        setCodeErrorMessage(error.response.data.message);
        setAlertModal({ visible: true, key: invitationCodeErrorKey });
      },
      enabled: false,
      retry: 0,
    }
  );

  const onSubmit = () => {
    refetch();
  };

  const acceptOnlyEngNum = (e) => {
    const regExp = /^[A-Za-z0-9]+$/;
    if (!regExp.test(e.key)) {
      e.preventDefault();
    }
    if (e.code === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <>
      <InvitationContainer>
        <div className="green-logo-container">
          <svg xmlns="http://www.w3.org/2000/svg" width="112" height="112" viewBox="0 0 56 56" fill="none">
            <path d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z" fill="#284C39" />
            <svg x="10" y="15" width="36" height="26" viewBox="0 0 36 26" fill="none">
              <path
                d="M11.8538 25.3181C10.8741 25.3181 8.15403 24.7927 6.22206 23.7418C4.29555 22.6965 2.7631 21.2133 1.6685 19.3415C0.579367 17.4698 0.0211182 15.3298 0.0211182 12.9709C0.0211182 10.6121 0.579367 8.49946 1.68492 6.61674C2.5606 5.11714 5.23144 0.558105 11.5199 0.558105C12.9484 0.558105 14.4535 0.716823 15.9859 1.03973C17.5239 1.35717 18.8866 1.78954 20.036 2.32042L20.2221 2.40799L20.9335 8.5542L19.5708 8.5323C18.3065 4.60814 17.0751 2.88961 11.7608 2.88961C11.6622 2.88961 11.5583 2.88961 11.4543 2.88961C7.31119 2.9334 5.86631 5.86147 5.17124 7.26804C4.42143 8.79501 4.03832 10.5573 4.03832 12.5057C4.03832 14.7825 4.44332 16.5558 5.31353 18.0827C6.21658 19.6699 7.60126 20.8849 9.22127 21.5088C11.0712 22.2203 12.6966 22.5816 14.0485 22.5816C15.5043 22.5816 16.6755 22.1601 17.5239 21.3337V16.2438C17.5239 15.4447 17.4089 15.0452 16.993 14.5691C16.4183 13.9013 14.0266 13.9342 14.0266 13.9342V12.6042H22.3128V13.9232L21.9625 13.9397C20.6161 14.0053 20.6216 14.4432 20.6271 15.9045V20.9342L20.6052 20.9725C20.4519 22.7895 16.0188 25.3017 11.8429 25.3017L11.8538 25.3181Z"
                fill="white"
              />
              <path
                d="M29.9969 24.4536C25.936 24.4536 23.7905 21.9086 23.7905 17.0923C23.7905 14.9031 24.2722 13.0532 25.1861 11.7507C26.2698 10.1963 27.9172 9.4082 30.0845 9.4082C33.2753 9.4082 35.6123 11.4277 35.979 14.3613L33.5161 14.3722C32.9524 12.6045 31.7702 11.5372 30.0024 11.5372C26.735 11.5372 26.2479 14.9743 26.2479 17.0212C26.2479 20.5349 27.5395 22.3191 30.0845 22.3191C31.7319 22.3191 32.9524 21.2901 33.5051 19.5278L35.9735 19.5771C35.4043 22.5325 33.1111 24.4536 29.9969 24.4536Z"
                fill="white"
              />
            </svg>
          </svg>
          <div className="complete-desc">
            <h4>
              그린 클럽 멤버십 가입 신청이
              <br />
              완료되었습니다.
            </h4>
            <p>
              좋은 멤버십 서비스를 위해 한정된 인원으로
              <br />
              다이닝 클럽이 운영 됩니다.
              <br />
              선정된 분들께는 문자메시지로 초대코드를 보내드립니다.
            </p>
          </div>
        </div>
        <div className="g-line"></div>
        {/*
        <div className="g-line">
          <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="6" viewBox="0 0 375 6" fill="#fff">
            <path opacity="0.1" d="M0 6V0H375V6H0Z" fill="white" width="100%" height="6" />
          </svg>
        </div>
        */}

        {/*
        <SuccessCompleteContainer>
          <TitleSuper>신청이 완료되었습니다.</TitleSuper>
          <Title>
            모실 수 있게 되면, 문자로
            <br />
            초대코드를 보내드려요.
          </Title>
          <LimitedImg src={Limited} alt="limited 우편 이미지" />
          <CompleteDesc>
            좋은 서비스를 위해 한정인원으로 운영 중입니다.
            <br />
            많은 분을 모시지 못하는 점, 양해 부탁드려요.
          </CompleteDesc>
        </SuccessCompleteContainer>
        */}
        <InvitationCodeContainer>
          <h3>초대코드를 받으셨나요?</h3>
          <InvitationCode>
            <Input
              value={invitationCode}
              onKeyPress={acceptOnlyEngNum}
              onChange={(e) => {
                if (invitationCode.length < 10 || e.target.value.length < invitationCode.length) {
                  setInvitationCode(e.target.value);
                }
              }}
              type="text"
              maxLength={10}
              className="code-input"
              placeholder="초대코드를 입력해주세요."
              clearicon={<InputClear onClick={() => setInvitationCode('')} />}
            />
            <Button
              classKey="code-submit-btn"
              onClick={onSubmit}
              defaultType="button"
              colorLevel="white"
              type="medium"
              disabled={!/^[A-Za-z0-9]*$/.test(invitationCode) || invitationCode?.length !== 10}
            >
              확인
            </Button>
          </InvitationCode>
        </InvitationCodeContainer>
      </InvitationContainer>
      <ModalPortal>
        <Modal.Alert key={invitationCodeErrorKey} modal="alert" isDim={true} isAnimation={true}>
          <section className="content-wrapper">
            <h2 className="title">알림</h2>
            <p className="desc">{codeErrorMessage}</p>
          </section>
          <section className="button-wrapper">
            <button onClick={() => setAlertModal({ visible: false, key: invitationCodeErrorKey })}>닫기</button>
          </section>
        </Modal.Alert>
      </ModalPortal>
    </>
  );
};

export default Invitation;
