import React, { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { RaffleItem } from '../Raffle.types';
import { useInitApply, raffleInfoQueryWrapper } from './Apply.hooks';
import { ApplyContainer } from './Apply.styles';
import Schedule from './Schedule/Schedule';
import TableInfoMarkup from './TableInfoMarkup/TableInfoMarkup';

import Button from '@/components/Button/Button';
import MainSubtitle from '@/components/MainSubtitle/MainSubtitle';
import { useUserProfileQuery } from '@/components/MyPage/Profile/Profile.hooks';
import RefundPolicy from '@/components/RefundPolicy/RefundPolicy';
import Seperator from '@/components/Seperator/Seperator';
import SplitNoLineTable from '@/components/SplitNoLineTable/SplitNoLineTable';
import TermsGroup from '@/components/TermsGroup/TermsGroup';
import { sortPolicies } from '@/hooks/sortPolicies';
import { useFetchPolicies } from '@/hooks/useFetchPolicies';
import { eggdiningApi } from '@/shared/apis/eggdiningApi';
import user from '@/shared/apis/queryKeys/user';
import { IMutationProps } from '@/shared/types/common.types';

const guideLineList = [
  '추첨은 일정별로 각각 진행됩니다.',
  '당첨 시 방문 일정은 응모하신 일정 중 하나로 배정됩니다.',
  '당첨 확률은 일정 별로 다를 수 있습니다.',
  '당첨 후 일정 변경은 불가합니다.',
];
// 약관에 따라 수정 해주어야 합니다. 그때 그때 요구하는 약관 목록이 다름
const requiredPolicies = ['SERVICE', 'EGG_CANCEL_FEE', 'MARKETING', 'LATE_NIGHT_MARKETING', 'KEEP_ACTIVE_EVEN_DORMANT'];

// table Title
const tableTitle = '7월 추첨예약 패키지 안내';

interface ITermsList {
  id: number;
  type: string;
  required: boolean;
  value: boolean;
  content: {
    EN: {
      name: string;
      contentUrl: string;
    };
    KO: {
      name: string;
      contentUrl: string;
    };
  };
  createdAt: string;
}

interface ITermsHookForm {
  agreements: ITermsList[];
  schedule: RaffleItem[];
}

function Apply() {
  const [searchParams] = useSearchParams();
  const eventType = searchParams.get('eventType');
  const optionParams = searchParams.get('options');

  const _ = useInitApply();
  const navigate = useNavigate();
  const { servicePolicies, loading: getPoliciesLoading, openServiceTerm, currentPolicy } = useFetchPolicies({ url: '/service/policies', requiredPolicies: requiredPolicies });
  const { data: userProfile, isLoading: userProfileLoading } = useUserProfileQuery();
  const { useGetRaffleSlotQuery, usePostRaffleEntryMutation } = raffleInfoQueryWrapper();
  const { data, mutateAsync } = usePostRaffleEntryMutation();
  const { data: raffleData, isLoading: getRaffleSlotLoading } = useGetRaffleSlotQuery();

  const { mutateAsync: agreementMutateAsync } = useMutation((mutatedata: IMutationProps) => {
    return eggdiningApi.mutation(mutatedata);
  });

  const validationSchema = () => {
    return yup.object({
      agreements: yup.array(
        yup.object({
          required: yup.boolean(),
          value: yup.boolean().when('required', {
            is: (required) => required,
            then: yup.boolean().test('AGREEMENT_VALUE', '인증 ', (value) => {
              return value;
            }),
          }),
        })
      ),
      schedule: yup.array().test({
        message: '방문 일자를 하나 이상 선택해 주세요',
        test: (objectList) => {
          if (!objectList?.length) {
            return false;
          }
          objectList.filter((x) => {
            return x.value;
          });
          const maxChoiceLength = objectList.filter((x) => x.value).length;
          return maxChoiceLength <= raffleData.raffle.selectableItemCount && maxChoiceLength > 0;
        },
      }),
    });
  };

  const methods = useForm<ITermsHookForm>({
    resolver: yupResolver(validationSchema()),
    defaultValues: {
      agreements: [],
      schedule: [],
    },
    mode: 'onChange',
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    getValues,
    watch,
    reset,
  } = methods;

  watch('schedule');

  const onClickRaffleConnect = async () => {
    const value: number[] = getValues('schedule')
      .filter((item) => {
        return item.value;
      })
      .map((x) => x.id);

    const putResultData = getValues('agreements').map((agree) => {
      return {
        servicePolicyType: agree.type,
        agreement: agree.value ? agree.value : false,
      };
    });
    try {
      await agreementMutateAsync({
        operation: 'put',
        url: '/user/termsAgreements',
        data: {
          user: {
            phone: userProfile.user.phone,
          },
          termsAgreements: putResultData,
        },
      });

      await mutateAsync(value);
      navigate(`/raffle-reservation/complete?eventType=${eventType}&options=${optionParams}`);
    } catch (err: any) {
      alert(err?.response?.data?.messages || '에러가 발생 했습니다.');
      navigate('/main');
    }
  };

  const onlyRaffle = useCallback(() => {
    if (raffleData.raffle && raffleData.raffle.items.length === 1) {
      const converting = JSON.parse(JSON.stringify(raffleData));
      converting.raffle.items[0].value = true;
      return converting.raffle.items;
    }
    return (
      raffleData.raffle.items.map((item) => {
        return {
          ...item,
          value: false,
        };
      }) || []
    );
  }, [raffleData]);

  useEffect(() => {
    const settingScheduls = raffleData && onlyRaffle();

    if (servicePolicies.length) {
      reset({ ...getValues(), agreements: sortPolicies(requiredPolicies, servicePolicies), schedule: settingScheduls });
    }
  }, [raffleData, servicePolicies]);

  const tableInfo = [
    {
      title: '예약인원',
      desc: '2명 (성인 2명)',
    },
    {
      title: '일정안내',
      desc: '하단 참조',
    },
  ];

  if (getRaffleSlotLoading || getPoliciesLoading || userProfileLoading) {
    return null;
  }

  return (
    <FormProvider {...methods}>
      <ApplyContainer>
        <div className="schedule-wrapper">
          <div className="schedule-header">
            방문하고 싶은 레스토랑을
            <br />
            하나만 골라주세요.
          </div>
          {optionParams !== 'normal' && (
            <>
              <MainSubtitle subtitle={tableTitle} />
              <SplitNoLineTable tableInfo={tableInfo} />
              <TableInfoMarkup />
            </>
          )}
          {optionParams !== 'normal' ? null : <Schedule raffleList={getValues('schedule')} maxLength={raffleData.raffle.selectableItemCount} />}
          <div className="guide-line-precaution-wrapper">
            <div className="title">유의사항 안내</div>
            <div className="description">
              {guideLineList.map((guideLine, index) => {
                return (
                  <div key={index}>
                    <i>•</i>
                    {guideLine}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <Seperator />
        <div className="raffle-terms-wrapper">
          <TermsGroup openServiceTerm={openServiceTerm} allCheckComment="이용약관 전체 동의" currentPolicy={currentPolicy} />
        </div>
      </ApplyContainer>
      <Button
        onClick={() => {
          onClickRaffleConnect();
        }}
        disabled={!isValid}
        colorLevel="primary"
        type="sticky"
        classKey="auth-button"
      >
        확인
      </Button>
    </FormProvider>
  );
}

export default Apply;
