import React from 'react';

import styled from '@emotion/styled';

import { IMenuListViewerContainer } from './MenuListViewer.types';

import { Colors, Typography } from '@/shared/styles';

export const MenuListViewerItem = styled.div<React.CSSProperties>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 32px 32px;
  /* border-bottom: 2px solid ${Colors.white_opacity10}; */

  & > h2 {
    ${Typography.body_28_s};
  }

  & > span {
    ${Typography.title_32}
    color: ${Colors.white}
  }
  :last-of-type {
    border-bottom: none;
  }
`;

export const MenuListViewerContainer = styled.div<IMenuListViewerContainer>``;
