import * as React from 'react';
import { Controller } from 'react-hook-form';

import Input from '@/components/Input/Input';
import { HookFormProps } from '@/shared/types/common.types';

export const ControlledInput: React.FC<HookFormProps> = ({ ...props }: any) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <Input
            {...props}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onChange(e);
              return props.onChange && props.onChange(e);
            }}
            error={error && error.message}
            value={value ?? ''}
            timer={props.timer}
          />
        );
      }}
    />
  );
};
