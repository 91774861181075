import React from 'react';

import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

export const VisitCancelAndNoShowContainer = styled.div<React.CSSProperties>`
  display: flex;
  padding: 32px;
  flex-direction: column;
  gap: 32px;
`;
export const VisitCancelAndNoShowItemContainer = styled.div<React.CSSProperties>`
  display: flex;
  gap: 32px;
  align-items: center;
  & > div:first-of-type {
    width: 192px;
    height: 192px;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    opacity: 0.4;
    border: 2px solid rgba(255, 255, 255, 0.1);
    img {
      max-width: 100%;
      min-height: 100%;
    }
    & > span {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      ${Typography.body_28_m};
      color: ${Colors.white};
    }
  }
  & > div:last-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    & > h3 {
      overflow: hidden;
      text-overflow: ellipsis;

      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 48px;
    }
    & > span {
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px;
      opacity: 0.8;
    }
    .reserve-stts {
      align-self: flex-start;
      opacity: 1;
      border-radius: 12px;
      background: rgba(255, 124, 117, 0.1);

      padding: 2px 12px;
      /*max-width : 60px;*/
      justify-content: center;
      gap: 8px;
      color: var(--red-400, #ff7c75);
      text-align: center;

      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px; /* 150% */
    }
  }
`;
