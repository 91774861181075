import { useQuery } from 'react-query';

import { eggdiningApi } from '@/shared/apis/eggdiningApi';
import MEMBERSHIP_QUERY_KEYS from '@/shared/apis/queryKeys/membership';
import { LocalStorage } from '@/shared/configs/storage';
import { useMembershipInfoStore } from '@/stores/useMembershipInfoStore';
import { useUserProfileStore } from '@/stores/useUserProfle';

export interface IUserMembershipGet {
  membership: {
    membershipGrade: string;
    applyStartDate: string;
    applyEndDate: string;
    cancelDate: string;
    subscriptionType: string;
    paymentDate: string;
  };
}

const useUserMembership = () => {
  const memberId = useUserProfileStore((store) => store.memberId) || LocalStorage.getItem('memberId');
  const { setMemsershipInfo } = useMembershipInfoStore();
  return useQuery(useUserMembership.getKeys(), () => useUserMembership.userAuthPhoneCheck(memberId), {
    onSuccess: ({ membership }) => {
      setMemsershipInfo(membership);
    },
    enabled: Boolean(memberId),
  });
};
useUserMembership.getKeys = () => [MEMBERSHIP_QUERY_KEYS.GET_USER_MEMBERSHIP];

useUserMembership.userAuthPhoneCheck = (memberId) => {
  return eggdiningApi.get<IUserMembershipGet>(`eggdining/users/${memberId}/membership`);
};

export { useUserMembership };
