import React from 'react';

import styled from '@emotion/styled';

import { Colors } from '../../colors';

export const RedirectWrapperContainer = styled.div<React.CSSProperties>`
  width: 100%;
  height: 100vh;
  background-color: #fff;
  display: flex;
  padding: 258px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${Colors.black};

  .redirect-app-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    width: 100%;

    h4 {
      color: #000;
      text-align: center;
      /* Headline 2 - SemiBold, 24, 30 */
      font-family: pretendard;
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: 60px; /* 125% */
    }
    p {
      color: #000;
      font-feature-settings: 'liga' off, 'clig' off;

      /* Body 2 - Regular, 14, 20 */
      font-family: 'Pretendard Variable';
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: 40px;

      em {
        color: var(--primary-dc-light-green, #41996a);
      }
    }

    .button-container {
      margin-top: 48px;
      width: 100%;
    }
  }
`;

export const DiningClubLogo = styled.div<React.CSSProperties>`
  display: flex;
  width: 160px;
  height: 160px;
  padding: 13.281px;
  justify-content: center;
  border-radius: 24px;
  align-items: center;
  flex-shrink: 0;
  background: var(--DC-Green, #284c39);
`;
