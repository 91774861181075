import React from 'react';

import { SplitNoLineTableContainer } from './SplitNoLineTable.styled';

/**
 *
 * [{
 * title: string
 * desc: string
 * }]
 */

interface SplitNoLineTableProp {
  tableInfo: {
    title: string;
    desc: string;
  }[];
}

const SplitNoLineTable = ({ tableInfo }: SplitNoLineTableProp): React.ReactElement => {
  return (
    <SplitNoLineTableContainer>
      {tableInfo.map((info) => {
        return (
          <div className="info-box" key={info.title}>
            <div className="label">{info.title}</div>
            <div className="value">{info.desc}</div>
          </div>
        );
      })}
    </SplitNoLineTableContainer>
  );
};

export default SplitNoLineTable;
