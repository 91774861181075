import React from 'react';

import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

export const NoticeContainer = styled.div<React.CSSProperties>`
  display: flex;
  flex-direction: column;
  padding: 32px 32px 48px 32px;
  gap: 16px;
  & > h2 {
    ${Typography.body_28_s};
    color: ${Colors.white};
  }
  & > p {
    ${Typography.small_3h};
    color: ${Colors.white};
    white-space: pre-line;
  }
`;
