import Alert from '@/components/Modal/Alert/Alert';
import EventPopup from '@/components/Modal/EventPopup/EventPopup';
import Menu from '@/components/Modal/Menu/Menu';
import Normal from '@/components/Modal/Normal/Normal';
import Toast from '@/components/Modal/Toast/Toast';

export { Alert, Menu, Toast, Normal };

export default {
  Alert,
  Menu,
  Toast,
  Normal,
  EventPopup,
};
