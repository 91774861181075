import React from 'react';

import styled from '@emotion/styled';

import { Typography, Colors } from '@/shared/styles';

export const CategorySelectorContainer = styled.div<React.CSSProperties>`
  display: flex;
  /* width: 343px; */
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  padding: 0 32px;
  ${Typography.title_32}

  .title {
    margin-bottom: 28px;
  }
  .radio-group {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 40px;
    /* flex-direction: column; */
    /* margin-bottom: 64px; */
    > div {
      /* margin-bottom: 40px; */
      &:last-of-type {
        /* margin-bottom: 0; */
      }
    }
  }
  .radio-custom {
    label {
      align-items: center;
      gap: 16px;
    }
  }
`;
