import { useEffect, useState } from 'react';
import type { NavigateFunction } from 'react-router-dom';

import { TUseCarouselViewer } from './CarouselViewer.types';

import { useHeaderStore } from '@/stores/common/useHeaderStore';

export const useStateCarouselViewer = (navigate: NavigateFunction): TUseCarouselViewer => {
  const setHeaderState = useHeaderStore((store) => store.setHeaderState);

  const [isCarouselViewAll, setIsCarouselViewAll] = useState(false);

  useEffect(() => {
    if (isCarouselViewAll) {
      setHeaderState({
        title: '매장 상세보기',
        handleGoToBack: () => setIsCarouselViewAll(false),
        handleGoToHome: () => navigate('/main'),
        handleOnAlarm: null,
        handleOnClose: null,
        handleOnShare: null,
      });
      setIsCarouselViewAll(true);
    }
  }, [isCarouselViewAll]);

  return [isCarouselViewAll, setIsCarouselViewAll];
};
