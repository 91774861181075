import React from 'react';

import styled from '@emotion/styled';

import ArrowDown from '@/assets/img/icon/chevronDown.svg';
import { Colors, Typography } from '@/shared/styles';

export const DetailPartySizeContainer = styled.div<React.CSSProperties>`
  padding: 0 32px 100px;

  .people-container {
    &:not(&:first-of-type) {
      margin-top: 20px;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    border: 2px solid ${Colors.gray3};
    border-radius: 4px;
    padding: 24px 32px;
    &.error {
      border: 2px solid ${Colors.red2};
    }
    .label-container {
      .name {
        ${Typography.body_28_m};
        color: ${Colors.gray9};
      }
      .description {
        display: flex;
        margin-top: 4px;
        align-items: center;
        > div {
          ${Typography.small_1};
          color: ${Colors.gray6};
        }
        .icon {
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }
      }
    }
    .select-container {
      .select-people-box {
        height: 80px;
        border: none;
        border-radius: 4px;
        -webkit-appearance: none;
        outline: 0;
        min-width: 98px;
        background: url(${ArrowDown}) 100% 50% no-repeat;
        background-size: 40px 40px;
        color: ${Colors.gray9};
        ${Typography.body_28_m};
      }
    }
  }
`;
