import React from 'react';

import { BookingSubtitleContainer } from './MainSubtitle.constants';
import { IMainSubtitle } from './MainSubtitle.type';

import { ReactComponent as Arrow } from '@/assets/img/icon/arrowRightGray.svg';
import Subtitle from '@/components/Subtitle/Subtitle';

const MainSubtitle = ({ subtitle, count, moveTo, firebrick }: IMainSubtitle): React.ReactElement => {
  return (
    <BookingSubtitleContainer>
      <div className="left">
        <Subtitle subtitle={subtitle} firebrick={firebrick} />
        {count ? <div className="count">{count}</div> : null}
      </div>
      {moveTo ? (
        <div className="move" onClick={moveTo}>
          전체보기 <Arrow className="icon" />
        </div>
      ) : null}
    </BookingSubtitleContainer>
  );
};

export default MainSubtitle;
