import React from 'react';

import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

interface IManageMembershipStyle extends React.CSSProperties {
  isYolkMember?: boolean;
}

export const ManageMembershipContainer = styled.div<IManageMembershipStyle>`
  width: 100%;
  margin: 80px 32px;
  .membership-header {
    display: flex;
    text-align: center;
    /*margin-bottom: ${(props) => (props.isYolkMember ? '100px' : '40px')};*/
    margin-bottom: 80px;
    .badge {
      margin-bottom: 28px;
    }
    .comment {
      .title {
        font-weight: 700;
        font-size: 44px;
        line-height: 50px;
        color: ${Colors.gray9};
        mix-blend-mode: normal;
        margin-bottom: 20px;
      }
      .description {
        font-weight: 500;
        font-size: 30px;
        line-height: 40px;
        color: ${Colors.gray8};
      }
    }
  }

  .white-club-top-container {
    width: 100%;
  }
  .user-current-memberhip-info {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .user-profile-emblem-normal {
    display: flex;
    width: 112px;
    height: 112px;
    padding: 3px 12px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 99px;
    margin-right: 8px;
    background: #fff;
  }

  .user-profile-emblem-yolk {
    display: flex;
    width: 112px;
    height: 112px;
    padding: 3px 12px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 99px;
    margin-right: 8px;
    background: var(--gray-900, #14120e);
  }

  .user-current-membership-title {
    margin-top: 24px;
    color: #fff;
    font-family: Fustat;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.4px;
  }

  .user-current-membership-contents {
    margin-top: 24px;
    color: #fff;
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;

    /* Body 2 - Regular, 14, 20 */
    font-family: pretendard, sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }

  .depth-line {
    width: 100%;
    height: 6px;
    opacity: 0.1;
    background: #fff;
    margin-bottom: 50px;
  }

  .depth-line-small {
    width: 100%;
    height: 2px;
    opacity: 0.1;
    background: #fff;
    margin: 30px 0;
  }

  .black-benefit {
    border: 2px solid var(--secondary-dc-beige, #ddc5a4);
    background: var(--secondary-dc-beige-10, rgba(221, 197, 164, 0.1));
    border-radius: 6px;
    display: flex;
    padding: 21px 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    margin: 26px 0 100px 0;

    h4 {
      text-align: left;
      margin-top: 16px;
      color: #fff;
      font-feature-settings: 'liga' off, 'clig' off;

      /* Label 1 - Medium, 16, 22 */
      font-family: pretendard, sans-serif;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 137.5% */
    }
    small {
      margin-top: 32px;
      color: #fff;
      /* Body 3 - Regular, 12, 18 */
      font-family: pretendard, sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
    }
  }

  .black-benefit2 {
    border-radius: 3px;
    border: 1px solid var(--green-300, #e8ff68);
    background: rgba(232, 255, 104, 0.1);
    display: flex;
    padding: 21px 32px;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    margin: 52px 0 70px 0;

    h4 {
      text-align: left;
      margin-top: 16px;
      color: #fff;
      font-feature-settings: 'liga' off, 'clig' off;

      /* Label 1 - Medium, 16, 22 */
      font-family: pretendard, sans-serif;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 137.5% */
    }
    small {
      margin-top: 32px;
      color: #fff;
      /* Body 3 - Regular, 12, 18 */
      font-family: pretendard, sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
    }
  }

  .black-benefit-year-price {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .year-price-benefit {
    border-radius: 8px;
    background: var(--secondary-500, #ddc5a4);
    padding: 12px 20px;
    color: var(--gray-900, #14120e) !important;
    text-align: center;

    /* Body 3 - Regular, 12, 18 */
    font-family: pretendard, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
  }

  .black-benefit-right-item {
    margin-left: auto;
    color: #fff;
    display: flex;
    align-items: center;
    flex-direction: row;
    align-items: center;
  }

  .black-benefit-right-item > div {
    margin-left: 0;
    color: #fff;
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;

    /* Label 3 - Medium, 12, 18 */
    font-family: pretendard, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
    opacity: 0.8;
  }

  .black-club {
    display: flex;
    padding: 8px 16px;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    font-family: Fustat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.24px;
    background: var(--gray-900, #14120e);
  }

  .benefit-contents {
    display: flex;
    padding: 0px 4px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;

    .black-benefit-contents {
      display: flex;
      align-items: center;
      color: #fff;
      opacity: 0.8;

      /* Body 3 - Regular, 12, 18 */
      font-family: pretendard, sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px; /* 150% */
    }
  }

  .normal-grade {
    background: #fff;
  }
  .yolk-grade {
    background: var(--gray-900, #14120e);
  }

  .manage-membership-title-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 40px;
  }

  .manage-membership-title {
    color: #fff;
    /* Title 2 - SemiBold, 18, 24 */
    font-family: pretendard, sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    > span {
      padding: 10px 18px;
      border-radius: 6px;
      background: var(--secondary-50010, rgba(221, 197, 164, 0.1));
      color: var(--secondary-500, #ddc5a4);
      text-align: center;

      /* Body 3 - Regular, 12, 18 */
      font-family: pretendard, sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
    }
  }

  .manage-membership-title-right-item {
    margin-left: auto;
    color: #fff;
    display: flex;
    align-items: center;
    flex-direction: row;
    align-items: center;
  }

  .manage-membership-title-right-item > div {
    color: #fff;
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;

    /* Label 3 - Medium, 12, 18 */
    font-family: pretendard, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
  }

  .manage-membership-title-container {
    margin: 50px 0;
  }
  .membership-pay-info-container {
    margin-bottom: 35px !important;
  }
  .membership-pay-info {
    display: flex;
    align-items: center;
    color: #fff;
    margin-bottom: 34px;
    font-feature-settings: 'liga' off, 'clig' off;
    /* Label 2 - Medium, 14, 20 */
    font-family: pretendard, sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    .payment-titl {
      min-width: 120px;
    }
    .payment-content {
    }
  }

  .membership-cancel-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin: 50px 0;
    height: 88px;
  }

  .membership-cancel-title {
    color: #fff;
    font-family: pretendard, sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }

  .membership-cancel-title-right-item {
    margin-left: auto;
    color: #fff;
    display: flex;
    align-items: center;
    flex-direction: row;
    align-items: center;
  }

  .membership-cancel-title-right-item > div {
    color: #fff;
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;

    /* Label 3 - Medium, 12, 18 */
    font-family: pretendard, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
  }

  .manage-membership-info {
    margin-bottom: 100px;
    .title {
      margin-bottom: 40px;
      align-items: center;
      ${Typography.title_36_sb}
      color: ${Colors.gray9};
      > div {
        margin-right: 8px;
      }
      p {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 76px;
        height: 40px;
        background-color: ${Colors.yellow0};
        border-radius: 4px;
        ${Typography.s_small_sb}
        color: ${Colors.yellow3};
      }
    }
    > div {
      display: flex;
    }
    .membership-info {
      .payment-info {
        min-width: 97px;
        margin-right: 40px;
        > div {
          color: ${Colors.gray6};
        }
      }
      .payment-data {
        > div {
          color: ${Colors.gray8};
        }
      }
      > div {
        > div {
          ${Typography.body_28}
          margin-bottom: 12px;
        }
      }
    }
  }
  .general-member {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 0px;
    margin-bottom: 100px;
    background: ${Colors.gray0};
    border-radius: 8px;
    ${Typography.body_28_sb}
    div:first-of-type {
      margin-bottom: 12px;
      > span {
        color: ${Colors.red2};
      }
    }
    div:last-of-type {
      ${Typography.small_1}
      color: ${Colors.gray6};
    }
  }
  .benefit {
    padding-bottom: 40px;
    > :first-of-type {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;
      div {
        font-weight: 600;
        font-size: 36px;
        line-height: 46px;
        color: ${Colors.gray9};
      }
      p {
        font-weight: 400;
        font-size: 24px;
        line-height: 30px;
        color: ${Colors.gray6};
      }
    }
    .event-description {
      display: flex;
      justify-content: space-between;
      height: 160px;
      background: ${Colors.white};
      border: 2px solid ${Colors.gray3};
      border-radius: 8px;
      padding: 40px 32px;
      .description {
        > :first-of-type {
          margin-bottom: 4px;
          ${Typography.small_m}
          color: ${Colors.gray8};
        }
        div:last-of-type {
          ${Typography.body_28_b}
          color: ${Colors.gray8};
        }
      }
      .express-img {
        width: 200px;
        height: 80px;
      }
    }
  }
  .rescission-membership {
    padding-bottom: 80px;
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 184px;
      height: 62px;
      background: ${Colors.white};
      border: 2px solid ${Colors.gray3};
      border-radius: 31px;
      font-weight: 400;
      font-size: 28px;
      line-height: 36px;
      color: ${Colors.gray9};
    }
  }
`;

export const ButtonWrapper = styled.div<React.CSSProperties>`
  position: sticky;
  bottom: 0;
`;
