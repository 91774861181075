import { useNavigate } from 'react-router-dom';

import classNames from 'classnames';

import { ReactComponent as Emblem } from './assets/img/icon/emblem.svg';
import { useUserProfileQuery } from './Profile.hooks';
import { ProfileContainer } from './Profile.styles';

//import MemberShipEgg from '@/assets/img/membership-egg.png';
//import NormalEgg from '@/assets/img/normal-egg.png';
import { useUserMembership } from '@/components/ManageMembership/ManageMembership.hooks';
import MembershipEmblem from '@/components/MembershipEmblem/MembershipElblem';

function Profile() {
  const navigate = useNavigate();
  const { data: userMembership, isLoading: userMembershipLoading } = useUserMembership();
  const { data: userProfile, isLoading: userProfileLoading } = useUserProfileQuery();
  const goToProfilePage = () => {
    navigate('/mypage/edit');
  };

  if (userMembershipLoading || userProfileLoading) {
    return (
      <div>
        <div>loading</div>
      </div>
    );
  }

  if (!userMembership) {
    return null;
  }

  let grade: 'white' | 'green' | 'black';

  if (userMembership.membership.membershipGrade === '01') {
    grade = 'white';
  } else if (userMembership.membership.membershipGrade === '02') {
    grade = 'green';
  } else {
    grade = 'black';
  }

  return (
    <ProfileContainer>
      {/*
      <div
        className={classNames('user-profile-emblem', {
          'normal-grade': userMembership.membership.membershipGrade === '01',
          'yolk-grade': userMembership.membership.membershipGrade !== '01',
        })}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 20 20" fill="none">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.3815 0.450225C10.1818 0.214509 9.81824 0.214508 9.61854 0.450224L7.85133 2.53606C7.72887 2.68061 7.53523 2.74352 7.35119 2.69857L4.69547 2.04983C4.39535 1.97651 4.10122 2.19021 4.0782 2.49829L3.87453 5.22451C3.86041 5.41343 3.74074 5.57815 3.56542 5.64995L1.03558 6.68611C0.749687 6.8032 0.63734 7.14897 0.799806 7.41174L2.23746 9.73701C2.33708 9.89815 2.33708 10.1018 2.23746 10.2629L0.799807 12.5882C0.637341 12.8509 0.749686 13.1967 1.03558 13.3138L3.56542 14.35C3.74074 14.4218 3.86041 14.5865 3.87453 14.7754L4.0782 17.5016C4.10122 17.8097 4.39535 18.0234 4.69547 17.9501L7.35119 17.3013C7.53523 17.2564 7.72887 17.3193 7.85133 17.4638L9.61854 19.5497C9.81824 19.7854 10.1818 19.7854 10.3815 19.5497L12.1487 17.4638C12.2712 17.3193 12.4648 17.2564 12.6489 17.3013L15.3046 17.9501C15.6047 18.0234 15.8988 17.8097 15.9218 17.5016L16.1255 14.7754C16.1396 14.5865 16.2593 14.4218 16.4346 14.35L18.9645 13.3138C19.2504 13.1967 19.3627 12.8509 19.2002 12.5882L17.7626 10.2629C17.663 10.1018 17.663 9.89815 17.7626 9.73702L19.2002 7.41174C19.3627 7.14897 19.2504 6.8032 18.9645 6.68611L16.4346 5.64995C16.2593 5.57815 16.1396 5.41343 16.1255 5.22451L15.9218 2.49829C15.8988 2.19021 15.6047 1.97651 15.3046 2.04983L12.6489 2.69857C12.4648 2.74352 12.2712 2.68061 12.1487 2.53606L10.3815 0.450225ZM6.6798 8.16208L7.03768 4.58329L8.44298 4.72382L8.0851 8.30261C8.01582 8.99546 8.55989 9.59665 9.25619 9.59665H9.29389V4.65359H10.7062V9.59665H10.7439C11.4402 9.59665 11.9842 8.99546 11.915 8.30262L11.5571 4.72382L12.9624 4.58329L13.3203 8.16209C13.4727 9.68633 12.2757 11.009 10.7439 11.009H10.7062V15.9521H9.29389L9.29389 11.009H9.25619C7.72434 11.009 6.52737 9.68633 6.6798 8.16208Z"
            fill={userMembership.membership.membershipGrade === '01' ? '#14120E' : '#fff'}
          />
        </svg>
      </div>*/}
      <MembershipEmblem type="emblem" size="medium" grade={grade} />
      <div className="user-info">
        <div className="name">{userProfile.user.name}</div>
        <div className="desc">회원님은 {grade === 'white' ? 'White' : 'Green'} Club 멤버 입니다.</div>
      </div>

      {/* <div className="right-item" onClick={goToProfilePage}>
        <div>회원정보 수정</div>
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 12 12" fill="none">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.55887 0.558105L3.67499 1.44199L8.23305 6.00005L3.67499 10.5581L4.55887 11.442L10.0008 6.00005L4.55887 0.558105Z"
              fill="white"
            />
          </svg>
        </div>
      </div> */}

      {/*
      <div onClick={goToProfilePage}>
        <div className="user-photo">
          <img src={userMembership.membership.membershipGrade === '01' ? NormalEgg : MemberShipEgg} alt="에그 프로필 이미지" />
        </div>
        <div className="user-name">{userProfile.user.name}</div>
      </div>*/}
    </ProfileContainer>
  );
}

export default Profile;
