import { IInfoStore } from '@/stores/useInfoStore';

export const getInfoState = (store): Partial<IInfoStore> => ({ calendar: store.calendar, viewDate: store.viewDate, calendarDirty: store.calendarDirty });
export const setInfoState = (store): Partial<IInfoStore> => ({
  setCalendar: store.setCalendar,
  setViewDate: store.setViewDate,
  setTimes: store.setTimes,
  setCalendarDirty: store.setCalendarDirty,
  setTimeDirty: store.setTimeDirty,
});
export const getBookingInfoState = (store) => ({ partySize: store.partySize, dateTime: store.dateTime });
