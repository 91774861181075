export const autoHypenCardNum = (cardNum: string) => {
  return cardNum
    ?.replace(/[^0-9]/g, '')
    .replace(/^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3-$4')
    .replace(/(-{0,4})$/g, '');
};

export const hypenPhoneNum = (phoneNum: string, type?: string) => {
  if (type === 'hide') {
    return phoneNum?.replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, '$1-****-$3');
  }
  return phoneNum?.replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3');
};

export const autoHypenPhoneNum = (phoneNum: string) => {
  return phoneNum
    ?.replace(/[^0-9]/g, '')
    .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3')
    .replace(/(-{1,2})$/g, '');
};
