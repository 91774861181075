import React from 'react';

import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

export const SlotsContainer = styled.div<React.CSSProperties>`
  background-color: ${Colors.white};

  .title {
    padding: 20px 32px;
    background-color: ${Colors.yellow1};
    ${Typography.subtitle_b}
    color: ${Colors.gray9};
  }
  .sub {
    display: flex;
    justify-content: space-between;
    padding: 20px 0 20px 32px;
    ${Typography.small_b}
    > div {
      color: ${Colors.gray6};
    }
    & div:last-of-type {
      text-align: center;
      width: 233px;
    }
  }
  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 24px 20px 32px;
    border-top: 2px solid ${Colors.gray2};
    ${Typography.body_26_sb}
    .reservation-time {
      .user {
        margin-top: 4px;
        color: ${Colors.blue};
      }
    }
    .announce-time {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 185px;
      height: 56px;
      background-color: ${Colors.gray2};
      color: ${Colors.gray6};
      ${Typography.s_small_sb}

      &.complete {
        background-color: ${Colors.red0};
        color: ${Colors.red1};
      }
      &.reservation {
        color: ${Colors.gray8};
        background-color: white;
      }
    }
  }
  .data-none {
    text-align: center;
    border-top: 2px solid ${Colors.gray2};
    padding: 28px 0;
    letter-spacing: -0.01em;
    ${Typography.small_m}
    >div {
      color: ${Colors.gray6};
    }
  }
`;
