import { useMutation } from 'react-query';

import { eggdiningApi } from '@/shared/apis/eggdiningApi';
import { useBookingInfoStore } from '@/stores/useBookingInfoStore';

interface IDelProvisionReservation {
  token: string;
  storeUri: string;
}

const useDeleteProvisionalReservation = ({ token, storeUri }: IDelProvisionReservation) => {
  const { provisionalToken, dispatchBookingInfo } = useBookingInfoStore((state) => state);

  return useMutation(() => useDeleteProvisionalReservation.fetcher({ token, storeUri }), {
    onSuccess() {
      if (provisionalToken === token) {
        dispatchBookingInfo({ type: 'SET_PROVISIONAL_TOKEN', value: null });
        dispatchBookingInfo({ type: 'SET_EXPIRY_TIME', value: null });
      }
    },
    onError(error) {
      console.log(error);
    },
  });
};

useDeleteProvisionalReservation.fetcher = ({ token, storeUri }: IDelProvisionReservation) => {
  return eggdiningApi.deleteApi(`/eggdining/stores/${storeUri}/reservation/pre`, { data: { token: token } });
};

export { useDeleteProvisionalReservation };
