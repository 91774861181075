import React from 'react';

import { SeperatorContainer } from './Seperator.styles';
import { ISeperator } from './Seperator.type';

const Seperator = ({ type = 'default', inline = false }: ISeperator): React.ReactElement => {
  return <SeperatorContainer type={type} inline={inline} />;
};

export default Seperator;
