import React from 'react';

import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

export const ApplyContainer = styled.div<React.CSSProperties>`
  padding: 80px 0;
  .schedule-wrapper {
    padding: 0 32px;
    .schedule-header {
      margin-bottom: 64px;
      ${Typography.title_44}
    }
  }
  .guide-line-precaution-wrapper {
    background-color: ${Colors.gray1};
    padding: 24px 32px;
    margin-bottom: 64px;
    .title {
      margin-bottom: 24px;
      ${Typography.body_28_sb}
      color: ${Colors.blue}
    }
    .description {
      > div {
        margin-bottom: 16px;
        color: ${Colors.gray8};
        > i {
          margin-right: 8px;
        }
      }
      ${Typography.small_1}
      color: ${Colors.gray8}
    }
  }
  .raffle-terms-wrapper {
    padding: 0 32px;
    margin-top: 64px;
  }
`;
