import type { NavigateFunction } from 'react-router-dom';

import { IHeaderPathData } from './Header.types';

export const HeaderVisibleExceptions = [''];

export const HeaderPathData = (navigate: NavigateFunction): IHeaderPathData => {
  return {
    '/components': {
      title: '컴포넌트',
      handleGoToBack: () => navigate(-1),
      handleGoToHome: () => navigate('/main'),
      handleOnAlarm: null,
      handleOnAlarmOff: null,
      handleOnClose: null,
      handleOnShare: null,
      alarmOn: null,
    },
    '/reservation': {
      title: '예약',
      handleGoToBack: () => navigate(-1),
      handleGoToHome: () => navigate('/main'),
      handleOnAlarm: null,
      handleOnAlarmOff: null,
      handleOnClose: null,
      handleOnShare: null,
      alarmOn: null,
    },
    '/mypage': {
      title: 'My Club',
      handleGoToBack: null,
      handleGoToHome: null,
      handleOnAlarm: null,
      handleOnAlarmOff: null,
      handleOnClose: null,
      handleOnShare: null,
      alarmOn: null,
    },
    '/membership': {
      title: '욜크 멤버십',
      handleGoToBack: () => navigate(-1),
      handleGoToHome: () => navigate('/main'),
      handleOnAlarm: null,
      handleOnAlarmOff: null,
      handleOnClose: null,
      handleOnShare: null,
      alarmOn: null,
    },
  };
};
