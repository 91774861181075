import React, { useEffect, useRef, useState } from 'react';

interface Tintersection {
  target: React.RefObject<Element> | Element | null;
  options?: any;
  callback?: any;
}

export type IntersertionChangeHandler = (entry: any) => void;

function useInterserction({ target, options, callback }: Tintersection) {
  const [intersecting, setIntersecting] = useState(false);
  const intersecteRef = useRef(false);

  useEffect(() => {
    if (target === null) {
      return undefined;
    }

    const element = target instanceof Element ? target : target?.current;

    if (!element) {
      return undefined;
    }

    const observer = new IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting);
      if (callback) {
        callback(entry);
      }

      if (entry.isIntersecting) {
        intersecteRef.current = true;
      }
    }, options);

    observer.observe(element);

    return () => {
      if (!element) {
        return;
      }

      observer.unobserve(element);
    };
  }, [target, options]);
  return intersecting;
}

export { useInterserction };
