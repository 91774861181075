import React from 'react';

import styled from '@emotion/styled';

import { Typography } from '@/shared/styles/typography';

export const ReservationInfoContainer = styled.div<React.CSSProperties>`
  display: flex;
  flex-direction: column;
  margin: 40px 32px 96px 32px;
  gap: 32px;
  align-self: stretch;

  & > h3 {
    ${Typography.title_48};
    font-weight: 600;
    padding-bottom: 64px;
  }

  h4 {
    ${Typography.title_36_sb};
  }

  .booking-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
  }

  .info-box {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    ${Typography.body_28_ss}
    /* margin-bottom: 12px;
    text-align: left;
    display: flex; */

    & > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 8px;
      flex: 0 0 auto;
      white-space: pre-wrap;
    }

    .label {
      display: flex;
      width: 120px;
      align-items: flex-start;
      gap: 8px;
      ${Typography.body_28_s}
    }
  }
`;
