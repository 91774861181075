import React from 'react';

import { ReservationInfoContainer } from './ReservationInfo.constants';
import { IReservationInfo } from './ReservationInfo.type';

import MainSubtitle from '@/components/MainSubtitle/MainSubtitle';
import { hypenPhoneNum } from '@/shared/libs/function/auto-hypen';
import { useBookingInfoStore } from '@/stores/useBookingInfoStore';
import { useBookingStore } from '@/stores/useBookingStore';

const ReservationInfo = (props): React.ReactElement => {
  const { type }: IReservationInfo = props;

  const { partyPolicies } = useBookingStore().reservationInfo;
  const { dateTime, partySize, detailPartySize, user, request } = useBookingInfoStore();
  const { name: storeName } = useBookingStore().reservationInfo;
  const detailPartySizeFunc = () => {
    const detailArray = detailPartySize
      .map((count, idx) => {
        return {
          name: partyPolicies[idx].name,
          count,
        };
      })
      .filter((party) => party.count > 0);
    return !detailArray.length ? null : (
      <span>
        (
        {detailArray
          .map((party, idx) => (
            <span key={idx}>
              {party.name} {party.count}명
            </span>
          ))
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          .reduce((acc, cur) => [acc, ', ', cur])}
        )
      </span>
    );
  };
  if (!dateTime || !partyPolicies) {
    return null;
  }

  return (
    <ReservationInfoContainer>
      {/* <div className="booking-info">
        <div className="mall-name">{storeName}</div>
        <div className="info-text">
          {dateTime?.format('YYYY.M.D (dd) · LT · ')} {partySize}명
        </div>
      </div> */}
      {type === 'payment' ? (
        <>
          <MainSubtitle subtitle="예약내용 확인"></MainSubtitle>
          {/* <h3>예약내용 확인</h3> */}
          <div className="booking-info">
            <div className="info-box">
              <div className="label">매장명</div>
              <div className="value">{storeName}</div>
            </div>
            <div className="info-box">
              <div className="label">예약일시</div>
              <div className="value">{dateTime.format('YYYY년 M월 D일 (ddd) ∙ LT')}</div>
            </div>
            <div className="info-box">
              <div className="label">예약인원</div>
              <div className="value">
                {partySize}명&nbsp;
                {detailPartySize.length >= 2 && partyPolicies.length >= 2 ? detailPartySizeFunc() : null}
              </div>
            </div>
            <div className="info-box">
              <div className="label">예약자</div>
              <div className="value">{user.name}</div>
            </div>
            <div className="info-box">
              <div className="label">연락처</div>
              <div className="value">{hypenPhoneNum(user.phoneNumber, 'hide')}</div>
            </div>
            <div className="info-box">
              <div className="label">요청사항</div>
              <div className="value">{request?.length > 0 ? request : '없음'}</div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="booking-info">
            <div className="info-box">
              <div className="label">예약일시</div>
              <div className="value">{dateTime.format('YYYY년 M월 D일 (ddd) ∙ LT')}</div>
            </div>
            <div className="info-box">
              <div className="label">예약인원</div>
              <div className="value">
                {partySize}명44&nbsp;
                {detailPartySize.length >= 2 && partyPolicies.length >= 2 ? detailPartySizeFunc() : null}
              </div>
            </div>
            <div className="info-box">
              <div className="label">예약금액</div>
              <div className="value">50,000원</div>
            </div>
          </div>
        </>
      )}
    </ReservationInfoContainer>
  );
};

export default ReservationInfo;
