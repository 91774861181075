import React from 'react';

import styled from '@emotion/styled';

import ArrowDown from '@/assets/img/icon/chevronDown.svg';
import { Typography } from '@/shared/styles';

export const AdditionalInfoFormContainer = styled.section<React.CSSProperties>`
  > div {
    padding: 0 32px;
    .additional-notice {
      ${Typography.small_2}
    }
    .additional-item {
      padding: 0 0 64px;

      .text-additional-info {
        position: relative;

        .input {
          outline: none;
          border: 2px solid #c1c1c1;
          border-radius: 8px;
          width: 100%;
          padding: 24px;
          box-sizing: border-box;
          position: relative;
          ${Typography.body_28}
          &:focus {
            & + .clear-button {
              opacity: 1;
            }
          }

          &:placeholder-shown {
            & + .clear-button {
              display: none;
            }
          }
        }

        .clear-button {
          width: 40px;
          height: 40px;
          position: absolute;
          right: 20px;
          top: 20px;
          opacity: 0;
          transition-property: all;
          transition-duration: 1s;
          transition-timing-function: cubic-bezier(0, 1, 0.5, 1);

          .icon {
            width: 40px;
            height: 40px;
          }
        }
      }

      .radio-container {
        display: flex;
      }

      .additional-select {
        height: 92px;
        border: 2px solid #cfd3d9;
        border-radius: 4px;
        -webkit-appearance: none;
        outline: 0;
        width: 100%;
        padding: 0 32px;
        background: url(${ArrowDown}) 95% 50% no-repeat;
        background-size: 40px 40px;
        ${Typography.body_28}

        .MuiOutlinedInput-input {
          display: flex;
          align-items: center;
          padding: 0;
          height: 100%;
        }
        .MuiOutlinedInput-notchedOutline {
          border: none;
        }
      }

      .description {
        display: flex;
        align-items: center;
        padding: 16px 0;

        .icon {
          width: 24px;
          height: 24px;
          margin-right: 16px;
        }
      }
    }
  }
`;
