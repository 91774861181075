import React, { useId } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useOnClickCehckvisitEvent } from './Infomation.hooks';

import Button from '@/components/Button/Button';
import Modal from '@/components/Modal/Modal';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import { InfomationContainer } from '@/components/Raffle/Infomation/Infomation.styles';
import { useModalStore } from '@/stores/common/useModalStore';

const Infomation = ({ data, entryCheck }): React.ReactElement => {
  const [searchParams] = useSearchParams();
  const eventType = searchParams.get('eventType');
  const optionsParams = searchParams.get('options') || 'normal';
  const navigate = useNavigate();

  const { setAlertModal } = useModalStore();
  const failUserMembershipCheck = useId();
  const { checkEventChageClickEvent } = useOnClickCehckvisitEvent({ setAlertModal, failUserMembershipCheck, eventType, optionsParams });

  const checkEventStatus = (state) => {
    switch (state) {
      case '00':
        return '닫기';
      case '01':
        return entryCheck.isEntry ? '내 응모내역 확인' : '추첨 응모';
      case '02':
        return '응모 종료';
      case '03':
        return '응모 종료';
      case '04':
        return '응모 종료';
      case '05':
        return '응모 종료';
      default:
        return '응모 종료';
    }
  };

  return (
    <>
      <InfomationContainer>
        <img src={'https://egg-image.table.ceo/egg-event-main.svg'} alt="추첨 예약 이벤트 렌딩 이미지" />
      </InfomationContainer>
      <Button
        classKey="yellow-gradation"
        colorLevel="primary"
        type="sticky"
        disabled={checkEventStatus(data?.raffle?.state) === '응모 종료'}
        onClick={() => {
          checkEventChageClickEvent(data?.raffle?.state);
        }}
      >
        {checkEventStatus(data?.raffle?.state)}
      </Button>
      <ModalPortal>
        <Modal.Alert key={failUserMembershipCheck} modal="alert" isDim={true} isAnimation={true}>
          <section className="content-wrapper">
            <div className="title">알림</div>
            <p className="desc">
              욜크 멤버십 회원만 응모 가능한 이벤트입니다.
              <br />
              멤버십 혜택을 확인해 보시겠어요?
            </p>
          </section>
          <section className="button-wrapper">
            <button onClick={() => setAlertModal({ visible: false, key: failUserMembershipCheck })}>닫기</button>
            <button onClick={() => navigate('/event/egg/renewal')}>둘러보기</button>
          </section>
        </Modal.Alert>
      </ModalPortal>
    </>
  );
};

export default Infomation;
