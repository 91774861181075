import React from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { ITabsItemContainer } from './Tabs.type';

import { Colors, Typography } from '@/shared/styles';

const TabItemActive = css`
  opacity: 1;
  &::after {
    position: absolute;
    bottom: 0;
    content: '';
    display: block;
    width: 100%;
    height: 4px;
    background: #fff;
  }
`;

export const TabsItemContainer = styled.div<ITabsItemContainer>`
  position: relative;
  padding: 27px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: ${Typography.body_28_m};
  color: ${Colors.white};
  opacity: 0.4;
  ${(props) => props.active && TabItemActive}
`;

export const TabsContainer = styled.div<React.CSSProperties>`
  position: sticky;
  top: 96px;
  z-index: 99;
  display: flex;
  width: 100%;
  overflow-x: auto;
  white-space: pre;
  gap: 32px;
  padding: 0 32px;
  height: 96px;
  flex-grow: 0;
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
  background: var(--gray-700, #3b3b39);
`;
