import create from 'zustand';

export interface IFooterState {
  isFooterVisible: boolean;
  setIsFooterVisible: (props) => void;
}
export const useFooterStore = create<IFooterState>((set) => ({
  isFooterVisible: true,
  setIsFooterVisible: (isFooterVisible) => set(() => ({ isFooterVisible })),
}));
