/* eslint-disable @typescript-eslint/no-shadow */

import { useCallback } from 'react';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { debounce, throttle } from 'lodash';

dayjs.locale('ko-kr');
dayjs.extend(duration);

declare const window;
declare const navigator;

const useDebounce = (delay = 1000) => {
  return useCallback(
    debounce((func, ...args) => {
      func(...args);
    }, delay),
    [delay]
  );
};

const useThrottle = (delay = 1000) => {
  return useCallback(
    throttle((func, ...args) => {
      func(...args);
    }, delay),
    [delay]
  );
};

const durationIsValid = (iso8601DurationString) => {
  if (iso8601DurationString === 'P0D') {
    return true;
  }
  return dayjs.duration(iso8601DurationString).toISOString() !== 'P0D';
};

const durationParser = (duration) => {
  const descTokens = [];
  if (duration.years() > 0) {
    descTokens.push(`${duration.years()}년`);
  }
  if (duration.months() > 0) {
    descTokens.push(`${duration.months()}개월`);
  }
  if (duration.days() > 0) {
    descTokens.push(`${duration.days()}일`);
  }
  return descTokens.join(' ');
};

const isMobile = () => {
  if (typeof window === 'undefined') {
    return false;
  }
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(window.navigator.userAgent)) {
    return true;
  }
  return false;
};

const isApp = () => {
  if (/eggapp/i.test(window.navigator.userAgent)) {
    return true;
  }
  return false;
};

const isWebView = () => {
  const standalone = window.navigator.standalone,
    userAgent = window.navigator.userAgent.toLowerCase(),
    safari = /safari/.test(userAgent),
    ios = /iphone|ipod|ipad/.test(userAgent);

  if (ios) {
    if (!standalone && safari) {
      // browser
    } else if (standalone && !safari) {
      // standalone
    } else if (!standalone && !safari) {
      // uiwebview
    }
  } else {
    // not iOS
  }
};

/**
 *
 * @param func {Function} 결과값을 리턴할 함수
 * @param checkInterval {Number} 체크 주기 default : 0.05초 50ms
 * @param checkTimeout {Number} 체크 중단 시간 default : 5초 5000ms
 * @returns {Promise}
 */

function waitUntilExist(func, checkInterval = 50, checkTimeout = 5000) {
  return new Promise((resolve, reject) => {
    let interval = null;
    let timeout = null;
    interval = setInterval(async () => {
      const ret = await func();
      if (ret) {
        clearInterval(interval);
        clearTimeout(timeout);
        resolve(ret);
      }
    }, checkInterval);
    timeout = setTimeout(() => {
      clearInterval(interval);
      // eslint-disable-next-line prefer-promise-reject-errors
      reject(null);
    }, checkTimeout);
  });
}

// export const delay = (ms = 0, ...values) => new Promise((resolve) => setTimeout(() => resolve(...values), ms));

export { useDebounce, useThrottle, durationIsValid, durationParser, isMobile, isApp, waitUntilExist };
