import React from 'react';

import styled from '@emotion/styled';

export const MembershipContainer = styled.section<React.CSSProperties>`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  /* margin: 30px 0; */
  border-radius: 6px;
  border: 2px solid var(--secondary-500, #ddc5a4);
  background: var(--secondary-50010, rgba(221, 197, 164, 0.1));
  /*
  padding: 21px 32px;
  margin-bottom: 64px;
  background: #ffffff;
  box-shadow: 0px 4px 16px -4px rgba(39, 42, 45, 0.16);
  border-radius: 8px;*/
  padding: 24px;

  .membership-info {
    display: flex;
    align-items: center;
    flex-direction: row;

    h4 {
      color: #fff;
      /* Title 4 - SemiBold, 14, 20 */
      font-family: pretendard, sans-serif;
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: 40px; /* 142.857% */
    }
    .year-payment {
      margin-left: 12px;
      display: flex;
      padding: 12px 14px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border-radius: 6px;
      border-radius: 6px;
      background: var(--secondary-50010, rgba(221, 197, 164, 0.1));
      color: var(--secondary-500, #ddc5a4);
      text-align: center;

      /* Body 3 - Regular, 12, 18 */
      font-family: 'Pretendard Variable';
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
    }

    ol > li {
      width: 100%;
    }
  }

  .white-club {
    display: flex;
    padding: 8px 16px;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    font-family: pretendard, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.24px;
    background: #fff;
    color: var(--gray-900, #14120e);
  }

  .black-club {
    display: flex;
    padding: 8px 16px;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    font-family: pretendard, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.24px;
    background: var(--gray-900, #14120e);
  }

  .right-item {
    margin-left: auto;
    color: #fff;
    display: flex;
    align-items: center;
    flex-direction: row;
    align-items: center;
  }

  .right-item > div {
    margin-left: 0;
    color: #fff;
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;

    /* Label 3 - Medium, 12, 18 */
    font-family: pretendard, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
    opacity: 0.8;
  }

  .black-benefit {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
  }

  .black-benefit-contents {
    display: flex;
    align-items: center;
    gap: 12px;
    align-self: stretch;

    /* align-items: center;
    margin: 12px 0 !important;
    color: #fff;
    opacity: 0.8; */

    /* Body 3 - Regular, 12, 18 */
    font-family: pretendard, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 150% */

    & > .desc {
      span {
        color: var(--secondary-500, #ddc5a4);
      }
    }
  }

  .btn-upgrade-plan {
    opacity: 1 !important;
    display: flex;
    padding: 18px 24px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 6px;
    background: var(--green-300, #e8ff68);
    color: var(--gray-900, #14120e) !important;
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;

    /* Label 3 - Medium, 12, 18 */
    font-family: pretendard, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 150% */
  }

  .content {
    display: flex;
    justify-content: space-between;
    .user-name {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 28px;
      line-height: 36px;
      color: #272a2d;
      span {
        margin-left: 8px;
      }
    }
  }
  .membership-status {
    display: flex;
    align-items: center;
    height: 62px;
    font-weight: 400;
    font-size: 28px;
    line-height: 36px;
    color: #272a2d;
    padding: 13px 28px;
    background: #ffffff;
    border: 2px solid #f6c944;
    border-radius: 31px;
  }

  & > button.small100 {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 150% */
  }
`;
