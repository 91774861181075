import React from 'react';
import { useNavigate } from 'react-router-dom';

import BookingFooter from '@/components/BookingFooter/BookingFooter';
import Button from '@/components/Button/Button';
import { useBookingStore } from '@/stores/useBookingStore';
import { useInfoStore } from '@/stores/useInfoStore';

const InfoFooter = (): React.ReactElement => {
  const navigate = useNavigate();
  const { storeUri } = useBookingStore();
  const { calendarDirty, timeDirty } = useInfoStore();

  const moveCategory = () => navigate(`/reservation/${storeUri}/step/category`);
  const isActive = calendarDirty && timeDirty;
  // const isActive = false;
  // return <BookingFooter text="확인" action={moveCategory} active={calendarDirty && timeDirty} />;
  return (
    <Button type="sticky" colorLevel="primary" isActive={isActive} disabled={!isActive} onClick={moveCategory}>
      다음
    </Button>
  );
};

export default InfoFooter;
