import React from 'react';

import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

export const DepositInfoContainer = styled.section<React.CSSProperties>`
  display: flex;
  flex-direction: column;
  gap: 32px;
  & > h1 {
    ${Typography.title_36_sb};
    color: ${Colors.white};
  }
`;
export const DepositInfoTextContainer = styled.div<React.CSSProperties>`
  ${Typography.body_28_ss};
  color: ${Colors.white};
`;
