import React from 'react';

import styled from '@emotion/styled';

export const MyPageContainer = styled.section<React.CSSProperties>`
  /* width: 100%; */
  display: flex;
  padding: 48px 32px 96px 32px;
  flex-direction: column;
  align-items: center;
  gap: 48px;

  /* width: 100%; */
  /* padding: 80px 32px 0; */
  /* text-align: left; */

  & > .user-infomation {
    width: 100%;
    display: flex;
    /* width: 343px; */
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;

    & > .edit-info {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
      align-self: stretch;

      text-align: center;
      font-feature-settings: 'liga' off, 'clig' off;

      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 36px;

      & > div {
        display: flex;
      }
    }
  }

  & > .divider {
    height: 2px;
    width: 100%;
    opacity: 0.1;
    background: #fff;
  }
`;
