import React from 'react';
import Layout from '@/components/Layout/Layout';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import AuthIdentityPage from '@/pages/auth/Identity';
import AuthLoginCallbackPagePage from '@/pages/auth/loginCallbackPage';
import AuthSigninPage from '@/pages/auth/signin';
import BannerMembershipPage from '@/pages/banner/membership';
import CallbackPage from '@/pages/callback';
import ComponentsPage from '@/pages/components';
import Detail$IdPage from '@/pages/detail/[id]';
import ErrorPage from '@/pages/error';
import EventEggLineupPage from '@/pages/event/egg/lineup';
import EventEggRafflePage from '@/pages/event/egg/raffle';
import EventEggRenewalPage from '@/pages/event/egg/renewal';
import EventEggShunjiPage from '@/pages/event/egg/shunji';
import IndexPage from '@/pages/index';
import LoginSocialPage from '@/pages/login/social';
import MainIndexPage from '@/pages/main/index';
import MembershipCancelSuccessPage from '@/pages/membership/cancel-success';
import MembershipCancelPage from '@/pages/membership/cancel';
import MembershipEntryPage from '@/pages/membership/entry';
import MembershipInvitationWildcardPage from '@/pages/membership/invitation/wildcard';
import MembershipJoinPage from '@/pages/membership/join';
import MembershipPaymentAddPage from '@/pages/membership/payment/add';
import MembershipPaymentChangePage from '@/pages/membership/payment/change';
import MypageEditPage from '@/pages/mypage/edit';
import MypageIndexPage from '@/pages/mypage/index';
import MypageMembershipPage from '@/pages/mypage/membership';
import MypageWithdrawalPage from '@/pages/mypage/withdrawal';
import NotFoundIndexPage from '@/pages/not-found/index';
import PopupmodalIndexPage from '@/pages/popupmodal/index';
import RaffleReservationAnnouncementIndexPage from '@/pages/raffle-reservation/announcement/index';
import RaffleReservationApplyIndexPage from '@/pages/raffle-reservation/apply/index';
import RaffleReservationCompleteIndexPage from '@/pages/raffle-reservation/complete/index';
import RaffleReservationIndexPage from '@/pages/raffle-reservation/index';
import RedirectAppStorePage from '@/pages/redirect/appStore';
import RedirectInviteCodePage$CodeKeyPage from '@/pages/redirect/inviteCodePage/[codeKey]';
import ReservationHistoryIndexPage from '@/pages/reservation/history/index';
import ReservationHistory$ReservationIdPage from '@/pages/reservation/history/[reservationId]';
import ReservationStepCompleteView$ReservationIdPage from '@/pages/reservation/step/complete-view/[reservationId]';
import ReservationStepCompleteIndexPage from '@/pages/reservation/step/complete/index';
import Reservation$StoreUriStepCategoryPage from '@/pages/reservation/[storeUri]/step/category';
import Reservation$StoreUriStepDetailInfoPage from '@/pages/reservation/[storeUri]/step/detail-info';
import Reservation$StoreUriStepInfoIndexPage from '@/pages/reservation/[storeUri]/step/info/index';
import Reservation$StoreUriStepPaymentPage from '@/pages/reservation/[storeUri]/step/payment';

const CustomRouter = (props = {}) => <BrowserRouter>
  <Layout>
    <Routes {...props}>
      <Route key="AuthIdentityPage" path="/auth/Identity" element={<AuthIdentityPage/>} />
      <Route key="AuthLoginCallbackPagePage" path="/auth/loginCallbackPage" element={<AuthLoginCallbackPagePage/>} />
      <Route key="AuthSigninPage" path="/auth/signin" element={<AuthSigninPage/>} />
      <Route key="BannerMembershipPage" path="/banner/membership" element={<BannerMembershipPage/>} />
      <Route key="CallbackPage" path="/callback" element={<CallbackPage/>} />
      <Route key="ComponentsPage" path="/components" element={<ComponentsPage/>} />
      <Route key="Detail$IdPage" path="/detail/:id" element={<Detail$IdPage/>} />
      <Route key="ErrorPage" path="/error" element={<ErrorPage/>} />
      <Route key="EventEggLineupPage" path="/event/egg/lineup" element={<EventEggLineupPage/>} />
      <Route key="EventEggRafflePage" path="/event/egg/raffle" element={<EventEggRafflePage/>} />
      <Route key="EventEggRenewalPage" path="/event/egg/renewal" element={<EventEggRenewalPage/>} />
      <Route key="EventEggShunjiPage" path="/event/egg/shunji" element={<EventEggShunjiPage/>} />
      <Route key="IndexPage" path="/" element={<IndexPage/>} />
      <Route key="LoginSocialPage" path="/login/social" element={<LoginSocialPage/>} />
      <Route key="MainIndexPage" path="/main/" element={<MainIndexPage/>} />
      <Route key="MembershipCancelSuccessPage" path="/membership/cancel-success" element={<MembershipCancelSuccessPage/>} />
      <Route key="MembershipCancelPage" path="/membership/cancel" element={<MembershipCancelPage/>} />
      <Route key="MembershipEntryPage" path="/membership/entry" element={<MembershipEntryPage/>} />
      <Route key="MembershipInvitationWildcardPage" path="/membership/invitation/*" element={<MembershipInvitationWildcardPage/>} />
      <Route key="MembershipJoinPage" path="/membership/join" element={<MembershipJoinPage/>} />
      <Route key="MembershipPaymentAddPage" path="/membership/payment/add" element={<MembershipPaymentAddPage/>} />
      <Route key="MembershipPaymentChangePage" path="/membership/payment/change" element={<MembershipPaymentChangePage/>} />
      <Route key="MypageEditPage" path="/mypage/edit" element={<MypageEditPage/>} />
      <Route key="MypageIndexPage" path="/mypage/" element={<MypageIndexPage/>} />
      <Route key="MypageMembershipPage" path="/mypage/membership" element={<MypageMembershipPage/>} />
      <Route key="MypageWithdrawalPage" path="/mypage/withdrawal" element={<MypageWithdrawalPage/>} />
      <Route key="NotFoundIndexPage" path="/not-found/" element={<NotFoundIndexPage/>} />
      <Route key="PopupmodalIndexPage" path="/popupmodal/" element={<PopupmodalIndexPage/>} />
      <Route key="RaffleReservationAnnouncementIndexPage" path="/raffle-reservation/announcement/" element={<RaffleReservationAnnouncementIndexPage/>} />
      <Route key="RaffleReservationApplyIndexPage" path="/raffle-reservation/apply/" element={<RaffleReservationApplyIndexPage/>} />
      <Route key="RaffleReservationCompleteIndexPage" path="/raffle-reservation/complete/" element={<RaffleReservationCompleteIndexPage/>} />
      <Route key="RaffleReservationIndexPage" path="/raffle-reservation/" element={<RaffleReservationIndexPage/>} />
      <Route key="RedirectAppStorePage" path="/redirect/appStore" element={<RedirectAppStorePage/>} />
      <Route key="RedirectInviteCodePage$CodeKeyPage" path="/redirect/inviteCodePage/:codeKey" element={<RedirectInviteCodePage$CodeKeyPage/>} />
      <Route key="ReservationHistoryIndexPage" path="/reservation/history/" element={<ReservationHistoryIndexPage/>} />
      <Route key="ReservationHistory$ReservationIdPage" path="/reservation/history/:reservationId" element={<ReservationHistory$ReservationIdPage/>} />
      <Route key="ReservationStepCompleteView$ReservationIdPage" path="/reservation/step/complete-view/:reservationId" element={<ReservationStepCompleteView$ReservationIdPage/>} />
      <Route key="ReservationStepCompleteIndexPage" path="/reservation/step/complete/" element={<ReservationStepCompleteIndexPage/>} />
      <Route key="Reservation$StoreUriStepCategoryPage" path="/reservation/:storeUri/step/category" element={<Reservation$StoreUriStepCategoryPage/>} />
      <Route key="Reservation$StoreUriStepDetailInfoPage" path="/reservation/:storeUri/step/detail-info" element={<Reservation$StoreUriStepDetailInfoPage/>} />
      <Route key="Reservation$StoreUriStepInfoIndexPage" path="/reservation/:storeUri/step/info/" element={<Reservation$StoreUriStepInfoIndexPage/>} />
      <Route key="Reservation$StoreUriStepPaymentPage" path="/reservation/:storeUri/step/payment" element={<Reservation$StoreUriStepPaymentPage/>} />
      <Route key="NotFountPage" path="*" element={<NotFoundIndexPage />} />
    </Routes>
  </Layout>
</BrowserRouter>;

export default CustomRouter;
