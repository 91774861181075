import React from 'react';

import { EmblemContainer, EmblemWithTextContainer, EmblemWithTextBigContainer } from './MembershipEmblem.styles';
import { MEmblemProps } from './MembershipEmblem.types';

const MembershipEmblem = ({ type, grade, size }: MEmblemProps): React.ReactElement => {
  let svgWidth,
    svgHeight,
    svgFillColor = '';

  if (size === 'medium') {
    svgWidth = 80;
    svgHeight = 80;
  } else {
    svgWidth = 40;
    svgHeight = 40;
  }

  if (grade === 'white') {
    svgFillColor = '#FFFFFF';
  } else if (grade === 'black') {
    svgFillColor = '#14120E';
  } else if (grade === 'green') {
    svgFillColor = '#E8FF68';
  }

  if (type === 'emblem') {
    if (grade === 'white') {
      return (
        <EmblemContainer className={`${grade} ${size}`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="{svgWidth}" height="{svgHeight}" viewBox="0 0 40 40" fill="none">
            <path d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z" fill="white" />
            <svg x="5.5" y="11.5" width="29" height="17" viewBox="0 0 29 17" fill="none">
              <path
                d="M14.6873 16.5613L11.7592 5.71298L8.79596 16.5613H7.35343L3.0962 3.60586C2.81082 2.85918 2.43161 2.36661 2.12278 2.00305C1.60284 1.38928 0.903076 1.46356 0.903076 1.46356V0.603516H6.5872V1.49484L6.208 1.50656C5.4926 1.50656 5.21113 2.1946 5.39487 2.83964L8.33857 13.1172L10.8913 3.25402C10.5161 1.65512 8.3933 1.47529 8.3933 1.47529V0.603516H14.1556V1.49875C14.1556 1.49875 12.6036 1.6043 12.7795 2.37834L15.7311 13.0937L18.7999 2.30406C19.0344 1.55739 17.0407 1.57693 17.0407 1.57693V0.603516H22.1658V1.53393C22.1658 1.53393 20.8914 1.46747 20.2346 3.5824L16.3018 16.5613H14.6834H14.6873Z"
                fill="black"
              />
              <path
                d="M24.4684 16.6125C21.7006 16.6125 20.2385 14.8768 20.2385 11.593C20.2385 10.0996 20.5669 8.84081 21.1885 7.94949C21.9273 6.89007 23.0532 6.35449 24.527 6.35449C26.7045 6.35449 28.2956 7.73057 28.5458 9.73213L26.8648 9.73995C26.4778 8.53588 25.6725 7.80875 24.4684 7.80875C22.2401 7.80875 21.9078 10.1504 21.9078 11.546C21.9078 13.9425 22.7874 15.1582 24.5231 15.1582C25.6451 15.1582 26.4778 14.4585 26.857 13.2544L28.538 13.2896C28.151 15.3068 26.5872 16.6125 24.4645 16.6125H24.4684Z"
                fill="black"
              />
            </svg>
          </svg>
        </EmblemContainer>
      );
    } else if (grade === 'green') {
      return (
        <EmblemContainer className={`${grade} ${size}`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="{svgWidth}" height="{svgHeight}" viewBox="0 0 56 56" fill="none">
            <path d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z" fill="#284C39" />
            <svg x="10" y="15" width="36" height="26" viewBox="0 0 36 26" fill="none">
              <path
                d="M11.8538 25.3181C10.8741 25.3181 8.15403 24.7927 6.22206 23.7418C4.29555 22.6965 2.7631 21.2133 1.6685 19.3415C0.579367 17.4698 0.0211182 15.3298 0.0211182 12.9709C0.0211182 10.6121 0.579367 8.49946 1.68492 6.61674C2.5606 5.11714 5.23144 0.558105 11.5199 0.558105C12.9484 0.558105 14.4535 0.716823 15.9859 1.03973C17.5239 1.35717 18.8866 1.78954 20.036 2.32042L20.2221 2.40799L20.9335 8.5542L19.5708 8.5323C18.3065 4.60814 17.0751 2.88961 11.7608 2.88961C11.6622 2.88961 11.5583 2.88961 11.4543 2.88961C7.31119 2.9334 5.86631 5.86147 5.17124 7.26804C4.42143 8.79501 4.03832 10.5573 4.03832 12.5057C4.03832 14.7825 4.44332 16.5558 5.31353 18.0827C6.21658 19.6699 7.60126 20.8849 9.22127 21.5088C11.0712 22.2203 12.6966 22.5816 14.0485 22.5816C15.5043 22.5816 16.6755 22.1601 17.5239 21.3337V16.2438C17.5239 15.4447 17.4089 15.0452 16.993 14.5691C16.4183 13.9013 14.0266 13.9342 14.0266 13.9342V12.6042H22.3128V13.9232L21.9625 13.9397C20.6161 14.0053 20.6216 14.4432 20.6271 15.9045V20.9342L20.6052 20.9725C20.4519 22.7895 16.0188 25.3017 11.8429 25.3017L11.8538 25.3181Z"
                fill="white"
              />
              <path
                d="M29.9969 24.4536C25.936 24.4536 23.7905 21.9086 23.7905 17.0923C23.7905 14.9031 24.2722 13.0532 25.1861 11.7507C26.2698 10.1963 27.9172 9.4082 30.0845 9.4082C33.2753 9.4082 35.6123 11.4277 35.979 14.3613L33.5161 14.3722C32.9524 12.6045 31.7702 11.5372 30.0024 11.5372C26.735 11.5372 26.2479 14.9743 26.2479 17.0212C26.2479 20.5349 27.5395 22.3191 30.0845 22.3191C31.7319 22.3191 32.9524 21.2901 33.5051 19.5278L35.9735 19.5771C35.4043 22.5325 33.1111 24.4536 29.9969 24.4536Z"
                fill="white"
              />
            </svg>
          </svg>
        </EmblemContainer>
      );
    }
  } else if (type === 'emblemWithText') {
    if (size === 'medium') {
      if (grade === 'white') {
        return (
          <EmblemWithTextContainer className={`${grade}`}>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 16 16" fill="none">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.3815 0.450225C8.18179 0.214508 7.81823 0.214509 7.61852 0.450225L6.32102 1.98167C6.19856 2.12621 6.00492 2.18913 5.82088 2.14417L3.87102 1.66786C3.5709 1.59455 3.27678 1.80824 3.25376 2.11633L3.10422 4.11794C3.09011 4.30686 2.97043 4.47158 2.79512 4.54339L0.937679 5.30414C0.651786 5.42124 0.53944 5.767 0.701906 6.02978L1.75744 7.73701C1.85707 7.89815 1.85707 8.10176 1.75744 8.26289L0.701906 9.97013C0.53944 10.2329 0.651786 10.5787 0.937679 10.6958L2.79512 11.4565C2.97043 11.5283 3.09011 11.693 3.10422 11.882L3.25376 13.8836C3.27678 14.1917 3.5709 14.4054 3.87102 14.332L5.82088 13.8557C6.00492 13.8108 6.19856 13.8737 6.32102 14.0182L7.61852 15.5497C7.81823 15.7854 8.18179 15.7854 8.3815 15.5497L9.679 14.0182C9.80147 13.8737 9.9951 13.8108 10.1791 13.8557L12.129 14.332C12.4291 14.4054 12.7232 14.1917 12.7463 13.8836L12.8958 11.882C12.9099 11.693 13.0296 11.5283 13.2049 11.4565L15.0623 10.6958C15.3482 10.5787 15.4606 10.2329 15.2981 9.97013L14.2426 8.26289C14.143 8.10176 14.143 7.89815 14.2426 7.73701L15.2981 6.02978C15.4606 5.767 15.3482 5.42124 15.0623 5.30414L13.2049 4.54339C13.0296 4.47158 12.9099 4.30686 12.8958 4.11794L12.7463 2.11633C12.7232 1.80824 12.4291 1.59455 12.129 1.66786L10.1791 2.14417C9.9951 2.18913 9.80147 2.12621 9.679 1.98167L8.3815 0.450225ZM5.34383 6.52966L5.63013 3.66662L6.75438 3.77905L6.46807 6.64208C6.41265 7.19635 6.84791 7.67731 7.40494 7.67731H7.4351V3.72286H8.56495L8.56495 7.67731H8.59509C9.15213 7.67731 9.58739 7.19636 9.53196 6.64208L9.24566 3.77905L10.3699 3.66662L10.6562 6.52966C10.7781 7.74906 9.82057 8.80716 8.59509 8.80716H8.56495V12.7617H7.4351L7.4351 8.80716H7.40494C6.17946 8.80716 5.22189 7.74906 5.34383 6.52966Z"
                fill="#14120E"
              />
            </svg>
            WhiteClub
          </EmblemWithTextContainer>
        );
      } else if (grade === 'black') {
        return (
          <EmblemWithTextContainer className={`${grade}`}>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 16 16" fill="none">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.38148 0.450225C8.18178 0.214508 7.81822 0.214509 7.61851 0.450225L6.32101 1.98167C6.19854 2.12621 6.0049 2.18913 5.82087 2.14417L3.87101 1.66786C3.57089 1.59455 3.27676 1.80824 3.25375 2.11633L3.1042 4.11794C3.09009 4.30686 2.97042 4.47158 2.7951 4.54339L0.937664 5.30414C0.651771 5.42124 0.539425 5.767 0.701891 6.02978L1.75743 7.73701C1.85706 7.89815 1.85706 8.10176 1.75743 8.26289L0.701891 9.97013C0.539425 10.2329 0.651771 10.5787 0.937664 10.6958L2.7951 11.4565C2.97042 11.5283 3.09009 11.693 3.1042 11.882L3.25375 13.8836C3.27676 14.1917 3.57089 14.4054 3.87101 14.332L5.82087 13.8557C6.0049 13.8108 6.19854 13.8737 6.32101 14.0182L7.61851 15.5497C7.81822 15.7854 8.18178 15.7854 8.38148 15.5497L9.67899 14.0182C9.80145 13.8737 9.99509 13.8108 10.1791 13.8557L12.129 14.332C12.4291 14.4054 12.7232 14.1917 12.7462 13.8836L12.8958 11.882C12.9099 11.693 13.0296 11.5283 13.2049 11.4565L15.0623 10.6958C15.3482 10.5787 15.4606 10.2329 15.2981 9.97013L14.2426 8.26289C14.1429 8.10176 14.1429 7.89815 14.2426 7.73701L15.2981 6.02978C15.4606 5.767 15.3482 5.42124 15.0623 5.30414L13.2049 4.54339C13.0296 4.47158 12.9099 4.30686 12.8958 4.11794L12.7462 2.11633C12.7232 1.80824 12.4291 1.59455 12.129 1.66786L10.1791 2.14417C9.99509 2.18913 9.80145 2.12621 9.67899 1.98167L8.38148 0.450225ZM5.34382 6.52966L5.63012 3.66662L6.75436 3.77905L6.46806 6.64208C6.41263 7.19635 6.84789 7.67731 7.40493 7.67731H7.43508V3.72286H8.56493L8.56493 7.67731H8.59507C9.15211 7.67731 9.58737 7.19636 9.53194 6.64208L9.24564 3.77905L10.3699 3.66662L10.6562 6.52966C10.7781 7.74906 9.82055 8.80716 8.59507 8.80716H8.56493V12.7617H7.43508L7.43508 8.80716H7.40493C6.17945 8.80716 5.22188 7.74906 5.34382 6.52966Z"
                fill="white"
              />
            </svg>
            BlackClub
          </EmblemWithTextContainer>
        );
      } else if (grade === 'green') {
        return (
          <EmblemWithTextContainer className={`${grade}`}>
            {/*
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 14 14" fill="none">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7ZM9.18105 3.34895L9.17948 3.49108L7.56499 6.51943H7.40637L8.84731 3.34895H9.18065H9.18105ZM4.99328 10.6805L4.99171 10.8226H5.32505L6.76639 7.65215H6.60776L4.99328 10.6805ZM11.0527 9.631C10.8807 10.3154 10.377 10.8226 9.46296 10.8226C8.26858 10.8226 7.81352 9.9506 7.81352 8.83044C7.81352 7.75346 8.27251 6.80526 9.48691 6.80526C10.441 6.80526 10.9408 7.36279 11.0535 8.02437H10.5121C10.3817 7.61721 10.0727 7.25207 9.46492 7.25207C8.62548 7.25207 8.34828 8.02633 8.34828 8.81159C8.34828 9.59685 8.60428 10.3754 9.48769 10.3754C10.0868 10.3754 10.3746 9.98633 10.5093 9.631H11.0527ZM4.54372 7.03769C5.90967 7.03769 6.52217 6.29248 6.52217 5.09889V5.0985C6.52217 3.90491 5.90967 3.1601 4.54372 3.1601H2.95593V3.30223C3.33285 3.33521 3.453 3.43925 3.453 3.70742V6.48998C3.453 6.75814 3.33285 6.86219 2.95593 6.89517V7.03769H4.54372ZM4.27325 6.85119H4.5818C5.60892 6.85119 6.08596 6.17234 6.08596 5.09889C6.08596 4.02545 5.58732 3.34659 4.54372 3.34659H3.85073V6.50097C3.85073 6.73373 3.96566 6.84387 4.27325 6.85119Z"
                fill="#14120E"
              />
            </svg>*/}
            Green&nbsp;<span className="bold">Club</span>
          </EmblemWithTextContainer>
        );
      }
    } else if (size === 'big') {
      if (grade === 'white') {
        return (
          <EmblemWithTextBigContainer>
            <svg xmlns="http://www.w3.org/2000/svg" width="112" height="112" viewBox="0 0 16 16" fill="none">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.3815 0.450225C8.18179 0.214508 7.81823 0.214509 7.61852 0.450225L6.32102 1.98167C6.19856 2.12621 6.00492 2.18913 5.82088 2.14417L3.87102 1.66786C3.5709 1.59455 3.27678 1.80824 3.25376 2.11633L3.10422 4.11794C3.09011 4.30686 2.97043 4.47158 2.79512 4.54339L0.937679 5.30414C0.651786 5.42124 0.53944 5.767 0.701906 6.02978L1.75744 7.73701C1.85707 7.89815 1.85707 8.10176 1.75744 8.26289L0.701906 9.97013C0.53944 10.2329 0.651786 10.5787 0.937679 10.6958L2.79512 11.4565C2.97043 11.5283 3.09011 11.693 3.10422 11.882L3.25376 13.8836C3.27678 14.1917 3.5709 14.4054 3.87102 14.332L5.82088 13.8557C6.00492 13.8108 6.19856 13.8737 6.32102 14.0182L7.61852 15.5497C7.81823 15.7854 8.18179 15.7854 8.3815 15.5497L9.679 14.0182C9.80147 13.8737 9.9951 13.8108 10.1791 13.8557L12.129 14.332C12.4291 14.4054 12.7232 14.1917 12.7463 13.8836L12.8958 11.882C12.9099 11.693 13.0296 11.5283 13.2049 11.4565L15.0623 10.6958C15.3482 10.5787 15.4606 10.2329 15.2981 9.97013L14.2426 8.26289C14.143 8.10176 14.143 7.89815 14.2426 7.73701L15.2981 6.02978C15.4606 5.767 15.3482 5.42124 15.0623 5.30414L13.2049 4.54339C13.0296 4.47158 12.9099 4.30686 12.8958 4.11794L12.7463 2.11633C12.7232 1.80824 12.4291 1.59455 12.129 1.66786L10.1791 2.14417C9.9951 2.18913 9.80147 2.12621 9.679 1.98167L8.3815 0.450225ZM5.34383 6.52966L5.63013 3.66662L6.75438 3.77905L6.46807 6.64208C6.41265 7.19635 6.84791 7.67731 7.40494 7.67731H7.4351V3.72286H8.56495L8.56495 7.67731H8.59509C9.15213 7.67731 9.58739 7.19636 9.53196 6.64208L9.24566 3.77905L10.3699 3.66662L10.6562 6.52966C10.7781 7.74906 9.82057 8.80716 8.59509 8.80716H8.56495V12.7617H7.4351L7.4351 8.80716H7.40494C6.17946 8.80716 5.22189 7.74906 5.34383 6.52966Z"
                fill="#14120E"
              />
            </svg>
            <div className="membership-title">WhiteClub</div>
          </EmblemWithTextBigContainer>
        );
      } else if (grade === 'black') {
        return (
          <EmblemWithTextBigContainer>
            <div className={`${grade}`}>
              <svg xmlns="http://www.w3.org/2000/svg" width="58" height="58" viewBox="0 0 29 28" fill="none">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14.8815 0.450224C14.6818 0.214508 14.3182 0.214509 14.1185 0.450225L11.4119 3.64485C11.2894 3.78939 11.0958 3.85231 10.9117 3.80735L6.84429 2.81376C6.54417 2.74045 6.25005 2.95414 6.22703 3.26223L5.91508 7.43764C5.90097 7.62656 5.78129 7.79128 5.60598 7.86309L1.73132 9.45004C1.44543 9.56713 1.33308 9.9129 1.49555 10.1757L3.69742 13.737C3.79705 13.8982 3.79705 14.1018 3.69742 14.2629L1.49555 17.8242C1.33308 18.087 1.44543 18.4328 1.73132 18.5499L5.60598 20.1368C5.78129 20.2086 5.90097 20.3733 5.91508 20.5623L6.22703 24.7377C6.25005 25.0458 6.54417 25.2595 6.84429 25.1861L10.9117 24.1926C11.0958 24.1476 11.2894 24.2105 11.4119 24.3551L14.1185 27.5497C14.3182 27.7854 14.6818 27.7854 14.8815 27.5497L17.5881 24.3551C17.7106 24.2105 17.9042 24.1476 18.0882 24.1926L22.1557 25.1861C22.4558 25.2595 22.7499 25.0458 22.773 24.7377L23.0849 20.5623C23.099 20.3733 23.2187 20.2086 23.394 20.1368L27.2687 18.5499C27.5546 18.4328 27.6669 18.087 27.5044 17.8242L25.3026 14.2629C25.2029 14.1018 25.2029 13.8982 25.3026 13.737L27.5044 10.1757C27.6669 9.9129 27.5546 9.56713 27.2687 9.45004L23.394 7.86309C23.2187 7.79128 23.099 7.62656 23.0849 7.43764L22.773 3.26223C22.7499 2.95414 22.4558 2.74045 22.1557 2.81376L18.0882 3.80735C17.9042 3.85231 17.7106 3.78939 17.5881 3.64485L14.8815 0.450224ZM9.85168 11.4269L10.3527 6.41663L12.3201 6.61337L11.8191 11.6237C11.7221 12.5937 12.4838 13.4353 13.4586 13.4353H13.5114V6.51505H15.4886V13.4353H15.5414C16.5162 13.4353 17.2779 12.5937 17.1809 11.6237L16.6799 6.61337L18.6473 6.41663L19.1483 11.4269C19.3617 13.5609 17.686 15.4126 15.5414 15.4126H15.4886V22.333H13.5114V15.4126H13.4586C11.314 15.4126 9.63828 13.5609 9.85168 11.4269Z"
                  fill="white"
                />
              </svg>
            </div>
            <div className="membership-title">BlackClub</div>
          </EmblemWithTextBigContainer>
        );
      } else if (grade === 'green') {
        return (
          <EmblemWithTextBigContainer>
            <svg xmlns="http://www.w3.org/2000/svg" width="112" height="112" viewBox="0 0 56 56" fill="none">
              <path d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z" fill="#284C39" />
              <svg x="10" y="15" width="36" height="26" viewBox="0 0 36 26" fill="none">
                <path
                  d="M11.8538 25.3181C10.8741 25.3181 8.15403 24.7927 6.22206 23.7418C4.29555 22.6965 2.7631 21.2133 1.6685 19.3415C0.579367 17.4698 0.0211182 15.3298 0.0211182 12.9709C0.0211182 10.6121 0.579367 8.49946 1.68492 6.61674C2.5606 5.11714 5.23144 0.558105 11.5199 0.558105C12.9484 0.558105 14.4535 0.716823 15.9859 1.03973C17.5239 1.35717 18.8866 1.78954 20.036 2.32042L20.2221 2.40799L20.9335 8.5542L19.5708 8.5323C18.3065 4.60814 17.0751 2.88961 11.7608 2.88961C11.6622 2.88961 11.5583 2.88961 11.4543 2.88961C7.31119 2.9334 5.86631 5.86147 5.17124 7.26804C4.42143 8.79501 4.03832 10.5573 4.03832 12.5057C4.03832 14.7825 4.44332 16.5558 5.31353 18.0827C6.21658 19.6699 7.60126 20.8849 9.22127 21.5088C11.0712 22.2203 12.6966 22.5816 14.0485 22.5816C15.5043 22.5816 16.6755 22.1601 17.5239 21.3337V16.2438C17.5239 15.4447 17.4089 15.0452 16.993 14.5691C16.4183 13.9013 14.0266 13.9342 14.0266 13.9342V12.6042H22.3128V13.9232L21.9625 13.9397C20.6161 14.0053 20.6216 14.4432 20.6271 15.9045V20.9342L20.6052 20.9725C20.4519 22.7895 16.0188 25.3017 11.8429 25.3017L11.8538 25.3181Z"
                  fill="white"
                />
                <path
                  d="M29.9969 24.4536C25.936 24.4536 23.7905 21.9086 23.7905 17.0923C23.7905 14.9031 24.2722 13.0532 25.1861 11.7507C26.2698 10.1963 27.9172 9.4082 30.0845 9.4082C33.2753 9.4082 35.6123 11.4277 35.979 14.3613L33.5161 14.3722C32.9524 12.6045 31.7702 11.5372 30.0024 11.5372C26.735 11.5372 26.2479 14.9743 26.2479 17.0212C26.2479 20.5349 27.5395 22.3191 30.0845 22.3191C31.7319 22.3191 32.9524 21.2901 33.5051 19.5278L35.9735 19.5771C35.4043 22.5325 33.1111 24.4536 29.9969 24.4536Z"
                  fill="white"
                />
              </svg>
            </svg>
            <div className="membership-title">GreenClub</div>
          </EmblemWithTextBigContainer>
        );
      }
    }
  }
};

export default MembershipEmblem;
