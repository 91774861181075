import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import AgreeTerms from '@/components/EditProfile/AgreeTerms/AgreeTerms';
import UserInformation from '@/components/EditProfile/UserInformation/UserInformation';
import { EditProfileContainer } from '@/shared/styles/pages/editProfile';
import { useFooterStore } from '@/stores/common/useFooterStore';
import { useHeaderStore } from '@/stores/common/useHeaderStore';
import { useNavigationStore } from '@/stores/common/useNavigationStore';

function Edit() {
  const setIsNavigationVisible = useNavigationStore((store) => store.setIsNavigationVisible);
  const setIsFooterVisible = useFooterStore((store) => store.setIsFooterVisible);
  const setHeaderState = useHeaderStore((store) => store.setHeaderState);
  const navigate = useNavigate();

  useEffect(() => {
    setHeaderState({
      title: '회원정보 수정',
      handleGoToBack: () => navigate('/mypage'),
      handleGoToHome: null,
      handleOnAlarm: null,
      handleOnClose: null,
      handleOnShare: null,
    });
    setIsNavigationVisible(false);
    setIsFooterVisible(false);
  }, []);

  return (
    <EditProfileContainer>
      <UserInformation />
      <AgreeTerms />
    </EditProfileContainer>
  );
}

export default Edit;
