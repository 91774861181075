import create from 'zustand';

import { Nullable } from '@/shared/types/common.types';

// 초대코드 전역 관리
interface IInvitationCodeState {
  invitationCode: string;
  setInvitationCode: (invitationCode: string) => void;
}

const useInvitationCodeStore = create<IInvitationCodeState>((set) => ({
  invitationCode: '',
  setInvitationCode: (invitationCode) => set(() => ({ invitationCode })),
}));

// 카드 정보가 변경 되었는지 확인
interface ICardPaymentStore {
  isChangePayment: boolean;
  setIsChangePayment: (isChangePayment: boolean) => void;
}

const useCardPaymentStore = create<ICardPaymentStore>((set) => ({
  isChangePayment: false,
  setIsChangePayment: (isChangePayment) => set(() => ({ isChangePayment })),
}));

// 멤버십 가입(결제) 진입할 때 진입했던 이전 페이지 기록 저장
interface ISaveEntryPathState {
  entryPath: Nullable<string>;
  setEntryPath: (entryPath: string) => void;
}

const useSaveEntryPathStore = create<ISaveEntryPathState>((set) => ({
  entryPath: '/main',
  setEntryPath: (entryPath: string) => set(() => ({ entryPath })),
}));

export { useInvitationCodeStore, useCardPaymentStore, useSaveEntryPathStore };
