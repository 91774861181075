import React from 'react';

import { ModalContainer } from '../modal.styles';

import { IModalProps } from '@/components/Modal/Modal.type';
import { useModalStore } from '@/stores/common/useModalStore';

const EventPopup = ({ isDim, classKey = undefined, isAnimation, children, onClose }: IModalProps) => {
  const { alertModal, setAlertModal } = useModalStore();

  if (!alertModal.visible) {
    return <></>;
  }
  return (
    <ModalContainer className={`event-alert ${isAnimation ? 'animation' : ''} ${classKey}`}>
      {isDim ? <div className="modal-dim" onClick={() => (onClose ? onClose() : setAlertModal(false))} /> : null}
      <div className="modal-content">{children}</div>
    </ModalContainer>
  );
};

export default EventPopup;
