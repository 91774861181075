import {} from './DetailPartySize.type';

import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import classNames from 'classnames';
import hasIn from 'lodash/hasIn';

import { DetailPartySizeContainer } from './DetailPartySize.constants';

import InputHint from '@/components/InputHint/InputHint';
import MainSubtitle from '@/components/MainSubtitle/MainSubtitle';
import { useBookingInfoStore } from '@/stores/useBookingInfoStore';
import { useBookingStore } from '@/stores/useBookingStore';

const DetailPartySize = (): React.ReactElement => {
  const { detailPartySize, partySize, dispatchBookingInfo } = useBookingInfoStore();
  const reservationInfo = useBookingStore((store) => store.reservationInfo);
  const { partyPolicies: detailPeople } = reservationInfo;

  const methods = useFormContext();
  const {
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  }: any = methods;

  const sendParentEvent = () => window.parent.postMessage({ func: 'hideKeyPad' }, '*');

  useEffect(() => {
    if (detailPeople) {
      dispatchBookingInfo({ type: 'SET_DETAIL_PARTY_SIZE', value: Array.from({ length: detailPeople.length }, () => 0) });
    }
  }, [detailPeople]);

  if (!detailPartySize || detailPartySize.length === 0 || !detailPeople || detailPeople.length === 0) {
    return null;
  }

  return (
    <DetailPartySizeContainer>
      <MainSubtitle subtitle="인원 상세" firebrick={true} />
      {detailPeople.map((people, idx) => (
        // <div className="people-container" key={idx} >
        <div className={classNames('people-container', { error: hasIn(errors, 'detailPartySize') })} key={idx}>
          <div className="label-container">
            <div className="name">{people.name}</div>
            {people.description ? (
              <div className="description">
                <div>{people.description}</div>
              </div>
            ) : null}
          </div>
          <div className="select-container">
            <FormControl>
              <select
                placeholder="0명"
                onChange={(e) => {
                  dispatchBookingInfo({ type: 'SET_DETAIL_PARTY_SIZE_INDEX', value: e.target.value, index: idx });
                  setValue('detailPartySize', Boolean(partySize === detailPartySize.reduce((a, b) => a + b, 0)));

                  if (partySize !== detailPartySize.reduce((a, b) => a + b, 0)) {
                    setError('detailPartySize', { message: '상세 인원을 인원 수 만큼 선택해주세요.', type: 'DETAIL_PARTY_SIZE_INVALID' });
                  } else {
                    clearErrors('detailPartySize');
                  }
                }}
                value={detailPartySize[idx]}
                className="select-people-box"
                id={'select-people-box' + idx}
                onBlur={sendParentEvent}
              >
                {Array.from({ length: partySize - detailPartySize.reduce((a, b) => a + b, 0) + (detailPartySize[idx] || 0) + 1 }, (_, n) => (
                  <option value={n} key={n}>
                    {n}명
                  </option>
                ))}
              </select>
            </FormControl>
          </div>
        </div>
      ))}
      {hasIn(errors, 'detailPartySize') ? <InputHint text={errors.detailPartySize.message} /> : null}
    </DetailPartySizeContainer>
  );
};

export default DetailPartySize;
