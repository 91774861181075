import React, { useEffect, useId } from 'react';

import { GlobalErrorModalContainer } from '@/components/GlobalErrorModal/GlobalErrorModal.styles';
import Modal from '@/components/Modal/Modal';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import { useModalStore } from '@/stores/common/useModalStore';

const GlobalErrorModal = (): React.ReactElement => {
  const { globalErrorModal, setAlertModal, setOpenGlobalErrorModal } = useModalStore();
  const errorModalId = useId();

  useEffect(() => {
    if (globalErrorModal.visible) {
      setAlertModal({ visible: true, key: errorModalId });
    }
  }, [globalErrorModal.visible]);

  return (
    <GlobalErrorModalContainer>
      <ModalPortal>
        <Modal.Alert key={errorModalId} modal="alert" isDim={true} isAnimation={true}>
          <section className="content-wrapper">
            <h2 className="title">{globalErrorModal.title}</h2>
            <p className="desc">{globalErrorModal.message}</p>
          </section>
          <section className="button-wrapper">
            <button
              onClick={() => {
                if (globalErrorModal.callback) {
                  globalErrorModal.callback();
                }
                setOpenGlobalErrorModal({ visible: false, message: '', title: '알림', closeText: '닫기' });
                setAlertModal({ visible: false, key: errorModalId });
              }}
            >
              확인
            </button>
          </section>
        </Modal.Alert>
      </ModalPortal>
    </GlobalErrorModalContainer>
  );
};

export default GlobalErrorModal;
