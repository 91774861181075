import { useQuery } from 'react-query';

import { eggdiningApi } from '@/shared/apis/eggdiningApi';
import USER_QUERY_KEYS from '@/shared/apis/queryKeys/user';
import { IPaymentMethod } from '@/shared/types/PaymentMethod.type';
import { useUserProfileStore } from '@/stores/useUserProfle';

const useFetchCardData = (enabled = true) => {
  const { memberId } = useUserProfileStore();
  return useQuery(
    useFetchCardData.getQueryKey(),
    () =>
      useFetchCardData
        .fetcher(memberId)
        .then((response) => response.card)
        .catch((error) => console.log(error)),
    { enabled: enabled }
  );
};

useFetchCardData.getQueryKey = () => USER_QUERY_KEYS.GET_USER_PAYMENT_METHOD;
useFetchCardData.fetcher = (memberId) => eggdiningApi.get<IPaymentMethod>(`/eggdining/users/${memberId}/payment-method`);
export { useFetchCardData };
