import React from 'react';
import { useSearchParams } from 'react-router-dom';

import styled from '@emotion/styled';

import { Header, RaffleCompleteTalbe, RaffleResult } from './RaffleInfomation.styles';

import { ReactComponent as FllCheck } from '@/assets/img/icon/fullCheck.svg';
import { CompleteContainer } from '@/components/Raffle/Complete/Complete.styles';

const RaffleInfomation = ({ raffleInfoData }): React.ReactElement => {
  const [searchParams] = useSearchParams();
  const optionParams = searchParams.get('options');

  return (
    <CompleteContainer>
      <Header>
        <FllCheck />
        <div className="title">응모가 완료되었습니다.</div>
        <div className="description">
          8월 7일 월요일 오후 3시
          <br />
          당첨 발표에 반드시 참여해주세요!
        </div>
      </Header>
      <RaffleResult>
        <div className="raffle-result-title">응모내용 확인</div>
        <RaffleCompleteTalbe>
          <tbody>
            <tr>
              <td>응모대상</td>
              {/* 매장 Name */}
              {/* <td>{raffleInfoData.raffleTitle}</td> */}
              {/* 슬롯 Name */}
              <td>
                <div>{raffleInfoData.items[0].name}</div>
                <div>{raffleInfoData.items[0].info}</div>
              </td>
            </tr>
            <tr>
              <td>응모자</td>
              <td>{raffleInfoData.userName}</td>
            </tr>
            <tr>
              <td>연락처</td>
              <td>{raffleInfoData.userPhone}</td>
            </tr>
            {/* {optionParams !== 'normal' && (
              <tr>
                <td>응모일정</td>
                <td>
                  {raffleInfoData.items.map((item) => {
                    return <div key={item.id}>{item.name}</div>;
                  })}
                </td>
              </tr>
            )} */}
          </tbody>
        </RaffleCompleteTalbe>
      </RaffleResult>
    </CompleteContainer>
  );
};

export default RaffleInfomation;
