import React from 'react';

import styled from '@emotion/styled';

import { Typography } from '@/shared/styles/typography';

export const SplitNoLineTableContainer = styled.div<React.CSSProperties>`
  margin-bottom: 40px;
  .info-box {
    margin-bottom: 12px;
    text-align: left;
    display: flex;
    ${Typography.body_28}
    & > div {
      display: inline-block;
    }

    .label {
      width: 140px;
      color: #878d94;
    }
  }
`;
