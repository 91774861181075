import React from 'react';

import styled from '@emotion/styled';

import { ImageCarouselContainer, ImageMainCarouselItem } from '@/components/ImageCarousel/ImageCarousel.styles';

export const MainContainer = styled.section<React.CSSProperties>`
  padding: 20px 24px 0 24px;

  img {
    width: 576px;
    height: 768px;
  }

  & > ${ImageCarouselContainer} {
    ${ImageMainCarouselItem} {
      height: 312px;
      /* border-radius: 8px; */
    }
  }
`;
