import React, { useEffect, useId, useMemo, useState } from 'react';

import { useHeaderSetting, useRafflesEntryResultQuery, useRaffleSlotList } from './Announcement.hooks';
import { AnnouncementContainer } from './Announcement.styles';
import AnnouncementModal from './Modal/Modal';
import Slots from './Slots/Slots';
import HeaderTimer from './SlotTime/HeaderTimer';

import { ReactComponent as CongrateYolk } from '@/assets/img/event/sungi/congrate_yolk.svg';
import { ReactComponent as Info } from '@/assets/img/event/sungi/info.svg';
import Button from '@/components/Button/Button';
import Seperator from '@/components/Seperator/Seperator';
import { useModalStore } from '@/stores/common/useModalStore';

/**
 * raffleStatus  '02' = 응모마감, '03' = 추첨중, '04' = '추첨완료', '05' = 종료
 * raffleDate raffleStatus가 '02'일 때는 필수 항목
 * raffleTotalSlotCount 총 추첨 팀 개수
 * raffleCompletedSlotCount 예약 완료 추첨 팀 수
 * raffleEndDate raffleStatus가 '04', '05' 인 경우 필수
 * raffleSlots [{
 *     raffleSlotVisitDateTime 예약 방문 일자
 *     raffleSlotVisitPartySize 예약 방문 인원
 *     raffleSlotStatus  '00' = 대기, '01' = 당첨, '99' = 완료
 *     raffleSlotWinner "raffleSlotStatus '01' or  '99' 인 경우 필수 값, 당첨자 정보 (이름 / 번호)마스킹 된 정보로 넘어감"
 *     raffleSlotTime "raffleSlotStatus '00', '01' 인 경우 필수 값  '00' 인 경우 추첨 시간이 노출됨. '01' 인 경우 재 추첨 시간이 노출됨."
 * }]
 */

const slotComment = {
  raffleComplete: '예약 대기 중인 일정이 없습니다.',
  raffleWait: '추첨 예정인 일정이 없습니다.',
  reservationComplete: '예약 완료된 일정이 없습니다.',
};

function Announcement() {
  const reservationComplete = useId();
  const timeOut = useId();
  const notJoinRaffle = useId();
  const failRaffle = useId();
  const winRaffle = useId();
  const completeReservation = useId();

  // const failRaffle = useId();

  const [userPreToken, setUserPreToken] = useState('');
  const { data: userRaffleResult, refetch: refetchUserRaffleResult } = useRafflesEntryResultQuery({
    reservationComplete,
    timeOut,
    notJoinRaffle,
    failRaffle,
    winRaffle,
    setUserPreToken,
  });
  const { setAlertModal } = useModalStore();
  const _ = useHeaderSetting();
  const { data: resultData, refetch: resultRefetch, isLoading, dataUpdatedAt } = useRaffleSlotList();
  //   setAlertModal({ visible: true, key: reservationComplete });

  const anfilter = useMemo(() => {
    return resultData.raffleSlots.reduce(
      (acc, cur) => {
        const { raffleSlotStatus } = cur;
        //대기
        if (raffleSlotStatus === '00') {
          acc.slotArr.push(cur);
        }
        // 추첨 완료
        if (raffleSlotStatus === '01') {
          acc.ok.push(cur);
        }
        // 예약 완료
        if (raffleSlotStatus === '99') {
          acc.completeReservation.push(cur);
        }
        return acc;
      },
      { ok: [], completeReservation: [], slotArr: [] }
    );
  }, [dataUpdatedAt]);

  useEffect(() => {
    const id = setInterval(() => {
      try {
        resultRefetch();
      } catch (err) {
        clearInterval(id);
      }
    }, 5000);
    return () => clearInterval(id);
  }, []);

  return (
    <AnnouncementContainer>
      <div className="an-header">
        <div className="banner-image">
          <object data="https://egg-image.table.ceo/announcement_banner.svg" type="image/svg+xml" />
        </div>
        <HeaderTimer rafflesResultData={resultData} />
      </div>
      <div className="con">
        <div className="header">
          <div className="title-icon">
            <CongrateYolk />
          </div>
          <div className="title">당첨자 현황</div>
        </div>
        <div className="slots-run">
          <Slots title="추첨 진행 예정" leftSubTitle="응모 대상" rightSubTitle="추첨 시간" slotItems={anfilter.slotArr} children={undefined} slotComment={slotComment.raffleWait} />
          <Slots title="추첨 완료" leftSubTitle="응모 대상" rightSubTitle="예약 제한시간" slotItems={anfilter.ok} slotComment={slotComment.raffleComplete}>
            <div className="emergency">
              <p className="info-icon">
                <Info />
              </p>
              <div className="description">
                제한시간 안에 예약을 진행하지 않으면
                <br />
                당첨이 취소되고 재추첨을 진행합니다.
              </div>
            </div>
          </Slots>
        </div>
        <div className="seperator-custom">
          <Seperator type="line" />
        </div>
        <div className="reservation-complete">
          <Slots
            title="예약 완료"
            leftSubTitle="응모 대상"
            rightSubTitle="당첨자"
            slotItems={anfilter.completeReservation}
            children={undefined}
            slotComment={slotComment.reservationComplete}
          />
        </div>
      </div>
      <AnnouncementModal
        reservationComplete={reservationComplete}
        timeOut={timeOut}
        notJoinRaffle={notJoinRaffle}
        failRaffle={failRaffle}
        userPreToken={userPreToken}
        winRaffle={winRaffle}
      />
      <Button
        onClick={() => {
          refetchUserRaffleResult();
        }}
        classKey="yellow-gradation"
        colorLevel="primary"
        type="sticky"
      >
        내 당첨 확인하기
      </Button>
    </AnnouncementContainer>
  );
}

export default Announcement;
