import React from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import YolkCelebrate from '@/assets/img/yolk_celebrate.png';
import { Colors, Typography } from '@/shared/styles';

export const JoinComponentsContainer = styled.div<React.CSSProperties>`
  li button {
    background: #ccc;
  }
`;

interface ICardContent extends React.CSSProperties {
  visibleCard?: boolean;
}

export const CardContent = styled.div<ICardContent>`
  padding: 24px;
  border-radius: 6px;
  background: ${Colors.gray13};
  border: 2px solid ${Colors.gray13};
  display: flex;
  flex-direction: column;
  align-items: center;
  ${Typography.body_28}
  color: #fff;
  cursor: pointer;

  .desc {
    ${Typography.title_32_m};
  }

  .plus-icon {
    margin-bottom: 16px;
  }

  ${(props) =>
    props.visibleCard &&
    css`
      flex-direction: row;
      justify-content: space-between;
      gap: 16px;
      /*background-color: ${Colors.white};*/
      background: rgba(255, 255, 255, 0.1);

      span {
        display: block;
        padding-top: 4px;
        /* color: ${Colors.gray6}; */
      }
    `}
`;

export const SignUpCelebration = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Colors.white};

  /*
  &::before {
    content: url(${YolkCelebrate});
    width: 48px;
    height: 48px;
    display: inline-block;
    vertical-align: bottom;
    margin-right: 14px;
    padding: 2px;
    box-sizing: border-box;
  }*/
`;
