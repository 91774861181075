import React from 'react';
import { useSearchParams } from 'react-router-dom';

import styled from '@emotion/styled';

import { useUserMembership } from '@/components/ManageMembership/ManageMembership.hooks';
import Infomation from '@/components/Raffle/Infomation/Infomation';
import { useRaffleInfoInitHooks, useGetRaffleDataQuery, useRafflesEntryCheckQuery } from '@/components/Raffle/Infomation/Infomation.hooks';

const RaffleEventPage = () => {
  const [searchParams] = useSearchParams();
  const eventType = searchParams.get('eventType');

  const _ = useRaffleInfoInitHooks(eventType);
  const { data, isLoading, isError: RaffleDataError } = useGetRaffleDataQuery(eventType);
  const { data: entryCheck, isLoading: checkLoading, isError } = useRafflesEntryCheckQuery(eventType);
  const { data: userMembership, isLoading: userMembershipLoading } = useUserMembership();

  if (isLoading || checkLoading || userMembershipLoading || isError || RaffleDataError) {
    return null;
  }

  return <Infomation data={data} entryCheck={entryCheck} />;
};

export default RaffleEventPage;
