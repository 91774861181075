import 'react-responsive-carousel/lib/styles/carousel.min.css';

import React, { Fragment } from 'react';
import { Carousel } from 'react-responsive-carousel';

import { ImageCarouselContainer } from './ImageCarousel.styles';
import { IImageCarousel } from './ImageCarousel.types';

const ImageCarousel = ({ items, ...props }: IImageCarousel): React.ReactElement => {
  return (
    <ImageCarouselContainer>
      <Carousel {...props}>
        {items.map((item) => (
          <Fragment key={item.key}>{item.render()}</Fragment>
        ))}
      </Carousel>
    </ImageCarouselContainer>
  );
};

export default ImageCarousel;
