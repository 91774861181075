import { keyframes } from '@emotion/react';

const slideUp = keyframes`
  from {
    bottom: -281px;
  }

  to {
    bottom: 0;
  }
`;
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;
export const Animations = {
  slideUp,
  fadeIn,
  fadeOut,
};
