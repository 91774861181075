import 'dayjs/locale/ko';

import React from 'react';
import { useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';

import { VisitCancelAndNoShowContainer, VisitCancelAndNoShowItemContainer } from '@/components/VisitCancelAndNoShow/VisitCancelAndNoShow.styles';
import { goToDetailPage } from '@/hooks/reservationHistory.hook';
import { RESERVATION_HISTORY_STATUS } from '@/shared/constants/reservation-history.constants';
import { IReservationHistoryItem, IReservationHistoryItems } from '@/shared/types/ReservationHistory.types';

dayjs.locale('ko');

// VisitCancelAndNoShow.styles.tsx

const VisitCancelAndNoShow = ({ items }: IReservationHistoryItems): React.ReactElement => {
  const navigate = useNavigate();

  const removeSpacingStatusText = (text) => {
    if (/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(text)) {
      const trimText = text.replace(/ /g, '');
      return trimText;
    }
    return text;
  };
  return (
    <VisitCancelAndNoShowContainer>
      {items.map((item: IReservationHistoryItem) => {
        const { store, reservation } = item;
        const visitDetail = `${dayjs(reservation.visitAt).format('YYYY.MM.DD(dd) ∙ A h:mm ∙ ')}${reservation.totalPartySize}명`;

        return (
          <VisitCancelAndNoShowItemContainer key={reservation.id} onClick={() => goToDetailPage(navigate, reservation.id)}>
            <div>
              <img src={store.imageUrl} alt={`${store.name}이미지`} />
              <span></span>
            </div>
            <div>
              <span className="reserve-stts">{removeSpacingStatusText(RESERVATION_HISTORY_STATUS[reservation.state])}</span>
              <h3>{store.name}</h3>
              <span>{visitDetail}</span>
            </div>
          </VisitCancelAndNoShowItemContainer>
        );
      })}
    </VisitCancelAndNoShowContainer>
  );
};

export default VisitCancelAndNoShow;
