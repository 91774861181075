import React, { useMemo, useRef } from 'react';
import ReactDOM from 'react-dom';

import { ToastPortalContainer } from './ToastPortal.styles';

import { useModalStore } from '@/stores/common/useModalStore';

const ToastPortal = ({ children }): React.ReactElement => {
  const { toastList } = useModalStore();

  const container = useRef(document.body);
  if (!toastList) {
    return;
  }

  const content = useMemo(() => {
    const childrenToArray = children.length > 1 ? children : [children];
    if (!childrenToArray || !childrenToArray.length) {
      return null;
    }

    const toastListKeys = toastList?.map((toastItem) => toastItem.key);

    const toastOrderArray = childrenToArray
      .map((_children) => {
        const order = toastListKeys?.findIndex((item) => item === _children.key);
        return { ..._children, order };
      })
      .filter((_children) => _children.order !== -1)
      .sort((a, b) => b.order - a.order);

    return [toastOrderArray];
  }, [children]);

  // 포탈이 무분결하게 생기는 이슈 수정.
  // popupmodal 페이지 때문에 생성. 지워도 될듯함
  if (!content.length) {
    return null;
  }
  return ReactDOM.createPortal(<ToastPortalContainer>{content}</ToastPortalContainer>, container.current);
};

export default ToastPortal;
