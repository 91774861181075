import React from 'react';

import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

export const ButtonContainerArea = styled.div<React.CSSProperties>`
  display: flex;
  width: 100%;
  padding: 24px;
  align-items: center;
  gap: 24px;

  background: ${Colors.gray12};
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.2), 0px -2px 10px 0px rgba(0, 0, 0, 0.15);
`;

export const ButtonContainer = styled.button<React.CSSProperties>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  &.primary {
    background: ${Colors.green2};
    color: ${Colors.white};

    &.premium {
      /*background: ${Colors.yellow_gradation};*/
      margin: 0;
    }
    &.yellow3 {
      background: ${Colors.yellow3};
      ${Typography.title_44};
      padding: 55px 0px 51px;
      min-height: 156px;
    }
    &.yellow-gradation {
      background: ${Colors.yellow_gradation};
      ${Typography.title_44};
      padding: 55px 0px 51px;
      min-height: 156px;
    }
    &:hover {
      /*background: ${Colors.yellow2};*/
    }
    &.disabled {
      /* background: ${Colors.green1}; */
    }
  }
  &.secondary {
    background-color: #4f4f4d;
    color: ${Colors.white};
    &:hover:not(.disabled) {
      /* background-color: ${Colors.gray1}; */
    }
    &.disabled {
      color: ${Colors.gray2};
      border-color: ${Colors.gray2};
    }
    span {
      font-size: 24px;
      font-weight: 500;
    }
  }
  &.green {
    background: ${Colors.green2};
    color: ${Colors.white};

    &.disabled {
      color: ${Colors.white};
      background: ${Colors.white_opacity10};
      span {
        font-size: 24px;
        color: ${Colors.white};
      }
    }

    span {
      font-size: 24px;
      /* color: ${Colors.gray11}; */
    }
  }

  &.gray-white {
    background: ${Colors.white_opacity10};
    span {
      font-size: 24px;
      color: ${Colors.white};
    }
  }

  &.gray {
    background: ${Colors.gray11_opacity40};

    span {
      font-size: 28px;
      font-weight: 200;
      white-space: nowrap;
    }
  }

  &.gray2 {
    color: var(--gray-900, #14120e);
    background-color: ${Colors.white};
    &:hover:not(.disabled) {
      background-color: ${Colors.white};
    }
    &.disabled {
      background-color: ${Colors.white};
      opacity: 0.4;
    }
    span {
      font-size: 24px;
    }
  }

  &.gray3 {
    border-radius: 3px;
    opacity: 0.4;
    background: var(--gray-900, #14120e);
  }

  &.blank {
    /* background-color: #4f4f4d; */
    /* color: ${Colors.white}; */
    /* border: 2px solid ${Colors.gray4}; */
    &:hover:not(.disabled) {
      /* background-color: ${Colors.gray1}; */
    }
    &.disabled {
      color: ${Colors.gray2};
      border-color: ${Colors.gray2};
    }
    span {
      font-size: 32px;
    }
  }

  &.white {
    background-color: #fff;
    color: #${Colors.black1};
    /* border: 2px solid ${Colors.gray4}; */
    &:hover:not(.disabled) {
      /* background-color: ${Colors.black1}; */
    }
    &.disabled {
      color: ${Colors.black1};
      border-color: ${Colors.gray2};
    }
    span {
      font-size: 24px;
    }
  }

  &.sticky {
    width: 100%;
    height: 95px;
    position: sticky;
    position: -webkit-sticky;
    z-index: 10;
    bottom: env(safe-area-inset-bottom);
    ${Typography.title_32_m};
    /* margin: 24px 0; */
    border-radius: 6px;
    &.disabled {
      opacity: 0.4;
    }
  }

  &.large100 {
    width: 100%;
    height: 96px;
    z-index: 10;
    ${Typography.title_32_m};
    /* margin: 24px 0; */
    border-radius: 6px;
    &.disabled {
      opacity: 0.4;
    }
  }

  &.extra-large {
    height: 96px;
    padding: 28px 46px;
    ${Typography.title_32_m};
    border-radius: 8px;
  }
  &.large {
    height: 92px;
    padding: 28px 46px;
    ${Typography.body_28};
    border-radius: 8px;
  }
  &.medium {
    height: 88px;
    padding: 16px 40px;
    ${Typography.body_28};
    border-radius: 8px;
  }
  &.small {
    height: 56px;
    padding: 12px 21px;
    ${Typography.body_28};
    border-radius: 6px;
  }
  &.small100 {
    width: 100%;
    height: 56px;
    padding: 12px 21px;
    ${Typography.body_28};
    border-radius: 6px;
  }
  &.small100v2 {
    width: 100%;
    height: 80px;
    padding: 12px 21px;
    ${Typography.body_28};
    border-radius: 6px;
    font-size: 32px !important;
  }
  &.radius-small {
    height: 56px;
    padding: 12px 21px;
    ${Typography.body_28};
    border-radius: 16px;
  }
  &.extra-small {
    height: 40px;
    padding: 4px 16px;
    border-radius: 32px;
    ${Typography.small_1};
  }
  &.zero-padding-small {
    height: 62px;
    padding: 12px 0px;
    ${Typography.body_28};
    border-radius: 5px;
  }
  &.tiny-small {
    padding: 10px 20px;
    ${Typography.small_m};
    border-radius: 6px;
  }
  &.active {
    border-color: ${Colors.yellow1};
  }
  &.zero-border {
    border-width: 0;
  }
`;
