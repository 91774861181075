import React from 'react';

import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

export const CountdownRendererContainer = styled.div<React.CSSProperties>`
  display: flex;
  gap: 12px;
  /* margin-bottom: 20px; */
`;
export const CountdownRendererItemContainer = styled.div<React.CSSProperties>`
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.1);

  /* background: ${Colors.white}; */
  /* box-shadow: 0px 4px 12px ${Colors.gray2}; */
  /* border-radius: 8px; */
  text-align: center;
  padding: 12px 24px;
  gap: 8px;
  min-width: 128px;
  ${Typography.body_28};
  color: ${Colors.white};
  & > span {
    color: ${Colors.beige};
  }
`;
