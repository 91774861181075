/* eslint-disable @typescript-eslint/ban-ts-comment */
// eslint-disable-next-line import/named
import axios, { AxiosRequestConfig } from 'axios';
import merge from 'lodash/merge';

import { REQUESTS_OPERATION } from '@/shared/constants/common.constants';
import { IMutationProps } from '@/shared/types/common.types';

const apiBase = function (defaultConfig: AxiosRequestConfig) {
  const api = axios.create(defaultConfig);

  function configure(config: AxiosRequestConfig): AxiosRequestConfig {
    return merge(api.defaults, config);
  }

  async function get<T>(url: string, config?: any): Promise<T> {
    const response = await api.get(url, { ...config });
    return response.data.response as T;
  }

  async function authGet<T>(url: string): Promise<T> {
    const response = await api.get(url).then((res) => {
      if (res.data.status < 0) {
        // @ts-ignore
        throw new Error(`[${res.status}] ${res.message}`);
      }
      return res;
    });
    return response.data.response as T;
  }

  async function post<T>(url: string, data?: any): Promise<T> {
    const response = await api.post(url, data).then((res) => {
      if (res.data.status < 0) {
        // @ts-ignore
        throw new Error(`[${res.status}] ${res.message}`);
      }
      return res;
    });
    return response.data.response as T;
  }

  async function put<T>(url: string, data?: any): Promise<T> {
    const response = await api.put(url, data).then((res) => {
      if (res.data.status < 0) {
        // @ts-ignore
        throw new Error(`[${res.status}] ${res.message}`);
      }
      return res;
    });
    return response.data.response as T;
  }

  async function patch<T>(url: string, data?: any): Promise<T> {
    const response = await api.patch(url, data).then((res) => {
      if (res.data.status < 0) {
        // @ts-ignore
        throw new Error(`[${res.status}] ${res.message}`);
      }
      return res;
    });
    return response.data.response as T;
  }

  async function deleteApi<T>(url: string, data?: any): Promise<T> {
    const response = await api.delete(url, data).then((res) => {
      if (res.data.status < 0) {
        // @ts-ignore
        throw new Error(`[${res.status}] ${res.message}`);
      }
      return res;
    });
    return response.data.response as T;
  }

  async function mutation(config: IMutationProps) {
    const { operation, data, headers, url } = config;
    switch (operation) {
      case REQUESTS_OPERATION.PATCH:
        return await api.patch(url, data);
      case REQUESTS_OPERATION.PUT:
        return await api.put(url, data);
      case REQUESTS_OPERATION.POST:
        return await api.post(url, data, { headers });
      case REQUESTS_OPERATION.DELETE:
        return await api.delete(url, { headers, data });
      default:
        break;
    }
  }

  return { configure, get, put, authGet, mutation, post, patch, api, deleteApi };
};

export default apiBase;
