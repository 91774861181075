import React, { useEffect, useId, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { WinningModalContent } from '../Announcement.styles';

import { ReactComponent as Banner } from '@/assets/img/event/sungi/banner.svg';
import { ReactComponent as ApplyFail } from '@/assets/img/event/sungi/comply_fail.svg';
import { ReactComponent as ApplyWinning } from '@/assets/img/event/sungi/comply_winning.svg';
import { ReactComponent as CongrateYolk } from '@/assets/img/event/sungi/congrate_yolk.svg';
import { ReactComponent as FailReservation } from '@/assets/img/event/sungi/fail-reservation.svg';
import { ReactComponent as Info } from '@/assets/img/event/sungi/info.svg';
import Modal from '@/components/Modal/Modal';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import Seperator from '@/components/Seperator/Seperator';
import { useModalStore } from '@/stores/common/useModalStore';

function AnnouncementModal({ reservationComplete, timeOut, notJoinRaffle, failRaffle, userPreToken, winRaffle }) {
  const { setAlertModal } = useModalStore();
  const navigate = useNavigate();
  return (
    <ModalPortal>
      <Modal.Alert key={reservationComplete} modal="alert" isDim={true} isAnimation={true}>
        <section className="content-wrapper">
          <ApplyWinning />
          <WinningModalContent>
            <div className="winning-title">8월 패키지 추첨예약 당첨!</div>
            {/* <div className="visit-date">방문 일정 : 2023년 3월 2일 오후 6: 00</div> */}
            <div className="description">
              이미 예약을 마치셨어요.
              <br />
              당일날 꼭 뵐게요!
            </div>
          </WinningModalContent>
        </section>
        <section className="button-wrapper">
          <button onClick={() => setAlertModal({ visible: false, key: reservationComplete })}>닫기</button>
          <button onClick={() => navigate('/reservation/history')}>예약내역 확인</button>
        </section>
      </Modal.Alert>
      {/* 당첨 20 */}
      <Modal.Alert key={winRaffle} modal="alert" isDim={true} isAnimation={true}>
        <section className="content-wrapper">
          <ApplyWinning />
          <WinningModalContent>
            <div className="winning-title">8월 패키지 추첨예약 당첨!</div>
            <div className="description">
              제한시간 안에 예약을 마쳐주세요.
              <br />
              시간 만료 시 당첨이 취소됩니다.
            </div>
          </WinningModalContent>
        </section>
        <section className="button-wrapper">
          <button className="yellow" onClick={() => navigate(`/reservation/newtm/step/detail-info?token=${userPreToken}&reservationType=RAFFLE`)}>
            지금 예약
          </button>
        </section>
      </Modal.Alert>
      <Modal.Alert key={timeOut} modal="alert" isDim={true} isAnimation={true}>
        <section className="content-wrapper">
          <h2 className="title">알림</h2>
          <p className="desc">예약유효시간이 만료되어 당첨이 취소되었습니다. 응모에 참여해주셔서 감사합니다.</p>
        </section>
        <section className="button-wrapper">
          <button onClick={() => setAlertModal({ visible: false, key: timeOut })}>확인</button>
        </section>
      </Modal.Alert>
      <Modal.Alert key={notJoinRaffle} modal="alert" isDim={true} isAnimation={true}>
        <section className="content-wrapper">
          <h2 className="title">알림</h2>
          <p className="desc">추첨 대상자가 아닙니다. 응모에 참여하지 않으셨습니다.</p>
        </section>
        <section className="button-wrapper">
          <button onClick={() => setAlertModal({ visible: false, key: notJoinRaffle })}>확인</button>
        </section>
      </Modal.Alert>
      <Modal.Alert key={failRaffle} modal="alert" isDim={true} isAnimation={true}>
        <section className="content-wrapper">
          <FailReservation />
          <WinningModalContent>
            <div className="winning-title">지금은 꽝, 재추첨을 기다려봐요!</div>
            <div className="description">
              만약 제한시간 내 예약을 마치지 못한
              <br />
              당첨자가 있을 경우 재추첨을 진행합니다.
            </div>
          </WinningModalContent>
        </section>
        <section className="button-wrapper">
          <button onClick={() => setAlertModal({ visible: false, key: failRaffle })}>확인</button>
        </section>
      </Modal.Alert>
    </ModalPortal>
  );
}

export default AnnouncementModal;
