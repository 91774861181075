import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import styled from '@emotion/styled';

import { useGetRaffleData, useInitComplete } from './Complete.hooks';
import HowParticipate from './HowParticipate/HowParticipate';
import RaffleInfomation from './RaffleInfomation/RaffleInfomation';

import Button from '@/components/Button/Button';
import Seperator from '@/components/Seperator/Seperator';

const Complete = (): React.ReactElement => {
  const [searchParams] = useSearchParams();
  const eventType = searchParams.get('eventType');

  const _ = useInitComplete(eventType);

  const { data, isLoading } = useGetRaffleData();
  const navigate = useNavigate();

  if (isLoading) {
    return null;
  }
  return (
    <div>
      <RaffleInfomation raffleInfoData={data.entry} />
      <Seperator />
      <HowParticipate />
      <Button
        onClick={() => {
          navigate('/main');
          navigate(`/event/egg/raffle?eventType=${eventType}`);
        }}
        colorLevel="primary"
        type="sticky"
        classKey="auth-button"
      >
        닫기
      </Button>
    </div>
  );
};

export default Complete;
