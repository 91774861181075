import dayjs from 'dayjs';
import create from 'zustand';

import { ICalendar } from '@/components/Calendar/Calendar.type';
import { Nullable } from '@/shared/types/common.types';

export interface IInfoStore {
  calendar: Nullable<ICalendar>;
  setCalendar: (calendar) => void;
  times: Nullable<any>;
  setTimes: (times) => void;
  viewDate: Nullable<dayjs.Dayjs>;
  setViewDate: (viewDate) => void;
  calendarDirty: boolean;
  setCalendarDirty: (calendarDirty) => void;
  timeDirty: boolean;
  setTimeDirty: (timeDirty) => void;
  clear: () => void;
}

const inintalState = {
  calendar: null,
  titmes: null,
  viewDate: dayjs(),
  calendarDirty: false,
  timeDirty: false,
};

export const useInfoStore = create<IInfoStore>((set) => ({
  calendar: null,
  setCalendar: (calendar) => set(() => ({ calendar })),
  times: null,
  setTimes: (times) => set(() => ({ times })),
  viewDate: dayjs(),
  setViewDate: (viewDate) => set(() => ({ viewDate })),
  calendarDirty: false,
  setCalendarDirty: (calendarDirty) => set(() => ({ calendarDirty })),
  timeDirty: false,
  setTimeDirty: (timeDirty) => set(() => ({ timeDirty })),
  clear: () =>
    set(() => {
      return {
        ...inintalState,
      };
    }),
}));
