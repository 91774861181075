import React from 'react';

import { MenuListViewerContainer, MenuListViewerItem } from '@/components/MenuListViewer/MenuListViewer.styles';
import Seperator from '@/components/Seperator/Seperator';
import { AddComma } from '@/shared/libs/functions';

const MenuListViewer = ({ menus, type, setViewAll = () => {} }): React.ReactElement => {
  return (
    <MenuListViewerContainer onClick={setViewAll} menuLength={menus.length} type={type}>
      {menus.map((menu, i) => (
        <React.Fragment key={`${menu.menuName}_${i}`}>
          <MenuListViewerItem>
            <h2>{menu.menuName}</h2>
            <span>{AddComma(menu.menuPrice)}원</span>
          </MenuListViewerItem>
          {i !== menus.length - 1 && <Seperator type="line" inline={true} />}
        </React.Fragment>
      ))}
    </MenuListViewerContainer>
  );
};

export default MenuListViewer;
