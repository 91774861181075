import React from 'react';

import styled from '@emotion/styled';

import { Typography, Colors } from '@/shared/styles';

export const BookingFooterContainer = styled.div<React.CSSProperties>`
  width: 100%;
  background: ${Colors.green2};
  /* color: ${Colors.gray11}; */
  text-align: center;
  position: sticky;
  bottom: 0;
  /* margin: 24px; */
  height: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  ${Typography.title_32_m}
  /* line-height: 104px; */
  border-radius: 6px;
  &.disabled {
    opacity: 0.4;
  }
`;
