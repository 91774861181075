import { css } from '@emotion/react';

const title_48 = css`
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 60px;
`;

const title_46 = css`
  font-style: normal;
  font-weight: 400;
  font-size: 46px;
  line-height: 55px;
`;

const title_44 = css`
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 50px;
`;

const title_44_m = css`
  font-style: normal;
  font-weight: 500;
  font-size: 44px;
  line-height: 50px;
`;

const title_40 = css`
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
`;

const title_40_s = css`
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 50px;
`;

const title_34 = css`
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  line-height: 48px;
`;

const title_36 = css`
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 48px;
`;

const title_36_sm = css`
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 48px;
`;

const title_36_m = css`
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 46px;
`;

const title_36_sb = css`
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 46px;
`;

const title_36_b = css`
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 46px;
`;

const title_31 = css`
  font-style: normal;
  font-weight: 400;
  font-size: 31px;
  line-height: 42px;
`;

const title_32_s = css`
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 44px;
`;

const title_32_ss = css`
  font-style: normal;
  font-weight: 200;
  font-size: 32px;
  line-height: 44px;
`;

const title_32 = css`
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 44px;
`;

const title_32_m = css`
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 44px;
`;

const title_32_sb = css`
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 44px;
`;

const title_32_b = css`
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
`;

const subtitle = css`
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 40px;
`;

const subtitle_m = css`
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
`;

const subtitle_b = css`
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
`;

const body_36_sb = css`
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 46px;
`;

const body_28 = css`
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 36px;
`;
const body_28_ss = css`
  font-style: normal;
  font-weight: 200;
  font-size: 28px;
  line-height: 40px;
`;
const body_28_s = css`
  font-style: normal;
  font-weight: 300;
  font-size: 28px;
  line-height: 40px;
`;
const body_28_m = css`
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
`;
const body_28_sb = css`
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
`;
const body_28_b = css`
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
`;

const body_27 = css`
  font-style: normal;
  font-weight: 400;
  font-size: 27px;
  line-height: 35px;
`;

const body_27_s = css`
  font-style: normal;
  font-weight: 300;
  font-size: 27px;
  line-height: 35px;
`;

const body_26 = css`
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 34px;
`;

const body_26_s = css`
  font-style: normal;
  font-weight: 300;
  font-size: 26px;
  line-height: 34px;
`;

const body_26_ss = css`
  font-style: normal;
  font-weight: 200;
  font-size: 26px;
  line-height: 34px;
`;

const body_26_m = css`
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 34px;
`;

const body_26_sb = css`
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 34px;
  letter-spacing: -0.01em;
`;

const small_1 = css`
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
`;

const small_2 = css`
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
`;

const small_3 = css`
  font-style: normal;
  font-weight: 200;
  font-size: 24px;
  line-height: 36px;
`;

const small_3h = css`
  font-style: normal;
  font-weight: 200;
  font-size: 24px;
  line-height: 42px;
`;

const small_m = css`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
`;

const small_b = css`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
`;

const s_small = css`
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
`;

const s_small_300 = css`
  font-style: normal;
  font-weight: 200;
  font-size: 22px;
  line-height: 30px;
`;

const s_small_sb = css`
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
`;

const ss_small = css`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.01em;
`;
export const Typography = {
  title_48,
  title_46,
  title_44,
  title_44_m,
  title_40,
  title_40_s,
  title_36,
  title_36_m,
  title_36_sm,
  title_36_sb,
  title_36_b,
  title_34,
  title_31,
  title_32_ss,
  title_32_s,
  title_32,
  title_32_m,
  title_32_sb,
  title_32_b,
  subtitle,
  subtitle_m,
  subtitle_b,
  body_36_sb,
  body_28,
  body_28_ss,
  body_28_s,
  body_28_m,
  body_28_sb,
  body_28_b,
  body_27,
  body_27_s,
  body_26,
  body_26_s,
  body_26_ss,
  body_26_sb,
  body_26_m,
  small_1,
  small_2,
  small_3,
  small_3h,
  small_m,
  small_b,
  s_small,
  s_small_300,
  s_small_sb,
  ss_small,
};
