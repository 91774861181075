import React from 'react';
import { zeroPad } from 'react-countdown';

import { CountdownRendererContainer, CountdownRendererItemContainer } from '@/components/CountdownRenderer/CountdownRenderer.styles';
import QUERY_KEYS from '@/shared/apis/queryKeys/booking';
import queryClient from '@/shared/configs/queryClient';

const CountdownRenderer = ({ days, hours, minutes, seconds, completed }): React.ReactElement => {
  if (completed) {
    queryClient.invalidateQueries(QUERY_KEYS.BOOKING_MALLDETAIL_GETMALL);

    return <></>;
  }
  return (
    <CountdownRendererContainer>
      <CountdownRendererItemContainer>
        <span>{zeroPad(days)}</span>일
      </CountdownRendererItemContainer>
      <CountdownRendererItemContainer>
        <span>{zeroPad(hours)}</span>시간
      </CountdownRendererItemContainer>
      <CountdownRendererItemContainer>
        <span>{zeroPad(minutes)}</span>분
      </CountdownRendererItemContainer>
      <CountdownRendererItemContainer>
        <span>{zeroPad(seconds)}</span>초
      </CountdownRendererItemContainer>
    </CountdownRendererContainer>
  );
};

export default CountdownRenderer;
