import * as yup from 'yup';

export const validationName = yup
  .string()
  .required('이름을 정확히 입력해주세요.')
  .test('USER_NAME_SPECIAL_CHARACTERS', '띄어쓰기나 특수문자는 입력하실 수 없습니다.', (value) => {
    // eslint-disable-next-line no-useless-escape
    if (/[!@#\$%\^\&*\)\(+=._-]/g.test(value)) {
      return false;
    }
    return true;
  });

export const validationEmail = yup.string().test('EMAIL_INVALID', '이메일을 양식에 맞게 입력해주세요.', (value) => (value?.length ? /\S+@\S+\.\S+/.test(value) : true));

export const validationCardNumber = yup
  .string()
  .required('카드번호를 반드시 입력해주세요.')
  .test('CARDNUMBER_INVALID', '카드번호를 4글자 숫자로 입력해주세요', (value) => (value.length ? /\d{4}/.test(value) : true));
export const validationCardNumberLast = yup
  .string()
  .required('카드번호를 반드시 입력해주세요.')
  .test('CARDNUMBER_INVALID', '카드번호를 4글자 이하 숫자로 입력해주세요', (value) => (value.length ? /\d{3,4}/.test(value) : true));

export const validationPhoneNumber = yup
  .string()
  .required('휴대폰 번호를 반드시 입력해주세요.')
  .test('USER_PHONE_INVALID', '잘못된 핸드폰번호 입니다.', (value) => {
    // 임시 처리
    // if (value.length >= 3) {
    //   return value.slice(0, 3) === '010';
    // }
    return true;
  })
  .test('USER_PHONE_NOT_FIT_FORM', '휴대폰 번호를 양식에 맞게 입력해 주세요.', (value) => {
    return value.length >= 13;
  });

export const validationPhoneCertified = yup.boolean().test('PHONE_AUTH_INVALID', '휴대폰 인증을 진행해주세요.', (value) => value);
