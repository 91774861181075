import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';

import Button from '@/components/Button/Button';
import { RedirectWrapperContainer, DiningClubLogo } from '@/shared/styles/pages/redirect/redirect';

const RedirectAppStore = (): React.ReactElement => {
  const appStoreURL = 'https://apps.apple.com/app/id1499581258';
  const playStoreURL = 'https://play.google.com/store/apps/details?id=com.eggapp&hl=ko';
  const [seconds, setSeconds] = useState(7);

  const userAgent = navigator.userAgent || navigator.vendor;
  const isAndroid = /android/i.test(userAgent);
  const isIOS = /iPhone|iPad|iPod/i.test(userAgent);

  const handleRedirect = () => {
    let redirectUri = '';
    if (isIOS) {
      redirectUri = appStoreURL;
    } else if (isAndroid) {
      redirectUri = playStoreURL;
    } else {
      alert('This device is not supported.');
    }
    const link = document.createElement('a');
    link.href = redirectUri;
    link.click();
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          handleRedirect();
          //if (isIOS) {
          //  window.location.href = appStoreURL;
          //} else if (isAndroid) {
          //  window.location.href = playStoreURL;
          //} else {
          //  alert('This device is not supported.');
          //}
          //window.location.href = '/redirect/appStore';
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  }, []);

  return (
    <>
      <RedirectWrapperContainer>
        <div className="redirect-app-container">
          <DiningClubLogo>
            <svg xmlns="http://www.w3.org/2000/svg" width="110" height="108" viewBox="0 0 55 54" fill="none">
              <path d="M40.1373 5.85254L40.1301 6.67691L30.756 24.2587H29.8345L38.2006 5.85254H40.1373Z" fill="white" />
              <path d="M15.813 49.2454L15.8202 48.421L25.1943 30.8392H26.1158L17.7497 49.2454H15.813Z" fill="white" />
              <path
                d="M51.0054 42.3264C50.0046 46.3007 47.0815 49.2454 41.7753 49.2454C34.8384 49.2454 32.196 44.184 32.196 37.679C32.196 31.426 34.8599 25.9218 41.9121 25.9218C47.4523 25.9218 50.3538 29.1581 51.009 32.9992H47.8663C47.1103 30.634 45.314 28.5173 41.7861 28.5173C36.9119 28.5173 35.3027 33.0136 35.3027 37.5746C35.3027 42.1356 36.7895 46.6535 41.9193 46.6535C45.3968 46.6535 47.0707 44.3928 47.8519 42.33H51.0126L51.0054 42.3264Z"
                fill="white"
              />
              <path
                d="M24.6974 16.0114C24.6974 22.9411 21.1408 27.2682 13.2102 27.2682H3.99097V26.4402C6.17969 26.2494 6.87807 25.6446 6.87807 24.0859V7.93327C6.87807 6.37452 6.17969 5.76974 3.99097 5.57895V4.75458H13.2102C21.1408 4.75458 24.6974 9.07803 24.6974 16.0114ZM11.2915 26.1882H13.4334C19.3984 26.1882 22.1667 22.2464 22.1667 16.015C22.1667 9.7836 19.2688 5.84174 13.2102 5.84174H9.18558V24.1543C9.18558 25.5547 9.90196 26.1882 11.8351 26.1882"
                fill="white"
              />
            </svg>
          </DiningClubLogo>
          <h4>
            다이닝클럽 앱 설치 후<br />
            링크 이동 버튼을 눌러주세요.
          </h4>
          <p>
            <em>{seconds}</em>초 뒤에 앱스토어로 이동됩니다.
          </p>
          <div className="button-container">
            <Button classKey="premium" disabled={false} colorLevel="primary" type="large100" onClick={handleRedirect}>
              링크 이동
            </Button>
          </div>
        </div>
      </RedirectWrapperContainer>
    </>
  );
};

export default RedirectAppStore;
