import React from 'react';

import styled from '@emotion/styled';

export const PolicyModalContainer = styled.section<React.CSSProperties>`
  > div {
    background: rgba(0, 0, 0, 0.25);
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    min-height: 100vh;
    width: 100vw;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;

    .modal-content {
      height: 100vh;
      position: relative;
      width: 100%;
      .close-button {
        position: absolute;
        top: 25px;
        right: 0;
        transform: translate(-50%, -50%);
        height: 48px;
        vertical-align: middle;
        margin: auto;
      }
    }

    .content {
      background-color: white;
    }

    #iframe-mobile {
      // max-width: 360px;
      background: white;
      width: 100vw;
      height: 100vh;
    }
    #iiframe-desktop {
      width: 100%;
    }
  }
`;
