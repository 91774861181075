import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { createBrowserHistory } from '@remix-run/router';

import { PolicyModalContainer } from './PolicyModal.styles';
import { IPolicyModal } from './PolicyModal.type';

import {} from './PolicyModal.constants';
import { ReactComponent as Close } from '@/assets/img/icon/Close.svg';

const PolicyModal = (props: IPolicyModal): React.ReactElement => {
  const navigate = useNavigate();
  const [modalState, setModalState] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const history = createBrowserHistory();
  const resizeCalc = useCallback(() => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
      setIsMobile(true);
    }
  }, []);

  useLayoutEffect(resizeCalc, []);

  useEffect(() => {
    if (props.url) {
      setModalState(true);
    } else {
      setModalState(false);
    }
  }, [props]);

  const closeModal = () => {
    props.openServiceTerm('');
    setModalState(false);
  };

  history.listen(({ location, action }) => {
    if (action === 'POP') {
      props.openServiceTerm('');
      setModalState(false);
      return true;
    }
  });

  return modalState ? (
    <PolicyModalContainer>
      <div className="modal-container">
        <div className="modal-content">
          <div className="close-button">
            <Close className="close-button" onClick={closeModal} />
          </div>
          <iframe id={isMobile ? 'iframe-mobile' : 'iframe-desktop'} className={'content'} title="tablemanager-policy" src={props.url} width="100%" height="100%" />
        </div>
      </div>
    </PolicyModalContainer>
  ) : (
    <></>
  );
};

export default PolicyModal;
