import React from 'react';

import styled from '@emotion/styled';

import { Colors } from '@/shared/styles';
import { Typography } from '@/shared/styles/typography';

export const BookingInfoViewContainer = styled.div<React.CSSProperties>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  padding: 0 32px;
  .mall-name {
    ${Typography.title_48}
  }

  .info-container {
    display: flex;
    width: 100%;
    padding: 32px;
    justify-content: center;
    align-items: center;
    gap: 24px;
    border-radius: 6px;
    background: var(--gray-90040, rgba(20, 18, 14, 0.4));

    /* display: flex;
    border: 2px solid ${Colors.gray3};
    border-radius: 8px;
    padding: 28px 0;
    margin-bottom: 80px;
    box-shadow: 0 10px 32px -4px rgba(233, 235, 238, 1); */
    .divider {
      height: 40px;
      /* flex-shrink: 0; */
      /* fill: var(--warm-gray-02, rgba(196, 187, 171, 0.20)); */
    }

    .reservation-info {
      display: flex;
      align-items: center;
      gap: 12px;
      ${Typography.title_32_s}

      & > svg {
        width: 32px;
        height: 32px;
      }

      /* text-align: center;
      display: flex;
      flex-direction: column;
      width: 33.3%; */

      & > div {
        display: inline-block;
      }

      .label {
        /* margin-bottom: 26px; */
        ${Typography.title_32}
        > span {
          /* color: ${Colors.gray6}; */
        }
      }

      .value {
        color: ${Colors.gray8};
        ${Typography.body_28_m}
      }
    }
  }
`;
