import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { eggdiningApi } from '@/shared/apis/eggdiningApi';
import QUERY_KEYS from '@/shared/apis/queryKeys/booking';
import { IMallDetailResults } from '@/stores/useBookingStore';

export default function useQueryMallUri() {
  const params = useParams();
  const storeUri = params.id;
  return useQuery(useQueryMallUri.getKeys(storeUri), () => useQueryMallUri.fetcher(`eggdining/stores/${storeUri}`), {
    retry: false,
    enabled: Boolean(storeUri),
  });
}

useQueryMallUri.getKeys = (storeUri) => [QUERY_KEYS.BOOKING_MALLDETAIL_GETMALL, storeUri];
useQueryMallUri.fetcher = (storeUri) => eggdiningApi.get<IMallDetailResults>(storeUri);
