import styled from '@emotion/styled';

import { IToastModalProps } from './Modal.type';

import { Animations, Typography, Variables } from '@/shared/styles';
import { Colors } from '@/shared/styles/colors';

export const ModalContainer = styled.div<IToastModalProps>`
  .alert-express-img {
    width: 100%;
  }
  z-index: 10;

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: ${(props) => (props.toastModal ? 'auto' : '100%')};

  & > .modal-dim {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }

  & > .modal-content {
    background: var(--gray-700, #3b3b39) !important;
    color: #fff !important;
  }

  &.event-alert {
    z-index: 20;

    /* 임시 */
    img {
      width: 100%;
      height: auto;
    }

    &.animation {
      animation-duration: 0.3s;
      animation-name: ${Animations.fadeIn};
    }
    & > .modal-content {
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: 576px;
      width: calc(100% - 160px);
      /* min-height: 340px; */
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      /* background: ${Colors.white}; */
      /* backdrop-filter: blur(56.3792px); */
      border-radius: 20px;

      .content-wrapper {
        .title {
          padding-top: 50px;
          padding-inline: 40px;
          padding-bottom: 30px;
          ${Typography.title_32_sb};
          color: ${Colors.black};
          overflow-wrap: break-word;
          word-wrap: break-word;
          word-break: break-word;
        }

        img {
          border-radius: 20px 20px 0 0;
        }

        .desc {
          padding-bottom: 50px;
          padding-inline: 40px;
          ${Typography.body_26};
          color: ${Colors.gray8};

          .desc-in-padding {
            padding-top: 28px;
          }

          .check-img-wrapper {
            margin-right: calc(-10px + 12px);
          }
        }
      }

      .button-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        button {
          width: 100%;
          padding-block: 29px;
          ${Typography.title_32_sb};

          &:nth-of-type(2) {
            border-left: 2px solid ${Colors.gray2};
            color: ${Colors.yellow3};
          }

          &.red {
            color: ${Colors.red2};
          }
          &.gray {
            color: ${Colors.gray8};
          }
        }
      }
    }
    .event-modal-bottom {
      display: flex;
      padding: 12px 16px;
      justify-content: space-between;
      align-items: flex-start;
      > div {
        ${Typography.body_28_sb}
        color: ${Colors.white};
        padding: 16px;
      }
    }
  }

  // alert
  &.alert {
    z-index: 20;

    &.animation {
      animation-duration: 0.3s;
      animation-name: ${Animations.fadeIn};
    }
    & > .modal-content {
      position: absolute;
      top: 50%;
      left: 50%;
      display: flex;
      transform: translate(-50%, -50%);
      width: 590px;
      /* width: calc(100% - 160px);       */
      /* max-width: 560px; */
      /* min-height: 340px; */
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      background: rgba(59, 59, 57, 0.8);

      /* effect-overlays */
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15), 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
      /* backdrop-filter: blur(50px); */

      /* position: absolute;
      top: 50%;
      left: 50%;
      max-width: 560px;
      width: calc(100% - 160px);
      min-height: 340px;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: ${Colors.white};
      backdrop-filter: blur(56.3792px);
      border-radius: 20px; */

      .header-wrapper {
        display: flex;
        width: 100%;
        height: 104px;
        padding: 0px 32px;
        align-items: center;
        gap: 32px;
        background: var(--gray-700, #3b3b39);
        text-overflow: ellipsis;

        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 44px; /* 137.5% */
        border-radius: 20px;
      }

      .content-wrapper {
        display: flex;
        min-height: 192px;
        padding: 48px 32px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        color: ${Colors.white};
        .title {
          ${Typography.title_32_m};
          font-weight: 600;
          text-align: center;
          /*color: ${Colors.black};*/
          color: #fff;
          overflow-wrap: break-word;
          word-wrap: break-word;
          word-break: break-word;
          white-space: pre-line;
        }
        .desc {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 16px;
          text-align: center;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 36px;

          .desc-in-padding {
            label {
              gap: 16px;

              & > div:nth-of-type(2) {
                font-feature-settings: 'liga' off, 'clig' off;
                font-size: 28px;
                font-style: normal;
                font-weight: 500;
                line-height: 40px; /* 142.857% */
              }
            }
          }
        }

        &.cancel-reservation {
          gap: 40px;
          border-top: 2px solid rgba(255, 255, 255, 0.1);
          color: #fff;
          font-feature-settings: 'liga' off, 'clig' off;

          font-size: 28px;
          font-style: normal;
          font-weight: 400;
          line-height: 40px; /* 142.857% */

          & > .desc {
            padding: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 24px;
            align-self: stretch;

            .refund-table {
              padding: 0;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 24px;
              align-self: stretch;

              h4 {
                font-size: 32px;
                font-style: normal;
                font-weight: 600;
                line-height: 44px; /* 137.5% */
              }

              .desc-item {
                display: flex;
                align-items: flex-start;
                gap: 16px;
                align-self: stretch;

                span {
                  font-feature-settings: 'liga' off, 'clig' off;

                  font-size: 28px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 40px; /* 142.857% */
                  padding: 0;
                  border-bottom: none;
                  &:nth-of-type(2) {
                    font-weight: 500;
                    text-align: right;
                    flex: 1 0 0;
                  }
                }
              }

              .total-wrapper {
                display: flex;
                align-items: center;
                gap: 32px;
                align-self: stretch;
                padding: 0;
              }

              dl {
                display: flex;
                align-items: flex-start;
                gap: 16px;
                align-self: stretch;
                /* padding: 24px 0; */

                font-feature-settings: 'liga' off, 'clig' off;

                font-size: 28px;
                font-style: normal;
                font-weight: 400;
                line-height: 40px; /* 142.857% */

                dt {
                  opacity: 1;
                }

                dd {
                  font-weight: 500;
                  opacity: 1;
                }
              }

              .total-wrapper {
                font-feature-settings: 'liga' off, 'clig' off;

                font-size: 28px;
                font-style: normal;
                font-weight: 500;
                line-height: 40px;

                span {
                  font-size: 32px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 44px; /* 137.5% */
                }
              }
            }

            .desc-in-padding {
              padding-top: 0px;
            }

            .check-img-wrapper {
              margin-right: calc(-10px + 12px);
            }
          }
        }

        &.cancel-reservation2 {
          gap: 40px;
          color: #fff;
          font-feature-settings: 'liga' off, 'clig' off;

          font-size: 28px;
          font-style: normal;
          font-weight: 400;
          line-height: 40px; /* 142.857% */

          & > .desc {
            padding: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 24px;
            align-self: stretch;

            .refund-table {
              padding: 0;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 24px;
              align-self: stretch;

              h4 {
                font-size: 32px;
                font-style: normal;
                font-weight: 600;
                line-height: 44px; /* 137.5% */
              }

              .desc-item {
                display: flex;
                align-items: flex-start;
                gap: 16px;
                align-self: stretch;

                span {
                  font-feature-settings: 'liga' off, 'clig' off;

                  font-size: 28px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 40px; /* 142.857% */
                  padding: 0;
                  border-bottom: none;
                  &:nth-of-type(2) {
                    font-weight: 500;
                    text-align: right;
                    flex: 1 0 0;
                  }
                }
              }

              .total-wrapper {
                display: flex;
                align-items: center;
                gap: 32px;
                align-self: stretch;
                padding: 0;
              }

              dl {
                display: flex;
                align-items: flex-start;
                gap: 16px;
                align-self: stretch;
                /* padding: 24px 0; */

                font-feature-settings: 'liga' off, 'clig' off;

                font-size: 28px;
                font-style: normal;
                font-weight: 400;
                line-height: 40px; /* 142.857% */

                dt {
                  opacity: 1;
                }

                dd {
                  font-weight: 500;
                  opacity: 1;
                }
              }

              .total-wrapper {
                font-feature-settings: 'liga' off, 'clig' off;

                font-size: 28px;
                font-style: normal;
                font-weight: 500;
                line-height: 40px;

                span {
                  font-size: 32px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 44px; /* 137.5% */
                }
              }
            }

            .desc-in-padding {
              padding-top: 0px;
            }

            .check-img-wrapper {
              margin-right: calc(-10px + 12px);
            }
          }
        }
      }

      .lots-of-content-wrapper {
        .title {
          padding-block: 27px;
          border-bottom: 2px solid ${Colors.gray2};
        }

        .desc {
          overflow: auto;
          padding-top: 50px;
        }

        .refund-table {
          padding-top: 48px;
          h4 {
            ${Typography.body_28_sb};
          }

          dl {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 28px 0 16px;
            border-bottom: 2px solid #fff;
            gap: 12px;

            dt,
            dd {
              ${Typography.body_26};
            }

            dt {
              width: 50%;
              /*color: ${Colors.gray6};*/
              color: #fff;
              opacity: 0.9;
            }

            dd {
              /*color: ${Colors.gray8};*/
              color: #fff;
              opacity: 0.8;
            }
          }

          .total-wrapper {
            padding-top: 16px;
            ${Typography.body_26_sb};
            display: flex;
            align-items: center;
            justify-content: space-between;

            em {
              font-style: normal;
            }

            span {
              line-height: 42px;
              /*color: ${Colors.gray8};*/
              color: #fff;
            }
          }
        }
      }

      .button-wrapper {
        display: flex;
        align-items: flex-start;
        align-self: stretch;
        /* display: flex;
        align-items: center;
        justify-content: center;
        border-top: 2px solid ${Colors.gray2}; */

        &.cancel-reservation {
          font-size: 32px;
        }

        button {
          font-size: 28px;
          display: flex;
          padding: 26px 0px;
          justify-content: center;
          align-items: center;
          flex: 1 0 0;
          border-top: 2px solid rgba(255, 255, 255, 0.1);
          ${Typography.title_32}
          color: ${Colors.beige};

          &:nth-of-type(2) {
            border-left: 2px solid rgba(255, 255, 255, 0.1);
          }

          /* width: 100%;
          padding-block: 29px;
          ${Typography.title_32_sb}; */

          &.white {
            color: ${Colors.white};
          }

          /*
           &:nth-of-type(1) {
            border-right: 1px solid ${Colors.gray2};
            color: ${Colors.white};
          }
          

          &.red {
            color: ${Colors.red2};
          }
          &.yellow {
            color: ${Colors.yellow3};
          }
          &.gray {
            color: ${Colors.gray8};
          }
          &.green {
            color: var(--green-300, #e8ff68);
          } */
        }
      }
    }
  }
  &.normal {
    &.animation {
      animation-duration: 0.3s;
      animation-name: ${Animations.fadeIn};
    }
    & > .modal-content {
    }
  }

  // menu
  &.menu {
    & > .modal-content {
      .header-wrapper {
        padding: 32px 32px 0;
        display: flex;
        justify-content: space-between;

        &.calendar {
          padding: 48px 32px 0;
          .title {
            font-size: 40px;
            font-style: normal;
            font-weight: 600;
            line-height: 56px; /* 140% */
          }
        }

        .title {
          font-weight: 600;
          font-size: 32px;
          line-height: 44px;
          color: ${Colors.white};

          > b {
            color: ${Colors.blue};
          }
        }

        svg {
          margin: 10px;
        }
      }

      .content-wrapper {
        padding-block: 48px;
        padding-inline: 32px;
        ${Typography.body_28};
        overflow: auto;

        &.calendar {
          padding: 16px 32px 0;
          color: #fff;
          font-feature-settings: 'liga' off, 'clig' off;

          font-size: 28px;
          font-style: normal;
          font-weight: 400;
          line-height: 40px;

          .club-box {
            display: flex;
            margin-top: 40px;
            margin-bottom: 48px;
            padding: 24px;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
            align-self: stretch;
            border-radius: 6px;
            border: 2px solid var(--secondary-500, #ddc5a4);
            background: var(--secondary-50010, rgba(221, 197, 164, 0.1));

            .grade {
              border-radius: 16px;
              background: var(--primary-500, #41996a);
              display: flex;
              height: 48px;
              padding: 0px 12px;
              align-items: center;
              /* gap: 8px; */
              font-feature-settings: 'liga' off, 'clig' off;
              font-size: 24px;
              font-style: normal;
              font-weight: 500;
              line-height: 36px; /* 150% */

              & > .bold {
                font-weight: 700;
              }
            }

            .list {
              display: flex;
              align-items: flex-start;
              gap: 12px;
              align-self: stretch;
              & > svg {
                display: flex;
                width: 24px;
                height: 36px;
                padding: 6px 0px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }

        img {
          max-width: 100%;
          padding-top: 30px;
          display: inline-block;
        }
      }

      .button-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: sticky;
        bottom: 0;
        padding: 24px 32px;
        /* width: 750px; */

        button {
          ${Typography.title_32_m};
          width: 100%;
          height: 96px;
        }

        button:first-of-type {
          flex-shrink: unset;
          border-color: ${Colors.gray2};
        }
        button:nth-of-type(2) {
          max-width: 60%;
          border-top: 2px solid ${Colors.gray2};
        }

        &.calendar {
          display: flex;
          flex-direction: row;
          padding: 24px 32px;
          align-items: center;
          gap: 24px;
          white-space: nowrap;

          & > button {
            border-top: none;
          }

          & > button:nth-of-type(1) {
            max-width: fit-content;
          }

          & > button:nth-of-type(2) {
            flex: 1 0 0;
          }
        }
      }

      &.animation {
        animation-name: ${Animations.slideUp};
        animation-duration: 0.3s;
      }

      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 0 auto;
      width: 100%;
      height: auto;
      overflow: auto;
      max-height: calc(100% - 44px);
      display: flex;
      flex-direction: column;
      background: white;
      ${Variables.maxWidth};
      background: ${Colors.white};
      border-radius: 20px 20px 0px 0px;
    }
  }

  // toast
  &.toast {
    position: relative;
    /* max-width: 720px; */
    margin: 0 auto;
    width: 100%;
    width: calc(100% - 32px - 32px);

    &.animation {
      animation-name: ${Animations.fadeIn};
      animation-duration: 0.5s;
    }
    &.animation-fadeout {
      animation-name: ${Animations.fadeOut};
      animation-duration: 0.5s;
    }
    & > .modal-content {
      padding: 24px 32px;
      border-radius: 10px;
      background: rgba(20, 18, 14, 0.8) !important;

      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15), 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
      backdrop-filter: blur(25px);

      ${Typography.body_28};
      line-height: 40px;

      /* background: #272a2d;
      box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
      border-radius: 8px;

      text-align: center;
      ${Typography.title_32_m};
      div {
        color: ${Colors.white};
      } */
    }
  }
  &.setting {
    &.animation {
      animation-name: ${Animations.slideUp};
      animation-duration: 0.3s;
    }
    & > .modal-content {
    }
  }
  &.check {
    &.animation {
      animation-name: ${Animations.slideUp};
      animation-duration: 0.3s;
    }
    & > .modal-content {
    }
  }
`;
