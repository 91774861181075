const WELCOME_YOLK_TOAST_KEY = 'welcomeYolkToastKey';

const LOGOUT_KEY = 'LOGOUT_KEY';

const STAY_YOLK_MEMBERSHIP = 'STAY_YOLK_MEMBERSHIP';

const SOCIAL_LOGIN = 'SOCIAL_LOGIN';

const CONTINUE_KEY = 'CONTINUE_KEY';

const SUCCESSES_WITHDRAWAL = 'SUCCESSES_WITHDRAWAL';

export { WELCOME_YOLK_TOAST_KEY, LOGOUT_KEY, STAY_YOLK_MEMBERSHIP, SOCIAL_LOGIN, CONTINUE_KEY, SUCCESSES_WITHDRAWAL };
