import create from 'zustand';

interface IDetailInfoStore {
  policyMenus: any[];
  setPolicyMenus: (policyMenus) => void;
}
export const useDetailInfoStore = create<IDetailInfoStore>((set) => ({
  policyMenus: [],
  setPolicyMenus: (policyMenus) => set(() => ({ policyMenus })),
}));
