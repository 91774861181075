import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import styled from '@emotion/styled';

// import LineupRendingPage from '@/assets/img/event_egg_rending_page.jpg';
import LineupRendingPage2 from '@/assets/img/event_egg_rending_page2.jpg';
import { useFooterStore } from '@/stores/common/useFooterStore';
import { useHeaderStore } from '@/stores/common/useHeaderStore';
import { useNavigationStore } from '@/stores/common/useNavigationStore';

const LineupContainer = styled.div<React.CSSProperties>`
  img {
    width: 100%;
  }
`;

const Lineup = () => {
  const navigate = useNavigate();

  const setIsNavigationVisible = useNavigationStore((store) => store.setIsNavigationVisible);
  const setIsFooterVisible = useFooterStore((store) => store.setIsFooterVisible);
  const setHeaderState = useHeaderStore((store) => store.setHeaderState);

  useEffect(() => {
    setIsNavigationVisible(false);
    setIsFooterVisible(false);
    setHeaderState({
      title: '오픈 라인업',
      handleGoToBack: null,
      handleGoToHome: null,
      handleOnAlarm: null,
      handleOnClose: () => navigate(-1),
      handleOnShare: null,
    });
  }, []);

  return (
    <LineupContainer>
      <img src={LineupRendingPage2} alt="에그다이닝 오픈 라인업 상세보기" />
    </LineupContainer>
  );
};

export default Lineup;
