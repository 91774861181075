import { css } from '@emotion/react';

import { Colors } from './colors';
import { Variables } from './variables';
export const GlobalStyles = css`
  @import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css');

  /* reset */
  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
  }

  /* HTML5 display-role reset for older browsers */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }
  html,
  body {
    line-height: 1;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;

    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  }
  ol,
  ul {
    list-style: none;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote:before,
  blockquote:after,
  q::before,
  q::after {
    content: '';
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;

    th {
      font-weight: normal;
    }
  }

  * {
    @font-face {
      font-family: Inter;
      font-style: normal;
      font-weight: 100 900;
      src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2');
      unicode-range: U+0041-005A, U+0061-007A;
    }

    font-family: ${Variables.fontDfaultFamily};
    color: ${Colors.white};
    box-sizing: border-box;
    scrollbar-width: none; /* Firefox */
    margin: 0;
    padding: 0;
    text-size-adjust: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: none;
  }
  *::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
    height: 0;
    background: transparent;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
  a:visited {
    color: inherit;
  }

  button {
    cursor: pointer;
    padding: 0;
    border: none;
    background-color: transparent;
  }
  #root {
    display: flex;
    justify-content: center;
    -webkit-tap-highlight-color: transparent !important;
    width: 100%;
    height: 100%;
    overflow: auto;
    .ptr {
      background: ${Colors.gray12};
    }
  }
  body {
    min-height: 100vh;
    height: 100%;
    /* background: ${Colors.gray12}; */
    /* mobile viewport bug fix */
    @supports (-webkit-touch-callout: none) {
      height: -webkit-fill-available;
    }
  }

  html {
    height: -webkit-fill-available;
  }

  textarea {
    display: block;
  }

  div[role='presentation'] {
    margin: 0 auto;

    .MuiPopover-paper {
      min-width: calc(100% - 32px - 32px) !important;
      left: 50% !important;
      transform: translateX(-50%) !important;
      .css-i4bv87-MuiSvgIcon-root {
        fill: #1976d2;
      }
      .css-6hp17o-MuiList-root-MuiMenu-list {
        padding: 0;
        > li {
          padding: 15px 16px;
        }
      }
    }
  }
`;
