export const mock = [
  {
    id: 23,
    type: 'PRIVACY_FOR_SATISFACTION',
    required: true,
    content: {
      EN: {
        name: 'Consent for personal information use and utilization',
        contentUrl: 'https://theyeyak.com/policy/privacy/satisfaction',
      },
      KO: {
        name: '개인정보 수집 이용 동의',
        contentUrl: 'https://theyeyak.com/policy/privacy/satisfaction',
      },
    },
    createdAt: '2022-05-18T15:00:00.000Z',
  },
  {
    id: 1,
    type: 'SERVICE',
    required: true,
    content: {
      EN: {
        name: 'Terms of service',
        contentUrl: 'https://theyeyak.com/policy/service',
      },
      KO: {
        name: '서비스 이용약관',
        contentUrl: 'https://theyeyak.com/policy/service',
      },
    },
    createdAt: '2022-01-10T15:00:00.000Z',
  },
  {
    id: 3,
    type: 'PRIVACY_FOR_THIRD_PARTY',
    required: true,
    content: {
      EN: {
        name: 'Consent to provide personal information',
        contentUrl: 'https://theyeyak.com/policy/privacy/third-party',
      },
      KO: {
        name: '개인정보 제3자 제공 동의',
        contentUrl: 'https://theyeyak.com/policy/privacy/third-party',
      },
    },
    createdAt: '2022-01-10T15:00:00.000Z',
  },
  {
    id: 4,
    type: 'MARKETING',
    required: false,
    content: {
      EN: {
        name: 'Consent for marketing activities',
        contentUrl: 'https://theyeyak.com/policy/marketing',
      },
      KO: {
        name: '광고성 정보 수신 동의',
        contentUrl: 'https://theyeyak.com/policy/marketing',
      },
    },
    createdAt: '2022-01-10T15:00:00.000Z',
  },
  {
    id: 5,
    type: 'KEEP_ACTIVE_EVEN_DORMANT',
    required: false,
    content: {
      EN: {
        name: 'Consent for 10 years once the account is dormant',
        contentUrl: '',
      },
      KO: {
        name: '더예약의 광고와 마케팅 메시지를 카카오톡 으로 발송',
        contentUrl: '',
      },
    },
    createdAt: '2022-01-10T15:00:00.000Z',
  },
  {
    id: 6,
    type: 'LATE_NIGHT_MARKETING',
    required: false,
    content: {
      EN: {
        name: 'Consent to receive marketing promotions at night',
        contentUrl: '',
      },
      KO: {
        name: '야간시간 광고성 정보 수신',
        contentUrl: '',
      },
    },
    createdAt: '2022-01-10T15:00:00.000Z',
  },
];

export const joinMock = [
  {
    id: 23,
    type: 'PRIVACY_FOR_SATISFACTION',
    required: true,
    content: {
      EN: {
        name: 'Consent for personal information use and utilization',
        contentUrl: 'https://theyeyak.com/policy/privacy/satisfaction',
      },
      KO: {
        name: '가입약관',
        contentUrl: 'https://theyeyak.com/policy/privacy/satisfaction',
      },
    },
    createdAt: '2022-05-18T15:00:00.000Z',
  },
  {
    id: 1,
    type: 'SERVICE',
    required: true,
    content: {
      EN: {
        name: 'Terms of service',
        contentUrl: 'https://theyeyak.com/policy/service',
      },
      KO: {
        name: '개인정보 수집 동의문',
        contentUrl: 'https://theyeyak.com/policy/service',
      },
    },
    createdAt: '2022-01-10T15:00:00.000Z',
  },
  {
    id: 3,
    type: 'PRIVACY_FOR_THIRD_PARTY',
    required: true,
    content: {
      EN: {
        name: 'Consent to provide personal information',
        contentUrl: 'https://theyeyak.com/policy/privacy/third-party',
      },
      KO: {
        name: '개인정보 제 3자 제공동의',
        contentUrl: 'https://theyeyak.com/policy/privacy/third-party',
      },
    },
    createdAt: '2022-01-10T15:00:00.000Z',
  },
];
