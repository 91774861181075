import React from 'react';

import classnames from 'classnames';

import { BookingFooterContainer } from './BookingFooter.constants';
import { IBookingFooter } from './BookingFooter.type';

const BookingFooter = ({ text, action, active }: IBookingFooter): React.ReactElement => {
  return (
    <BookingFooterContainer className={classnames('footer-text', { disabled: !active })} onClick={active ? action : null}>
      {text}
    </BookingFooterContainer>
  );
};

export default BookingFooter;
