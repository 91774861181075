import React from 'react';

import BookingAgreeCheckbox from '../BookingAgreeCheckbox/BookingAgreeCheckbox';

/* eslint-disable @typescript-eslint/no-shadow */
import CheckBox from '@/components/CheckBox/CheckBox';
import MainSubtitle from '@/components/MainSubtitle/MainSubtitle';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import PolicyModal from '@/components/PolicyModal/PolicyModal';
import { sortPolicies } from '@/hooks/sortPolicies';
import { useFetchPolicies } from '@/hooks/useFetchPolicies';
import { PaymentAgreementContainer } from '@/shared/constants/payment-agreement.constants';
import { useBookingInfoStore } from '@/stores/useBookingInfoStore';

const requiredPolicies = ['SERVICE', 'EGG_DISCOUNT_DESC', 'EGG_CANCEL_FEE', 'EGG_SAVE_CARD_INFO', 'MARKETING', 'LATE_NIGHT_MARKETING', 'KEEP_ACTIVE_EVEN_DORMANT'];

const Agreement = (): React.ReactElement => {
  const { agreement, dispatchBookingInfo } = useBookingInfoStore();
  const { servicePolicies, loading, openServiceTerm, currentPolicy, policiesList } = useFetchPolicies({ url: '/service/policies', requiredPolicies: requiredPolicies });

  const changeAllCheckbox = () => {
    const agreementNameList = [...requiredPolicies];
    if (agreementNameList.every((agreementName) => agreement[agreementName])) {
      dispatchBookingInfo({ type: 'TOGGLE_AGREEMENT_ALL', value: agreementNameList });
    } else {
      dispatchBookingInfo({ type: 'TOGGLE_AGREEMENT_ALL', value: agreementNameList.filter((agreementItem) => !agreement[agreementItem]) });
    }
  };

  const showServiceTerm = (type) => {
    window.history.pushState(null, null, '');
    openServiceTerm(type);
  };

  return (
    <PaymentAgreementContainer className="payment-agreement-container">
      <MainSubtitle subtitle="서비스 이용 및 약관 동의" />
      <CheckBox checked={requiredPolicies.every((agreementItem) => agreement[agreementItem])} onChange={changeAllCheckbox}>
        <div className="checkbox-all">전체 동의</div>
      </CheckBox>
      <div className="seperator-line"></div>
      {!loading &&
        sortPolicies(requiredPolicies, servicePolicies)?.map((policy) => {
          return (
            <div key={policy.id} className="booking-agree-checkbox-container">
              <BookingAgreeCheckbox
                value={agreement[policy.type]}
                setValue={() => dispatchBookingInfo({ type: 'TOGGLE_AGREEMENT', value: policy.type })}
                required={policy.required}
                desc={policy.content.KO.name}
                expand={policy.content.KO.contentUrl}
                expandFunction={() => showServiceTerm(policy.type)}
                key={policy.type}
              />
            </div>
          );
        })}
      <ModalPortal>
        <PolicyModal url={currentPolicy} openServiceTerm={openServiceTerm} />
      </ModalPortal>
    </PaymentAgreementContainer>
  );
};

export default Agreement;
