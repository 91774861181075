import React, { useEffect, useId, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { ReactComponent as PlusCircle } from '@/assets/img/icon/plus-circle.svg';
import Button from '@/components/Button/Button';
import ChooseSubscription from '@/components/ChooseSubscription/ChooseSubscription';
import { WELCOME_YOLK_TOAST_KEY } from '@/components/GlobalToastWrapper/GlobalToastWrapper.constants';
import { CardContent } from '@/components/JoinComponents/JoinComponents.styles';
import Modal from '@/components/Modal/Modal';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import TermsGroup from '@/components/TermsGroup/TermsGroup';
import { useMembershipSubscriptionInfo } from '@/hooks/join.hook';
import { sortPolicies } from '@/hooks/sortPolicies';
import { useFetchCardData } from '@/hooks/useFetchCardData';
import { useFetchPolicies } from '@/hooks/useFetchPolicies';
import { eggdiningApi } from '@/shared/apis/eggdiningApi';
import { maskingCardNum } from '@/shared/libs/function/masking-card-number';
import { ButtonWrapper, JoinWrapper } from '@/shared/styles/pages/membership/join';
import { useFooterStore } from '@/stores/common/useFooterStore';
import { useHeaderStore } from '@/stores/common/useHeaderStore';
import { useModalStore } from '@/stores/common/useModalStore';
import { useNavigationStore } from '@/stores/common/useNavigationStore';
import { useBookingInfoStore } from '@/stores/useBookingInfoStore';
import { useCardPaymentStore, useInvitationCodeStore, useSaveEntryPathStore } from '@/stores/useMembershipJoinStore';
import { useSaveSubscriptionStore, useSaveTermsStore } from '@/stores/useSaveTermsStore';
import { useUserProfileStore } from '@/stores/useUserProfle';

const requiredPolicies = ['SERVICE', 'EGG_PRIVACY_FOR_YOLK_MEMBERSHIP', 'EGG_SAVE_CARD_INFO', 'MARKETING', 'LATE_NIGHT_MARKETING', 'KEEP_ACTIVE_EVEN_DORMANT'];

const Join = (): React.ReactElement => {
  const navigate = useNavigate();
  const setIsFooterVisible = useFooterStore((store) => store.setIsFooterVisible);
  const setHeaderState = useHeaderStore((store) => store.setHeaderState);
  const setIsNavigationVisible = useNavigationStore((store) => store.setIsNavigationVisible);

  const { setMenuModal, setAlertModal, setToastList, toastList, menuModal } = useModalStore();
  const { isChangePayment } = useCardPaymentStore();
  const { invitationCode } = useInvitationCodeStore();
  const { entryPath } = useSaveEntryPathStore();
  const { memberId, phone } = useUserProfileStore();
  const { saveTerms, setSaveTerms } = useSaveTermsStore();
  const { saveSubscriptionType, setSaveSubscriptionType } = useSaveSubscriptionStore();
  const { user } = useBookingInfoStore();

  const checkPaymentKey = useId();
  const failedPaymentKey = useId();
  const alreadyExistsHistoryKey = useId();

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      chooseSubscription: yup.string().test('CHOOSE ERROR!', (value) => {
        if (value === '01' || value === '02' || value === '03' || value === '04') {
          return true;
        }
        return false;
      }),
      agreements: yup.array(
        yup.object({
          required: yup.boolean(),
          value: yup.boolean().when('required', {
            is: (required) => required,
            then: yup.boolean().test('AGREEMENT_VALUE', '인증 ', (value) => {
              return value;
            }),
          }),
        })
      ),
    });
  }, []);

  const method = useForm({
    defaultValues: { chooseSubscription: '03', agreements: [] },
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const {
    getValues,
    reset,
    watch,
    setValue,
    formState: { isValid },
    handleSubmit,
  } = method;

  watch('agreements');
  watch('chooseSubscription');

  useEffect(() => {
    setIsFooterVisible(false);
    setHeaderState({
      title: '멤버십 가입',
      handleGoToBack: null,
      handleGoToHome: null,
      handleOnAlarm: null,
      handleOnClose: () => navigate(-1),
      handleOnShare: null,
    });
    setIsNavigationVisible(false);
  }, []);

  // 카드 데이터 불러올 때 enabled control하는 boolean 값
  const [isGetPaymentCardData, setIsGetPaymentCardData] = useState<boolean>(false);
  // 카드 데이터 불러오기
  const { data: paymentCardData, refetch: paymentCardReFetch } = useFetchCardData(isGetPaymentCardData);

  useEffect(() => {
    if (isChangePayment) {
      setMenuModal({ visible: true, key: checkPaymentKey });
      paymentCardReFetch();
    }
  }, [isChangePayment]);

  const { servicePolicies, loading, openServiceTerm, currentPolicy } = useFetchPolicies({ url: '/service/policies', requiredPolicies: requiredPolicies });
  useEffect(() => {
    if (servicePolicies?.length) {
      reset({ ...getValues(), agreements: sortPolicies(requiredPolicies, servicePolicies) });
    }
  }, [servicePolicies]);

  // 결제 버튼 클릭 시 약관동의 데이터 전송 put
  const { mutate: termsAgreementMutate } = useMutation(
    (resultData) => {
      return eggdiningApi.put('/user/termsAgreements', {
        user: {
          phone: phone,
          email: user.email,
        },
        termsAgreements: resultData,
      });
    },
    {
      onError: (error) => {
        console.log(error);
        alert('관리자에게 문의하세요.');
      },
    }
  );

  const onSubmit = async () => {
    await Promise.resolve(setIsGetPaymentCardData(Boolean(paymentCardData)));
    paymentCardReFetch();
  };

  // 욜크 멤버십 시작 버튼 클릭시 submit되는 post api
  const { mutate: submitMembershipMutate } = useMutation(
    () => {
      return eggdiningApi.post(`eggdining/users/${memberId}/membership`, {
        subscriptionType: getValues('chooseSubscription'),
        inviteTicketCode: invitationCode,
      });
    },
    {
      onSuccess: () => {
        setToastList([...toastList, { visible: true, key: WELCOME_YOLK_TOAST_KEY }]);
        // navigate(entryPath, { replace: true });
        navigate('/mypage', { replace: true });
      },
      onError: () => {
        setAlertModal({ visible: true, key: failedPaymentKey });
      },
    }
  );

  // 해지후 재등록: 해지 후 이용 기한이 지나지 않았는데 재등록 하는 경우 이용 기한을 확인하기위한 get
  const { data: membershipInquiryData, refetch: membershipInquiryRefetch } = useMembershipSubscriptionInfo();

  useEffect(() => {
    if (paymentCardData) {
      membershipInquiryRefetch();
    }
  }, [paymentCardData]);

  const submitReRegistration = () => {
    // 해지한 이력이 있는 경우
    const { applyEndDate, cancelDate } = membershipInquiryData;
    const getTimeApply = (dateData) => new Date(dateData).getTime();

    if (getTimeApply(applyEndDate) > getTimeApply(cancelDate)) {
      setAlertModal({ visible: true, key: alreadyExistsHistoryKey });
    }
  };

  const onSubmitMembership = (result) => {
    // 약관동의 체크한 데이터 put
    const putResultData = result.agreements?.map((agree) => {
      return {
        servicePolicyType: agree.type,
        agreement: agree.value ? agree.value : false,
      };
    });
    termsAgreementMutate(putResultData);

    // 멤버십 결제하여 데이터 post
    submitMembershipMutate();
  };

  const checkUserReRegistration = (submitData) => {
    if (membershipInquiryData && membershipInquiryData?.cancelDate?.length) {
      submitReRegistration();
    } else {
      onSubmitMembership(submitData);
    }
  };

  useEffect(() => {
    if (saveTerms?.length) {
      setValue('agreements', saveTerms);
    }
    if (saveSubscriptionType?.length) {
      setValue('chooseSubscription', saveSubscriptionType);
    }
  }, []);

  const confirmPaymentCard = (type: string) => {
    setSaveTerms(getValues('agreements'));
    setSaveSubscriptionType(getValues('chooseSubscription'));
    navigate(`/membership/payment/${type}`);
  };

  return (
    <>
      <FormProvider {...method}>
        <JoinWrapper>
          <ChooseSubscription />
          <TermsGroup openServiceTerm={openServiceTerm} allCheckComment={'이용약관 전체 동의'} currentPolicy={currentPolicy} />
        </JoinWrapper>
        <ButtonWrapper>
          <Button
            defaultType="button"
            disabled={!isValid}
            onClick={() => {
              onSubmit();
              setMenuModal({ visible: true, key: checkPaymentKey });
            }}
            colorLevel="primary"
            type="sticky"
          >
            멤버십 구독 결제하기
          </Button>
        </ButtonWrapper>
      </FormProvider>
      <ModalPortal>
        <Modal.Menu key={checkPaymentKey} modal="menu" isDim={true} isAnimation={true}>
          <section className="header-wrapper">
            <h2 className="title">결제수단 확인</h2>
          </section>
          <section className="content-wrapper">
            {paymentCardData ? (
              <CardContent visibleCard={true}>
                <section>
                  {paymentCardData.cardCompanyName}
                  <span>{maskingCardNum(paymentCardData.cardNumber)}</span>
                </section>
                <section>
                  <Button onClick={() => confirmPaymentCard('change')} colorLevel="gray" type="small" isActive={true}>
                    변경
                  </Button>
                </section>
              </CardContent>
            ) : (
              <CardContent onClick={() => confirmPaymentCard('add')}>
                <PlusCircle className="plus-icon" />
                카드 1개 등록 가능
              </CardContent>
            )}
          </section>
          <section className="button-wrapper">
            <Button
              classKey="premium"
              onClick={handleSubmit(
                (data) => {
                  checkUserReRegistration(data);
                },
                (error) => {
                  console.log(error);
                }
              )}
              disabled={paymentCardData ? false : true}
              colorLevel="primary"
              type="large"
            >
              그린 클럽 멤버십 시작
            </Button>
          </section>
        </Modal.Menu>
        <Modal.Alert key={failedPaymentKey} modal="alert" isDim={true} isAnimation={true}>
          <section className="content-wrapper">
            <h2 className="title">알림</h2>
            <p className="desc">결제에 실패했습니다. 사용할 수 있는 카드인지 다시 확인해주세요.</p>
          </section>
          <section className="button-wrapper">
            <button onClick={() => setAlertModal({ visible: false, key: failedPaymentKey })}>확인</button>
          </section>
        </Modal.Alert>
        <Modal.Alert key={alreadyExistsHistoryKey} modal="alert" isDim={true} isAnimation={true}>
          <section className="content-wrapper">
            <h2 className="title">알림</h2>
            <p className="desc">이미 이용하시던 내역이 있어요. 이번에 결제하신 내역은 현재 이용내역이 끝난 시점부터 반영됩니다.</p>
          </section>
          <section className="button-wrapper">
            <button
              onClick={handleSubmit(
                (data) => {
                  onSubmitMembership(data);
                  setAlertModal({ visible: false, key: alreadyExistsHistoryKey });
                },
                (error) => {
                  console.log(error);
                }
              )}
            >
              확인
            </button>
          </section>
        </Modal.Alert>
      </ModalPortal>
    </>
  );
};

export default Join;
