import React from 'react';
import { useFormContext } from 'react-hook-form';

import { ScheduleContainer } from '@/components/Raffle/Apply/Schedule/Schedule.styles';
import { CustomControlledCheckbox } from '@/shared/hook-form/CustomControlledCheckbox';

const Schedule = ({ ...props }): React.ReactElement => {
  const { raffleList, maxLength } = props;
  const { watch, getValues, control, setValue } = useFormContext();

  const maxLengthCheck = () => {
    return (
      (getValues('schedule') &&
        getValues('schedule').filter((item) => {
          return item.value;
        })) ||
      []
    );
  };

  return (
    <ScheduleContainer>
      {getValues('schedule')?.map((raffle, i) => {
        return (
          <CustomControlledCheckbox
            disabled={maxLengthCheck().length >= maxLength && !raffle.value}
            key={i}
            className="custom-check-style"
            checkType="empty"
            name={`schedule.${i}.value`}
            control={control}
            onChange={() => {}}
          >
            <div className="checkbox-wrapper">
              <div className="raffle-title">{raffle.title}</div>
              <div className="raffle-description">{raffle.description}</div>
            </div>
          </CustomControlledCheckbox>
        );
      })}
    </ScheduleContainer>
  );
};

export default Schedule;
