import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';

function HeaderTimer({ rafflesResultData }) {
  const [diffTime, setDiffTime] = useState('00:00:00');
  const [cehckRaffleResultTime, setCehckRaffleResultTime] = useState(true);

  const [hour, minute, second] = diffTime.split(':');

  const render = () => {
    if (rafflesResultData.raffleStatus === '04') {
      // 추첨 완료
      return (
        <div className="description">
          <div className="title complete">추첨 완료</div>
          <div className="desc complete">
            <div>
              모든 좌석이 예약되었습니다.
              <br />
              참여해주셔서 감사합니다.
            </div>
            <div className="raffle-done">{dayjs(rafflesResultData.raffleEndDate).format('YYYY.MM.DD HH:mm')} 추첨 종료</div>
          </div>
        </div>
      );
    }
    if (rafflesResultData.raffleStatus === '03' || !cehckRaffleResultTime) {
      // 추첨 중
      return (
        <div className="description">
          <div className="title raffling">추첨 중</div>
          <div className="desc raffling">
            현재 {rafflesResultData.raffleTotalSlotCount}팀 중 <p className="letter-box count">{rafflesResultData.raffleCompletedSlotCount || 0}팀</p> 예약이 완료되었어요.
          </div>
        </div>
      );
    }
    if (rafflesResultData.raffleStatus === '02' || cehckRaffleResultTime) {
      return (
        <div className="description">
          <div className="title wait">추첨 발표까지</div>
          <div className="desc wait">
            <div>
              <div>
                <span className="letter-box time">{hour?.[0]}</span>
                <span className="letter-box time">{hour?.[1]}</span>
              </div>
              <div>HRS</div>
            </div>
            <div className="colon">:</div>
            <div>
              <div>
                <span className="letter-box time">{minute?.[0]}</span>
                <span className="letter-box time">{minute?.[1]}</span>
              </div>
              <div>MINS</div>
            </div>
            <div className="colon">:</div>
            <div>
              <div>
                <span className="letter-box time">{second?.[0]}</span>
                <span className="letter-box time">{second?.[1]}</span>
              </div>
              <div>SECS</div>
            </div>
          </div>
        </div>
      );
    }
  };

  function secondsToHHMMSS(seconds) {
    const duration = dayjs.duration(seconds, 'seconds');
    const time = dayjs().startOf('day').add(duration);

    return time.format('HH:mm:ss');
  }

  useEffect(() => {
    if (rafflesResultData.raffleStatus === '02') {
      const id = setInterval(() => {
        const nowDate = dayjs();
        setDiffTime(secondsToHHMMSS(dayjs(rafflesResultData.raffleDate).diff(nowDate, 's')));
        if (dayjs(rafflesResultData.raffleDate).diff(nowDate, 's') <= 0) {
          setCehckRaffleResultTime(false);
          clearInterval(id);
        }
      }, 1000);
      return () => clearInterval(id);
    }
  }, []);

  return render();
}

export default HeaderTimer;
