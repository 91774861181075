import React from 'react';

import { NoReservationHistoryContainer } from '@/components/NoReservationHistory/NoReservationHistory.styles';

const NoReservationHistory = (): React.ReactElement => {
  return (
    <NoReservationHistoryContainer>
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="62" height="62" viewBox="0 0 33 33" fill="none">
          <g clip-path="url(#clip0_744_4535)">
            <path
              d="M16.5 0.5C7.66429 0.5 0.5 7.66429 0.5 16.5C0.5 25.3357 7.66429 32.5 16.5 32.5C25.3357 32.5 32.5 25.3357 32.5 16.5C32.5 7.66429 25.3357 0.5 16.5 0.5ZM16.5 29.7857C9.16429 29.7857 3.21429 23.8357 3.21429 16.5C3.21429 9.16429 9.16429 3.21429 16.5 3.21429C23.8357 3.21429 29.7857 9.16429 29.7857 16.5C29.7857 23.8357 23.8357 29.7857 16.5 29.7857Z"
              fill="white"
            />
            <path
              d="M14.7852 22.7857C14.7852 23.2404 14.9658 23.6764 15.2873 23.9979C15.6088 24.3194 16.0448 24.5 16.4994 24.5C16.9541 24.5 17.3901 24.3194 17.7116 23.9979C18.0331 23.6764 18.2137 23.2404 18.2137 22.7857C18.2137 22.3311 18.0331 21.895 17.7116 21.5735C17.3901 21.252 16.9541 21.0714 16.4994 21.0714C16.0448 21.0714 15.6088 21.252 15.2873 21.5735C14.9658 21.895 14.7852 22.3311 14.7852 22.7857ZM15.6423 18.7857H17.3566C17.5137 18.7857 17.6423 18.6571 17.6423 18.5V8.78572C17.6423 8.62857 17.5137 8.5 17.3566 8.5H15.6423C15.4852 8.5 15.3566 8.62857 15.3566 8.78572V18.5C15.3566 18.6571 15.4852 18.7857 15.6423 18.7857Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_744_4535">
              <rect width="32" height="32" fill="white" transform="translate(0.5 0.5)" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <h1>예약이 없습니다</h1>
    </NoReservationHistoryContainer>
  );
};

export default NoReservationHistory;
