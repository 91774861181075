import { useEffect, useState } from 'react';

import { eggdiningApi } from '@/shared/apis/eggdiningApi';
import { IFetchPolicies } from '@/shared/types/FetchPolicies.type';

const useFetchPolicies = ({ url, dispatchInfo = (arg) => {}, requiredPolicies = [] }) => {
  const [servicePolicies, setServicePolicies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPolicy, setCurrentPolicy] = useState('');
  const [policiesList, setPoliciesList] = useState([]);

  const openServiceTerm = (type) => {
    const serviceUrl = servicePolicies.find((item) => item.type === type)?.content.KO.contentUrl;
    setCurrentPolicy(serviceUrl);
    return serviceUrl;
  };

  const getServicePolicies = () => {
    setLoading(true);
    let policyList = [];
    policyList = requiredPolicies;

    dispatchInfo({ type: 'CHANGE_AGREEMENT_LIST', value: [...policyList] });
    setPoliciesList(policyList);

    eggdiningApi
      .get<IFetchPolicies>(url, { params: { servicePolicyType: policyList } })
      .then((response) => {
        setServicePolicies(response.servicePolicies);
      })
      .catch(() => {
        // alert('약관 정보를 불러오는데 실패하였습니다.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getServicePolicies();
  }, []);

  return { servicePolicies, loading, openServiceTerm, currentPolicy, policiesList };
};

export { useFetchPolicies };
