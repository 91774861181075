import React from 'react';

import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

export const EmblemContainer = styled.div<React.CSSProperties>`
  &.medium {
    width: 80px;
    height: 80px;
    border-radius: 99px;
    margin-right: 8px;
  }

  &.white {
    /* background: ${Colors.white}; */
  }

  &.green {
    /* background: var(--green-300, #e8ff68); */
  }
  &.black {
    /* background: var(--gray-900, #14120e); */
  }
`;

export const EmblemWithTextContainer = styled.div<React.CSSProperties>`
  display: flex;
  padding: 0px 16px;
  height: 48px;
  align-items: center;
  gap: 4px;
  border-radius: 16px;
  font-family: pretendard, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;

  &.medium {
    width: 80px;
    height: 80px;
    padding: 3px 12px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 99px;
    margin-right: 8px;
  }

  & > .bold {
    font-weight: 700;
  }

  &.white {
    background: #fff;
    color: var(--gray-900, #14120e);
  }

  &.green {
    background: var(--primary-500, #41996a);
    color: #fff;
  }
  &.black {
    background: var(--gray-900, #14120e);
  }
`;

export const EmblemWithTextBigContainer = styled.div<React.CSSProperties>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .membership-title {
    margin-top: 24px;
    color: #fff;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.4px;
  }

  .white {
    display: flex;
    width: 112px;
    height: 112px;
    padding: 3px 12px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 99px;
    margin-right: 8px;
    background: #fff;
  }

  .green {
    display: flex;
    width: 112px;
    height: 112px;
    padding: 3px 12px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 99px;
    margin-right: 8px;
  }
  .black {
    display: flex;
    width: 112px;
    height: 112px;
    padding: 3px 12px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 99px;
    margin-right: 8px;
    background: var(--gray-900, #14120e);
  }
`;
