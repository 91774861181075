import React from 'react';

import styled from '@emotion/styled';

import { Colors } from '@/shared/styles';

export const NavigationModalContainer = styled.section<React.CSSProperties>`
  > div {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.6);
    .modal-footer {
      display: flex;
    }
    .modal-container {
      background: white;
      border-radius: 32px;
      width: 100%;
      text-align: left;
      margin: 0 80px;
      max-width: calc(100% - 160px);
      box-sizing: border-box;
      .modal-header {
        font-weight: 600;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: -0.01em;
        color: ${Colors.black};
        padding: 40px 40px 0;
      }
      .modal-body {
        text-align: left;
        margin: 32px 0 64px;
        color: ${Colors.gray8};
        font-size: 13px;
        line-height: 17px;
        padding: 0 40px;
      }
      .modal-footer {
        border-top: 1px solid ${Colors.gray2};
        .cancel-button {
          width: 100%;
          background: white;
          outline: 0;
          border: 0;
          font-weight: 500;
          text-align: center;
          padding: 32px;
          border-bottom-left-radius: 32px;
          border-right: 1px solid ${Colors.gray2};
        }
        .confirm-button {
          width: 100%;
          background: white;
          outline: 0;
          border: 0;
          font-weight: 500;
          text-align: center;
          padding: 32px;
          border-radius: 32px;
          color: ${Colors.yellow1};
        }
      }
    }
  }
  .modal.openModal {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
