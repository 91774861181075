import cryptoBrowsify from 'crypto-browserify';
import dayjs from 'dayjs';
import queryString from 'query-string';

declare const window;

const MERCHANT_ID = process.env.NEXT_PUBLIC_NICEPAY_MERCHANT_ID;
const MERCHANT_KEY = process.env.NEXT_PUBLIC_NICEPAY_MERCHANT_KEY;

const getEdiDate = () => dayjs(new Date()).format('YYYYMMDDHHmmss');

// eslint-disable-next-line require-await
const openPaymentModal = async (context) => {
  const { amount, orderId, user, goodsName, returnUrl, merchantId, merchantKey, callBackUrl, payType, directOption = {} } = context;
  const ediDate = getEdiDate();
  let option;
  if (payType === 'PG') {
    option = { DirectShowOpt: 'CARD' };
  } else if (payType === 'KAKAO') {
    option = {
      DirectShowOpt: 'CARD',
      NicepayReserved: 'DirectKakao=Y',
    };
  } else if (payType === 'NAVER') {
    option = {
      DirectShowOpt: 'CARD',
      NicepayReserved: 'DirectNaver=Y',
    };
  } else if (payType === 'EXCEPTION') {
    option = directOption;
  }
  const extraObject = { clientCallbackUrl: returnUrl };

  const body = {
    PayMethod: 'CARD',
    GoodsName: goodsName,
    Amt: amount,
    MID: merchantId,
    Moid: orderId,
    BuyerName: user.name,
    BuyerTel: user.phone,
    BuyerEmail: user.email,
    ReturnURL: callBackUrl,
    CharSet: 'utf-8',
    ReqReserved: queryString.stringify(extraObject),
    EdiDate: ediDate,
    SignData: cryptoBrowsify.createHash('sha256').update([ediDate, merchantId, amount, merchantKey].join('')).digest('hex'),
    SkinType: 'purple',
  };
  Object.assign(body, option);
  const formData = new FormData();

  Object.entries(body).forEach((entry) => {
    const [key, value] = entry;
    formData.append(key, value);
  });

  const form = document.createElement('form');
  form.method = 'post';
  form.acceptCharset = 'EUC-KR';

  Object.entries(body).forEach(([name, value]) => {
    const input = document.createElement('input');
    input.name = name;
    input.value = value;
    input.type = 'hidden';
    form.appendChild(input);
  });

  document.body.appendChild(form);
  form.action = 'https://web.nicepay.co.kr/v3/v3Payment.jsp';
  form.submit();
  // if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
  // } else {
  //   form.action = callBackUrl;
  //   window.nicepaySubmit = function () {
  //     form.submit();
  //   };
  //   window.goPay(form);
  // }
};

export { openPaymentModal };
