import { useQuery } from 'react-query';

import { eggdiningApi } from '@/shared/apis/eggdiningApi';
import USER_QUERY_KEYS from '@/shared/apis/queryKeys/user';
import { useUserProfileStore } from '@/stores/useUserProfle';

interface IUserProfileGet {
  user: {
    memberUseServiceId: number;
    name: string;
    idP: number;
    phone: string;
  };
}

const useUserProfileQuery = () => {
  const { memberId, setUser } = useUserProfileStore();
  const token = localStorage.getItem('jwtToken');
  if (token) {
    eggdiningApi.configure({ headers: { authorization: 'Bearer ' + token } });
  }
  return useQuery(useUserProfileQuery.getQueryKey(), () => useUserProfileQuery.fetcher(memberId), {
    enabled: Boolean(memberId),
    onSuccess: ({ user }) => {
      setUser({ ...user, memberId });
    },
  });
};

useUserProfileQuery.getQueryKey = () => USER_QUERY_KEYS.GET_USER_PROFILE;
useUserProfileQuery.fetcher = (memberId) => eggdiningApi.get<IUserProfileGet>(`/eggdining/users/${memberId}`);

export { useUserProfileQuery };
