import create from 'zustand';

import { Nullable } from '@/shared/types/common.types';

interface IModal {
  visible: boolean;
  key: Nullable<string>;
}

interface GlobalErrorModal {
  visible: boolean;
  title: string;
  message: string;
  closeText: string;
  callback?: any;
}

interface GlobalConfirmModal extends GlobalErrorModal {
  okayText: string;
  callback?: any;
  closeCallback?: any;
}

interface IUseModalStore {
  alertModal: IModal;
  setAlertModal: (props) => void;
  menuModal: IModal;
  setMenuModal: (props) => void;
  toastModal: IModal;
  setToastModal: (props) => void;
  normalModal: IModal;
  setNormalModal: (props) => void;
  toastList: IModal[];
  setToastList: (props) => void;
  globalErrorModal: GlobalErrorModal;
  setOpenGlobalErrorModal: (props: GlobalErrorModal) => void;
  globalConfirmModal: GlobalConfirmModal;
  setOpenGlobalConfirmModal: (props: GlobalConfirmModal) => void;
}

export const initialModal = { visible: false, key: null };
export const initialGlobalErrorModal = { visible: false, title: '알림', message: '오류 발생', closeText: '닫기', callback: () => {} };
export const initialGlobalConfirmModal = { visible: false, title: '알림', message: '오류 발생', closeText: '닫기', okayText: '확인', callback: () => {} };

export const useModalStore = create<IUseModalStore>((set) => ({
  alertModal: initialModal,
  menuModal: initialModal,
  toastModal: initialModal,
  normalModal: initialModal,
  globalErrorModal: initialGlobalErrorModal,
  globalConfirmModal: initialGlobalConfirmModal,
  toastList: [],

  setAlertModal: (alertModal) => set({ alertModal }),
  setMenuModal: (menuModal) => set({ menuModal }),
  setToastModal: (toastModal) => set({ toastModal }),
  setNormalModal: (normalModal) => set({ normalModal }),
  setOpenGlobalErrorModal: (globalErrorModal) => set({ globalErrorModal }),
  setOpenGlobalConfirmModal: (globalConfirmModal) => set({ globalConfirmModal }),
  setToastList: (toastList) => set({ toastList }),
}));
