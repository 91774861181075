import { useMutation } from 'react-query';

import { eggdiningApi } from '@/shared/apis/eggdiningApi';
import OAUTH_USER_QUERY_KEYS from '@/shared/apis/queryKeys/auth';
import { LocalStorage } from '@/shared/configs/storage';

const useSignInquery = () => {
  return useMutation(useSignInquery.getQueryKey(), () => useSignInquery.autoLogin());
};

useSignInquery.getQueryKey = () => [OAUTH_USER_QUERY_KEYS.USER_SIGN_IN];
useSignInquery.autoLogin = () =>
  eggdiningApi.post(`/eggdining/oauth/${LocalStorage.getItem('social')}/login`, {
    accessToken: LocalStorage.getItem('accessToken'),
  });

export { useSignInquery };
