import React from 'react';

import styled from '@emotion/styled';

import { Colors } from '../colors';

import LoginBgImage from '@/assets/img/f338391c4436ac62afad529315ae626c.jpg';

export const MainSplashContainer = styled.section<React.CSSProperties>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  /* background-color: #fff; */
  /* transition: background-color 1s ease-out; */
  /*background-color: #284c39;*/

  /* opacity: 1; */
  /* transition: opacity 1s ease-out; */
  background-color: ${Colors.gray10};
  /* transition: background-color 1s ease-out; */
  /*transition: 1s background-color;*/

  svg {
    width: 400px;
    height: 80px;
  }

  .logo-text {
    color: ${Colors.white};
    text-align: center;
    font-family: pretendard, sans-serif;
    font-size: 80px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 40px */
    letter-spacing: -0.8px;
  }

  &.fadeout-bg {
    opacity: 0.4;
    /*background-color : ${Colors.white};*/
  }

  &.yellowbg {
    background-color: #f2c74a;
    svg.egglogomain {
      .yellow-dot {
        transition: fill 1s;
        fill: #231815;
      }
    }
  }
`;
