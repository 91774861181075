import styled from '@emotion/styled';

import { ISeperatorContainer } from './Seperator.type';

import { Colors } from '@/shared/styles';

export const SeperatorContainer = styled.div<ISeperatorContainer>`
  height: ${(props) => (props.type === 'line' ? '2px' : '12px')};
  background-color: ${(props) => (props.type === 'line' ? Colors.gray2 : Colors.white_opacity10)};
  opacity: ${(props) => (props.type === 'line' ? '10%' : '')};
  margin: ${(props) => (props.inline ? '0 32px' : '0')};
  flex-shrink: 0;
`;
