import styled from '@emotion/styled';

export const ButtonWrapper = styled.div`
  position: sticky;
  bottom: 0;
`;

export const JoinWrapper = styled.main`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 100px;
  overflow: auto;

  .terms-group-container {
    padding-bottom: 80px;
    padding-inline: 32px;
  }
`;
