import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

const Header = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
  .title {
    margin-bottom: 20px;
    ${Typography.title_44}
    color: ${Colors.gray9}
  }
  .description {
    width: 417px;
    text-align: center;
    ${Typography.subtitle_m}
    color: ${Colors.gray8}
  }
  svg {
    margin-bottom: 40px;
  }
`;

const RaffleResult = styled.section`
  .raffle-result-title {
    margin-bottom: 40px;
    ${Typography.title_36_sb}
    color: ${Colors.gray9}
  }
`;

const RaffleCompleteTalbe = styled.table`
  tr {
    border-bottom: 12px solid white;
    td:first-of-type {
      min-width: 148px;
      ${Typography.body_28}
      color: ${Colors.gray6}
    }
    > td {
      ${Typography.body_28}
      color: ${Colors.gray8};
      > div {
        color: ${Colors.gray8};
      }
    }
  }
`;

export { Header, RaffleResult, RaffleCompleteTalbe };
