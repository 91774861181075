import React from 'react';

import { NoticeContainer } from './Notice.styles';

import { useBookingStore } from '@/stores/useBookingStore';

const Notice = (): React.ReactElement => {
  const { mall } = useBookingStore();

  return (
    <NoticeContainer>
      <h2>매장 이용 안내</h2>
      <p dangerouslySetInnerHTML={{ __html: mall.storeUsingGuide }}></p>
    </NoticeContainer>
  );
};

export default Notice;
