import { useMutation, useQuery } from 'react-query';

import NOTIFICATION_QUERY_KEYS from '../shared/apis/queryKeys/notification';

import { eggdiningApi } from '@/shared/apis/eggdiningApi';
import { INotificationResult } from '@/shared/types/Notification.types';
import { useBookingStore } from '@/stores/useBookingStore';
import { useUserProfileStore } from '@/stores/useUserProfle';

function useQueryFetchMallNotification(serviceType: string) {
  const { memberId } = useUserProfileStore();

  // const { storeUri } = useBookingStore().mall;
  const { storeUri } = useBookingStore();
  return useQuery([useQueryFetchMallNotification.getKeys(), storeUri], () => useQueryFetchMallNotification.fetcher(memberId, storeUri, serviceType), {
    enabled: Boolean(storeUri) && Boolean(memberId) && Boolean(serviceType),
    staleTime: Infinity,
    retry: false,
  });
}

useQueryFetchMallNotification.getKeys = () => [NOTIFICATION_QUERY_KEYS.FETCH_MALL_NOTIFICATION];
useQueryFetchMallNotification.fetcher = (memberId, storeUri, serviceType) =>
  eggdiningApi.authGet<INotificationResult>(`/eggdining/users/${memberId}/notifications/stores/${storeUri}/${serviceType}`);

function useMutationUpdateMallNotification(serviceType: string, isApplyNotification) {
  const { memberId } = useUserProfileStore();
  const { storeUri } = useBookingStore();
  return useMutation(
    [useMutationUpdateMallNotification.getKeys(), storeUri],
    () => useMutationUpdateMallNotification.updater(memberId, storeUri, serviceType, isApplyNotification),
    {
      onSuccess: () => {},
      retry: false,
    }
  );
}

useMutationUpdateMallNotification.getKeys = () => [NOTIFICATION_QUERY_KEYS.FETCH_MALL_NOTIFICATION];
useMutationUpdateMallNotification.updater = (memberId, storeUri, serviceType, isApplyNotification: boolean) =>
  eggdiningApi.patch<INotificationResult>(`/eggdining/users/${memberId}/notifications/stores/${storeUri}/${serviceType}`, { isApplyNotification });

export { useQueryFetchMallNotification, useMutationUpdateMallNotification };
