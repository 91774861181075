import styled from '@emotion/styled';

import CircleCheck from '@/assets/img/membership/circle_check.svg';
import CircleCheckReverse from '@/assets/img/membership/circle_check_reverse.svg';
import { Colors, Typography } from '@/shared/styles';

export const BannerMembershipContainer = styled.section`
  padding: 62px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 62px;
  margin-bottom: 200px;

  .button-wrapper {
    position: fixed;
    width: 100%;
    bottom: -1px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  width: 686px;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  .title {
    text-align: center;

    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px; /* 125% */
  }
  .desc {
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
  }
`;

export const ContentsContainer = styled.div`
  display: flex;
  /* width: 686px; */
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;

  .membership-container {
    display: flex;
    padding: 40px;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    align-self: stretch;

    border-radius: 6px;
    border: 2px solid rgba(255, 255, 255, 0.1);
    background: var(--gray-700, #3b3b39);

    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.4), 0px 4px 16px 0px rgba(0, 0, 0, 0.2);

    &.green {
      border: 2px solid var(--primary-dc-light-green, #41996a);
      background: #3c443e;
    }

    button {
      width: 100%;
      height: 96px;
      /* color: var(--gray-900, #14120e); */
      text-align: center;
      font-feature-settings: 'liga' off, 'clig' off;
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 44px; /* 137.5% */
      &.soon {
        color: #fff;
      }
    }

    .contents {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      align-self: stretch;

      .grade {
        display: flex;
        padding: 8px 20px;
        align-items: center;
        gap: 0px;
        border-radius: 12px;

        font-feature-settings: 'liga' off, 'clig' off;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 40px; /* 137.5% */

        & > span.bold {
          font-weight: 700;
        }

        &.green {
          background: ${Colors.green2};
          color: ${Colors.white};
        }

        &.black {
          background: var(--gray-900, #14120e);
        }

        &.white {
          background: #fff;
          color: var(--gray-900, #14120e);
          & > .bold {
            color: var(--gray-900, #14120e);
          }
        }

        & > svg {
          width: 36px;
          height: 36px;
        }
      }

      .subscribe {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        align-self: stretch;

        .item {
          display: flex;
          padding: 32px 0px;
          flex-direction: column;
          align-items: center;
          gap: 16px;
          align-self: stretch;
          border-radius: 6px;
          background: var(--gray-90040, rgba(20, 18, 14, 0.4));

          &:first-of-type {
            background: none;
          }

          .title {
            text-align: center;
            font-feature-settings: 'liga' off, 'clig' off;

            font-size: 28px;
            font-style: normal;
            font-weight: 500;
            line-height: 40px;

            display: flex;
            align-items: center;
            gap: 16px;

            .tag {
              display: flex;
              padding: 2px 12px;
              justify-content: center;
              align-items: center;
              gap: 8px;
              border-radius: 12px;
              background: var(--secondary-50010, rgba(221, 197, 164, 0.1));

              color: ${Colors.beige};
              text-align: center;

              font-size: 24px;
              font-style: normal;
              font-weight: 400;
              line-height: 36px;
            }
          }
          .desc {
            display: flex;
            align-items: flex-end;
            gap: 12px;

            h3 {
              text-align: center;

              font-size: 48px;
              font-style: normal;
              font-weight: 600;
              line-height: 60px; /* 125% */
            }
            h4 {
              text-align: center;
              font-feature-settings: 'liga' off, 'clig' off;

              font-size: 28px;
              font-style: normal;
              font-weight: 400;
              line-height: 40px; /* 142.857% */
              opacity: 0.8;
              &.strike-through {
                text-decoration: line-through;
              }
            }
          }
          .tag {
            display: flex;
            padding: 6px 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 16px;
            background: var(--gray-900, #14120e);

            text-align: center;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 36px; /* 150% */
          }
        }
      }

      .tagline {
        display: flex;
        align-items: flex-start;
        gap: 12px;

        .original-price {
          text-align: center;
          font-feature-settings: 'liga' off, 'clig' off;
          font-size: 28px;
          font-style: normal;
          font-weight: 400;
          line-height: 40px;
          text-decoration-line: line-through;
          opacity: 0.8;
        }
        .discount {
          color: var(--green-300, #e8ff68);
          text-align: center;
          font-size: 28px;
          font-style: normal;
          font-weight: 600;
          line-height: 40px; /* 142.857% */
        }
      }
      .price {
        display: flex;
        align-items: flex-end;
        gap: 12px;
        .title {
          flex: 1 0 0;
          text-align: center;
          font-size: 48px;
          font-style: normal;
          font-weight: 600;
          line-height: 60px;
        }
        .desc {
          text-align: center;
          font-size: 32px;
          font-style: normal;
          font-weight: 400;
          line-height: 60px; /* 187.5% */
          opacity: 0.8;
        }
      }
      .vat-desc {
        display: flex;
        align-items: flex-start;
        gap: 12px;
        opacity: 0.8;

        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px;

        svg {
          display: flex;
          width: 24px;
          height: 36px;
          padding: 6px 0px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .exclusive {
      display: flex;
      padding: 0 20px 20px 20px;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      align-self: stretch;

      .exclusive-title {
        color: var(--primary-500, #41996a);
        text-align: center;

        /* Title 4 - SemiBold, 14, 20 */
        font-family: pretendard;
        font-size: 28px !important;
        font-style: normal;
        font-weight: 600;
        line-height: 40px; /* 142.857% */
      }

      .exclusive-desc1 {
        color: #fff;
        text-align: center;

        /* Headline 2 - SemiBold, 24, 30 */
        font-family: pretendard;
        font-size: 46px;
        font-style: normal;
        font-weight: 600;
        line-height: 60px; /* 125% */
      }

      .exclusive-desc2 {
        color: #fff;
        text-align: center;
        font-feature-settings: 'liga' off, 'clig' off;

        /* Body 2 - Regular, 14, 20 */
        font-family: pretendard;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px; /* 142.857% */
      }

      .exclusive-li {
        display: flex;
        margin-top: 12px;
        padding: 0px 4px;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        align-self: stretch;

        color: var(--white, #fff);
        font-feature-settings: 'liga' off, 'clig' off;

        /* Body 2 - Regular, 14, 20 */
        font-family: pretendard;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px; /* 142.857% */
      }
    }

    .checklist {
      display: flex;
      padding: 0px 4px;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      align-self: stretch;

      .listitem {
        display: flex;
        align-items: flex-start;
        gap: 12px;

        font-feature-settings: 'liga' off, 'clig' off;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px; /* 142.857% */

        svg {
          display: flex;
          width: 28px;
          height: 40px;
          padding: 6px 0px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .invite-code {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      align-self: stretch;

      & > h5 {
        color: var(--secondary-dc-beige, #ddc5a4);
        text-align: center;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 40px;
      }

      & > h3 {
        text-align: center;
        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        line-height: 56px;
        align-self: stretch;
      }

      & > h4 {
        text-align: center;
        font-feature-settings: 'liga' off, 'clig' off;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px;
        align-self: stretch;
      }
    }
    .invite-desc {
      display: flex;
      padding: 0px 4px;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      align-self: stretch;

      & > .listItem {
        display: flex;
        align-items: flex-start;
        gap: 12px;
        align-self: stretch;

        opacity: 0.8;

        div {
          width: 24px;
          height: 36px;
          padding: 6px 0px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          svg {
            display: flex;
            width: 24px;
            height: 24px;
            flex-shrink: 0;
          }
        }

        span {
          color: var(--white, #fff);
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 36px;
          flex: 1 0 0;
        }
      }
    }
  }

  .divider {
    height: 2px;
    align-self: stretch;
    opacity: 0.1;
    background: #fff;
  }

  .notice-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    .title {
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: 40px; /* 142.857% */
    }
    .listitem {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;

      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px;

      span {
        flex: 1 0 0;
      }
    }
  }
`;
