import { useQuery } from 'react-query';

import { eggdiningApi } from '@/shared/apis/eggdiningApi';
import MEMBERSHIP_QUERY_KEYS from '@/shared/apis/queryKeys/membership';
import { IMembershipPrices } from '@/shared/types/PaymentMethod.type';

// 가격정보 조회
const useMembershipSubscriptionPrices = () => {
  return useQuery(useMembershipSubscriptionPrices.getKeys(), () => eggdiningApi.get<IMembershipPrices>('/eggdining/membership-price'), {
    onError: (error) => {
      console.log(error);
    },
  });
};
useMembershipSubscriptionPrices.getKeys = () => [MEMBERSHIP_QUERY_KEYS.GET_MEMBERSHIP_PRICE];

export { useMembershipSubscriptionPrices };
