import React from 'react';

import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

export const WithdrawalContainer = styled.div<React.CSSProperties>`
  padding: 80px 32px;
  .withdrawal-header {
    padding-top: 10px;
    text-align: center;
    margin-bottom: 120px;
    .title {
      margin-bottom: 20px;
      ${Typography.title_44}
      color: ${Colors.white}
    }
    .description {
      margin-top: 36px;
      ${Typography.subtitle_m}
      color: ${Colors.white};
      line-height: 45px !important; /* 142.857% */
    }
  }

  .depth-line {
    width: 100%;
    height: 2px;
    opacity: 0.1;
    background: #fff;
    margin-bottom: 50px;
  }
  .withdrawal-instructions {
    .title {
      margin-bottom: 40px;
      ${Typography.title_36_sb}
    }
    .content-wrapper {
      margin-bottom: 65px;
      ${Typography.body_26}
      > div {
        display: flex;
        margin-bottom: 36px;
        span {
          margin-right: 12px;
        }
      }
    }
    .checkbox-wrapper {
      margin-bottom: 121px;
      .checkbox-coment {
        margin-left: 14px;
        ${Typography.body_26_sb}
        color: ${Colors.white}
      }
    }
  }
  .bottom {
    > button {
      width: 100%;
      &:first-of-type {
        margin-bottom: 20px;
      }
    }
  }
`;
