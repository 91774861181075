import React from 'react';

import styled from '@emotion/styled';

import { Typography } from '@/shared/styles/typography';

export const InputHintContainer = styled.section<React.CSSProperties>`
  margin: 20px 0 0;
  .hint-text {
    color: #ff471e;
    ${Typography.small_2}
  }
`;
