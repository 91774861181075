/* eslint-disable no-undef */
import React, { useEffect, useRef } from 'react';

import { IKakaoMap } from './KakaoMap.types';

import { KakaoMapContainer } from '@/components/KakaoMap/KakaoMap.styles';

// address 필요 없음
const KakaoMap = ({ lat, lng, className, address }: IKakaoMap): React.ReactElement => {
  const mapRef = useRef(null);

  function mapClickEvent() {
    // const latlng = [lat, lng].join();
    // if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    //   window.location.href = `kakaomap://look?p=${latlng}`;
    // } else {
    //   window.open(`https://map.kakao.com/link/to/${latlng}`, '_blank');
    // }
  }
  useEffect(() => {
    if (mapRef.current && typeof kakao !== 'undefined') {
      kakao.maps.load(() => {
        const options = {
          center: new kakao.maps.LatLng(lat, lng),
          level: 1,
          draggable: false,
          disableDoubleClickZoom: true,
          disableDoubleClick: true,
        };
        const map = new window.kakao.maps.Map(mapRef.current, options);
        const marker = new kakao.maps.Marker({
          map: map,
          position: new kakao.maps.LatLng(lat, lng),
        });
        marker.setMap(map);
      });
    }
  }, [lat, lng]);
  if (typeof kakao === 'undefined') {
    return null;
  }
  return (
    <div onClick={mapClickEvent}>
      <KakaoMapContainer className={className ?? null} ref={mapRef} />
    </div>
  );
};

export default KakaoMap;
