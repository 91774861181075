import React, { useEffect, useId } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import styled from '@emotion/styled';

import Address from '@/components/Address/Address';
import Button from '@/components/Button/Button';
import { ButtonContainer, ButtonContainerArea } from '@/components/Button/Button.styles';
import CompleteHeader from '@/components/CompleteHeader/CompleteHeader';
import CompleteReservationInfo from '@/components/CompleteReservationInfo/CompleteReservationInfo';
import MainSubtitle from '@/components/MainSubtitle/MainSubtitle';
import Modal from '@/components/Modal/Modal';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import RefundPolicy from '@/components/RefundPolicy/RefundPolicy';
import Seperator from '@/components/Seperator/Seperator';
import ToastPortal from '@/components/ToastPortal/ToastPortal';
import UserInfo from '@/components/UserInfo/UserInfo';
import { useReservationHistoryDetailData } from '@/hooks/reservationHistory.hook';
import { copyMessage, makeShareText } from '@/shared/libs/function/shreText';
import { Colors, Typography } from '@/shared/styles';
import { useFooterStore } from '@/stores/common/useFooterStore';
import { useHeaderStore } from '@/stores/common/useHeaderStore';
import { useModalStore } from '@/stores/common/useModalStore';
import { useNavigationStore } from '@/stores/common/useNavigationStore';

const BookingStepCompleteviewEmotion = styled.div<React.CSSProperties>`
  display: flex;
  flex-direction: column;
  /* gap: 96px; */
  width: 100%;
  padding: 80px 0;
  height: calc(100% - 104px - 96px);
  overflow: auto;
  flex-wrap: nowrap;
  .refund-policy-container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 0 32px;
    ${Typography.body_36_sb};
  }
`;

const BookingStepCompleteviewContents = styled.div<React.CSSProperties>`
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: 0px;
`;

const ButtonFooterContainer = styled.div<React.CSSProperties>`
  position: sticky;
  bottom: 0;
  display: flex;
  flex-direction: row;
  z-index: 1;
  gap: 24px;
  padding: 24px 24px;

  border-top: 2px solid var(--white-10, rgba(255, 255, 255, 0.1));
  background: var(--gray-700, #3b3b39);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.2), 0px -4px 10px 0px rgba(0, 0, 0, 0.15);

  & > button.footer-button {
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;

    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 44px; /* 137.5% */
    &:nth-of-type(1) {
      color: var(--gray-900, #14120e);
      height: 95px;
    }
    &:nth-of-type(2) {
      flex: 1 0 0;
      height: 95px;
    }
  }
  /* background: rgba(59, 59, 57, 0.8);
  backdrop-filter: blur(25px); */

  ${ButtonContainerArea} {
    width: auto;
    gap: 24px;
    padding: 0;
    background: none;
    backdrop-filter: none;
    &:nth-of-type(2) {
      flex: 1 0 0;
    }

    ${ButtonContainer} {
      padding: 0 40px;
    }
  }
`;

const AddressWrapper = styled.section`
  width: 100%;
  padding: 0 32px 96px;
  /* padding-bottom: 96px; */
  /* padding-inline: 32px; */
  /* margin-bottom: 100px; */
`;

const BookingStepCompleteview = (): React.ReactElement => {
  const navigate = useNavigate();
  const shareReservationHistoryKey = useId();
  const successCopyTextKey = useId();
  const { toastList, setToastList, setAlertModal } = useModalStore();
  const { reservationId } = useParams();

  const { data: completeDetailData, isLoading } = useReservationHistoryDetailData(reservationId);

  const setIsNavigationVisible = useNavigationStore((store) => store.setIsNavigationVisible);
  const setIsFooterVisible = useFooterStore((store) => store.setIsFooterVisible);
  const setHeaderState = useHeaderStore((store) => store.setHeaderState);

  useEffect(() => {
    setIsNavigationVisible(false);
    setIsFooterVisible(false);
    setHeaderState({
      title: '예약 완료',
      handleGoToBack: () => navigate('/main', { replace: true }),
      handleGoToHome: null,
      handleOnAlarm: null,
      handleOnClose: null,
      handleOnShare: () => {
        setAlertModal({ visible: true, key: shareReservationHistoryKey });
      },
    });
  }, []);

  if (isLoading) {
    return null;
  }
  const { reservation, store, visitor } = completeDetailData;

  makeShareText({ reservation, store, visitor });

  return (
    <>
      <BookingStepCompleteviewEmotion>
        <CompleteHeader />
        <Seperator />
        <BookingStepCompleteviewContents>
          <CompleteReservationInfo reservation={reservation} name={store.name} />
          <UserInfo reservation={reservation} visitor={visitor} />
          <AddressWrapper>
            <Address store={store} />
          </AddressWrapper>
          <div className="refund-policy-container">
            <h4>취소 수수료 안내</h4>
            {/* <MainSubtitle subtitle="취소 수수료 안내" /> */}
            <RefundPolicy policies={store.depositRefunds} />
          </div>
        </BookingStepCompleteviewContents>
      </BookingStepCompleteviewEmotion>
      <ButtonFooterContainer>
        <Button colorLevel="white" type="large" classKey="footer-button" onClick={() => navigate('/main', { replace: true })}>
          홈으로
        </Button>
        <Button colorLevel="primary" type="large" classKey="footer-button" onClick={() => navigate('/reservation/history')}>
          예약 내역 확인
        </Button>
      </ButtonFooterContainer>
      <ModalPortal>
        <Modal.Alert key={shareReservationHistoryKey} modal="alert" isDim={true} isAnimation={true}>
          <section className="content-wrapper">
            <h2 className="title">예약 내역을 공유하시겠습니까?</h2>
            {/* <p className="desc">
              예약 내역을 다른 사람에게 <br />
              공유하시겠습니까?
            </p> */}
          </section>
          <section className="button-wrapper">
            <button className="white" onClick={() => setAlertModal({ visible: false, key: shareReservationHistoryKey })}>
              아니요
            </button>
            <button
              onClick={() => {
                setAlertModal({ visible: false, key: shareReservationHistoryKey });
                copyMessage();
                setToastList([...toastList, { visible: true, key: successCopyTextKey }]);
              }}
            >
              내용 복사
            </button>
          </section>
        </Modal.Alert>
      </ModalPortal>
      <ToastPortal>
        <Modal.Toast key={successCopyTextKey} toastKey={successCopyTextKey} modal="toast" autoHideDuration={3000} isAnimation={true} position="bottom">
          <div>텍스트가 복사되었습니다</div>
        </Modal.Toast>
      </ToastPortal>
    </>
  );
};

export default BookingStepCompleteview;
