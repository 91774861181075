import * as React from 'react';
import { Controller } from 'react-hook-form';

import CheckBox from '@/components/Raffle/Apply/CheckBox/CheckBox';
import { IMypageEditAgreeTerms } from '@/shared/types/common.types';

export const CustomControlledCheckbox: React.FC<IMypageEditAgreeTerms> = ({ preventChange, ...props }: any) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      render={({ field: { onChange, name: fieldName, value }, fieldState: { error } }) => {
        return (
          <CheckBox
            {...props}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (preventChange) {
                return preventChange(e);
              }

              onChange(e);
              return props.onChange && props.onChange(e);
            }}
            name={fieldName}
            checked={value ?? ''}
          />
        );
      }}
    />
  );
};
