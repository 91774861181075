import React from 'react';

import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

export const PaymentTitle = styled.h1`
  ${Typography.title_36_sb};
  color: ${Colors.white};
`;

export const CardPaymentInfoContainer = styled.main<React.CSSProperties>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 80px 32px;

  > div {
    padding-top: 40px;

    &:not(&:first-of-type) {
      .input-wrapper {
        max-width: 320px;
      }
    }
  }
`;

export const ButtonWrapper = styled.div`
  position: sticky;
  bottom: 0;
  div {
    color: ${Colors.white};
  }
`;
