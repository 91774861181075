import React from 'react';

import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

export const VisitCompletedContainer = styled.div<React.CSSProperties>`
  display: flex;
  padding: 32px;
  flex-direction: column;
  gap: 32px;
`;
export const VisitCompletedItemContainer = styled.div<React.CSSProperties>`
  display: flex;
  gap: 32px;
  align-items: center;
  & > div:first-of-type {
    width: 192px;
    height: 192px;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    border: 2px solid rgba(255, 255, 255, 0.1);
    opacity: 0.4;

    img {
      max-width: 100%;
      min-height: 100%;
    }
    & > span {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      ${Typography.body_28_m};
    }
  }

  .visit-completed {
    background: var(--gray-90040, rgba(20, 18, 14, 0.4));
    align-self: flex-start;
    opacity: 1;
    border-radius: 12px;
    padding: 2px 12px;
    /*max-width : 60px;*/
    justify-content: center;
    gap: 8px;
    text-align: center;

    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 150% */
  }
  & > div:last-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    & > h3 {
      overflow: hidden;
      text-overflow: ellipsis;

      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 48px;
    }
    h4 {
    }
    & > span {
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px;
      opacity: 0.8;
    }
  }
`;
