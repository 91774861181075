import React from 'react';

import Radio from '../Radio/Radio';
import { IRadioGroup } from '../Radio/Radio.types';
import { IRadioGroupProps } from './RadioGroup.types';

const RadioGroup = ({ ...props }: IRadioGroupProps): React.ReactElement => {
  return (
    <div className={props.className}>
      {props.items.map((item: IRadioGroup) => {
        return (
          <React.Fragment key={item.id}>
            <Radio className="radio-custom" name={props.name} value={item.value} selected={props.selected} onChange={props.onChange} disabled={item.disabled ?? null}>
              {item.render && item.render()}
            </Radio>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default RadioGroup;
