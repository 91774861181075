import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { eggdiningApi } from '@/shared/apis/eggdiningApi';
import MEMBERSHIP_QUERY_KEYS from '@/shared/apis/queryKeys/membership';
import { LocalStorage } from '@/shared/configs/storage';
import { useUserProfileStore } from '@/stores/useUserProfle';

const useMembershipCancel = () => {
  const memberId = useUserProfileStore((store) => store.memberId) || LocalStorage.getItem('memberId');
  const navigate = useNavigate();

  return useMutation(() => useMembershipCancel.cancelMembership(memberId), {
    onSuccess: () => {
      navigate('/membership/cancel-success');
    },
  });
};

useMembershipCancel.getKeys = () => MEMBERSHIP_QUERY_KEYS.DELETE_USER_MEMBERSHIP;
useMembershipCancel.cancelMembership = (memberId) => {
  return eggdiningApi.mutation({
    operation: 'delete',
    url: `/eggdining/users/${memberId}/membership`,
  });
};

export { useMembershipCancel };
