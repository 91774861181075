import React from 'react';

import styled from '@emotion/styled';

import { Colors } from '@/shared/styles/colors';
import { Typography } from '@/shared/styles/typography';

export const UserInfoForm = styled.div<React.CSSProperties>`
  margin: 96px 32px 76px 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;

  .input-label {
    /* margin-bottom: 8px;
    ${Typography.small_2}
    .firebrick {
      color: ${Colors.red2};
    } */
  }

  .input-box {
    width: 100%;
    position: relative;
    margin-top: 8px;

    &:not(&:first-of-type) {
      margin-top: 0px;
    }

    .input-wrapper {
      /* width: 100%; */
    }

    /* input {
      box-sizing: border-box;
      width: 100%;
      border-radius: 4px;
      outline: none;
      ${Typography.body_28_s}
      border: none;
      &.error {
        border: 2px solid ${Colors.red2};
      }
      &:focus {
        border: none;
        & + .clear-button {
          opacity: 1;
        }
      }
    } */
    .phone-change-button {
      position: absolute;
      top: 55px;
      right: 28px;
      height: 56px;
      padding: 0px 20px;
      /* justify-content: center; */
      /* align-items: center; */
      gap: 8px;
      background: ${Colors.gray11_opacity40};
      ${Typography.small_3}
      line-height: 56px;
      border-radius: 6px;
      /* 




      position: absolute;
      top: 58px;
      right: 28px;
      padding: 12px 28px;
      border: 2px solid #f6c944;
      border-radius: 48px;
      color: black;
      ${Typography.body_28} */
    }
    .clear-auth {
      /* border: 2px solid ${Colors.gray4}; */
      &:disabled {
        /* background: ${Colors.white}; */
      }
    }
  }
  .phone-auth-button {
    width: 243px;
    padding: 0 !important;
    border-radius: 4px;
    margin-left: 24px;
    &.error {
      margin-bottom: 42px;
    }
  }

  input:disabled {
    /* background: rgba(239, 239, 239, 0.3); */
  }
  .phone-auth-box {
    display: flex;
    align-items: flex-end;
  }
`;

interface IEmailNotice extends React.CSSProperties {
  textLength?: number;
}

export const EmailNotice = styled.p<IEmailNotice>`
  display: ${(props) => (props.textLength ? 'none' : '')};
  position: relative;
  top: -25px;
  opacity: 0.6;
  ${Typography.small_3};

  & > svg {
    margin-right: 8px;
    position: relative;
    top: 3px;
  }
`;
