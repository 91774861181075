import 'dayjs/locale/ko';

import React from 'react';
import { useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';

import { VisitScheduledContainer, VisitScheduledItemContainer } from '@/components/VisitScheduled/VisitScheduled.styles';
import { goToDetailPage } from '@/hooks/reservationHistory.hook';
import { IReservationHistoryItem, IReservationHistoryItems } from '@/shared/types/ReservationHistory.types';

dayjs.locale('ko');

const VisitScheduled = ({ items }: IReservationHistoryItems): React.ReactElement => {
  const navigate = useNavigate();

  return (
    <VisitScheduledContainer>
      {items.map((item: IReservationHistoryItem) => {
        const { store, reservation } = item;
        const today = dayjs(Date.now()).format('YYYY-MM-DD');
        const visitDiff = dayjs(today).diff(reservation.visitAt, 'day') === 0 ? 'D-DAY' : `D${dayjs(today).diff(reservation.visitAt, 'day')}`;
        const visitDetail = `${dayjs(reservation.visitAt).format('YYYY.MM.DD(dd) ∙ A h:mm ∙ ')}${reservation.totalPartySize}명`;

        return (
          <VisitScheduledItemContainer key={reservation.id} onClick={() => goToDetailPage(navigate, reservation.id)}>
            <div className="img-wrapper">
              <img src={store.imageUrl} alt={`${store.name}이미지`} />
            </div>
            <div className="text-wrapper">
              <h4 className="reserve-diff">{visitDiff}</h4>
              <h3>{store.name}</h3>
              <span>{visitDetail}</span>
            </div>
          </VisitScheduledItemContainer>
        );
      })}
    </VisitScheduledContainer>
  );
};

export default VisitScheduled;
