import React from 'react';

import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

export const HowParticipateContainer = styled.div<React.CSSProperties>`
  padding: 64px 32px;
  .how-participate-title {
    margin-bottom: 42px;
    ${Typography.title_36_sb}
    color: ${Colors.gray9}
  }
  & .how-participate-wrapper {
    ${Typography.body_28}
    color: ${Colors.gray8};
    margin-bottom: 80px;
    > div {
      display: flex;
      align-items: center;
      margin-bottom: 28px;
    }
    span {
      font-weight: 600;
    }
    b {
      margin-right: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 6px 18px;
      gap: 10px;

      width: 40px;
      height: 40px;

      background: #f6c944;
      border-radius: 24px;
      font-weight: 600;
      font-size: 22px;
      line-height: 30px;
      /* identical to box height, or 136% */

      color: #ffffff;
    }
    strong {
      font-weight: 600;
    }
  }
  .guide {
    padding: 24px 32px;
    margin-bottom: 100px;
    background-color: ${Colors.gray1};
    .title {
      margin-bottom: 24px;
      ${Typography.body_28_sb}
      color: ${Colors.blue}
    }
    .desc {
      ${Typography.small_1}
      color: ${Colors.gray8}
    }
  }
  .guide-line-precaution-wrapper {
    .title {
      margin-bottom: 40px;
      ${Typography.title_36_sb}
      color: ${Colors.gray9}
    }
    .desc {
      & > div {
        display: flex;
        margin-bottom: 36px;
        ${Typography.body_26}
        > div {
          color: ${Colors.gray8};
        }
        > p {
          margin-right: 12px;
        }
      }
    }
  }
`;
