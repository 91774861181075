import React from 'react';

import styled from '@emotion/styled';

import { Colors } from '@/shared/styles';
import { Typography } from '@/shared/styles/typography';

export const BookingAgreeCheckboxContainer = styled.section<React.CSSProperties>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .expand {
    color: ${Colors.white};
    display: flex;
    align-items: center;
    /* padding-right: 64px; */
    .expand-icon {
      width: 24px;
      height: 40px;
    }
  }
  .checkbox-label {
    margin-left: 16px;
    ${Typography.body_26_s}
    /* ${Typography.body_28_s} */

    span:nth-of-type(2) {
      opacity: 0.7;
    }
  }
`;
