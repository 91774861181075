import dayjs, { Dayjs } from 'dayjs';
import create from 'zustand';

import { TIcon } from '@/components/FacilityViewer/FacilityViewer.types';
import { Nullable } from '@/shared/types/common.types';
import { IStoreState } from '@/shared/types/MallList.type';

interface IPaymentInfo {
  type: string;
}
export interface IMall {
  storeUri: string;
  storeName: string;
  storeImages: string[];
  storeFoods: string[];
}

export interface IMallMain extends IMall {
  isReservable: boolean;
  storeState: IStoreState;
}

export interface MallAddress {
  road: string;
  build: string;
  jibun: string;
  extra: string;
  zipCode: string;
  lat: number;
  lng: number;
}

export interface MallOpenTime {
  target: string;
  type: string;
  start: number;
  end: number;
  etc: string;
}

export interface MallMenu {
  menuName: string;
  price: number;
  image: string;
}

export interface MallMebershipPeriod {
  current: {
    status: 'SOON' | 'SERVE';
    openDateTime: dayjs.Dayjs;
    closeDateTime: dayjs.Dayjs;
    reserveOpenDateTime: dayjs.Dayjs;
    reserveCloseDateTime: dayjs.Dayjs;
    commonOpenTime: dayjs.Dayjs | null;
  };
  storeWillBeOpendList: {
    membershipGrade: string;
    openStartDate: dayjs.Dayjs;
  }[];
}

export interface IMallDetail extends IMall {
  storeDescription: string;
  storeUsingGuide: string;
  storeInformation: string;
  storeContact: string;
  storeFacilities: TIcon[];
  storeAddress: MallAddress;
  storeOpenTimes: MallOpenTime[];
  storeMenus: MallMenu[];
  storeHasDeposit: boolean;
  storeHasMembershipService: boolean;
  storeMembershipPeriod: MallMebershipPeriod;
  storePartySize: {
    min: number;
    max: number;
  };
  storeState: IStoreState;
}

export interface IMallListResults {
  result: boolean;
  stores: IMallMain[];
}

export interface IMallDetailResults {
  store: IMallDetail;
}

export interface IPreReservationInfo {
  token: string;
  paymentAmount: number;
  expireTimestamp: number;
}

export interface IInfoAdditionalValidation {
  name: string;
  isValid: boolean;
  isEnabled: boolean;
}
export interface IInfoAdditional {
  name: string;
  kind: 'select' | 'text' | 'toggle' | 'count';
  choices: string[];
  description: string;
  validationMessage: string;
  isMulitiple: boolean;
  isEnabled: boolean;
  isRequired: boolean;
  validations: IInfoAdditionalValidation[];
}

export interface IInfoAdditionalValue extends IInfoAdditional {
  value: any;
}

export interface IPartyPolicy {
  name: string;
  description: string;
  enabled: boolean;
  isBlocked: boolean;
  blockMessage: string;
}

export interface IDepositRefundPolicy {
  percent: number;
  dateType: number;
  dateValue: number;
  refundable: boolean;
}

export interface IReservationStoreInfo {
  name: string;
  additionalInfo: IInfoAdditional[];
  partyPolicies: IPartyPolicy[];
  depositRefundPolicies: IDepositRefundPolicy[];
}
export interface IMakePreReservationResult {
  preReservation: IPreReservationInfo;
  store: IReservationStoreInfo;
}

export interface IFetchPreReservationInfo {
  date: string;
  expireDate: number;
  time: number;
  paymentAmount: number;
  totalPartySize: number;
}

export interface IFetchPreReservationResult {
  preReservation: IFetchPreReservationInfo;
  store: IReservationStoreInfo;
}

export interface IDetailParty {
  name: string;
  size: number;
}

export interface IPreAdditionalInfo {
  name: string;
  value: string[] | string | boolean;
}

export interface IConfirmPreReservationInfo {
  paymentAmount: number;
  visitorUserName: string;
  visitorPhoneToken: string;
  visitorPhoneCode: string;
  email: string;
  totalPartySize: number;
  partyDetail: IDetailParty[];
  additionalInfo: IPreAdditionalInfo[];
  menuInfo: any[];
  request: string;
  tempReservationToken: string;
  reservationType?: string;
}

export interface IResponsePutPrepayment {
  pg: string;
  merchantId: string;
  merchantUid: string;
  buyerName: string;
  buyerPhone: string;
  buyerEmail: string;
  paymentAmount: number;
  paymentName: string;
  paymentCallbackUrl: string;
}

export interface IConfirmReservationResult {
  reservationId: number;
  paymentAmount: number;
}

export interface ISubscriptionConfirmReservationResult {
  merchantUid: string;
}

export interface IUseBookingStore {
  mall: Nullable<IMallDetail>;
  setMall: (mall: IMallDetail) => void;
  storeUri: string;
  setStoreUri: (storeUri: string) => void;
  reservationInfo: Nullable<IReservationStoreInfo>;
  setReservationInfo: (reservationInfo: IReservationStoreInfo) => void;
  reservationId: string;
  setReservationId: (reservationId: any) => void;
  now: Nullable<Dayjs>;
  setNow: (now?: Nullable<Dayjs>) => void;
  clearTiktokIntervalTrigger: any;
  setClearTiktokIntervalTrigger: (clearTiktokIntervalTrigger?: any) => void;
  paymentInfo: IPaymentInfo;
  dispatchPaymentInfo: (action: any) => void;
  showPaymentForm: boolean;
  setShowPaymentForm: (props) => void;
}
export interface User {
  name: string;
  phoneNumber: string;
  email: string;
  token?: string;
  message?: string;
}
// export interface Agreement {
//   cardSave: boolean;
// }
export interface Payment {
  type: string;
}

const INITIAL_PAYMENT_INFO: IPaymentInfo = {
  type: 'PG',
};

const INITAL_MALL_DETAIL: IMallDetail = {
  storeUri: '',
  storeName: '',
  storeContact: '',
  storeImages: [],
  storeFoods: [],
  storeDescription: '',
  storeUsingGuide: '',
  storeInformation: '',
  storeFacilities: [],
  storeAddress: {
    road: '',
    build: '',
    jibun: '',
    extra: '',
    zipCode: '',
    lat: 0,
    lng: 0,
  },
  storeOpenTimes: [],
  storeMenus: [],
  storeHasDeposit: true,
  storeHasMembershipService: true,
  storeMembershipPeriod: {
    current: {
      status: 'SOON',
      openDateTime: dayjs(),
      closeDateTime: dayjs(),
      reserveOpenDateTime: dayjs(),
      reserveCloseDateTime: dayjs(),
      commonOpenTime: null,
    },
    storeWillBeOpendList: [],
  },
  storePartySize: {
    min: 2,
    max: 10,
  },
  storeState: '05',
};
interface IPaymentInfoReducerAction {
  type: 'CHANGE_PAYMENT_TYPE';
  value: any;
  index: number;
}
const paymentInfoReducer = (state: IPaymentInfo, action: IPaymentInfoReducerAction) => {
  const { value, index } = action;

  switch (action.type) {
    case 'CHANGE_PAYMENT_TYPE':
      return { ...state, type: value };
    default:
      return state;
  }
};

export const useBookingStore = create<IUseBookingStore>((set, get) => ({
  mall: INITAL_MALL_DETAIL,
  setMall: (mall) => set(() => ({ mall })),
  storeUri: null,
  setStoreUri: (storeUri) => set(() => ({ storeUri })),
  reservationId: null,
  setReservationId: (reservationId) => set(() => ({ reservationId })),
  reservationInfo: { partyPolicies: [], additionalInfo: [], depositRefundPolicies: [], name: null },
  setReservationInfo: (reservationInfo) => set(() => ({ reservationInfo })),
  now: null,
  setNow: (now = null) => set(() => ({ now })),
  clearTiktokIntervalTrigger: null,
  setClearTiktokIntervalTrigger: (clearTiktokIntervalTrigger = null) => set(() => ({ clearTiktokIntervalTrigger })),
  paymentInfo: INITIAL_PAYMENT_INFO,
  dispatchPaymentInfo: (action: any) => set(({ paymentInfo }) => ({ paymentInfo: paymentInfoReducer(paymentInfo, action) })),
  showPaymentForm: false,
  setShowPaymentForm: (props: any) => set(() => ({ showPaymentForm: props })),
}));
