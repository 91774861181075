import React from 'react';
import { useFormContext } from 'react-hook-form';

import { useMembershipSubscriptionPrices } from './ChooseSubscription.hooks';

import { ChooseSubscriptionContainer, Saving, SubscriptionContent, Title } from '@/components/ChooseSubscription/ChooseSubscription.styles';
import { ControlledRadioGroup } from '@/shared/hook-form/ControlledRadioGroup';
import { AddComma } from '@/shared/libs/functions';

const ChooseSubscription = (): React.ReactElement => {
  const { control } = useFormContext();
  const { data: membershipPriceData, isLoading } = useMembershipSubscriptionPrices();

  if (isLoading) {
    return null;
  }

  const sortMembershipPrices = membershipPriceData.prices.sort((a, b) => Number(a.subscriptionType) - Number(b.subscriptionType)).filter((price) => price.isUse !== false);
  const RADIO_CONTENTS = sortMembershipPrices?.map((price, index) => {
    return {
      id: price.id,
      value: price.subscriptionType,
      render: () => (
        <>
          <SubscriptionContent>
            <h5>{['01', '03'].includes(price.subscriptionType) ? '연간구독' : '월간구독'}</h5>
            <div>
              {AddComma(price.actualPrice)}/{['01', '03'].includes(price.subscriptionType) ? '년' : '월'} (VAT 포함)
            </div>
            {['01', '03'].includes(price.subscriptionType) ? (
              <>
                <div>({price.actualPrice / 12}원/월)</div>
              </>
            ) : null}
          </SubscriptionContent>
          {price.isUse ? (
            price.discountRate !== 0 && (
              <Saving isSave>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 12 12" fill="none">
                  <g clip-path="url(#clip0_1121_4157)">
                    <path
                      d="M1.35359 5.44135L0.646484 6.14846L6.00004 11.502L11.3536 6.14846L10.6465 5.44135L6.50003 9.58781V0.794922L5.50003 0.794922V9.58779L1.35359 5.44135Z"
                      fill="black"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1121_4157">
                      <rect width="12" height="12" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                {AddComma(price.fixedPrice - price.actualPrice)}원 절약
              </Saving>
            )
          ) : (
            <Saving>오픈 예정</Saving>
          )}
        </>
      ),
      disabled: !price.isUse,
    };
  });

  return (
    <ChooseSubscriptionContainer>
      <Title>
        Green Club에 가입하기 위한
        <br />
        마지막 단계입니다.
      </Title>
      {RADIO_CONTENTS && <ControlledRadioGroup className="radio-group" control={control} name="chooseSubscription" items={RADIO_CONTENTS} />}
    </ChooseSubscriptionContainer>
  );
};

export default ChooseSubscription;
