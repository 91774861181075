import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { IEntryInfo } from './Complete.types';

import { eggdiningApi } from '@/shared/apis/eggdiningApi';
import RAFFLE_QUERY_KEYS from '@/shared/apis/queryKeys/raffle';
import { useFooterStore } from '@/stores/common/useFooterStore';
import { useHeaderStore } from '@/stores/common/useHeaderStore';
import { useNavigationStore } from '@/stores/common/useNavigationStore';

export interface IEntryWar {
  entry: IEntryInfo;
}

const useInitComplete = (eventType) => {
  const setIsFooterVisible = useFooterStore((store) => store.setIsFooterVisible);
  const setIsNavigationVisible = useNavigationStore((store) => store.setIsNavigationVisible);
  const setHeaderState = useHeaderStore((store) => store.setHeaderState);
  const navigate = useNavigate();

  useEffect(() => {
    setIsFooterVisible(false);
    setIsNavigationVisible(false);
    setHeaderState({
      title: '응모내역 확인',
      handleGoToBack: null,
      handleGoToHome: null,
      handleOnAlarm: null,
      handleOnClose: () => {
        navigate('/main');
        navigate(`/event/egg/raffle?eventType=${eventType}`);
      },
      handleOnShare: null,
    });
  }, []);
};

const useGetRaffleData = () => {
  const [searchParams] = useSearchParams();
  const eventType = searchParams.get('eventType');

  return useQuery(useGetRaffleData.getQueryKey(), () => useGetRaffleData.getRaffleMyEntryQuery(eventType), {});
};

useGetRaffleData.getQueryKey = () => RAFFLE_QUERY_KEYS.GET_USER_RAFFLE;
useGetRaffleData.getRaffleMyEntryQuery = (eventType: string) => {
  return eggdiningApi.get<IEntryWar>(`/eggdining/raffles/${eventType}/entry/my`);
};

export { useInitComplete, useGetRaffleData };
