import create from 'zustand';

export interface IEggLoading {
  isOpenyEggLoading: boolean;
  setOpenEggLoading: (isOpenyEggLoading: boolean) => void;
  isOpenIconLoading: boolean;
  setOpenIconLoading: (isOpenIconLoading: boolean) => void;
}

export const useEggLoadingStore = create<IEggLoading>((set) => ({
  isOpenyEggLoading: false,
  setOpenEggLoading: (isOpenyEggLoading) => set(() => ({ isOpenyEggLoading })),
  isOpenIconLoading: false,
  setOpenIconLoading: (isOpenIconLoading) => set(() => ({ isOpenIconLoading })),
}));
