import React from 'react';

import { PaymentContainer } from './PaymentType.constants';

import MainSubtitle from '@/components/MainSubtitle/MainSubtitle';
import RadioGroup from '@/components/RadioGroup/RadioGroup';
import { useBookingStore } from '@/stores/useBookingStore';

const RADIO_CONTENTS = [
  {
    id: 1,
    value: 'KAKAO',
    render: () => <div className="radio-text">카카오페이</div>,
  },
  {
    id: 2,
    value: 'BILLING_KEY',
    render: () => <div className="radio-text">등록된 카드 결제</div>,
  },
  {
    id: 3,
    value: 'PG',
    render: () => <div className="radio-text">일반 카드 결제</div>,
  },
];

const PaymentType = (): React.ReactElement => {
  const { paymentInfo, dispatchPaymentInfo } = useBookingStore();
  return (
    <>
      <PaymentContainer className="payment-container">
        <MainSubtitle subtitle="결제 수단" />
        <div className="payment-radio-group">
          <RadioGroup
            selected={paymentInfo.type}
            name="payment"
            className="radio-group"
            items={RADIO_CONTENTS}
            onChange={(e) => {
              dispatchPaymentInfo({ type: 'CHANGE_PAYMENT_TYPE', value: e.target.value });
            }}
          />
        </div>
      </PaymentContainer>
    </>
  );
};

export default PaymentType;
