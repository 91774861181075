import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import AdditionalProfile from '@/components/MyPage/AdditionalProfile/AdditionalProfile';
import MemberShip from '@/components/MyPage/Membership/Membership';
import Profile from '@/components/MyPage/Profile/Profile';
import { MyPageContainer } from '@/shared/styles/pages/mypage';
import { useFooterStore } from '@/stores/common/useFooterStore';
import { useNavigationStore } from '@/stores/common/useNavigationStore';

function MyPage() {
  const setIsNavigationVisible = useNavigationStore((store) => store.setIsNavigationVisible);
  const setIsFooterVisible = useFooterStore((store) => store.setIsFooterVisible);
  const navigate = useNavigate();
  const goToProfilePage = () => {
    navigate('/mypage/edit');
  };

  useEffect(() => {
    setIsNavigationVisible(true);
    setIsFooterVisible(false);
  }, []);

  return (
    <MyPageContainer>
      <div className="user-infomation">
        <Profile />
        <MemberShip />
        <div className="edit-info" onClick={goToProfilePage}>
          <div>회원정보 수정</div>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 12 12" fill="none">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.55887 0.558105L3.67499 1.44199L8.23305 6.00005L3.67499 10.5581L4.55887 11.442L10.0008 6.00005L4.55887 0.558105Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className="divider" />
      <AdditionalProfile />
    </MyPageContainer>
  );
}

export default MyPage;
