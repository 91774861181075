import React, { useEffect, useId } from 'react';

import ModalPortal from '../ModalPortal/ModalPortal';

import { GlobalConfirmModalContainer } from '@/components/GlobalConfirmModal/GlobalConfirmModal.styles';
import Modal from '@/components/Modal/Modal';
import { useModalStore } from '@/stores/common/useModalStore';

const GlobalConfirmModal = (): React.ReactElement => {
  const { globalConfirmModal, setAlertModal, setOpenGlobalConfirmModal } = useModalStore();
  const confirmModalId = useId();

  useEffect(() => {
    if (globalConfirmModal.visible) {
      setAlertModal({ visible: true, key: confirmModalId });
    }
  }, [globalConfirmModal.visible]);

  return (
    <GlobalConfirmModalContainer>
      <ModalPortal>
        <Modal.Alert
          key={confirmModalId}
          modal="alert"
          isDim={true}
          isAnimation={true}
          onClose={() => {
            if (globalConfirmModal.closeCallback) {
              globalConfirmModal.closeCallback();
            }
            setOpenGlobalConfirmModal({ ...globalConfirmModal, visible: false });
            setAlertModal({ visible: false, key: confirmModalId });
          }}
        >
          <section className="content-wrapper">
            <h2 className="title">{globalConfirmModal.title}</h2>
            <p className="desc">{globalConfirmModal.message}</p>
          </section>
          <section className="button-wrapper">
            <button
              className="white"
              onClick={() => {
                if (globalConfirmModal.closeCallback) {
                  globalConfirmModal.closeCallback();
                }
                setOpenGlobalConfirmModal({ ...globalConfirmModal, visible: false });
                setAlertModal({ visible: false, key: confirmModalId });
              }}
            >
              {globalConfirmModal.closeText}
            </button>
            <button
              onClick={() => {
                if (globalConfirmModal.callback) {
                  globalConfirmModal.callback();
                }
                setOpenGlobalConfirmModal({ ...globalConfirmModal, visible: false });
                setAlertModal({ visible: false, key: confirmModalId });
              }}
            >
              {globalConfirmModal.okayText}
            </button>
          </section>
        </Modal.Alert>
      </ModalPortal>
    </GlobalConfirmModalContainer>
  );
};

export default GlobalConfirmModal;
