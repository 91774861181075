import React from 'react';

import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

export const FooterContainer = styled.footer<React.CSSProperties>`
  display: flex;
  flex-direction: column;
  gap: 46px;
  padding: 56px 26px;
  /* background: ${Colors.gray1}; */
  background: #14120e66;
  margin-top: 80px;
  /* margin-bottom: 55px; */
`;

export const TmInfo = styled.section`
  h5,
  div {
    ${Typography.small_2};
    color: ${Colors.white};
    opacity: 0.5;
  }
`;

export const TermsService = styled.section`
  ${Typography.small_1};

  ul {
    display: flex;

    li {
      display: flex;
      align-items: center;
      color: ${Colors.white};
    }

    li:not(li:first-of-type)::before {
      content: '';
      display: inline-block;
      width: 2px;
      min-height: 22px;
      background: ${Colors.gray5};
      margin: 0 24px;
    }
  }
`;

export const SalesRelay = styled.section`
  ${Typography.s_small};
  color: ${Colors.white};
  opacity: 0.5;
`;
