const USER_QUERY_KEYS = {
  GET_USER_PROFILE: 'GET_USER_PROFILE',
  GET_USER_PAYMENT_METHOD: 'GET_USER_PAYMENT_METHOD',
  GET_USER_TERMS: 'GET_USER_TERMS',
  GET_USER_SUBSCRIPTION_INFO: 'GET_USER_SUBSCRIPTION_INFO',
  PUT_USER_TREMS: 'PUT_USER_TREMS',
  PUT_USER_APPLY_MEMBERSHIP: 'PUT_USER_APPLY_MEMBERSHIP',
};

export default USER_QUERY_KEYS;
