import {} from './TableInfoMarkup.constants';
import React from 'react';

import styled from '@emotion/styled';

import { ReactComponent as RedInfoIcon } from '@/assets/img/icon/red_info.svg';
import { Colors } from '@/shared/styles';
import { Typography } from '@/shared/styles/typography';

const RefundPolicyEmotion = styled.div<React.CSSProperties>`
  margin-bottom: 80px;
  & > h3 {
    ${Typography.body_28};
    color: ${Colors.gray8};
    word-break: keep-all;
  }
  & > table {
    width: 100%;
    margin-top: 40px;
    border-collapse: separate;
    border-top: 2px solid ${Colors.gray9};
    border-bottom: 2px solid ${Colors.gray2};
    margin-bottom: 28px;

    thead {
      ${Typography.small_1}
      background: ${Colors.gray0};
      th {
        width: 50%;
        height: 64px;
        vertical-align: middle;
        &:first-of-type {
          border-right: 2px solid ${Colors.gray2};
        }
      }
    }
    tbody {
      ${Typography.small_1}
      td {
        width: 50%;
        height: 64px;
        vertical-align: middle;
        text-align: center;
        border-top: 2px solid ${Colors.gray2};
        &:first-of-type {
          border-right: 2px solid ${Colors.gray2};
        }
      }
    }
  }
  .warning-container {
    display: flex;
    margin-top: 28px;
    .warning-icon {
      margin-right: 16px;
    }
    .warning-desc {
      ${Typography.small_1};
      color: ${Colors.red1};
    }
  }
`;

const TableInfoMarkup = (): React.ReactElement => {
  return (
    <RefundPolicyEmotion>
      {/* <h3 className="cancel-notice">
        예약 후 노쇼로 인한 다른 회원의 이용 제한을 취소화하고 합리적인 예약문화를 정착하고자 취소 수수료 부과 제도를 통한 예약 시스템을 운영하고 있습니다.
      </h3> */}
      <table>
        <tbody>
          <tr>
            <td>스시 사이토(메인 카운터)</td>
            <td>7월 21일(금) 오후 12:00</td>
          </tr>
          <tr>
            <td>덴푸라 모토요시</td>
            <td>7월 22일(토) 오후 8:30</td>
          </tr>
          <tr>
            <td>니혼요리 야마자키</td>
            <td>7월 23일(일) 오후 8:30</td>
          </tr>
        </tbody>
      </table>
      <div className="warning-container">
        <b className="warning-icon">
          <RedInfoIcon />
        </b>
        <div className="warning-desc">일정은 변경이 불가하므로 꼭 확인해 주세요.</div>
      </div>
    </RefundPolicyEmotion>
  );
};

export default TableInfoMarkup;
