import React from 'react';

function ErrorPage() {
  return (
    <div>
      <div>error page</div>
    </div>
  );
}
export default ErrorPage;
