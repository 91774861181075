import create from 'zustand';

export interface IAuthPhoneToken {
  authPhoneToken: string;
  setAuthPhoneToken: (authPhoneToken: string) => void;
}
export const useAuthPhoneTokenStore = create<IAuthPhoneToken>((set) => ({
  authPhoneToken: '',
  setAuthPhoneToken: (authPhoneToken) =>
    set(() => {
      return { authPhoneToken: authPhoneToken };
    }),
}));

export interface IAuthPhoneCheckVerify {
  verifyCode: string;
  setVerifyCode: (verifyCode: string) => void;
}
export const useAuthPhoneTokenCheckVerifyStore = create<IAuthPhoneCheckVerify>((set) => ({
  verifyCode: '',
  setVerifyCode: (verifyCode) =>
    set(() => {
      return { verifyCode: verifyCode };
    }),
}));
