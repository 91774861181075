declare global {
  interface Window {
    Lillo: any;
    LilloBackHook: () => void;
    LilloForwardHook: () => void;
    Eggapp: any;
    webkit: any;
  }
}

interface ILilloSignUp {
  memberId: string;
  email: string;
  name: string;
  phone: string;
}

const useLilloSetPage = (page) => {
  const data = {
    pageUrl: `${process.env.REACT_APP_WEB_URL}${page.pathname}`,
    ref: `${process.env.REACT_APP_WEB_URL}${page.pathname}`,
    pageTitle: document.title,
  };
  window.Lillo?.pageView(data);
};

const useLilloSignUp = (userInfo: ILilloSignUp) => {
  const data = {
    brandUid: userInfo.memberId,
    userLogin: userInfo.memberId,
    userName: userInfo.name,
    userEmail: userInfo.email,
    userPhone: userInfo.phone,
  };
  window.Lillo?.signUp(data);
};

export { useLilloSetPage, useLilloSignUp };
