import React from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import Check from '@/assets/img/icon/checkbox_selected.svg';
import { Colors } from '@/shared/styles';

interface ICheckImg extends React.CSSProperties {
  checkType?: string;
  alignType?: string;
}

export const CheckBoxContainer = styled.div<ICheckImg>`
  input {
    display: none;
  }

  label {
    display: flex;
    align-items: center;
  }

  &:active {
    .check-img-wrapper {
      //background: rgba(246, 201, 68, 0.2);
    }
  }

  .check-img-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 50%;

    width: ${(props) => (props.checkType === 'small' || props.checkType === 'empty' ? '56px' : '80px')};
    height: ${(props) => (props.checkType === 'small' || props.checkType === 'empty' ? '56px' : '80px')};

    margin: ${(props) => (props.checkType === 'small' || props.checkType === 'empty' ? '-4px' : '-16px')};
    ${(props) => (props.alignType === 'fit' && props.checkType === 'small' ? 'margin: -10px' : null)};
    ${(props) => (props.alignType === 'fit' && props.checkType === 'empty' ? 'margin: -21px -17px' : null)};

    .check-img {
      display: block;
      display: flex;
      align-items: center;
      justify-content: center;
      width: ${(props) => (props.checkType === 'small' ? '36px' : '36px')};
      height: ${(props) => (props.checkType === 'small' ? '36px' : '36px')};
      border: 2px solid ${Colors.white};
      border-radius: 4px;

      ${(props) =>
        props.checkType === 'empty' &&
        css`
          width: 36px;
          height: 36px;
          //border: none;
        `}
    }
  }

  input:checked + label .check-img-wrapper .check-img {
    background-image: url(${Check});
    border: none;
    //background: ${(props) => (props.checkType === 'empty' ? 'transparent' : 'var(--green-300, #E8FF68)')};
    /* background: var(--green-300, #e8ff68); */
    /* border: 0px; */
    /* border-radius: 4px; */

    /*
    svg {
        width:24px;
        height:24px;
    }*/

    svg > path {
      //stroke: ${(props) => (props.checkType === 'empty' ? Colors.gray0 : Colors.black)};
      /* stroke: #3b3b39; */
    }
  }
`;
