const RAFFLE_QUERY_KEYS = {
  GET_USER_RAFFLE: 'GET_USER_RAFFLE',
  GET_RAFFLE_DATA: 'GET_RAFFLE_DATA',
  GET_RAFFLE_ENTRY_CHECK: 'GET_RAFFLE_ENTRY_CHECK',
  GET_RAFFLE_ENTRY_RESULT: 'GET_RAFFLE_ENTRY_RESULT',
  GET_USER_RAFFLE_REUSLT: 'GET_USER_RAFFLE_REUSLT',
  GET_RAFFLE_SLOT_LIST: 'GET_RAFFLE_SLOT_LIST',
};

export default RAFFLE_QUERY_KEYS;
