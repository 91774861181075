import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { cloneDeep } from 'lodash';

import CheckBox from '../CheckBox/CheckBox';
import PolicyModal from '../PolicyModal/PolicyModal';
import PolicyModalPortal from '../PolicyModalPortal/PolicyModalPortal';
import { ITermsGroup } from './TermsGroup.types';

import { ReactComponent as RightArrow } from '@/assets/img/icon/rightArrow.svg';
import { TermsGroupContainer, TermsItem, TermsList } from '@/components/TermsGroup/TermsGroup.styles';
import { ControlledCheckbox } from '@/shared/hook-form/ControlledCheckbox';

const TermsGroup = ({ allCheckComment, onChange = () => {}, openServiceTerm, currentPolicy }: ITermsGroup): React.ReactElement => {
  const { getValues, control, setValue } = useFormContext();
  const allChecked = !getValues('agreements')?.some((agreement) => !agreement.value);
  const { pathname } = useLocation();

  const onChangeAllChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    const copyAgreements = cloneDeep(getValues('agreements'))?.map((item) => ({ ...item, value: e.target.checked }));
    setValue('agreements', copyAgreements, { shouldValidate: true, shouldDirty: true });
  };

  const showServiceTerm = (type) => {
    window.history.pushState(null, null, pathname);
    openServiceTerm(type);
  };

  return (
    <TermsGroupContainer className="terms-group-container">
      <div className="terms-group">
        <CheckBox checked={allChecked} onChange={onChangeAllChecked} className="allchecked-items">
          <div>
            <div>{allCheckComment}</div>
          </div>
        </CheckBox>
        <div className="break-bar"></div>
        <TermsList>
          {getValues('agreements')?.map((agreement, i) => {
            return (
              <TermsItem key={agreement.id}>
                <ControlledCheckbox className="check-img-wrapper" key={agreement.id} checkType="empty" control={control} name={`agreements.${i}.value`} onChange={onChange}>
                  <div className="items">
                    {agreement.content.KO.name}&nbsp;
                    {agreement.required ? '(필수)' : '(선택)'}
                  </div>
                  {agreement.content.KO.contentUrl ? (
                    <div
                      className="right-items"
                      onClick={() => {
                        if (agreement.content.KO.contentUrl) {
                          showServiceTerm(agreement.type);
                        }
                      }}
                    >
                      &nbsp;
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 12 12" fill="none">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M4.55893 0.558105L3.67505 1.44199L8.23311 6.00005L3.67505 10.5581L4.55893 11.442L10.0009 6.00005L4.55893 0.558105Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  ) : (
                    ''
                  )}
                </ControlledCheckbox>
              </TermsItem>
            );
          })}
        </TermsList>
      </div>
      <PolicyModalPortal>
        <PolicyModal openServiceTerm={openServiceTerm} url={currentPolicy}></PolicyModal>
      </PolicyModalPortal>
    </TermsGroupContainer>
  );
};

export default TermsGroup;
