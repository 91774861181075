import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as BenefitExpress } from '@/assets/img/membership_cancel_express.svg';
import Button from '@/components/Button/Button';
import { STAY_YOLK_MEMBERSHIP } from '@/components/GlobalToastWrapper/GlobalToastWrapper.constants';
import { useMembershipCancel } from '@/hooks/useFetchCancelUserMembership';
import { MembershipCancelContainer } from '@/shared/styles/pages/membership/Cancel';
import { useFooterStore } from '@/stores/common/useFooterStore';
import { useHeaderStore } from '@/stores/common/useHeaderStore';
import { useModalStore } from '@/stores/common/useModalStore';
import { useNavigationStore } from '@/stores/common/useNavigationStore';

interface IMembershipCancelDel {
  response: any;
}

const MembershipCancelPage = (): React.ReactElement => {
  const setIsFooterVisible = useFooterStore((store) => store.setIsFooterVisible);
  const setIsNavigationVisible = useNavigationStore((store) => store.setIsNavigationVisible);
  const setHeaderState = useHeaderStore((store) => store.setHeaderState);
  const navigate = useNavigate();
  const { mutate } = useMembershipCancel();
  const { setToastList, toastList } = useModalStore();

  useEffect(() => {
    setIsFooterVisible(false);
    setIsNavigationVisible(false);
    setHeaderState({
      title: '멤버십 해지',
      handleGoToBack: () => navigate(-1),
      handleGoToHome: null,
      handleOnAlarm: null,
      handleOnClose: null,
      handleOnShare: null,
    });
  }, []);

  return (
    <MembershipCancelContainer>
      <div className="cancel-waring">
        <svg xmlns="http://www.w3.org/2000/svg" width="86" height="84" viewBox="0 0 33 32" fill="none">
          <g clip-path="url(#clip0_441_14701)">
            <path
              d="M16.5 0C7.66429 0 0.5 7.16429 0.5 16C0.5 24.8357 7.66429 32 16.5 32C25.3357 32 32.5 24.8357 32.5 16C32.5 7.16429 25.3357 0 16.5 0ZM15.3571 8.28572C15.3571 8.12857 15.4857 8 15.6429 8H17.3571C17.5143 8 17.6429 8.12857 17.6429 8.28572V18C17.6429 18.1571 17.5143 18.2857 17.3571 18.2857H15.6429C15.4857 18.2857 15.3571 18.1571 15.3571 18V8.28572ZM16.5 24C16.0514 23.9908 15.6243 23.8062 15.3103 23.4857C14.9962 23.1652 14.8204 22.7344 14.8204 22.2857C14.8204 21.837 14.9962 21.4062 15.3103 21.0857C15.6243 20.7652 16.0514 20.5806 16.5 20.5714C16.9486 20.5806 17.3757 20.7652 17.6898 21.0857C18.0038 21.4062 18.1796 21.837 18.1796 22.2857C18.1796 22.7344 18.0038 23.1652 17.6898 23.4857C17.3757 23.8062 16.9486 23.9908 16.5 24Z"
              fill="#FF7C75"
            />
          </g>
          <defs>
            <clipPath id="clip0_441_14701">
              <rect width="32" height="32" fill="white" transform="translate(0.5)" />
            </clipPath>
          </defs>
        </svg>
        <h4>
          지금 해지하면 아래의 혜택을
          <br />더 이상 누릴 수 없게 됩니다.
        </h4>
        <p>
          <svg xmlns="http://www.w3.org/2000/svg" width="8" height="16" viewBox="0 0 4 18" fill="none">
            <circle cx="2" cy="8.5" r="1.5" fill="white" />
          </svg>
          &nbsp;우선 예약을 할 수 있는 권리, 익스프레스 예약 불가
        </p>
        <p>
          <svg xmlns="http://www.w3.org/2000/svg" width="8" height="18" viewBox="0 0 4 18" fill="none">
            <circle cx="2" cy="8.5" r="1.5" fill="white" />
          </svg>
          &nbsp;다양한 미식 이벤트 및 콜라보 다이닝 예약 불가
        </p>
      </div>
      <div className="membership-cancel-component">
        <div className="bottom-section">
          <div>그래도 해지하시겠습니까 ?</div>
          <Button
            colorLevel="primary"
            type="extra-large"
            onClick={() => {
              setToastList([...toastList, { visible: true, key: STAY_YOLK_MEMBERSHIP }]);
              navigate('/mypage/membership');
            }}
          >
            아니요, 다이닝 클럽 멤버를 유지할게요
          </Button>
          <Button colorLevel="white" type="extra-large" onClick={mutate}>
            네, 해지할게요
          </Button>
        </div>
      </div>
      {/*
      <div className="membership-cancel-component">
        <div className="description">
          지금 해지하면 예약 오픈이 <br />
          훨씬 늦어져요
        </div>
        <div className="middle-section">
          <div className="benefit-img">
            <BenefitExpress />
          </div>
        </div>
        <div className="bottom-section">
          <div>그래도 해지하시겠어요?</div>
          <Button
            colorLevel="primary"
            type="extra-large"
            onClick={() => {
              setToastList([...toastList, { visible: true, key: STAY_YOLK_MEMBERSHIP }]);
              navigate('/mypage/membership');
            }}
          >
            아니요, 더 써볼게요
          </Button>
          <Button colorLevel="secondary" type="extra-large" onClick={mutate}>
            네, 해지할게요
          </Button>
        </div>
      </div>*/}
    </MembershipCancelContainer>
  );
};

export default MembershipCancelPage;
