import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { IGetRaffleData } from '../Raffle.types';

import { eggdiningApi } from '@/shared/apis/eggdiningApi';
import RAFFLE_QUERY_KEYS from '@/shared/apis/queryKeys/raffle';
import { useFooterStore } from '@/stores/common/useFooterStore';
import { useHeaderStore } from '@/stores/common/useHeaderStore';
import { useNavigationStore } from '@/stores/common/useNavigationStore';

const useInitApply = () => {
  const setIsFooterVisible = useFooterStore((store) => store.setIsFooterVisible);
  const setIsNavigationVisible = useNavigationStore((store) => store.setIsNavigationVisible);
  const setHeaderState = useHeaderStore((store) => store.setHeaderState);
  const navigate = useNavigate();

  useEffect(() => {
    setIsFooterVisible(false);
    setIsNavigationVisible(false);
    setHeaderState({
      title: '응모내역 확인',
      handleGoToBack: null,
      handleGoToHome: null,
      handleOnAlarm: null,
      handleOnClose: () => navigate(-1),
      handleOnShare: null,
    });
  }, []);
};

const useApplyHookform = () => {
  // const { data: raffleData, isLoading } = useQuery('WQEWQEQWEQWEQWEQW', () => eggdiningApi.get<IGetRaffleData>('/eggdining/raffles/1'), {});
  // const validationSchema = () => {
  //   return yup.object({
  //     agreements: yup.array(
  //       yup.object({
  //         required: yup.boolean(),
  //         value: yup.boolean().when('required', {
  //           is: (required) => required,
  //           then: yup.boolean().test('AGREEMENT_VALUE', '인증 ', (value) => {
  //             return value;
  //           }),
  //         }),
  //       })
  //     ),
  //     schedule: yup.array().test({
  //       message: '방문 일자를 하나 이상 선택해 주세요',
  //       test: (objectList) => {
  //         console.log(objectList, 'asdlkfjaklsdfjlaksdfjlksadfjlksadjfkldsafjkls');
  //         objectList.filter((x) => {
  //           return x.value;
  //         });
  //         const test = objectList.filter((x) => x.value).length;
  //         return test > 0;
  //       },
  //     }),
  //   });
  // };
  // const methods = useForm({
  //   resolver: yupResolver(validationSchema()),
  //   defaultValues: {
  //     agreements: [],
  //     schedule: [],
  //   },
  //   mode: 'onChange',
  // });
  // useEffect(() => {
  //   const settingScheduls =
  //     raffleData &&
  //     raffleData.raffle.items.map((item) => {
  //       return {
  //         ...item,
  //         value: false,
  //       };
  //     });
  //   const settingAgreement = servicePolicies.map((x) => {
  //     return {
  //       ...x,
  //       value: false,
  //     };
  //   });
  //   reset({ ...getValues(), agreements: settingAgreement, schedule: settingScheduls });
  // }, [raffleData, servicePolicies]);
  // return methods;
};

const raffleInfoQueryWrapper = () => {
  const [searchParams] = useSearchParams();
  const eventType = searchParams.get('eventType');

  const usePostRaffleEntryMutation = () => {
    return useMutation((value: number[]) => usePostRaffleEntryMutation.fetcher(value), {});
  };

  usePostRaffleEntryMutation.fetcher = (value) => {
    return eggdiningApi.post(`/eggdining/raffles/${eventType}/entry`, { itemIds: value });
  };

  const useGetRaffleSlotQuery = () => {
    return useQuery(useGetRaffleSlotQuery.getQueryKey(), () => useGetRaffleSlotQuery.getRaffleSlot());
  };
  useGetRaffleSlotQuery.getQueryKey = () => RAFFLE_QUERY_KEYS.GET_RAFFLE_SLOT_LIST;
  useGetRaffleSlotQuery.getRaffleSlot = () => {
    return eggdiningApi.get<IGetRaffleData>(`/eggdining/raffles/${eventType}`);
  };
  return {
    useGetRaffleSlotQuery,
    usePostRaffleEntryMutation,
  };
};
export { useInitApply, useApplyHookform, raffleInfoQueryWrapper };
