import React from 'react';

import styled from '@emotion/styled';

export const Subtitle = styled.footer<React.CSSProperties>`
  /* margin-bottom: 24px; */
  .firebrick {
    color: white;
  }
`;
