import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { SOCIAL_LOGIN } from '../GlobalToastWrapper/GlobalToastWrapper.constants';
import { useKakaoSignupMutation } from './LoginCallback.hooks';

import { LoginCallbackContainer } from '@/components/LoginCallback/LoginCallback.styles';
import { useUserProfileQuery } from '@/components/MyPage/Profile/Profile.hooks';
import { LocalStorage } from '@/shared/configs/storage';
import { useModalStore } from '@/stores/common/useModalStore';
import { useUserProfileStore } from '@/stores/useUserProfle';

const LoginCallback = (): React.ReactElement => {
  const navigate = useNavigate();
  const { setMemberId, phone } = useUserProfileStore();
  const { setToastList, toastList } = useModalStore();
  const [searchParams] = useSearchParams();
  const { mutateAsync: signUpKakao } = useKakaoSignupMutation();
  const { data: userProfile, isLoading: userProfileLoading } = useUserProfileQuery();

  useEffect(() => {
    const accessToken = searchParams.get('accessToken');
    const loginToken = searchParams.get('loginToken');
    const memberId = searchParams.get('memberId');
    const code = searchParams.get('code');
    const provider = searchParams.get('provider');
    const name = searchParams.get('name');

    const splitOauth = async () => {
      if (code === 'PHONE_AUTH_REQUIRED') {
        LocalStorage.setItem('accessToken', accessToken);
        if (name) {
          LocalStorage.setItem('signInName', name);
        }
        navigate('/auth/identity');
      } else if (code === 'SIGN_UP_REQUIRED') {
        LocalStorage.setItem('accessToken', accessToken);
        await signUpKakao();
        setToastList([...toastList, { visible: true, key: SOCIAL_LOGIN }]);
        navigate('/main');
      } else if (code === 'SIGN_IN') {
        setMemberId(memberId);
        LocalStorage.setItem('memberId', memberId);
        LocalStorage.setItem('jwtToken', loginToken);
        LocalStorage.setItem('provider', provider);
        setToastList([...toastList, { visible: true, key: SOCIAL_LOGIN }]);
      } else if (code === 'UNAVAILABLE_PROVIDER') {
        alert('로그인이 불가능한 계정 입니다.');
        navigate('/');
      } else {
        navigate('/error', {
          replace: true,
        });
      }
    };
    splitOauth();
  }, []);

  useEffect(() => {
    if (userProfile) {
      navigate('/main');
    }
  }, [userProfile]);
  return <LoginCallbackContainer></LoginCallbackContainer>;
};

export default LoginCallback;
