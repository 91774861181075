import create from 'zustand';

import { LocalStorage } from '@/shared/configs/storage';

export interface IUserInfoStore {
  provider: string;
  setProvider: () => void;
}

export const useUserInfoStore = create<IUserInfoStore>((set) => ({
  provider: LocalStorage.getItem('provider') || LocalStorage.getItem('social'),
  setProvider: () => set(() => ({ provider: LocalStorage.getItem('provider') })),
}));
